import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import Header from '../Header'
import Sidebar from '../Sidebar';
import axios from 'axios';
import { OverlayTrigger } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Spinner } from 'react-bootstrap';
import { Modal, Button, Col } from "react-bootstrap";
import { Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Banner from '../../../components/utils/Banner';
import format from 'date-fns/format';
import QRCodeGenerator from './QRCodeGenerator';
import { handleBack } from '../../../services/Functions';

export default function FormTerceros() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const pathParams = useParams();
    const idCalculation = pathParams.id;
    const [showNewEdit, setShowNewEdit] = useState(false)
    const [showQR, setShowQR] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [name, setName] = useState('');
    const [questionsIds, setQuestionsIds] = useState('');
    const [externalId, setExternalId] = useState('');

    const [msg, setMsg] = useState({
        type: '',
        message: '',
        open: false,
    });

    const [externalQuest, setExternalQuest] = useState({
        data: [],
        isFetching: false
    });

    const [questions, setQuestions] = useState({
        data: [],
        isFetching: false
    });

    const fetchQuestions = async () => {
        setQuestions({ data: questions.data, isFetching: true });
        try {
            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questions?userId=${UserService.getUserId()}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setQuestions({ data: response.data, isFetching: false });
        } catch (exception) {
            setQuestions({ data: [], isFetching: false });
        }
    };

    const fetchExternalQuest = async () => {
        setExternalQuest({ data: externalQuest.data, isFetching: true });
        try {
            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/${idCalculation}/questionnaires`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setExternalQuest({ data: response.data, isFetching: false });
        } catch (exception) {
            setExternalQuest({ data: [], isFetching: false });
        }
    };

    useEffect(() => {
        fetchExternalQuest();
        fetchQuestions();
    }, [])



    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            if (!items || items.length === 0) {
                return [];
            }
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    let valueA = a[sortConfig.key];
                    let valueB = b[sortConfig.key];

                    if (sortConfig.key.includes(".")) {
                        let params = sortConfig.key.split(".");
                        valueA = a[params[0]]?.[params[1]];
                        valueB = b[params[0]]?.[params[1]];
                    }

                    if (valueA === null || valueA === undefined || valueA === "") {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }

                    if (valueB === null || valueB === undefined || valueB === "") {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }

                    if (!isNaN(valueA) || moment(valueA).isValid()) {
                        if (valueA < valueB) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (valueA > valueB) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    } else {
                        if (
                            valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (
                            valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };
        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(externalQuest.data.data);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const renderTooltipQuestion = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.question")}
        </Tooltip>
    );

    const renderTooltipAnswer = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.answers")}
        </Tooltip>
    );

    const renderTooltipsendEmail = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.sendEmail")}
        </Tooltip>
    );

    const renderTooltipQr = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.qr")}
        </Tooltip>
    );
    const renderTooltipnoHabilitada = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.noHabilitada")}
        </Tooltip>
    );

  
    const handleClose = () => {
        setShowNewEdit(false)
        setName('')
        setExternalId('')
        setQuestionsIds('')
        setMsg({
            type: '',
            message: '',
            open: false
        })
        setButtonDisabled(false)
    }

    const handleShowQuestion = (x) => {
        setShowNewEdit(true)
        setQuestionsIds(x.externalQuestionsDto.map((x) => (x.id)))
        setName(x.name)
        setExternalId(x.id)
    }

    const handleCheckboxChange = (e, questionId) => {
        const checked = e.target.checked;
        setQuestionsIds((prevQuestionIds) => {
            if (checked) {
                return [...prevQuestionIds, questionId];
            } else {
                return prevQuestionIds.filter((id) => id !== questionId);
            }
        });
    };

    const location = useLocation();
    const dataCalculation = location.state?.data;

    if (location.state == undefined) {
        window.location.href = "/footprint/myfootprint";
    }

    const handleSubmitNew = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);

        const url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/${idCalculation}/questionnaire`;
        const data = {
            "name": name,
            "externalQuestionIds": questionsIds,
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await fetchExternalQuest()
            setMsg({ type: "success", message: t("fpThird.form-ok") });
        } catch (error) {
            console.error('Error:', error.message);
            setMsg({ type: "error", message: t("fpThird.form-ko") });
        }
    }

    const handleSubmitPut = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);

        const url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire`;
        const data = {
            "name": name,
            "externalQuestionIds": questionsIds,
            "id": externalId
        };

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await fetchExternalQuest()
            setMsg({ type: "success", message: t("fpThird.update-ok") });
        } catch (error) {
            console.error('Error:', error.message);
            setMsg({ type: "error", message: t("fpThird.update-ko") });
        }
    }

    const [urlQR, setUrlQR] = useState({
        data: [],
        isFetching: false
    });

    const fetchUrlQr = async (id) => {
        setUrlQR({ data: urlQR.data, isFetching: true });
        try {
            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${id}/url?organizationName=${dataCalculation.orgName}&calculationYear=${dataCalculation.year}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setUrlQR({ data: response.data, isFetching: false });
        } catch (exception) {
            setUrlQR({ data: [], isFetching: false });
        }
    };

    const handleShowQR = (x) => {
        setShowQR(true)
        setExternalId(x.id)
        fetchUrlQr(x.id);
    }

    const handleCloseQR = () => {
        setShowQR(false)
        setExternalId('')
    }



    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <button arial-label="btn-saveFom" className="ml-4 mt-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                    </svg>
                    <span>{t("footPrint.volver")}</span>
                </button>

                <Modal show={showNewEdit}>
                    <Modal.Header className="sec-green-background">
                        <Modal.Title className="text-white">{t("fpThird.formContacts")}</Modal.Title>
                        <div className="flex justify-end">
                            <button onClick={() => handleClose()}>
                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {msg.type !== "" && <Banner type={msg.type} open setOpen={true}>{msg.message}</Banner>}
                        <Form className='text-xs' onSubmit={(e) => { externalId ? handleSubmitPut(e) : handleSubmitNew(e) }}>
                            <Col col="6">
                                <Label className='font-bold' for="name">{t("fpThird.expFormTerceros")}</Label>
                                <FormGroup>
                                    <Label className='font-bold' for="name">{t("fpThird.name")}</Label>
                                    <Input className='text-xs' type="text" name="name" id="name" defaultValue={name} onChange={(e) => { setName(e.target.value) }} required>
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col col="6">
                                <FormGroup>
                                    <Label className='font-bold' for="questionsType">{t("fpThird.question")}</Label>
                                    {questions.isFetching === false && questions?.data?.data?.length > 0 &&
                                        questions.data?.data
                                            ?.map((question) => (
                                                <div key={question.id} className="form-check">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="forestType"
                                                        id={`questionCheckbox_${question.id}`}
                                                        value={question.id}
                                                        defaultChecked={questionsIds.includes(question.id)}
                                                        onChange={(e) => handleCheckboxChange(e, question.id)}
                                                    />
                                                    <Label className="form-check-label" for={`questionCheckbox_${question.id}`}>
                                                        {question.name}
                                                    </Label>
                                                </div>
                                                // )
                                            ))}
                                </FormGroup>
                            </Col>

                            <Button className="btn-green mr-4 rounded-lg px-4 py-2" type="submit" disabled={buttonDisabled || questionsIds.length == 0}>
                                {externalId ?
                                    t("fpThird.actualizar")
                                    :
                                    t("fpThird.guardar")
                                }
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={showQR}>
                    <Modal.Header className="sec-green-background">
                        <Modal.Title className="text-white">
                            {t("fpThird.calculo")} {dataCalculation.year}
                        </Modal.Title>
                        <div className="flex justify-end">
                            <button onClick={() => handleCloseQR()}>
                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <span className='flex justify-between mb-4'>
                            <span className=''>
                                {t("fpThird.organizacion")}:{dataCalculation.orgName}
                            </span>
                            <span className=''>
                                {t("fpThird.estado")}:{dataCalculation.status}
                            </span>
                        </span>
                        <QRCodeGenerator
                            url={urlQR.data.data}
                        ></QRCodeGenerator>
                    </Modal.Body>
                </Modal>

                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">



                    {!dataCalculation.thirdPartyForm &&
                        <div>
                            <h1 className="text-2xl md:text-3xl font-bold">
                                {t("fpThird.formContacts")}
                            </h1>

                            <div className='m-4'>
                                <div className='flex flex-row items-center justify-center '>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-barrier-block" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M4 7m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v7a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
                                        <path d="M7 16v4" />
                                        <path d="M7.5 16l9 -9" />
                                        <path d="M13.5 16l6.5 -6.5" />
                                        <path d="M4 13.5l6.5 -6.5" />
                                        <path d="M17 16v4" />
                                        <path d="M5 20h4" />
                                        <path d="M15 20h4" />
                                        <path d="M17 7v-2" />
                                        <path d="M7 7v-2" />
                                    </svg>
                                    <h1 className="text-xl md:text-2xl font-bold text-center ">{t("fpThird.proximamente")}</h1>
                                </div>
                                <p className='text-center mb-4'>{t("fpThird.descripcionTerceros")}</p>
                                <div className='flex justify-center mt-4'>
                                    <button className='btn btn-green rounded-lg text-white' onClick={() => window.history.back()}>{t("footPrint.volver")}</button>
                                </div>
                            </div>
                        </div>
                    }

                    {dataCalculation.thirdPartyForm &&
                        <div>
                            <div className="mb-4 text-gray-800">
                                <div className='flex justify-between flex-wrap'>
                                    <h1 className="text-2xl md:text-3xl font-bold">
                                        {t("fpThird.formContacts")}
                                    </h1>

                                    <button onClick={() => setShowNewEdit(true)} className='btn-green mr-4 rounded-lg px-4 py-2' >
                                        {t("fpThird.nuevoForm")}
                                    </button>
                                </div>

                                <p className='mt-1'>
                                    {t("fpThird.exp")}
                                </p>
                                <p className='mt-1'>
                                    {t("fpThird.exp2")}
                                </p>
                            </div>


                            <div className='text-lg flex gap-2 font-bold justify-center mb-4'>
                                <span>{dataCalculation?.orgName}</span> -
                                <span>{dataCalculation?.year}</span>
                            </div>

                            <div className="overflow-x-auto w-full">
                                <table className="table-striped table-bordered table-auto w-full bg-white ">
                                    <thead className='text-center  text-xl sec-green-background text-white'>
                                        <tr className='py-4'>
                                            <th>
                                                <button type="button" onClick={() => requestSort('fechaCreacion')} className={`${getClassNamesFor('fechaCreacion')} text-underline`}>
                                                    {t("fpThird.fechaCreacion")}
                                                </button>
                                            </th>
                                            <th>
                                                <button type="button" onClick={() => requestSort('nombre')} className={`${getClassNamesFor('nombre')} text-underline`}>
                                                    {t("fpThird.name")}
                                                </button>
                                            </th>
                                            <th>
                                                <button type="button" onClick={() => requestSort('question')} className={`${getClassNamesFor('question')} text-underline`}>
                                                    {t("fpThird.question")}
                                                </button>
                                            </th>
                                            <th>
                                                <button type="button" onClick={() => requestSort('answers')} className={`${getClassNamesFor('answers')} text-underline`}>
                                                    {t("fpThird.answers")}
                                                </button>
                                            </th>
                                            <th> {t("fpThird.acciones")}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center text-md'>
                                        {externalQuest.isFetching ? (
                                            <tr className='flex justify-center'>
                                                <Spinner animation="border" variant="dark" />
                                            </tr>
                                        ) : (
                                            externalQuest.data && externalQuest.data.data ? (
                                                externalQuest.data.data.length > 0 ? (
                                                    items.map((x) => (
                                                        <tr key={x.id}>
                                                            <td>{x.creationDate ? format(new Date(x.creationDate), 'dd/MM/yyyy') : ''}</td>
                                                            <td>{x.name}</td>
                                                            <td>{x.externalQuestionsDto.length}</td>
                                                            <td>{x.validatedAnswers + "/" + x.totalAnswers}</td>
                                                            <td>
                                                                <div className='flex items-center justify-center gap-2'>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderTooltipQuestion}
                                                                    >
                                                                        <button onClick={() => handleShowQuestion(x)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-question" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M8 9h8" />
                                                                                <path d="M8 13h6" />
                                                                                <path d="M14 18h-1l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" />
                                                                                <path d="M19 22v.01" />
                                                                                <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" />
                                                                            </svg>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderTooltipAnswer}
                                                                    >
                                                                        <button onClick={() => navigate(`/footprint/externalanswers/${x.id}`, { state: { data: dataCalculation } })}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-dots" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
                                                                                <path d="M12 11l0 .01" />
                                                                                <path d="M8 11l0 .01" />
                                                                                <path d="M16 11l0 .01" />
                                                                            </svg>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={dataCalculation.status === 'FINALIZED'? renderTooltipnoHabilitada: renderTooltipsendEmail}
                                                                      
                                                                    >
                                                                        <button   disabled={dataCalculation.status === 'FINALIZED'? true:false} onClick={() => navigate(`/footprint/sendForm/${x.id}`, { state: { data: dataCalculation } })}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail-forward" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
                                                                                <path d="M3 6l9 6l9 -6" />
                                                                                <path d="M15 18h6" />
                                                                                <path d="M18 15l3 3l-3 3" />
                                                                            </svg>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={dataCalculation.status === 'FINALIZED'? renderTooltipnoHabilitada: renderTooltipQr}
                                                                    >
                                                                        <button onClick={() => handleShowQR(x)}  disabled={dataCalculation.status === 'FINALIZED'? true:false}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-qrcode" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                                                                <path d="M7 17l0 .01" />
                                                                                <path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                                                                <path d="M7 7l0 .01" />
                                                                                <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                                                                <path d="M17 7l0 .01" />
                                                                                <path d="M14 14l3 0" />
                                                                                <path d="M20 14l0 .01" />
                                                                                <path d="M14 14l0 3" />
                                                                                <path d="M14 20l3 0" />
                                                                                <path d="M17 17l3 0" />
                                                                                <path d="M20 17l0 3" />
                                                                            </svg>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">{t("fpThird.noHayFormsCreados")}</td>
                                                    </tr>
                                                )
                                            ) : null
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>


        </div>
    )
}
