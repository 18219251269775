import React from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator({url}) {

  const data = url // El dato que quieres codificar en el QR

  const downloadQRCode = () => {
    const canvas = document.getElementById('qr-code'); // Obtén el elemento canvas del código QR
    const url = canvas.toDataURL('image/png'); // Convierte el contenido del canvas a una URL de datos (data URL)
    const link = document.createElement('a'); // Crea un elemento <a> para descargar
    link.href = url; // Establece la URL de descarga
    link.download = 'codigo_qr.png'; // Nombre del archivo a descargar
    link.click(); // Simula un click en el enlace para iniciar la descarga
  };

  const printQRCode = () => {
    const canvas = document.getElementById('qr-code'); // Obtén el elemento canvas del código QR
    const url = canvas.toDataURL('image/png'); // Convierte el contenido del canvas a una URL de datos (data URL)

    const windowContent = `<img src="${url}">`;
    const printWindow = window.open('', '', 'width=600,height=600');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Código QR</title>
        </head>
        <body>
          ${windowContent}
        </body>
      </html>`
    );
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className=' '>
      <div className='flex  flex-col items-center gap-4'>
      {/* <h1>Generador e Impresor de Código QR</h1> */}
      <QRCode id="qr-code" value={data} />

      </div>
    
      <div className='flex flex-wrap justify-center mt-4'>
      <button className='btn-green mr-4 rounded-lg px-4 py-2' onClick={downloadQRCode}>Descargar</button>
      <button className='btn-green mr-4 rounded-lg px-4 py-2' onClick={printQRCode}>Imprimir</button>

      </div>
   
    </div>
  );
}

export default QRCodeGenerator;