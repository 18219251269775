
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext"

import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.css';
import 'swiper/modules/a11y.css'
import 'swiper/modules/pagination.css';
import 'swiper/modules/scrollbar.css';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation.scss'; // Navigation module
import 'swiper/modules/pagination.scss'; // Pagination module
import valladolid from '../../../../images/valladolid.png'
import coverwind from '../../../../images/coverwind.png'
import frutas from '../../../../images/frutas-nieves.png'
import ptmar from '../../../../images/pereztorres.png'
import atlas from '../../../../images/atlas.png'
import ontime from '../../../../images/ontime.png'
import coolberries from '../../../../images/coolfarming2.png'
import befla from '../../../../images/befla.png'

export default function BloqueEmpresas() {
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  let images = [coolberries, ptmar, ontime, valladolid, frutas, coverwind, atlas, befla]
  return (



    <>
      <div className="block md:hidden">
        <Swiper
          slidesPerView={3}
          spaceBetween={50}

          modules={[Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            loop: true
          }}

          className="mySwiper"
          loop={true}
        >
          {images.map((imageUrl, index) => (
            <SwiperSlide key={index}>
              <img src={imageUrl} alt={`Slide ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="hidden md:block">
        <Swiper
          slidesPerView={5}
          spaceBetween={50}

          modules={[Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            loop: true
          }}

          className="mySwiper"
          loop={true}
        >
          {images.map((imageUrl, index) => (
            <SwiperSlide key={index} className='flex'>
              <img src={imageUrl} alt={`Slide ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>



  )
}
