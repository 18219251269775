const preguntas = [
  {
    titulo: "¿Crees que enviar un correo electrónico tiene impacto ambiental?",
    imagen: "  https://www.airco2.earth/arbol.svg",
    opciones: [
      { textoRespuesta: "Sí", isCorrect: true },
      { textoRespuesta: "No", isCorrect: false },
      { textoRespuesta: "Depende", isCorrect: false },
    ],
    respuesta: "Sí, la contaminación vinculada a los correos electrónicos se debe al almacenamiento de los " +
      "mensajes que requieren los servidores, ya que cada correo electrónico se almacena en tres " +
      "copias y así en al menos tres servidores diferentes por razones de seguridad. <br/>" +
      "Tu buzón de correo tiene un impacto y para reducirlo debes limpiarlo regularmente " +
      "deshaciéndote de correos y boletines innecesarios para evitar el almacenamiento innecesario " +
      "en los centros de datos."
  },
  {
    titulo: "¿Qué países emiten más CO₂?",
    imagen: "https://www.airco2.earth/wid2.svg",
    opciones: [
      { textoRespuesta: "Estados Unidos, China y Brasil", isCorrect: false },
      { textoRespuesta: "Estados Unidos, Rusia y España", isCorrect: false },
      { textoRespuesta: "Estados Unidos, China y Rusia", isCorrect: true },
      { textoRespuesta: "China, Rusia y Alemania", isCorrect: false },
    ],
    respuesta: "Los países que más CO₂ han emitido históricamente son: <b>Estados Unidos, China y Rusia</b>. Así lo " +
      "muestra este gráfico realizado por La Marea según los datos del medio británico Carbon Brief. <br/> <div style='background-color:white'>" +
      "<iframe scrolling='no' frameborder='0' marginheight='0px' marginwidth='0px' style='position: relative; width: 100%;' src='https://cbhighcharts2021.s3.eu-west-2.amazonaws.com/climate-justice/cumulative-emissions/cumulative-emissions.html' width='770px' height='550px'></iframe><span style='display:block; height:22px; max-width:770px;'><a href='https://www.carbonbrief.org' target='_blank'><img src='https://s3.eu-west-2.amazonaws.com/cbhighcharts2019/cb-logo-highcharts.svg' style='width: 22px; height: 22px; margin-top: 2px; margin-bottom: 2px; float:right; background-repeat: no-repeat; background-size: contain;'/></span>"
  },
  {
    titulo: "¿Cuáles son los gases de efecto invernadero?",
    imagen: "https://www.airco2.earth/wid1.svg",
    opciones: [
      { textoRespuesta: "C0₂", isCorrect: false },
      { textoRespuesta: "CO₂, CH₄, N₂O", isCorrect: true },
      { textoRespuesta: "CO₂ y H₂0", isCorrect: false },
      { textoRespuesta: "Ninguno", isCorrect: false },
    ],
    respuesta:"Los gases de efecto invernadero directo que se estiman en el Inventario son: dióxido de carbono (CO₂), "+
    "metano (CH₄), óxido nitroso (N₂O), principalmente, y hidrofluorocarburos (HFC), perfluorocarburos (PFC) y hexafluoruro de "+
    "azufre (SF₆)"
  },
  {
    titulo: "Enviar 65 mensajes de correo electrónico equivale aproximadamente a conducir un kilómetro en coche. "+
    "¿Cuántos correos crees que se envían diariamente? ",
    imagen: "https://www.airco2.earth/wid3.svg",
    opciones: [
      { textoRespuesta: "100.000 millones", isCorrect: false },
      { textoRespuesta: "190.000 millones", isCorrect: false },
      { textoRespuesta: "280.000 millones", isCorrect: true },
      { textoRespuesta: "350.000 millones", isCorrect: false },
    ],
    respuesta:" Cada día se envían unos 280.000 millones"
  },
  {
    titulo: "¿Cuánto CO₂ emite una persona de media en España al año? ",
    imagen: "https://www.airco2.earth/wid4.svg",
    opciones: [
      { textoRespuesta: "4,35 toneladas", isCorrect: false },
      { textoRespuesta: "6,46 toneladas", isCorrect: false },
      { textoRespuesta: "7,15 toneladas", isCorrect: true },
      { textoRespuesta: "8,58 toneladas", isCorrect: false },
    ],
    respuesta:" En España una persona emite una media de 7,15 toneladas de CO₂ al año, aunque esto varía mucho según el estilo de vida de cada persona: "+
    " alimentación, transporte, vivienda, etc."
  },
];

export default preguntas;