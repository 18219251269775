import React, { useState } from 'react';
//import useScript from './hooks/useScript';


export const Hubspot = () => {

    // useScript(`(function (h, o, t, j, a, r) {
    //     h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    //     h._hjSettings = { hjid: 3041169, hjsv: 6 };
    //     a = o.getElementsByTagName('head')[0];
    //     r = o.createElement('script'); r.async = 1;
    //     r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    //     a.appendChild(r);
    // })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    // `);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault(); // prevent form submit default behavior
        if (!name || !email) return; // if an input field is empty, don't submit the form
        const hubspot_response = await submit_hubspot_form(email, name);
    }

    const submit_hubspot_form = async (email, firstname) => {
        const portalId = '25435066'; // example portal ID (not real)
        const formGuid = '1cdc76f8-507f-4298-b305-329a223c2fa9'; // example form GUID (not real)
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`
        const data = {
            fields: [
                
                {
                    name: 'email',
                    value: email
                },
                {
                    name: 'firstname',
                    value: firstname
                }
            ]
        };



        return fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((responseJson) => {
                return responseJson;
            });

    }

    return (
        <div style={{ padding: '16px' }}>
            <h1>HubSpot Test Form</h1>
            <form onSubmit={handleSubmit}>
                name
                <input
                    name='firstname'
                    type='text'
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                mail
                <input
                    name='email'
                    type='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <input type="submit" value="Submit" />
            </form>
        </div>
    )
}
