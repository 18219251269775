import axios from "axios";
import 'chartjs-adapter-moment';
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import UserService from "../../../../services/UserService";

export default function ReductionCommitmentCreateModal({ showModal, hanldeShow, handleAddReductionCommitment }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [show, setShow] = useState(false);
    const [response, setResponse] = useState(undefined);
    const [spinner, setSpinner] = useState(false);
    const [reductionCommitmentTypes, setReductionCommitmentTypes] = useState(undefined);

    useEffect(() => {
        if (showModal !== undefined) {
            setShow(showModal.view);
        }
        if (showModal.questionId !== undefined) {
            setFormData({
                ...formData,
                questionId: showModal.questionId
            })
        }
    }, [showModal]);


    useEffect(() => {
        const getCommitmentsReductionType = async () => {
            const url = `${process.env.REACT_APP_CH_REDUCTION}/types`;

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });
                setReductionCommitmentTypes(response?.data?.data);
            } catch (exception) {
                console.error(exception);
            }
        };

        getCommitmentsReductionType();
    }, []);


    const [formData, setFormData] = useState({
        questionId: '',
        name: '',
        description: '',
        reductionCommitmentType: '',
        averagePercentageReduction: '',
        averageImplementationCost: '',
        active: true
    });



    const handleOnSubmit = (event) => {
        event.preventDefault();
        createReducctionCommitmentPost(formData);
    }

    const handleClose = () => {
        setShow(false);
        hanldeShow(false);
        setResponse(undefined);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };


    const createReducctionCommitmentPost = async (data) => {
        setSpinner(true);
        const url = `${process.env.REACT_APP_CH_REDUCTION_COMMITMENT}`;

        try {
            const response = await axios.post(`${url}`, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            });
            setResponse(response?.data);
            handleAddReductionCommitment(response.data.data);
        } catch (exception) {
            console.error(exception);
        }
        setSpinner(false);
    };

    return (
        <Modal show={show} animation={true}>
            <Modal.Header className="sec-green-background">
                <Modal.Title className="text-white"> {t("decarbonization.reduction.title")}</Modal.Title>
                <div className="flex justify-end">
                    <button onClick={() => handleClose()}>
                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {response !== undefined && <>
                        {response.success && <p className="text-center">{t("decarbonization.reduction.createOK")}</p>}
                        {!response.success && <p className="text-center">{t("decarbonization.reduction.createKO")}</p>}
                    </>}


                    {response === undefined &&
                        <form onSubmit={handleOnSubmit}>
                            <div className="grid gap-4 md:grid-cols-2">
                                <div>
                                    <label className="block text-xs font-medium mb-1" htmlFor="name">{t("decarbonization.reduction.name")} <span className="text-red-500">*</span></label>
                                    <input id="name" name="name" className="w-full text-sm" type="text" required placeholder={t("decarbonization.reduction.name")}
                                        value={formData.name}
                                        onChange={handleChange} />
                                </div>

                                <div>
                                    <label className="block text-xs font-medium mb-1" htmlFor="reductionCommitmentType">{t("decarbonization.reduction.type")} <span className="text-red-500">*</span></label>
                                    <select id="reductionCommitmentType" name="reductionCommitmentType" className="w-full text-sm" type="text" required
                                        value={formData.reductionCommitmentType}
                                        onChange={handleChange} >
                                        <option value="">{t("decarbonization.reduction.type")}</option>
                                        {reductionCommitmentTypes?.map(x => <option value={x.id}>{x.name.charAt(0).toUpperCase() + x.name.slice(1).toLowerCase()}</option>)}
                                    </select>
                                </div>

                                <div style={{ gridColumn: "span 2 / span 2" }}>
                                    <label className="block text-xs font-medium mb-1" htmlFor="description">{t("decarbonization.reduction.name")} <span className="text-red-500">*</span></label>
                                    <textarea id="description" name="description" className="w-full text-sm" type="text" required placeholder={t("decarbonization.reduction.description")}
                                        value={formData.description}
                                        onChange={handleChange} />
                                </div>

                                <div>
                                    <div>
                                        <label className="block text-xs font-medium mb-1" htmlFor="averagePercentageReduction">{t("decarbonization.reduction.percent")} <span className="text-red-500">*</span></label>
                                        <div className="relative">
                                            <input id="averagePercentageReduction" name="averagePercentageReduction" className="w-full text-sm pr-8" type="number" step={"0.01"} min={0.01} max={100} required
                                                value={formData.averagePercentageReduction}
                                                onChange={handleChange} />
                                            <div className="absolute inset-0 left-auto flex items-center justify-end pointer-events-none">
                                                <span className="text-sm text-gray-400 font-medium px-3">%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <label className="block text-xs font-medium mb-1" htmlFor="averageImplementationCost">{t("decarbonization.reduction.cost")} <span className="text-red-500">*</span></label>
                                        <div className="relative">
                                            <input id="averageImplementationCost" name="averageImplementationCost" className="w-full text-sm pr-8" type="number" step={"0.01"} min={0.01} required
                                                value={formData.averageImplementationCost}
                                                onChange={handleChange} />
                                            <div className="absolute inset-0 left-auto flex items-center justify-end pointer-events-none">
                                                <span className="text-sm text-gray-400 font-medium px-3">€</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center' style={{ gridColumn: "span 2 / span 2" }}>
                                    <button className='sec-green-background  text-white px-3 py-1 ' type="submit">
                                        {spinner && <Spinner className='' />}
                                        {!spinner && t("decarbonization.reduction.save")}
                                    </button>
                                </div>

                            </div>

                        </form>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='flex w-full justify-end items-center'>
                    <button className='btn-cancel text-white px-3 py-1 ' onClick={() => handleClose()}>
                        {t("footPrint.cerrarCerrar")}
                    </button>
                </div>
            </Modal.Footer>
        </Modal >
    );


}