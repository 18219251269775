import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from "reactstrap";
import { IdiomContext } from "../../../context/createcontext";
import { useLocation } from 'react-router-dom';
import project_complete from '../../../images/tarea-completada.png';
import Header from '../../../pages/landings/Header';
import AnswerTable from './AnswerTable';
import ExcelQuestions from './ExcelQuestions';
import FinishedForm from './FinishedForm';
import InputsQuestions from "./InputsQuestions";
import ModalDescriptionInstrucctions from './ModalDescriptionInstrucctions';
import SaveAnswerResponse from './SaveAnswerResponse';

export default function DataEmail() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [email, setEmail] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const pathParams = useParams();
  const idExternal = pathParams.idExternal;
  const organization = pathParams.organization;
  const year = pathParams.year;
  const [organizationLogo, setOrganizationLogo] = useState('');
  const [idContact, setIdContact] = useState(pathParams.idContact);
  const [idQuestion, setIdQuestion] = useState('');
  const [titleQuestion, setTitleQuestion] = useState('');
  const [descriptionQuestion, setDescriptionQuestion] = useState('');
  const [instructionsQuestion, setinstructionsQuestion] = useState('');
  const [answersQuestion, setAnswersQuestion] = useState('');
  const [idQuestionExternalData, setIdQuestionExternalData] = useState('');
  const [startQuestion, setStartQuestion] = useState({ state: false, externalData: false });
  const [screenDescription, setScreenDescription] = useState(false);
  const [answerTable, setAnswerTable] = useState(false);
  const [savedAnswerTable, setSavedAnswerTable] = useState(false);

  const [completed, setCompleted] = useState({state: false, data: undefined, spinner: false});
  const [selectedFormat, setSelectedFormat] = useState('inputs');
  const [showModalInstructions, setShowModalInstructions] = useState(false)
  const [savedAnswerResponse, setSavedAnswerResponse] = useState({})
  const [qr,setQr]=useState(false)
  const [event,setEvent]=useState(false);
  const [organizationWebsite,setOrganizationWebsite] = useState("");
  const [lastAnswer, setLastAnswer] = useState();

  const urlSegments =  decodeURI(window.location.origin + window.location.pathname).split('/');
  useEffect(()=>{
    if (urlSegments[urlSegments.length-2].includes("evento")) {
      setEvent(true)
    };
  },[])



  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const qr = params.get('qr');
    setQr(qr)
  }, []);

  const dataAnswer = (savedData) => {
    setSavedAnswerTable(true)
    setSelectedFormat(undefined);
    setSavedAnswerResponse(savedData)
  }

  const showAnswerTableByQuestion = (x) => {
    setAnswerTable(x)
    setSavedAnswerTable(false)
    setSelectedFormat(undefined);
  }

  const handleCloseModal = () => {
    setShowModalInstructions(false);
  };

  const [contactData, setContactData] = useState({
    data: undefined,
    isFetching: false
  });

  const [dataExternal, setDataExternal] = useState({
    data: [],
    isFetching: true
  });

  const checkContact = async (e) => {
    e.preventDefault();
    fetchValidateEmail();
    setQr(true)
  };

  const fetchValidateEmail = async () => {

    setContactData({ data: contactData.data, isFetching: true });

    let url = process.env.REACT_APP_CH_CONTACTS + `/external/contact?idExternalQuestionnaire=${idExternal}`;

    if (idContact != undefined) {
      url += `&idContact=${idContact}`;
    } else {
      url += `&email=${email}`;
    }

    try {
      const response = await axios.get(`${url}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      setContactData({ data: response.data.data, isFetching: false });
      if (response.data.success) {
        setIdContact(response.data.data.contactId);
      } else {
        setContactData({ data: undefined, isFetching: false });
      }
    } catch (exception) {
      console.error(exception)
      setContactData({ data: undefined, isFetching: false });
    }
  }




  const finishContactQuestionnaire = async () => {
    setCompleted({state: true, data: completed.data, spinner: true});
    setAnswerTable(false);
    setScreenDescription(false); 
    setStartQuestion({ state: false, externalData: false });
    setSelectedFormat("inputs");

    let url = process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE + `/${idExternal}/contact/${idContact}/finishQuestionnaire`;

    try {
      const response = await axios.patch(`${url}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      setCompleted({state: true, data: response.data, spinner: false});
    } catch (exception) {
      console.error(exception)
    }

  }



  const fetchDataExternal = async () => {
    setDataExternal({ data: dataExternal.data, isFetching: true });
    try {
      const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/public/${idExternal}/contact/${idContact}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      setDataExternal({ data: response.data, isFetching: false });
      const logo = response.data?.data?.urlOrganizationLogo || '';
      const website = response.data?.data?.urlOrganizationWebsite || '';
      const firstQuestionId = response.data?.data?.externalQuestionsDto[0]?.id || '';
      const firstQuestionTitle = response.data?.data?.externalQuestionsDto[0]?.name || '';
      const firstQuestionDescription = response.data?.data?.externalQuestionsDto[0]?.description || '';
      const firstInstructions = response.data?.data?.externalQuestionsDto[0]?.instructions || '';
      const firstQuestion = response.data?.data?.externalQuestionsDto[0] || {};
      const answers = response.data?.data?.externalQuestionsDto[0].answersByContact || 0;
      const idQuestionToExternalData = response.data?.data?.externalQuestionsDto[0].idQuestionToExternalData || {};

      setOrganizationLogo(logo);
      setOrganizationWebsite(website);
      setIdQuestion(firstQuestionId);
      setTitleQuestion(firstQuestionTitle);
      setDescriptionQuestion(firstQuestionDescription);
      setinstructionsQuestion(firstInstructions);
      setAnswersQuestion(answers);
      setIdQuestionExternalData(idQuestionToExternalData);
    } catch (exception) {
      console.error(exception)
      setDataExternal({ data: [], isFetching: false });
    }
  };


  useEffect(() => {
    if (idContact !== undefined) {
      fetchValidateEmail();
      fetchDataExternal();
    }
  }, [idContact])

  useEffect(() => {
    if (dataExternal?.data?.data?.externalQuestionsDto.length === 1 && !qr ) {
     
     setScreenDescription(true)
    }
  }, [ dataExternal?.data?.data?.externalQuestionsDto.length])


  const initCurrentQuestion = (lastAnswer) => {
    setSavedAnswerTable(false)
    setAnswerTable(false)
    setScreenDescription(true)
    setStartQuestion({ state: true, externalData: false })
    setSelectedFormat("inputs")

    const initCurrentQuestion = dataExternal.data.data.externalQuestionsDto[currentIndex];
    setIdQuestion(initCurrentQuestion.id);
    setTitleQuestion(initCurrentQuestion.name);
    setinstructionsQuestion(initCurrentQuestion.instructions);
    setAnswersQuestion(initCurrentQuestion.answersByContact);
    setIdQuestionExternalData(initCurrentQuestion.idQuestionToExternalData);
    setLastAnswer(lastAnswer)
    if(initCurrentQuestion.id == 5){
      setSelectedFormat("inputs");
    }
  }

  const currentIndex = dataExternal?.data?.data?.externalQuestionsDto?.findIndex(question => question.id === idQuestion);

  const handleNextPreviousButtons = (direction) => {
    setAnswerTable(false)
    setSavedAnswerTable(false)
    setScreenDescription(true)
    setStartQuestion({ state: false, externalData: false })
    setSelectedFormat("inputs")
    if (dataExternal.data?.data?.externalQuestionsDto.length > 0) {
      const currentIndex = dataExternal.data.data.externalQuestionsDto.findIndex(question => question.id === idQuestion);

      if (direction === "next" && currentIndex < dataExternal.data.data.externalQuestionsDto.length - 1) {
        const nextQuestion = dataExternal.data.data.externalQuestionsDto[currentIndex + 1];
        setIdQuestion(nextQuestion.id);
        setTitleQuestion(nextQuestion.name);

        setDescriptionQuestion(nextQuestion.description);
        setinstructionsQuestion(nextQuestion.instructions);
        setAnswersQuestion(nextQuestion.answersByContact);
        setIdQuestionExternalData(nextQuestion.idQuestionToExternalData);

      } else if (direction === "previous" && currentIndex > 0) {
        const previousQuestion = dataExternal.data.data.externalQuestionsDto[currentIndex - 1];
        setIdQuestion(previousQuestion.id);
        setTitleQuestion(previousQuestion.name);

        setDescriptionQuestion(previousQuestion.description);
        setinstructionsQuestion(previousQuestion.instructions);
        setAnswersQuestion(previousQuestion.answersByContact);
        setIdQuestionExternalData(previousQuestion.idQuestionToExternalData);
      }
    }
  };

  const handleGoQuestions = (x) => {
    setIdQuestion(x.id);
    setTitleQuestion(x.name);
    setDescriptionQuestion(x.description);
    setinstructionsQuestion(x.instructions);
    setAnswersQuestion(x.answersByContact);
    setIdQuestionExternalData(x.idQuestionToExternalData);
    setScreenDescription(true)
  }

  const getExtraText = () => {
    const questionIds = dataExternal?.data?.data?.externalQuestionsDto.map((v)=> v.id );
    
    let extraText = "";
    if(questionIds.length == 1){
      if(questionIds.includes(3)){
        extraText = t("fpThird.comoDistribuidor") + organization;
      } else if (questionIds.includes(4)) {
        extraText = t("fpThird.comoProveedor") + organization;
      }
    } else if (questionIds.length == 2 && questionIds.includes(3) && questionIds.includes(4)){
      extraText = t("fpThird.comoDistribuidorProveedor") + organization;
    }
    return extraText;
  }

  const setAnswerQuestion = (data) => {
    const newDataExternal = { ...dataExternal.data };
    newDataExternal.data.externalQuestionsDto = data
    setDataExternal({ data: newDataExternal, isFetching: false });
  }


 
 
  return (
    <div className="sec-neutro-background" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header tercerosLogo={organizationLogo}></Header>

      <ModalDescriptionInstrucctions
        show={showModalInstructions}
        handleClose={handleCloseModal}
        descriptionQuestion={descriptionQuestion}
        instructionsQuestion={instructionsQuestion} />

      <div className='flex flex-col mt-80 container'>

        {/* <div className=" py-8 mx-auto max-w-xl w-full"> */}
        <div className="  px-4 sm:px-6 lg:px-8 max-w-9xl py-8 mx-auto  w-full">
          <div className='flex-1'>

              <div className=" flex mb-5">

                <div className=" text-gray-800 text-center px-1 w-full">
                  <p className="text-xl md:text-2xl font-bold mb-2">
                    {t("fpThird.datosHuella")}<span> {event ? urlSegments[urlSegments.length-1] : (year ? year : '')}</span>
                  </p>
                  <p className="text-xl md:text-2xl font-bold ">
                    {t("fpThird.deLaOrganizacion")} <span>{organization ? organization : ''}</span>
                  </p>
                </div>
                {
                  screenDescription === true && idContact && idQuestion != undefined &&
                  <div className='flex items-end mb-2 justify-end'>
                    <button className=' flex flex-col items-end' arial-label="btn-saveFom" type='onclick' onClick={() => (setScreenDescription(false), setStartQuestion({ state: false, externalData: false }), setAnswerTable(false), setSelectedFormat("inputs"), setSavedAnswerTable(false))}  >
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list-numbers" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M11 6h9" />
                        <path d="M11 12h9" />
                        <path d="M12 18h8" />
                        <path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4" />
                        <path d="M6 10v-6l-2 2" />
                      </svg>
                      <span className='text-xs font-bold italic'>{t("fpThird.preguntas")}</span>
                    </button>
                  </div>
                }
              </div>
            

            { //EMAIL / PREGUNTAS + BTN EMPEZAR
              screenDescription === false && !startQuestion.state && completed.state === false &&

              <div className="w-full max-w-9xl mx-auto  text-justify">
                {idContact == undefined ?
                  <>
                    <div className="mb-8 pt-8 text-gray-800 text-center px-1">
                      <p className="text-md md:text-xl font-bold mb-2">
                        {t("fpThird.indicaEmail")}
                      </p>
                      <p className="text-md md:text-xl font-bold mb-2">
                        {t("fpThird.validarEmail")}
                      </p>
                    </div>

                    <Form className='text-md font-bold max-w-xl m-auto' onSubmit={(e) => checkContact(e)}>
                      <FormGroup>
                        <Label for="email">{t("fpThird.email")}</Label>
                        <Input className='' type="email" name="email" id="email" defaultValue={email} onChange={(e) => { setEmail(e.target.value) }} required></Input>
                      </FormGroup>
                      <div className='flex items-center'>
                        <input type="checkbox" className="text-black" required onChange={e => { setButtonDisabled(!e.target.checked) }} />
                        <span className="text-xs ml-2 ">{(t("landingEventos.leidoYacepto"))} <a rel="noopener noreferrer" target='_blank' href="/terminos">{(t("landingEventos.legales"))}</a><span className="text-red-500" >*</span></span>
                      </div>
                      <div className='flex justify-center' style={{ marginTop: '10vh' }}>
                        <Button className="btn-green  rounded-lg px-4 py-2" type="submit" disabled={buttonDisabled}>{t("fpThird.continuar")}</Button>
                      </div>
                    </Form>
                  </>
                  :
                  <div>

                    {!contactData.isFetching && contactData.data && contactData.data?.validated ?
                      <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto flex flex-col">

                        {dataExternal.isFetching ?
                          <div className='text-center mt-5'>
                            <Spinner animation="border" variant="dark" ></Spinner>
                            <p>{t("footPrint.chargequestions")}</p>
                          </div>
                          :
                          (dataExternal?.data?.message?.includes('Ya has finalizado este cuestionario') == false && dataExternal?.data?.message?.includes('El cálculo al que pertenece este cuestionario ya está finalizado') == false) ?
 
                            <div className='mt-3 max-w-3xl m-auto'>
                              <p className='mb-3'>
                                {t("fpThird.en")}  <b> {organization} </b> {t("fpThird.bienvenidaTerceros1")}
                              </p>
                              <p>
                              {t("fpThird.bienvenidaTerceros2") + getExtraText() + t("fpThird.bienvenidaTerceros3")}
                              </p>

                              <ul className='mt-3 flex flex-col gap-3 pl-3 font-semibold pt-3 max-w-3xl m-auto' style={{ listStyleType: 'decimal' }}>
                                {dataExternal.data?.data?.externalQuestionsDto.map((x, y) =>
                                  <li key={y} >
                                    <div className="flex justify-between items-center">
                                      <span className="linkhover col-10"
                                        onClick={() => { handleGoQuestions(x) }}
                                        style={{ cursor: 'pointer' }}>{x.name}</span>
                                      {x.answersByContact > 0 && <span className="text-sm linkhover text-xs"
                                        onClick={() => { handleGoQuestions(x); setAnswerTable(true) }}
                                        style={{ cursor: 'pointer' }}>
                                        ({x.answersByContact} {t("fpThird.respuestas").toLowerCase()})
                                      </span>
                                      }
                                    </div>
                                  </li>
                                )}
                              </ul>


                              <div className='flex justify-center gap-4' style={{ marginTop: '4rem' }}>
                                <button className="btn-green text-md  rounded-lg px-4 py-2" type="submit" onClick={() => setScreenDescription(true)}>{t("fpThird.responder")}</button>
                              </div>
                            </div>
                            :
                            <div>
                              <div className='flex flex-col justify-center mt-4'>
                                <div className="mt-4 flex justify-center">
                                  <img src={project_complete} />
                                </div>
                                <p className='text-center'>{t("fpThird.formularioFinalizado")}</p>
                                <div className="text-center mt-4">
                                  <button className="btn-green mt-4 rounded-lg px-4 py-2" type="button" onClick={() => window.location.href = "http://www.airco2.earth"}>
                                    {t("fpThird.btnairco")}
                                  </button>
                                </div>
                              </div>
                            </div>
                        }
                      </div>
                      :
                      <>
                        {!contactData.isFetching && contactData.data && !contactData.data?.validated &&
                          <div className="mb-8 text-gray-800 text-center px-1 pt-16">
                            <p className="text-md md:text-xl font-bold mb-2">{t("fpThird.revisaCorreo")}</p>
                            <div className='flex justify-center' style={{ marginTop: '4rem' }}>
                              <Button className="btn-green  rounded-lg px-4 py-2" type="submit" onClick={() => fetchValidateEmail()}>{t("fpThird.recargar")}</Button>
                            </div>
                          </div>}</>
                    }
                  </div>
                }

              </div>

            }

            { //TITULO PREGUNTA Y BTN EMPEZAR
              screenDescription === true && !startQuestion.state && idContact && idQuestion != undefined && answerTable === false && completed.state === false &&
              <div className=" text-gray-800  px-1  flex flex-col">
                <div className=''>

                  <h1 className=' text-center text-xl font-bold mb-4 mt-1 flex gap-2'>
                    <span> {/* {t("fpThird.preg")}  */}
                      {currentIndex + 1}. </span><span>{titleQuestion}</span>
                  </h1>

                  <div className="text-md md:text-xl mb-4">
                    <p className='mb-1 font-semibold'>
                      {t("fpThird.descripcion")}:
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: descriptionQuestion }} />
                  </div>

                  <div className="text-md md:text-xl">
                    <p className='mb-1 font-semibold'>
                      {t("fpThird.instrucciones")}:
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: instructionsQuestion }} />
                  </div>
                </div>
                <div className='flex flex-col items-center'>
                {!startQuestion.state && (idQuestion != 5 && idQuestion != 6) &&
                    <div className='mt-4'>
                      <div className='my-2 font-semibold'>
                        <p>{t("fpThird.selectOption")}</p>
                      </div>
                      <div className='flex flex-row items-center space-x-2'>
                        <input type="radio" name="answerFormat" value="inputs" id="inputs" onChange={() => { setSelectedFormat("inputs"); setStartQuestion({ state: startQuestion.state, externalData: false }) }} checked={selectedFormat == "inputs" ? true : false} />
                        <span htmlFor="inputs">{t("fpThird.useForm")}</span>
                      </div>
                      {idQuestionExternalData && <div className='flex flex-row items-center space-x-2'>
                        <input type="radio" name="answerFormat" value="calculada" id="calculada" onChange={() => { setSelectedFormat("calculada"); setStartQuestion({ state: startQuestion.state, externalData: true }) }} checked={selectedFormat == "calculada" ? true : false} />
                        <span htmlFor="calculada">{t("fpThird.knowdata")}</span>
                      </div>}
                      {!idQuestionExternalData && idQuestion != 5 &&  <div className='flex flex-row items-center space-x-2'>
                        <input type="radio" name="answerFormat" value="excel" id="excel" onChange={() => setSelectedFormat("excel")} checked={selectedFormat == "excel" ? true : false} />
                        <span htmlFor="excel">{t("fpThird.uploadFromFile")}</span>
                      </div>}
                    </div>
                  }
                </div>

                <div className={`flex items-center justify-${answersQuestion > 0 ? "between" : "center"} gap-4 flex-wrap`} style={{ marginTop: '3rem' }}>
                  {answersQuestion > 0 && <button className="btn-green text-md  rounded-lg px-4 py-2" type="submit" onClick={() => setAnswerTable(true)}>
                    {t("fpThird.verRespuestas")} ({answersQuestion})</button>}
                  <button className="btn-green text-md  rounded-lg px-8 py-2" type="submit" onClick={() => {setStartQuestion({ state: true, externalData: startQuestion.externalData });}}>{t("fpThird.responder")}</button>
                </div>
              </div>
            }

            {//PREGUNTAS CON INPUTS 
              (startQuestion.state || answerTable) && idContact && idQuestion != undefined  &&
              <div className='mb-4 font-semibold text-xl flex-wrap flex gap-2 items-end ' >

                <span className=''>{currentIndex + 1}. {titleQuestion ? titleQuestion : ''}</span>
                <span onClick={() => setShowModalInstructions(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                    <path d="M12 9h.01" />
                    <path d="M11 12h1v4h1" />
                  </svg>
                </span>
              </div>
            }
            { //PREGUNTAS CON INPUTS
              startQuestion.state && idContact && idQuestion != undefined && !answerTable &&
              <div className=''>
                <div className=''>
                  {(selectedFormat === "inputs" || selectedFormat === "calculada") &&
                    <InputsQuestions
                      questions={dataExternal.data?.data?.externalQuestionsDto}
                      idQuestion={idQuestion}
                      idExternal={idExternal}
                      titleQuestion={titleQuestion}
                      descriptionQuestion={descriptionQuestion}
                      instructionsQuestion={instructionsQuestion}
                      idContact={idContact}
                      currentIndexQuestion={currentIndex}
                      answerTableData={dataAnswer}
                      externalData={startQuestion.externalData}
                      setQuestions={setAnswerQuestion}
                      year={year}
                      lastAnswer={lastAnswer}
                    >
                    </InputsQuestions>
                  }

                  {selectedFormat == "excel" &&
                    <ExcelQuestions
                      questions={dataExternal.data?.data?.externalQuestionsDto}
                      idQuestion={idQuestion}
                      idExternal={idExternal}
                      titleQuestion={titleQuestion}
                      descriptionQuestion={descriptionQuestion}
                      instructionsQuestion={instructionsQuestion}
                      idContact={idContact}
                      currentIndexQuestion={currentIndex}
                      answerTableData={dataAnswer}
                      setQuestions={setAnswerQuestion}
                    />
                  }

                  {savedAnswerTable &&
                    <SaveAnswerResponse
                      savedAnswer={savedAnswerResponse}
                      showAnswerTableByQuestion={showAnswerTableByQuestion}
                      lastAnswer={lastAnswer}
                      initCurrentQuestion={initCurrentQuestion}
                    // idQuestion={idQuestion}
                    ></SaveAnswerResponse>
                  }

                </div>
              </div>
            }
            {answerTable === true &&
              <AnswerTable
                questions={dataExternal.data?.data?.externalQuestionsDto}
                idQuestion={idQuestion}
                idExternal={idExternal}
                titleQuestion={titleQuestion}
                idContact={idContact}
                currentIndexQuestion={currentIndex}
                answerTable={answerTable}
                setQuestions={setAnswerQuestion}
              >
              </AnswerTable>

            }

            { completed.state &&
              (completed.spinner ?
                <div className='text-center mt-5'>
                  <Spinner animation="border" variant="dark" ></Spinner>
                  <p>{t("fpThird.finalizandoCuestionario")}</p>
                </div>
              :
                <FinishedForm
                  data={completed.data}
                  url={organizationWebsite}
                  isEvent={event}>
                </FinishedForm>
              )
            }
            { //BOTONES PARA PASAR PREGUNTAS

              screenDescription === true && idContact && idQuestion != undefined &&
              <div>



                <div className='flex items-center  justify-between gap-4 flex-wrap pt-4 border-t border-gray-300' style={{ marginTop: '4rem', alignItems: 'stretch' }}>

                  {currentIndex > 0 && (
                    <button type='button' className='btn-sm btn-green rounded-lg flex-1 ' onClick={() => handleNextPreviousButtons("previous")}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right rotate-180	" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 6l6 6l-6 6" />
                      </svg>
                      {currentIndex}. {dataExternal.data.data.externalQuestionsDto[currentIndex - 1].name}

                    </button>
                  )}


                  {currentIndex < dataExternal.data?.data?.externalQuestionsDto.length - 1 && (
                    <button type='button' className='btn-sm btn-green rounded-lg flex-1 text-right ' onClick={() => handleNextPreviousButtons("next")}>

                      {currentIndex + 2}. {dataExternal.data.data.externalQuestionsDto[currentIndex + 1].name}
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right " width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 6l6 6l-6 6" />
                      </svg> </button>
                  )}



                </div>


                {(savedAnswerTable || answerTable === true ) &&
                <div className='mt-4  flex justify-center'>
                  <button className="btn-sm  btn-banner-o rounded-lg px-8 py-2 flex-1" type="submit"
                    onClick={() => (finishContactQuestionnaire())} disabled={completed.spinner}>
                    {t("fpThird.finalizar")}
                  </button>
                </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  )
}
