import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext";
import { useContext } from 'react'
import calculadora from '../../../../images/iconoCalculadora.png'
import empresa from '../../../../images/iconoEmpresa.png'
import grafico from '../../../../images/iconoGrafica.png'


export default function BannerPuntos() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    return (
        <div className=''>
            <section>
                <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5">
                    <div className='pt-5 text-center text30 sec-green-text' data-aos="zoom-y-out">
                        <h2 className='px-title'  >{t("landingCarbon.titlePuntos")}</h2>

                    </div>
                    {/* Items */}
                    <div className=" pt-5 mb-5 mt-5 max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>

                        {/* 1st item */}

                        <div
                            className={` flex flex-col py-5 px-2 gap-2 pointShadow`}

                            data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]"
                        >
                            <img src={calculadora} width="75" alt="imagen-item" />
                            <div className='divDentro'>




                                <h3 className=" mb-2 sec-green-text text24">{t("landingCarbon.calculateScopetitle")}</h3>
                                <p className="sec-grey-text text12">{t("landingCarbon.calculateScopeText")}</p>
                            </div>
                        </div>


                        {/* 2 item */}
                        <div
                            className={` flex flex-col py-5 px-2 gap-2 pointShadow`}
                            data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]"
                        >
                            <img src={empresa} width="75" alt="imagen-item" />
                            <div className='divDentro'>

                                <h3 className=" mb-2 sec-green-text text24">{t("landingCarbon.reduceFootprinttitle")}</h3>
                                <p className="sec-grey-text text12">{t("landingCarbon.reduceFootprintText")}</p>
                            </div>
                        </div>


                        {/* 3rd item */}
                        <div
                            className={` flex flex-col py-5 px-2 gap-2 pointShadow`}
                            data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
                           
                            <img className='' src={grafico} width="75" alt="imagen-item" />

                           
                           
                            <div className='divDentro'>

                                <h3 className=" mb-2 sec-green-text text24" >{t("landingCarbon.auditDatatitle")}</h3>
                                <p className="sec-grey-text text12">{t("landingCarbon.auditDataText")}</p>
                            </div>
                        </div>
                    </div>


                </div>
            </section>


        </div>
    )
}
