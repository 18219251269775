import React, { useContext, useEffect, useRef, useState } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/esm/Image';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../context/createcontext";
import logo from '../../images/logo2.png';





function
    Header({newImageUrl, homepageUrl, tercerosLogo}) {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [scrolling, setScrolling] = useState(false);

    const trigger = useRef(null);
    const mobileNav = useRef(null);


    






    // change the language

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [spain, updateSpain] = useState(lng === "es" ? true : false)
    const [portug, updatePortugal] = useState(lng === "pt" ? true : false)
    const [england, updateEngland] = useState(lng === "en" ? true : false)
    const [galic, updateGalicia] = useState(lng === "gl" ? true : false)
    const handleChangeEs = e => {
        i18n.changeLanguage("es")
        updateLng("es")
        updateEngland(false)
        updatePortugal(false)
        updateGalicia(false)
        updateSpain(true)




    }
    const handleChangePt = e => {
        i18n.changeLanguage("pt")
        updateLng("pt")
        updatePortugal(true)
        updateEngland(false)
        updateGalicia(false)
        updateSpain(false)


    };
    const handleChangeGa = e => {
        i18n.changeLanguage("gl")
        updateLng("gl")
        updateGalicia(true)
        updateEngland(false)
        updatePortugal(false)
        updateSpain(false)


    };
    const handleChangeEn = e => {
        i18n.changeLanguage("en")
        updateLng("en")
        updateEngland(true)
        updateGalicia(false)
        updatePortugal(false)
        updateSpain(false)


    };


    // actualizar el idioma del localStorage

    useEffect(() => {
        localStorage.setItem('idioma', lng);
        updateLng


    }, [lng]);



    const handleScroll = () => {
        setMobileNavOpen(false);


        if (window.scrollY > 0) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);


        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    const { pathname } = location;

    const backgroundColorNav =
        pathname.includes('/ayuntamientos-net-zero') && !scrolling
            ? 'transparent'
            : pathname.includes('/ayuntamientos-net-zero') && scrolling
                ? 'transparent'


                : 'var(--airco2-green-color)';


    const textLinkMvl = `linkhover py-2 flex ${pathname.includes('/ayuntamientos-net-zero') ? 'text-white font-bold' : 'text-gray-300'}`;

    return (

        <div id='inicio'>


            <header className="absolute w-full z-30" style={{ color: 'white' }}>


                <Navbar expand="xl" variant="dark" style={{ backgroundColor: backgroundColorNav }}>

                    <div className="w-full"


                    >
                        <div className="flex items-center justify-between h-20 ">


                            <div className={"navMargin lg:grow" + newImageUrl && "flex items-center "} >
                                <button aria-label='inicioAirco2'>

                                    <HashLink aria-label='inicio' className="nav-link" to={''} smooth={true} offset={50} duration={500}> <Image height="200" width="130" src={logo}></Image></HashLink>

                                </button>

                                {tercerosLogo && <><span className='font-bold'> x </span> <Image style={{maxHeight:'90px'}} src={tercerosLogo}></Image></>}

                                {newImageUrl && <span className='text-3xl' style={{color:"#666666"}}><b>x</b></span>} 

                                {newImageUrl != undefined ? 
                                    <button aria-label='homePageGreatPackaging' className='px-3'>
                                        <a href={homepageUrl}> <Image height="200" width="130" src={newImageUrl}></Image></a>
                                    </button>
                                : ""}

                            </div>

                            {/* Desktop navigation */}
                            {/* <Navbar className="hidden lg:flex lg:grow" style={{marginInlineStart: '150px'}}> */}
                            <div className="hidden lg:flex lg:grow "
                            >






                                {/* dropw idiomas, empieza hoy y login*/}
                                {/* <ul className="hidden lg:flex grow justify-center flex-wrap items-center m-1 menu-list ">
                                    <Dropdown title={
                                        <span><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-world" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="12" cy="12" r="9" />
                                            <line x1="3.6" y1="9" x2="20.4" y2="9" />
                                            <line x1="3.6" y1="15" x2="20.4" y2="15" />
                                            <path d="M11.5 3a17 17 0 0 0 0 18" />
                                            <path d="M12.5 3a17 17 0 0 1 0 18" />
                                        </svg></span>}
                                    >
                                        <li className={spain ? 'sec-green-text font-semibold' : 'text-white'}>
                                            <button className='text-ms linkhover' aria-label='btn-lng-es' onClick={handleChangeEs}>  Español</button>
                                        </li>

                                        <li className={galic ? 'sec-green-text font-semibold' : 'text-white'}>
                                            <button className="text-ms linkhover" aria-label='btn-lng-ga' onClick={handleChangeGa}>
                                                Galego
                                            </button>
                                        </li>

                                        <li className={england ? 'sec-green-text font-semibold' : 'text-white'}>
                                            <button className='text-ms linkhover' aria-label='btn-lng-en' onClick={handleChangeEn}>  English</button>
                                        </li>

                                        <li className={portug ? 'sec-green-text font-semibold' : 'text-white'}>
                                            <button className='text-ms linkhover' aria-label='btn-lng-pt' onClick={handleChangePt}>  Português</button>
                                        </li>

                                    </Dropdown>
                                </ul> */}


                            </div>





                            {/* Mobile menu */}
                            {/* <div className="md:hidden "> */}
                            <div className="lg:hidden">


                                {/* Hamburger button */}
                                {/* <button aria-label='btn-hamburger' ref={trigger} className={` mr-3 hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>

                                    <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="4" width="24" height="2" rx="1" />
                                        <rect y="11" width="24" height="2" rx="1" />
                                        <rect y="18" width="24" height="2" rx="1" />
                                    </svg>
                                </button> */}

                                {/*Mobile navigation */}
                                <nav id="mobile-nav" ref={mobileNav} className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out" style={mobileNavOpen ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: .8 }} >
                                    <ul className=" px-4 py-2"
                                        style={pathname.includes('/ayuntamientos-net-zero') ? { backgroundColor: 'rgba(255, 186, 91)' } : { backgroundColor: 'rgb(30, 41, 59)' }}


                                    >



                                        <li className="py-2 my-2 border-t border-b border-gray-700">

                                            <ul className="flex grow justify-start flex-wrap items-center mr-2 gap-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-world" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <circle cx="12" cy="12" r="9" />
                                                    <line x1="3.6" y1="9" x2="20.4" y2="9" />
                                                    <line x1="3.6" y1="15" x2="20.4" y2="15" />
                                                    <path d="M11.5 3a17 17 0 0 0 0 18" />
                                                    <path d="M12.5 3a17 17 0 0 1 0 18" />
                                                </svg>
                                                {(spain === false) &&
                                                    <button className='linkhover' aria-label='btn-lng-es' onClick={handleChangeEs}> ES</button>

                                                }
                                                {(galic === false) &&
                                                    <button className='linkhover' aria-label='btn-lng-ga' onClick={handleChangeGa}>GL </button>

                                                }
                                                {(england === false) &&

                                                    <button className='linkhover' aria-label='btn-lng-en' onClick={handleChangeEn}>EN</button>


                                                }

                                                {(portug === false) &&
                                                    <button className='linkhover' aria-label='btn-lng-pt' onClick={handleChangePt}> PT</button>

                                                }


                                            </ul>

                                        </li>










                                    </ul>
                                </nav>

                            </div>

                        </div>
                    </div>
                </Navbar>
            </header>
        </div>
    );
}

export default Header;