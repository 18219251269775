import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
export default function bloqueForm() {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const color = 'RGBA(135, 135, 135, 0.5)'
    const claseT = 'p-2 rounded-lg flex items-center w-full h-full'
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [consent, setConsent] = useState(false);
    const [company, setCompany] = useState("");
    const [city, setCity] = useState("")

    const [hubspotResponse, setHubspotResponse] = useState("");
    const [formError, setFormError] = useState("");
    const [text, setText] = useState("")

    const cityList = [
        "Álaba",
        "Albacete",
        "Alicante",
        "Almería",
        "Ávila",
        "Badajoz",
        "Illes Balears",
        "Barcelona",
        "Burgos",
        "Cáceres",
        "Cádiz",
        "Castelló",
        "Ciudad Real",
        "Córdoba",
        "A Coruña",
        "Cuenca",
        "Girona",
        "Granada",
        "Guadalajara",
        "Gipuzkoa",
        "Huelva",
        "Huesca",
        "Jaén",
        "León",
        "Lleida",
        "La Rioja",
        "Lugo",
        "Madrid",
        "Málaga",
        "Murcia",
        "Navarra",
        "Ourense",
        "Asturias",
        "Palencia",
        "Las Palmas",
        "Pontevedra",
        "Salamanca",
        "Santa Cruz de Tenerife",
        "Cantabria",
        "Segovia",
        "Sevilla",
        "Soria",
        "Tarragona",
        "Teruel",
        "Toledo",
        "València",
        "Valladolid",
        "Bizkaia",
        "Zamora",
        "Zaragoza",
        "Ceuta",
        "Melilla"
    ]
    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!name || !email || !company || !city) {
            handleError(t("landingEventos.camposObligatorios"))
            return;


        }

        if (!consent) {
            handleError(t("landingEventos.datosPersonales"))
            return;
        }


        setHubspotResponse(await submit_landing_hubspot_form(name, email, company, city));
    }

    const handleOnchange = () => {
        setFormError("");

    }


    const handleError = (mensaje) => {
        document.getElementById('main')?.scrollIntoView({ behavior: 'smooth' });
        setFormError(mensaje);
    }

    const submit_landing_hubspot_form = async (name, email, company, city) => {
        const portalId = '25435066';
        const formGuid = 'a7d19ab8-1a27-4a12-b6ae-dfc9f4c02de6';
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`
        const data = {
            fields: [

                {
                    name: 'firstname',
                    value: name
                },

                {
                    name: 'email',
                    value: email
                }
                ,

                {
                    name: 'company',
                    value: company
                },
                {
                    name: 'citydesp',
                    value: city
                },



            ]
        };



        return fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((responseJson) => {
                setText(t("landingEventos.solicitudOk"))
                return responseJson;


            });

    }
    return (
        <div className='w-full' style={{  marginBottom: '100px' }}>


            <div className='flex flex-wrap p-5 container' >

                <div className='flex text-center justify-center registro pc-form w-full container md:w-1/2 text-airco2-landing' data-aos-id-blocks>

                    <div className={claseT} data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" >
                        <h2 className="text-white mr-4 ml-4 pr-2 pl-2 text-landing-claim " data-aos="fade-up" data-aos-delay="100"> {t("landingEventos.neutralizaTuBosque")}
                        </h2>
                    </div>



                </div>






                {formError != "" &&
                    <div className='mb-3'><Banner type="error" children={formError} open setOpen={true} ></Banner></div>
                }
                {(hubspotResponse === "") &&
                    <div className='rounded-lg  registro container  w-full md:w-1/2 flex  justify-center pc-form'style={{marginTop:'50px'}} >
                        <div className=' container-form  flex flex-col justify-center rounded-lg h-full w-full'>
                            <p className=' text-2xl mt-2 text-white text-center  font-bold'> {(t("landingEventos.contacta"))}</p>
                            <div className='mr-3 ml-3 form-landing' id='formulario'  >

                                <div className='text-airco2-general font-bold p-1' data-aos="fade-up"
                                    data-aos-delay="400">
                                    <form onSubmit={handleSubmit} className=''>

                                        <div className='flex flex-wrap NameLastname '>
                                            <div className='w-full lg:w-1/2 pr-1 mt-2 mb-1'>
                                                <label className="block text-sm text-white mb-1  " htmlFor="name">{(t("landingEventos.nombre"))} <span className="text-red-500">*</span></label>
                                                <input id="name" className="form-user-data rounded-lg w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setName(e.target.value), handleOnchange() }} />
                                            </div>
                                            <div className='w-full lg:w-1/2 pr-1 mt-2 mb-1 '>
                                                <label className="block text-sm text-white mb-1  " htmlFor="email">{(t("landingEventos.email"))} <span className="text-red-500">*</span></label>
                                                <input id="email" className=" w-full bg-white rounded-lg form-user-data" type="email" defaultValue={''} required onChange={(e) => { setEmail(e.target.value), handleOnchange() }} />
                                            </div>

                                        </div>



                                        <div className='flex flex-wrap companyEvent '>

                                            <div className='w-full lg:w-1/2 pr-1 mt-2 mb-1'>
                                                <label className="block text-sm text-white mb-1 " htmlFor="company">{(t("landingEventos.company"))} <span className="text-red-500">*</span></label>
                                                <input id="company" className=" w-full rounded-lg bg-white form-user-data" type="text" defaultValue={''} required onChange={(e) => { setCompany(e.target.value), handleOnchange() }} />
                                            </div>
                                            <div className='w-full lg:w-1/2 pr-1 mt-2 mb-1'>
                                                <label className="block text-sm text-white mb-1 " htmlFor="city">{(t("landingEventos.ubicacionBosque"))} <span className="text-red-500">*</span></label>
                                                <select defaultValue=""  name="city" data-tip data-for="city" className="w-full rounded-lg bg-white form-user-data" type="text" onChange={(e) => {setCity(e.target.value), handleOnchange() }} required >
                                                    <option name="Selecciona" disabled className='w-full' value="">{t("landingEventos.seleccionaCiudad")}</option>
                                                    {
                                                        cityList.map((x) => {
                                                            return (
                                                                <option key={x} name={x} className='w-full' value={x} >{x}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>


                                        </div>


                                        <div className='mt-2 text-white'>
                                            <label className="flex items-center  font-bold">
                                                <input type="checkbox" className="text-black" required onChange={e => { setConsent(e.target.checked), handleOnchange() }} />
                                                <span className="text-sm ml-2 ">{(t("landingEventos.leidoYacepto"))} <a href="/terminos">{(t("landingEventos.legales"))}</a><span className="text-red-500" >*</span></span>
                                            </label>
                                        </div>

                                        <div className=" mt-4">
                                            <button className="btn text-white sec-green-background   whitespace-nowrap w-full" type="submit" >{(t("landingEventos.enviar"))}

                                            </button>
                                        </div>
                                    </form>


                                </div>


                            </div>

                        </div>


                    </div>
                }
                {(hubspotResponse !== "") &&

                    <div className='rounded-lg registro container pc-form w-full md:w-1/2 flex justify-center' style={{marginTop:'200px'}} >
                        <div className='container-form flex flex-col items-center justify-center rounded-lg h-full w-full'>
                            <p className='text-white font-bold text-lg text-center pb-2 font-bold'>{text}</p>
                            <button className="btn sec-green-background text-white w-1/2" onClick={() => (window.location.href = "/")} >{(t("landingEventos.visitaWeb"))}

                            </button>



                        </div>


                    </div>




                }
            </div>

        </div>
    )
}
