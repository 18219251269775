
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext"


import BloqueEmpresas from "./BloqueEmpresas";

export default function BloqueNews() {
    
    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
   
    return (
        <section className='max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5' >


            <div className="pt-5 mb-5 pb-5">
                <div className="text-center pt-2 px-title">
                    <h2 className="pt-5  text30  sec-green-text " data-aos="zoom-y-out">
                        <span>{t("landingCarbon.titleNews")}</span>
                        <span className="font-bold">Airco2  </span>
                        <span>{t("landingCarbon.titleNewsb")}</span>

                    </h2>
                    <h3 className="pt-4  text14 sec-grey-text " data-aos="zoom-y-out">
                        <span>{t("landingCarbon.subTitleNews")}  </span>
                        <span className="font-bold">Airco2  </span>
                        <span>{t("landingCarbon.subTitleNewsb")}  </span>
                    </h3>
                </div>


                <div className=" pt-5">
                    <BloqueEmpresas></BloqueEmpresas>
                  
                </div>





            </div>
        </section>


    )
}
