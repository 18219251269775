import React, { useState, useContext, useEffect } from "react";
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { getFont } from '../../../services/FunctionsDataWhiteLabel';
import UserService from "../../../services/UserService";
import { Col, Row } from "react-bootstrap";
import { Accordion, Spinner } from 'react-bootstrap';
import axios from "axios";

export default function Tutorials() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [dinamicUrl, setDinamicUrl] = useState("");

    const segments = window.location.href.split('/').filter(segment => !isNaN(segment)  && segment !== '');
    let categoryUrl = segments[0];
    let subacategoryUrl =  segments[1];
    let questionUrl =  segments[2];

    const [tutorialsData, setTutorialsData] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchTutorialsData = async () => {
            setTutorialsData({ data: tutorialsData.data, isFetching: true });
            try {

                const response = await axios.get(`${process.env.REACT_APP_CH_TUTORIALS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setTutorialsData({ data: response.data, isFetching: false });

            } catch (exception) {
                setTutorialsData({ data: [], isFetching: false });
            }
        };
        fetchTutorialsData();
    }, [])

    const [categoryStates, setCategoryStates] = useState();
    const [currentQuestions, setCurrentQuestions] = useState([]);
    const [selectedSubcategory, setSelectedSubcategory] = useState();



    useEffect(()=>{
        let state = [];
        let categoryId;
        let subcategoryId;
        let questionId;

        if (categoryUrl != undefined) {
            categoryId = Number(categoryUrl);
            subcategoryId = Number(subacategoryUrl);
            questionId = Number(questionUrl)
        }

        let indexCategory = 0;
        if(categoryId != undefined) {
            let indexFinder = tutorialsData.data?.data?.findIndex(item => item.id === categoryId);
            if(indexFinder != -1){
                indexCategory = indexFinder;
            }
        }
            
        tutorialsData.data?.data?.forEach((element, i) => {
            state.push({
                index: i ,
                visible: i == indexCategory ? true : false,
                categoryId: element.id,
                categoryName: element.name
            })
            if(i == indexCategory){
                categoryId = element.id;
                subcategoryId = subcategoryId ? subcategoryId : element.subcategoryList[0].id;
            }
        });
        setCategoryStates(state);

        if(state.length > 0 && categoryId != undefined && subcategoryId != undefined){
            handleQuestionsDisplay(categoryId, subcategoryId, questionId);
        }

        
    },[tutorialsData.data])


    const handleSubcategoryDisplay = (id) => {
        let array = [...categoryStates];
        let index = array.findIndex((element) => element.categoryId == id);
        array[index].visible = !array[index].visible;

        let url = `/footprint/tutorials/${array[index].categoryName}/${array[index].categoryId}`;
        setDinamicUrl(url)

        window.history.pushState({}, '', encodeURI(url));
        
        setCategoryStates(array);
    }

    const handleQuestionsDisplay = (categoryIdd, subcategoryIdd, questionIdd) => {

        let array = [...tutorialsData.data.data];
        let indexCategory = array.findIndex((element) => element.id == categoryIdd);
        let subcategoryArray = array[indexCategory].subcategoryList;
        let indexSubcategory = subcategoryArray.findIndex((element) => element.id == subcategoryIdd);
        let questionArray = subcategoryArray[indexSubcategory].tutorialList;

        let indexQuestion = 0;
        if(questionIdd != undefined) {
            let indexFinder = questionArray.findIndex(item => item.id === questionIdd);
            if (indexFinder != -1) {
                indexQuestion = indexFinder;
            }
        }

        let questionArrayFormated = questionArray.map((obj, index) => ({
            ...obj,
            ["visible"]:  index === indexQuestion ? true : false
        }));

            let url = `/footprint/tutorials/${array[indexCategory].name}/${array[indexCategory].id}/${subcategoryArray[indexSubcategory].name}/${subcategoryArray[indexSubcategory].id}`;

            if (questionArray.length > 0) {
                url += `/${questionArray[indexQuestion].question}/${questionArray[indexQuestion].id}`;
            }

        window.history.pushState({}, '', encodeURI(url));
        setDinamicUrl(url);

        setCurrentQuestions(questionArrayFormated);

        setSelectedSubcategory({id: subcategoryArray[indexSubcategory].id, name: subcategoryArray[indexSubcategory].name})
    }


    const handleQuestionView = (idQuestion) => {
        let currentQuestionsCopy = [...currentQuestions];
        let indexQuestion = currentQuestionsCopy.findIndex((element) => element.id == idQuestion);
        currentQuestionsCopy[indexQuestion].visible = !currentQuestionsCopy[indexQuestion].visible;

        const urlSegments = dinamicUrl.split('/');
        urlSegments[urlSegments.length - 2] = currentQuestionsCopy[indexQuestion].question;
        urlSegments[urlSegments.length - 1] = currentQuestionsCopy[indexQuestion].id;
        const newUrl = urlSegments.join('/');

        window.history.pushState({}, '', encodeURI(newUrl));

        setCurrentQuestions(currentQuestionsCopy);
    }
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
    return (
        <div className={`sec-neutro-background ${getFont(UserService.getwhiteLabel())} flex h-screen overflow-hidden`}>
            
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                   
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {/* Page header */}
                        <div className="mb-4 md:mb-8">
                            {/* Title */}
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Tutoriales</h1>
                        </div>

                        <div>
                            <Row>
                                <Col className={`tutorial-menu-position md:relative`} md={3}>
                                    <div className=" font-semibold border-b mb-2 mt-4 md:mt-0">
                                        <button className="w-full text-center md:text-left text-gray-800" onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}>Categorías
                                        {!isMenuCollapsed ?
                                        "" : 
                                        <span className="text-grey-800 md:hidden font-normal"><br/>- {selectedSubcategory.name} -</span>
                                        }
                                        </button>
                                    </div>
                                        {!tutorialsData.isFetching ?
                                        <ul className={`ml-2 mb-4 border-b ${isMenuCollapsed ? 'hidden md:block' : ''}`}>
                                            {tutorialsData.data?.data?.map((category, i) => {
                                                return (
                                                    <li key={`category-${category.id}`} className="space-y-2">
                                                        <button className="w-full text-left flex items-center justify-between " onClick={() => handleSubcategoryDisplay(category.id)}>
                                                            <span>{category.name}</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: categoryStates[i]?.visible ? 'rotate(90deg)' : ''}} className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="#64748b" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                <path d="M9 6l6 6l-6 6" />
                                                            </svg>
                                                        </button>
                                                        
                                                        <ul className="ml-3">
                                                        {(categoryStates[i]?.visible) && category?.subcategoryList?.map((subcategory) => {
                                                            return (
                                                                <li key={`subcategory-${subcategory.id}`} className="sapce-y-2">
                                                                    <button onClick={() => handleQuestionsDisplay(category.id, subcategory.id, undefined)} className=" text-left">
                                                                        <span className={`${selectedSubcategory.id == subcategory.id && "font-bold"}`}>{subcategory.name}</span>
                                                                    </button>
                                                                </li>
                                                            )
                                                        })}
                                                        </ul>
                                                        
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    :
                                    <Spinner />}
                                </Col>

                                <Col md={9}>
                                    <ul>
                                        {currentQuestions.length > 0 ?
                                        currentQuestions.map((v,i) => {
                                            return(
                                                <li key={i}>
                                                    <div>
                                                        <div>
                                                            <button className="flex items-center flex-inline" onClick={()=> handleQuestionView(v.id)} >
                                                                <h5 className="h5 text-black">{v.question}</h5>
                                                                {v.visible ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-up" width="38" height="38" viewBox="0 0 24 24" strokeWidth="0.75" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                    <path d="M6 15l6 -6l6 6" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="38" height="38" viewBox="0 0 24 24" strokeWidth="0.75" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                    <path d="M6 9l6 6l6 -6" />
                                                                </svg>
                                                                }
                                                            </button>
                                                        </div>
                                                        <div>
                                                        {v.visible &&
                                                            <>
                                                                <div className="max-w-lg w-full mb-4">
                                                                    <div dangerouslySetInnerHTML={{ __html: v.answer }} />
                                                                </div>
                                                                <Row>
                                                                    {v.imageUrl && 
                                                                    <Col md={v.videoUrl == undefined? 12 : 6}>
                                                                        <div className="mb-4 flex justify-center">
                                                                            <a href={v.imageUrl} target="_blank" rel="noopener noreferrer">
                                                                                <img src={v.imageUrl} alt="" className="" height={500} width={350} />
                                                                            </a>                                                           
                                                                        </div>
                                                                    </Col>
                                                                    }
                                                                    {v.videoUrl &&
                                                                    <Col md={v.imageUrl == undefined? 12 : 6}>
                                                                        <div className="mb-4" style={{maxWidth: '42rem'}}> 
                                                                            <iframe
                                                                            title=""
                                                                            width="560"
                                                                            height="315"
                                                                            src={v.videoUrl}
                                                                            allowFullScreen
                                                                            className="w-full"
                                                                            ></iframe>
                                                                        </div>
                                                                    </Col>
                                                                    }
                                                                </Row>
                                                            </>
                                                        }
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                        :
                                        ""
                                        }
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </main>
            </div>
        </div>        


)
}