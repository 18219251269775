import React from 'react';
import { useContext } from "react";
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import icon1 from '../../../images/icono01.svg';
import icon2 from '../../../images/icono02.svg';
import icon3 from '../../../images/icono03.svg';
import icon4 from '../../../images/icono04.svg';
import icon5 from '../../../images/icono05.svg';
import icon6 from '../../../images/icono06.svg';


function BloqueIconostxt() {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  const data = [
    {
      title: t("homepoint.captacion"),
      description: t("homepoint.despega"),
      icon: icon1
    },
    {
      title: t("homepoint.puntos"),
      description: t("homepoint.empresfinan"),
      icon: icon6
    },
    {
      title: t("homepoint.rsc"),
      description: t("homepoint.aumento"),
      icon: icon3
    },
    {
      title: t("homepoint.eficiencia"),
      description: t("homepoint.relojcontra"),
      icon: icon4
    },
    {
      title: t("homepoint.legal"),
      description: t("homepoint.despistes"),
      icon: icon5
    },
    {
      title: t("homepoint.num"),
      description: t("homepoint.nummercado"),
      icon: icon2
    }
  ]
  return (
    <section >
      <div className="max-w-6xl mx-auto px-4 sm:px-6 text-airco2-general-grey">

        <div className="pt-12 md:pt-20  pb-12 md:pb-20  "  data-aos="fade-up">

          <div className="mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 font-cabinet-grotesk">  {t("newHome.titleVentaja")}</h2>
            <p className="text-xl sec-grey-text">  {t("newHome.subtitleVentaja")}</p>
          </div>

          {/* Items */}
          <div className=" mb-5 md:max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>
            {data.map(item => {
              return <div className="relative flex flex-col " data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
                <div className='flex items-end gap-2 mb-2 ' >
                  <img src={item.icon} width="40" height="40" alt="imagen-item" />
                  <h3 className="sec-green-text text16 font-bold">
                    {item.title}</h3>
                </div>
                <p className=" sec-grey-text ">{item.description}</p>
              </div>
            })}
          </div>

        </div>
      </div>
    </section>
  );
}

export default BloqueIconostxt;
