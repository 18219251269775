
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import ExcelComponent from './ExcelComponent';
import InputsComponent from './InputsComponent';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalTitle, ModalFooter } from "react-bootstrap";



function InfoForm({ questBlock, sendDataToParent, idCalculation, addAnswer, isButtonDisabled, selectedBlockId, year, orgName, status, thirdPartyForm }) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [showSendButton, setShowSendButton] = useState(true);
  const [inputData, setInputData] = useState(true);
  const [colLength, setColLength] = useState(true);
  const [radioButtonsData, setRadioButtonsData] = useState({ current: { text: "", id: "" }, other: { text: "", id: "" } });
  const [radioButtonsTotal, setRadioButtonsTotal] = useState(null);
  const [excelAvailable, setExcelAvailable] = useState();

  //Si cambia de pregunta
  useEffect(() => {
    // setSelectedOption(null);
    document.getElementById("footprint-form")?.reset();

    setInputData(questBlock?.data?.inputs?.sort(function (a, b) {
      return a.position - b.position;
    }));
    setColLength(questBlock?.data?.inputs?.length > 4 ? 4 : 12 / questBlock?.data?.inputs?.length);

    questBlock?.data?.inputs?.forEach(function (objeto) {
      if (objeto.input.inputType === 'RADIOBUTTONS') {
        setShowSendButton(false);
        return;
      } else {
        setShowSendButton(true);
      }
    });
  }, [questBlock])


  const addAnswer1 = (props) => {
    addAnswer(props);
    if(modalExcel){
      toggleExcelModal();
    }

  };

  //Enviar al padre el idQuestion para mostrar la info de la pregunta elegida (km/combustible)
  const handleConfirmClick = () => {
    let allOptionRadioBtn = document.querySelectorAll('input[name="dataRadioButton"]');
    let saveRadioButtonsInfo = { current: { text: "", id: "" }, other: { text: "", id: "" } };

    allOptionRadioBtn.forEach(element => {
      if (element.checked) {
        saveRadioButtonsInfo.current.text = element.id;
        saveRadioButtonsInfo.current.id = element.value;
      } else {
        saveRadioButtonsInfo.other.text = element.id;
        saveRadioButtonsInfo.other.id = element.value;
      }
    });


    setRadioButtonsData(saveRadioButtonsInfo)
    sendDataToParent(saveRadioButtonsInfo.current.id)
    setRadioButtonsTotal(allOptionRadioBtn.length)
  }

  //Reset estados usados para gestionar radiobuttons
  useEffect(() => {
    setRadioButtonsData({ current: { text: "", id: "" }, other: { text: "", id: "" } });
    setRadioButtonsTotal(null)
  }, [selectedBlockId])



  const backToRadioButtons = () => {
    sendDataToParent(radioButtonsData.other.id);
    setRadioButtonsData({ current: radioButtonsData.other, other: radioButtonsData.current })
  }

  const [modalExcel, setModalExcel] = useState(false);
  const toggleExcelModal = () => setModalExcel(!modalExcel);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 830) {
        setExcelAvailable(false);
      } else {
        setExcelAvailable(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
          <Modal show={modalExcel} className='upload-excel-modal' animation={false} data-aos="fade-up" fullscreen  backdropClassName="upload-excel-modal-backshadow">
        <ModalHeader className='upload-excel-modal-header sec-neutro-background sec-grey-text text-lg flex justify-between '>
            <ModalTitle className=''>{t("footPrint.modalExcelTitle")}</ModalTitle>
            <button onClick={toggleExcelModal}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
            </button>
        </ModalHeader>
        <ModalBody className='upload-excel-modal-body'>
          <ExcelComponent
            questBlock={questBlock}
            idCalculation={idCalculation}
            inputData={inputData}
            addAnswer1={addAnswer1}
            showSendButton={showSendButton}
            isButtonDisabled={isButtonDisabled}
          />
        </ModalBody>
        </Modal>

      {questBlock?.length === 0 || inputData === undefined ?
        <div className='flex justify-center'>
          <Spinner animation="border" variant="dark" className="m-4" />
        </div>

        :

        <div className='text-airco2-general'>



          {inputData.length > 1 && inputData[0].input.inputType !== "RADIOBUTTONS" &&
            <div className={`${inputData == 0 && "hidden"}`}>

              <div>
                <div id="custom-list-container">
                  {inputData.every((inputObj) => inputObj.input.inputType !== "RADIOBUTTONS") && (
                    <div className=''>
                      {excelAvailable &&
                        <div onClick={toggleExcelModal} className="flex justify-end">
                          <div id="footprint-excel" className="flex item-center gap-1">
                            <button className="text-sm italic">{t("footPrint.btnCargarExcel")}</button>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-spreadsheet" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                              <path d="M8 11h8v7h-8z" />
                              <path d="M8 15h8" />
                              <path d="M11 11v7" />
                            </svg>
                          </div>
                        </div>}

                      <InputsComponent
                        questBlock={questBlock}
                        idCalculation={idCalculation}
                        addAnswer1={addAnswer1}
                        isButtonDisabled={isButtonDisabled}
                        inputData={inputData}
                        showSendButton={showSendButton}
                        colLength={colLength}
                        status={status}
                        year={year}
                        orgName={orgName}
                        thirdPartyForm={thirdPartyForm}
                      />

                    </div>)}
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}

export default InfoForm;