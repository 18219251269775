import React from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
import video from '../../../images/limpios.mp4'
import imgVideo from '../../../images/imac_conportada.webp'
export default function BloqueVideo() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    return (
        <div className='container p-4 text-airco2-concellos'>
            <h1 className="text-2xl text-airco2-titles-bloques flex flex-col flex-wrap justify-center mb-8 md:mb-1  mt-8"

                data-aos="fade-up" data-aos-delay="100"
            >

                <p className='mt-4 text-center '>{(t("landingConcellos.sociedad"))}<span className='font-bold'>{(t("landingConcellos.industria"))}</span>.</p>
                <p className='mt-4 text-center'>{(t("landingConcellos.montes"))}
                    <span className='font-bold' >{(t("landingConcellos.limpios"))}</span>
                    , {(t("landingConcellos.municipios"))}
                    <span className='font-bold' >{(t("landingConcellos.limpios"))}</span>


                </p>

            </h1>

            <div className="flex justify-center ">
                <div style={{ position: 'relative', maxWidth: '100%' }}>
                    <img
                        src={imgVideo}
                        alt="Imagen"
                        style={{ width: '100%', height: 'auto', display: 'block' }}
                    />
                    <video
                        controls
                        style={{
                            width: '43%',
                            height: 'auto',
                            position: 'absolute',
                            top: '33%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            </div>

         


        </div>

    )
}
