import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import AnalyticsCard01 from '../graficas/AnalyticsCard01';
import AnalyticsCard03 from '../graficas/AnalyticsCard03';
import DashboardCard04 from '../graficas/DashboardCard04';
import GraficaAlcance from '../graficas/GraficaAlcance';
import GraficaCategoryMonth from '../graficas/GraficaCategoryMonth';
import GraficaEmisioneMesGas from '../graficas/GraficaEmisioneMesGas';
import GraficaHuellaCompensada from '../graficas/GraficaHuellaCompensada';
import GraficaHuellaGenerada from '../graficas/GraficaHuellaGenerada';
import Sidebar from '../Sidebar'
import WelcomeBanner from '../partials/WelcomeBanner';


import axios from "axios";
import UserService from '../../../services/UserService';

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import cabecera2 from '../../../images/cabecera2_reporte.png';
import cabecera from '../../../images/cabecera_reporte.png';
import firmareporte from '../../../images/firma_reporte.png';
import form2 from '../../../images/formulario_reporte1.png';
import form1 from '../../../images/formulario_reporte2.png';
import form3 from '../../../images/formulario_reporte3.png';
import portada from '../../../images/portada_reporte.png';

import format from 'date-fns/format';

import { Spinner } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"


// headers: {
//   'Accept': 'application/json',
//   'Content-Type': 'application/json',
//   'Access-Control-Allow-Origin': '*',
//   'Authorization': `Bearer ${UserService.getToken()}`
// }

// https://flaviocopes.com/axios-send-authorization-header/
function DashboardIntranet() {

  const d = new Date();
  const  currentYear = d.getFullYear();

  let [coinscrap, UpdateCoinscrap] = useState(UserService.getHasCoinscrap())
  let [originData, setOriginData] = useState(UserService.getOriginData())


  // useEffect(() => {
  //   const dataUser = allEmissions.allEmissionsData.length
  //     if (originData.includes("excel")) {

  //     originData.includes("Uploaded")
  //       ?
  //       ""
  //       :
  //       window.location.href = "/company/dashboardexcel";

  //   } else {

  //     !coinscrap && UserService.getShowCoinscrap() ? window.location.href = "/company/dashboardcoinscrap" : ""
  //   }
  // }, [allEmissions]);



  useEffect(() => {
    const dataUser = allEmissions.allEmissionsData.length;
  
    if (originData.includes("excel")) {
      if (!originData.includes("Uploaded")) {
        window.location.href = "/company/dashboardexcel";
      }
    } else {
      if (!coinscrap && UserService.getShowCoinscrap()) {
        window.location.href = "/company/dashboardcoinscrap";
      }
    }
  
    if (originData === 'Formulario') {
      window.location.href = "/footprint/myfootprint";
    }
  }, [allEmissions, originData, coinscrap]);
  
  const [loading, setLoading] = useState(false)

  const docapture = function () {
    setLoading(true);
    document.querySelector("html").classList.add("wait");

    const doc = new jsPDF('p', 'mm', 'a4');
    doc.addImage(portada, 'PNG', 0, 0, 210, 300);
    doc.addPage('a4', 'p');
    doc.addImage(cabecera, 'PNG', 0, 0, 210, 20);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize('12');
    doc.text("REPORTE", 30, 30);
    doc.text("METODOLOGÍA DE CÁLCULO", 110, 30);
    doc.setFont("Helvetica", "normal");
    doc.setFontSize('12');
    doc.text("El presente documento es un resumen en el que se muestra los principales indicadores económico medioambientales referenciados en términos de huella de dióxido de carbono como traslación del conjunto de huella de Gases de Efecto Invernadero (en adelante, GEI)."
      , 30, 40, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });
    doc.text("Este estudio se centra en analizar la huella de carbono del conjunto de la organización. A diferencia de análisis parciales ofrecidos por consultoras medioambientales enfocados en instalaciones concretas de la compañía, Airco2 valora en conjunto el total de operaciones con proveedores del usuario logrando una cuantificación con mayor exactitud."
      , 30, 108, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });
    doc.text("Para mayor detalle y periodo de cálculo, así como para disponer de herramientas que permitan facilitar la reducción de la compañía en un futuro o bien compensar para lograr un negocio net-zero favor de contactar con el equipo de ventas a través del correo ventas@airco2.eu."
      , 30, 201, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.text("La metodología de cálculo de las emisiones de GEI de " + UserService.getCompanyName() + " está basada en el estándar GHG Protocol calibrado mediante factores de emisión propios nacionales facilitados por el Ministerio de Transición Ecológica."
      , 110, 40, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });
    doc.text("•Pertinencia: se han seleccionado las fuentes de GEI, datos y metodologías apropiadas."
      , 120, 108, { maxWidth: 60, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });
    doc.text("•Cobertura total: se han incluido todas las emisiones y remociones pertinentes de GEI"
      , 120, 142, { maxWidth: 60, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });
    doc.text("•Coherencia: se han permitido comparaciones significativas en la información relacionada con los GEI."
      , 120, 167, { maxWidth: 60, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });
    doc.text("•Precisión: se ha areducido el sesgo y la incertidumbre, en la medida de lo posible."
      , 120, 201, { maxWidth: 60, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });
    doc.text("•Transparencia: Se ha divulgado información suficiente y apropiada relacionada con los GEI para permitir al usuario tomar decisiones con confianza razonable."
      , 120, 226, { maxWidth: 60, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });
    doc.text("•Límites de la organización: " + UserService.getCompanyName() + " consolida sus emisiones "
      , 120, 276, { maxWidth: 60, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });

    doc.addPage('a4', 'p');
    doc.addImage(cabecera, 'PNG', 0, 0, 210, 20);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize('12');
    doc.setFont("Helvetica", "normal");
    doc.text("contabilizando el 100% de las emisiones de GEI atribuibles a las operaciones sobre las cuales ejerce el control. " +
      "En concreto, " + UserService.getCompanyName() + " ejerce el control financiero sobre sus operaciones, ya que tiene la facultad de dirigir sus políticas financieras y operativas."
      , 40, 30, { maxWidth: 60, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.text("Según la norma UNE-ISO 14064-1 las empresas deben contabilizar de manera separada al menos los alcances 1 y 2. En este momento a " + UserService.getCompanyName() + " se le aportan dichos alcances y a mayores elementos del alcance 3."
      , 30, 123, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.setFont("Helvetica", "bold");
    doc.text("Alcance 1. "
      , 30, 183, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.setFont("Helvetica", "normal");
    doc.text("Las  emisiones directas de"
      , 52, 183, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });
    doc.text("GEI (Alcance 1) son emisiones de fuentes que son propiedad de o están controladas por la empresa, resultado de los siguientes tipos de actividades:"
      , 30, 191, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });
    doc.text("• Transporte de materiales y productos. Estas emisiones resultan de la combustión de gasoil en fuentes móviles como las carretillas y grúas de las plantas."
      , 30, 232, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 1.8 });

    doc.text("• Emisiones fugitivas. Estas emisiones resultan de fugas de "
      , 30, 271, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 1.8 });

    doc.text("emisiones de gases refrigerantes (HFCs) durante el uso de equipos de aire acondicionado y refrigeración."
      , 110, 30, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });

    doc.setFont("Helvetica", "bold");
    doc.text("Alcance 2.", 110, 55, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });

    doc.setFont("Helvetica", "normal");
    doc.text(" Las  emisiones  indirectas"
      , 132, 55, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });

    doc.text(
      "de GEI son emisiones consecuencia de las actividades de la empresa, pero que " +
      "ocurren en fuentes que no son propiedad o están controladas por otra empresa. Se han cuantificado las " +
      "emisiones indirectas de GEI que provienen de la generación de electricidad."
      , 110, 64, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });

    doc.text(
      "El consumo de energía eléctrica se distribuye en casi todos los departamentos de la instalación para " +
      "accionar los múltiples equipos y herramientas mecánicas del proceso."
      , 110, 132, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });

    doc.setFont("Helvetica", "bold");
    doc.text("Alcance 3.", 110, 174, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });

    doc.setFont("Helvetica", "normal"); doc.text("Se trata  de  una  categoría", 132, 174, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });

    doc.text("opcional. Nuestro algoritmo analiza determinados movimientos de dicha" +
      "categoría para enriquecer el resultado final."
      , 110, 182, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });


    doc.addPage('a4', 'p');
    doc.addImage(cabecera, 'PNG', 0, 0, 210, 20);
    doc.setFont("Helvetica", "bold");
    doc.text("METODOLOGÍA DE CUANTIFICACIÓN", 30, 30);
    doc.setFont("Helvetica", "normal");
    doc.text("Se han cuantificado y documentado las emisiones de " + UserService.getCompanyName() + " completando las siguientes fases:"
      , 30, 40, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.text("a) Categorización del transaccional", 40, 74, { maxWidth: 60, maxHeigth: 4, lineHeightFactor: 2 });
    doc.text("b) Identificación de categorías de proveedores", 40, 90, { maxWidth: 60, maxHeigth: 4, lineHeightFactor: 2 });
    doc.text("c) Traslación de información en precios a unidades de consumo", 40, 106, { maxWidth: 60, maxHeigth: 4, lineHeightFactor: 2 });
    doc.text("d) Selección de los factores de emisión de GEI", 40, 122, { maxWidth: 60, maxHeigth: 4, lineHeightFactor: 2 });
    doc.text("e) Cálculo de las emisiones de GEI", 40, 138, { maxWidth: 60, maxHeigth: 4, lineHeightFactor: 2 });

    doc.text("Se ha identificado y documentado el transaccional del usuario a partir de tecnología Open Banking soportada por la Directiva Europea de PSD2. " +
      "Su categorización ha corrido por cuenta propia valiéndose Airco2 Fintech S.L. de su capacidad algorítmica."
      , 30, 154, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.text("Para calcular las emisiones de GEI se han tenido en cuenta los datos de actividad y los factores de emisión. Así pues, la expresión empleada ha sido la siguiente: "
      , 30, 221, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.setFont("Helvetica", "italic");
    doc.text("Emisiones CO₂ = Datos actividad x Factor de emisión x Factor de conversión"
      , 30, 263, { maxWidth: 70, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.setFont("Helvetica", "normal");
    doc.text("Las emisiones pertenecientes al alcance 1 han sido calculadas en base a las cantidades adquiridas de combustibles comerciales utilizando los " +
      "factores de emisión publicados en el último inventario nacional. El factor de emisión considerado para las emisiones indirectas (alcance 2) derivadas " +
      "del consumo eléctrico ha sido seleccionado siguiendo la empresa comercializadora y la tabla del MITECO."
      , 110, 40, { maxWidth: 70, maxHeigth: 180, align: 'justify', lineHeightFactor: 2 });


    doc.addPage('a4', 'p');
    doc.addImage(cabecera2, 'PNG', 10, 0, 190, 40);
    doc.setFontSize('11');
    doc.setFont("Helvetica", "bold");
    doc.text(" En Pontevedra, a " + format(new Date(), 'dd-MM-yyyy'), 30, 70);
    doc.setFont("Helvetica", "normal");
    doc.text("Airco2 FINTECH S.L. certifica que la empresa " + UserService.getCompanyName() + " ha calculado su huella medioambiental en términos de tCO2eq a partir de un examen " +
      "exhaustivo del conjunto de proveedores mediante los cuales lleva a cabo su actividad mercantil, para ello Airco2 se ha valido del análisis de " +
      "información de carácter financiera y los principios establecidos en GHG Protocol, así como las últimas recomendaciones de otros cuerpos " +
      "normativos internacionales y factores de emisión establecidos por organismos estatales."
      , 30, 90, { maxWidth: 150, maxHeigth: 170, align: 'justify', lineHeightFactor: 2 });

    doc.setDrawColor(220, 220, 220);
    doc.rect(30, 150, 80, 10);
    doc.setFont("Helvetica", "bold");
    doc.text("Huella de carbono resultante: " + Math.round(emisionTotal.emisionTotalData.co2, -1), 32, 156);
    doc.rect(30, 170, 80, 30);
    doc.text("Período analizado: ", 32, 176);
    doc.setFont("Helvetica", "normal");
    doc.text("Desde: " + format(new Date(new Date().getFullYear(), '00', '01'), 'dd/MM/yyyy'), 32, 186);
    doc.text("Hasta: " + format(new Date(new Date().getFullYear() + 1, '00', '01'), 'dd/MM/yyyy'), 32, 196);
    doc.addImage(firmareporte, 'PNG', 12, 210, 190, 85);

    doc.addPage('a4', 'p');
    doc.setFontSize('13');
    doc.setFont("Helvetica", "bold");
    doc.text("Resumen emisiones " + UserService.getCompanyName() + " " + format(new Date(), 'dd/MM/yyyy')
      , 38, 10);;
    var ghuellageneradaImg;
    var ghuellacompensadaImg;
    var galcanceImg;
    var gcategorymonthImg;
    var gdashboard04Img;
    var ganalytics03Img;
    var gemisionesgasImg;

    if (document.documentElement.clientWidth < 1390) {
      html2canvas(document.getElementById("ghuellagenerada"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        ghuellageneradaImg = canvas.toDataURL("imageh1/png", 1.0);
        doc.addImage(ghuellageneradaImg, 'PNG', 38, 20, 250, 200);
      })
      html2canvas(document.getElementById("ghuellacompensada"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        ghuellacompensadaImg = canvas.toDataURL("imageh2/png", 1.0);
        doc.addImage(ghuellacompensadaImg, 'PNG', 38, 95, 250, 200);
      })
      html2canvas(document.getElementById("galcance"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        galcanceImg = canvas.toDataURL("image1/png", 1.0);
        doc.addImage(galcanceImg, 'PNG', 38, 170, 250, 200);
      })
      html2canvas(document.getElementById("gcategorymonth"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        gcategorymonthImg = canvas.toDataURL("image2/png", 1.0);
        doc.addPage('a4', 'p');
        doc.addImage(gcategorymonthImg, 'PNG', 38, 25, 250, 200);
      })
      html2canvas(document.getElementById("gdashboard04"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        gdashboard04Img = canvas.toDataURL("image3/png", 1.0);
        doc.addImage(gdashboard04Img, 'PNG', 38, 150, 250, 200);
      })
      html2canvas(document.getElementById("ganalytics03"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        ganalytics03Img = canvas.toDataURL("image4/png", 1.0);
        doc.addPage('a4', 'p');
        doc.addImage(ganalytics03Img, 'PNG', 38, 25, 250, 200);
      })
      html2canvas(document.getElementById("gemisionesgas"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        gemisionesgasImg = canvas.toDataURL("image5/png", 1.0);
        doc.addImage(gemisionesgasImg, 'PNG', 38, 160, 250, 200);

        doc.addPage('a4', 'p');
        doc.setFont("Helvetica", "bold");
        doc.text("INSCRIPCIÓN EN EL REGISTRO DEL MITECO ", 20, 20);
        doc.addImage(form1, 'PNG', 18, 30, 172, 240);

        doc.addPage('a4', 'p');
        doc.setFont("Helvetica", "bold");
        doc.text("INSCRIPCIÓN EN EL REGISTRO DEL MITECO ", 20, 20);
        doc.addImage(form2, 'PNG', 23, 30, 162, 240);

        doc.addPage('a4', 'p');
        doc.setFont("Helvetica", "bold");
        doc.addImage(form3, 'PNG', 12, 10, 180, 140);
        doc.save('reporte_' + UserService.getCompanyName() + "_" + format(new Date(), 'dd-MM-yyyy'));
        document.querySelector("html").classList.remove("wait");

      })
    }

    if (document.documentElement.clientWidth > 1390) {
      html2canvas(document.getElementById("ghuellagenerada"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        ghuellageneradaImg = canvas.toDataURL("imageh1/png", 1.0);
        doc.addImage(ghuellageneradaImg, 'PNG', 40, 20, 200, 200);
      })
      html2canvas(document.getElementById("ghuellacompensada"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        ghuellacompensadaImg = canvas.toDataURL("imageh2/png", 1.0);
        doc.addImage(ghuellacompensadaImg, 'PNG', 40, 95, 200, 200);
      })
      html2canvas(document.getElementById("galcance"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        galcanceImg = canvas.toDataURL("image1/png", 1.0);
        doc.addImage(galcanceImg, 'PNG', 40, 170, 200, 180);
      })
      html2canvas(document.getElementById("gcategorymonth"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        gcategorymonthImg = canvas.toDataURL("image2/png", 1.0);
        doc.addPage('a4', 'p');
        doc.addImage(gcategorymonthImg, 'PNG', 38, 25, 200, 200);
      })
      html2canvas(document.getElementById("gdashboard04"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        gdashboard04Img = canvas.toDataURL("image3/png", 1.0);
        doc.addImage(gdashboard04Img, 'PNG', 38, 150, 200, 200);
      })
      html2canvas(document.getElementById("ganalytics03"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        ganalytics03Img = canvas.toDataURL("image4/png", 1.0);
        doc.addPage('a4', 'p');
        doc.addImage(ganalytics03Img, 'PNG', 38, 25, 200, 200);
      })
      html2canvas(document.getElementById("gemisionesgas"), {
        width: 1000,
        height: 800
      }).then(function (canvas) {
        gemisionesgasImg = canvas.toDataURL("image5/png", 1.0);
        doc.addImage(gemisionesgasImg, 'PNG', 38, 160, 200, 200);

        doc.addPage('a4', 'p');
        doc.setFont("Helvetica", "bold");
        doc.text("INSCRIPCIÓN EN EL REGISTRO DEL MITECO ", 20, 20);
        doc.addImage(form1, 'PNG', 18, 30, 172, 240);

        doc.addPage('a4', 'p');
        doc.setFont("Helvetica", "bold");
        doc.text("INSCRIPCIÓN EN EL REGISTRO DEL MITECO ", 20, 20);
        doc.addImage(form2, 'PNG', 23, 30, 162, 240);

        doc.addPage('a4', 'p');
        doc.setFont("Helvetica", "bold");
        doc.addImage(form3, 'PNG', 12, 10, 180, 140);
        doc.save('reporte_' + UserService.getCompanyName() + "_" + format(new Date(), 'dd-MM-yyyy'));
        document.querySelector("html").classList.remove("wait");

      })
    }
    setLoading(false);
  }

  const [sidebarOpen, setSidebarOpen] = useState(false);

  //Obtiene la huella total generada de una empresa por año /emissions/v1/user/total OKK
  const [emisionTotal, setEmisionstotal] = useState({
    emisionTotalData: [],
    isFetching: false
  });


  //Obtiene la huella total generada de una empresa por año /emissions/v1/user/total OKK
  useEffect(() => {
    const fetchEmisionTotalData = async () => {
      try {
        setEmisionstotal({ emisionTotalData: emisionTotal.emisionTotalData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_EMISSIONS_USER_TOTAL}/${UserService.getUserId()}/${currentYear}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setEmisionstotal({ emisionTotalData: response.data, isFetching: false });
      } catch (exception) {
        console.error(exception);
        setEmisionstotal({ emisionTotalData: emisionTotal.emisionTotalData, isFetching: false });
      }
    };
    fetchEmisionTotalData();
  }, []);


  //Obtiene el resumen de las emisiones de una empresa en un intervalo /emissions/v1/user/summary OKK
  const [summary, setSummary] = useState({
    summaryData: [],
    isFetching: false
  });

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        setSummary({ summaryData: summary.summaryData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_EMISSIONS_USER_SUMMARY}/${UserService.getUserId()}/${currentYear}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setSummary({ summaryData: response.data, isFetching: false });

      } catch (exception) {
        console.error(exception);
        setSummary({ summaryData: summary.summaryData, isFetching: false });
      }
    };
    fetchSummaryData();
  }, []);


  //Obtiene las emisiones de un usuario por mes y alcance  /emissions/v1/user/scope/monthly OK
  const [alcance, setAlcance] = useState({
    alcanceData: [],
    isFetching: false
  });

  useEffect(() => {
    const fetchAlcanceData = async () => {
      try {
        setAlcance({ alcanceData: alcance.alcanceData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_EMISSIONS_USER_SCOPE_MONTHLY}/${UserService.getUserId()}/${currentYear}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setAlcance({ alcanceData: response.data, isFetching: false });

      } catch (exception) {
        console.error(exception);
        setAlcance({ alcanceData: alcance.alcanceData, isFetching: false });
      }
    };
    fetchAlcanceData();
  }, []);




  //Obtiene las emisiones de un usuario por mes y categoría de un año en concreto /emissions/v1/user/category/monthly OKK
  const [categoryMonth, setCategoryMonth] = useState({
    categoryMonthData: [],
    isFetching: false
  });

  useEffect(() => {
    const fetchCategoryMonthData = async () => {
      try {
        setCategoryMonth({ categoryMonthData: categoryMonth.categoryMonthData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_EMISSIONS_USER_CATEGORY_MONTHLY}/${UserService.getUserId()}/${currentYear}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setCategoryMonth({ categoryMonthData: response.data, isFetching: false });

      } catch (exception) {
        console.error(exception);
        setCategoryMonth({ categoryMonthData: categoryMonth.categoryMonthData, isFetching: false });
      }
    };
    fetchCategoryMonthData();
  }, []);



  //Obtiene las emisiones de un usuario en un intervalo /emissions/v1/user/all   OKK
  const [allEmissions, setAllEmissions] = useState({
    allEmissionsData: [],
    isFetching: false
  });

  useEffect(() => {
    updateLoading(true)
    const fetchAllEmissionsData = async () => {
      try {
        setAllEmissions({ allEmissionsData: allEmissions.allEmissionsData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_EMISSIONS_USER_ALL}/${UserService.getUserId()}/${currentYear}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setAllEmissions({ allEmissionsData: response.data, isFetching: false });
          updateLoading(false);
        });

      } catch (exception) {
        console.error(exception);
        setAllEmissions({ allEmissionsData: allEmissions.allEmissionsData, isFetching: false });
        updateLoading(false);
      }
    };
    fetchAllEmissionsData();
  }, []);


  //Obtiene lista de categorias y subcategorias /emissions/v1/categories OKK
  const [categorias, setCategorias] = useState({
    categoriasData: [],
    isFetching: false
  });

  useEffect(() => {
    const fetchCategoriasData = async () => {
      try {
        setCategorias({ categoriasData: categorias.categoriasData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_EMISSIONS_CATEGORIES}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setCategorias({ categoriasData: response.data, isFetching: false });

      } catch (exception) {
        console.error(exception);
        setCategorias({ categoriasData: categorias.categoriasData, isFetching: false });
      }
    };
    fetchCategoriasData();
  }, []);








  //Obtiene el resumen de las emisiones compensadas en lo que va de año
  const [upLoading, updateLoading] = useState(true);
  const [transactionsSummary, setTransactionsSummary] = useState({
    transactionsSummaryData: [],
    isFetching: false,
  });

  useEffect(() => {
    //  updateLoading(true)
    const fetchTransactionsSummary = async () => {
      try {
        setTransactionsSummary({ transactionsSummaryData: transactionsSummary.transactionsSummaryData, isFetching: true });
        //TODO; fecha
        await axios.get(`${process.env.REACT_APP_TRANSACTIONS_USER_ALL}/${UserService.getUserId()}/${currentYear}-01-01/${currentYear}-12-31`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setTransactionsSummary({ transactionsSummaryData: response.data, isFetching: false });
          //updateLoading(false)
        });

      } catch (exception) {
        console.error(exception);
        // updateLoading(false)
        setTransactionsSummary({ transactionsSummaryData: transactionsSummary.transactionsSummaryData, isFetching: false });
      }
    };
    fetchTransactionsSummary();
  }, []);

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  // const transactions = transactionsSummary.transactionsSummaryData;


  return (

    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden sec-neutro-background">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />


        <main>

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto sec-neutro-background">


            {upLoading && <Spinner animation="border" variant="light" className='flex justify-center' />}

            {!upLoading && allEmissions.allEmissionsData.length === 0 &&
              <div style={{ marginTop: '8%' }}>
                <div className=' flex justify-center'>
                  <Image src="https://i.ibb.co/2dPVCFw/Footer-Mail.png"></Image>
                </div>
                <div className=' flex justify-center text-center'>
                  {UserService.getOriginData() == "excelUploaded" ?
                    <div>
                      <p className='h5'>{t("dash.nohayemisionesexcel1")}</p>
                      <p>{t("dash.nohayemisionesexcel2")}</p>
                    </div>
                    :
                    <p>{t("dash.nohayemisiones")}</p>
                  }







                </div>
              </div>}


            {!upLoading && allEmissions.allEmissionsData.length != 0 &&

              <div>
                <h1 className='h2 text-center'>{t("dash.emisiones")} {currentYear} 🌎</h1>

                <div className='flex justify-center md:justify-end mb-4'>
                  {loading
                    ?
                    <Spinner animation="border" variant="light" className='flex justify-center' />
                    : <>
                      <button arial-label="btn-DownReporte" id="buttonReport" className='btn btn-sm sec-green-background' onClick={docapture}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-download" width="33" height="33" viewBox="0 0 24 24" stroke="#ffffff" fill="none" >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <line x1="12" y1="11" x2="12" y2="17" />
                          <polyline points="9 14 12 17 15 14" />
                        </svg>
                        <span className='pl-2 text-sm text-white font-bold'>{t("dash.reporte")}</span>
                      </button>
                    </>
                  }
                </div>
                <div className="mb-8 border-b border-gray-200">
                </div>

                <div>
                  {/*Gráficas de CO2 compensado/generado */}
                  <div className="align-center grid grid-cols-12 gap-6 pb-4">
                    <GraficaHuellaGenerada data={categoryMonth.categoryMonthData} />
                    <GraficaHuellaCompensada data={transactionsSummary.transactionsSummaryData} dataGenerada={categoryMonth.categoryMonthData} />
                  </div>

                  {/*Banner compensate CO2 */}
                  {/* <WelcomeBanner /> */}

                  {/* Graficas */}
                  <div id="divgraficasdashboard" className="grid grid-cols-12 gap-6">
                    <GraficaAlcance data={alcance.alcanceData} />
                    <GraficaCategoryMonth data={categoryMonth.categoryMonthData} />
                    {/* <TablaDescubreTuHuella data={emisionestest} /> */}
                    <DashboardCard04 data={categoryMonth.categoryMonthData} />
                    <AnalyticsCard03 data={categoryMonth.categoryMonthData} />
                    {/* <AnalyticsFootprint03 data={emisionestest} /> */}
                    {/* <AnalyticsFootprint04 data={emisionestest} /> */}
                    <GraficaEmisioneMesGas data={categoryMonth.categoryMonthData} />
                    <AnalyticsCard01 />
                  </div>
                </div >

              </div>


            }
          </div>
        </main>

      </div>

    </div>



  );

}

export default DashboardIntranet;