import React from 'react'
import Header from '../../../partials/Header'
import Footer from '../../../partials/Footer'
import BloqueCartel from './BloqueCartel'
import BloqueNeutraliza from './BloqueNeutraliza'
import BloqueBanner from './BloqueBanner'


export default function landingBosqueTemplate() {
  return (
    <main>
      <Header></Header>
      <div style={{ minHeight: 'calc(100vh - 94px)', paddingTop: '88px' }} >
        {/* children */}

        <main className=''>
          <BloqueCartel></BloqueCartel>
          <BloqueNeutraliza></BloqueNeutraliza>
          <BloqueBanner></BloqueBanner>
          
         


        </main>

      </div>
      {/* Footer */}
      <Footer></Footer>


    </main>
  )
}
