const steps = [
    {
        target: '.welcome-sidebar-general',
        content: 'Este es nuestro sidebar!\nDesde aquí puedes acceder a las principales funcionalidades de Airco2',
        placement: 'right',
        disableScrolling: true,
        disableBeacon: true

    },
    {
      target: '.welcome-sidebar-mis-calculos',
      content: 'Aquí podrás ver todos tus cálculos y gestionar todo lo referente a estos.',
      placement: 'right',
      disableScrolling: true,
      disableBeacon: true

  },
  {
    target: '.welcome-sidebar-nuevo-calculo',
    content: 'Aquí puedes crear un nuevo cálculo, que es la pantalla que estás viendo ahora mismo.',
    placement: 'right',
    disableScrolling: true,
    disableBeacon: true

},
{
  target: '.welcome-sidebar-organizaciones',
  content: 'Desde aquí puedes crear, borrar, editar y ver tus organizaciones.',
  placement: 'right',
  disableScrolling: true,
  disableBeacon: true

},
// {
//   target: '.welcome-sidebar-planes',
//   content: 'Si todavía no te has animado, desde aquí podrás contratar uno de nuestros planes en cualquier momento!',
//   placement: 'right',
//   disableScrolling: true,
//   disableBeacon: true

// },
{
  target: '.welcome-sidebar-contactos',
  content: 'Esto es nuevo!\nDesde aquí puedes añadir contactos relacionados con tu organización (empleados, proveedores o distribuidores).\n Pronto podrás envíar formularios para que ellos mismos completen tu huella de terceros.',
  placement: 'right',
  disableScrolling: true,
  disableBeacon: true

},
{
  target: '.welcome-sidebar-perfil',
  content: 'Y por último, aquí tienes tu perfil, donde puedes modificar todos tus datos y tu suscripción.',
  placement: 'right',
  disableScrolling: true,
  disableBeacon: true

},
  {
    target: '.my-first-step',
    content: 'Vamos a empezar con tu primer cálculo!\nPrimero selecciona el año sobre el que quieres calcular tu huella',
    placement: 'bottom',
    disableScrolling: true,
    disableBeacon: true
  },
  {
    target: '.my-second-step',
    content: 'Ahora toca crear tu organización!',
    placement: 'bottom',
    disableScrolling: true,
    disableBeacon: true

},
{
  target: '.my-third-step',
  content: 'Si quieres también puedes añadir la facturación anual de la organización',
  placement: 'top',
  disableScrolling: false,
  disableBeacon: true

},
{
  target: '.my-fourth-step',
  content: 'Por último, indica el número de empleados de la organización',
  placement: 'top',
  disableScrolling: false,
  disableBeacon: true

},
{
  target: '.my-fifth-step',
  content: 'Si quieres haciendo click en este desplegable podrás completar información medioambiental y de carácter Social y laboral.',
  placement: 'top',
  disableScrolling: false,
  disableBeacon: true

},
{
  target: '.my-sixth-step',
  content: 'Una vez tengas todos los campos obligatorios completados dale a Siguiente y ya estrás dentro de nuestra calculadora!',
  placement: 'top',
  disableScrolling: false,
  disableBeacon: true

},



// 1
{
  target: '#footprint-title',
  content: 'Bienvenido a la calculadora! aquí tienes el nombre de la organización y el año para el que vas a realizar el cálculo',
  placement: 'bottom',
  disableScrolling: false,
  disableBeacon: true

},

// 2
{
  target: '#footprint-button-left',
  content: 'En esta parte podrás: volver a tus cálculos,ir a huella de terceros,ir a compromisos de reducción',
  placement: 'bottom',
  disableScrolling: false,
  disableBeacon: true

},


// 3
{
  target: '#footprint-button-right',
  content: 'Aquí controlarás el formulario. Puedes pasar las preguntas y finalizar. Estos mismos controles los tienes en la parte inferior',
  placement: 'bottom',
  disableScrolling: false,
  disableBeacon: true

},


// 4
{
  target: '#footprint-menu',
  content: '\nEste es el menú con todas las preguntas que puedes responder, puedes usarlo para moverte entre todas las preguntas, para ver a que alcance pertenecen y también podrás ver facilmente cuales has contestado. No es obligatorio que contestes a todas las preguntas',
  placement: 'bottom',
  disableScrolling: false,
  disableBeacon: true

},
// 5
{
  target: '#footprint-description-section',
  content: 'Con este control puedes leer la descripción e instrucciones de cada pregunta. Puedes mostrarlas u ocultarlas cuando lo necesites',
  placement: 'bottom',
  disableScrolling: false,
  disableBeacon: true

},
//6
{
  target: '#footprint-excel',
  content: '¿Tienes tus datos en un archivo excel o csv? Aquí podrás cargar tus datos',
  placement: 'bottom',
  disableScrolling: false,
  disableBeacon: true

},

//7
{
  target: '#footprint-form',
  content: 'Si por el contrario no tienes ningún archivo con tus datos... aquí debes introducir los datos necesarios para contestar la pregunta.',
  placement: 'bottom',
  disableScrolling: false,
  disableBeacon: true

},

{
  target: '.footprint-seccion-respuestas',
  content: 'Como todavía no has contestado a la pregunta no ves nada, pero aquí tendrás una tabla con todas los datos que has añadido a la pregunta pudiendo borrar, duplicar, editar y subir una factura vinculándola a una respuesta.',
  placement: 'top',
  disableScrolling: false,
  disableBeacon: true

},


];

export default steps;