import React from 'react'
import Forestal from './Forestal'
import Footer from '../../partials/Footer';
import Header from '../../partials/Header';

export default function ForestalHome() {
  return (
    <div>
      <Header></Header>
      <Forestal></Forestal>
      <Footer></Footer></div>
  )
}
