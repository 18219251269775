import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'

export default function BloqueBanner() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
  return (
    <div className='banner-footer-landing flex flex-col flex-wrap  items-center justify-center text-landing-claim'>
        
        
        <h3 className=" text-white m-3 " data-aos="fade-up" data-aos-delay="100" >  {t("landingEventos.plantarNoSolucion")}
                </h3>
                <h3 className=" text-white m-3  " data-aos="fade-up" data-aos-delay="200" >  {t("landingEventos.menosEmites")}
                </h3>
        
        
        </div>
  )
}
