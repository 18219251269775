import axios from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import UserService from '../../../services/UserService';
import { formatDateTime, formatThousands } from '../utils/Utils';
import MarketplacImages from './ImagesAdmin'
import Banner from '../../../components/utils/Banner';
import addForm from '../../../images/addForm.svg'

export function MarketplaceProduct() {
    return (<>
        <BodyPage></BodyPage>
    </>)
}


function ProductsMarketplace() {
    const [products, setProducts] = useState({
        data: [],
        isFetching: false
    });

    const [productsModArray, setProductsModArray] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                //TODO: envs
                const response = await axios.get(`${process.env.REACT_APP_MP_ALL_MP_PRODUCTS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setProducts({ data: response.data, isFetching: true });
                setProductsModArray(response.data)
            } catch (exception) {
                setProducts({ data: [], isFetching: true });
            }
        };
        fetchProducts();
    }, []);



    const [totalHaValidadas, setTotalHaValidadas] = useState(0)
    const [totalToneladasValidadas, setTotalToneladasValidadas] = useState(0)
    const [stockToneladasValidadas, setStockToneladasValidadas] = useState(0)

    useEffect(() => {
        if (products.data.length > 0) {
            setTotalHaValidadas(formatThousands(products.data.reduce((sum, li) => sum + li.size, 0)));
            setTotalToneladasValidadas(formatThousands(products.data.reduce((sum, li) => sum + li.totalCredits, 0)));
            setStockToneladasValidadas(formatThousands(products.data.reduce((sum, li) => sum + li.stockCredits, 0)));

        }
    }, [products])


    const statusColor = (status) => {
        switch (status) {
            case true:
                return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00b341" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l5 5l10 -10" />
                </svg>
            case false:
            default:
                return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff4500" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
        }
    };

    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);

        const sortedItems = useMemo(() => {
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (!isNaN(a[sortConfig.key]) || moment(a[sortConfig.key]).isValid()) {
                        if (a[sortConfig.key] < b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? -1 : 1;
                        }
                        if (a[sortConfig.key] > b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? 1 : -1;
                        }
                    } else {
                        if (a[sortConfig.key].toString().toLowerCase() < b[sortConfig.key].toString().toLowerCase()) {
                            return sortConfig.direction === 'ascending' ? -1 : 1;
                        }
                        if (a[sortConfig.key].toString().toLowerCase() > b[sortConfig.key].toString().toLowerCase()) {
                            return sortConfig.direction === 'ascending' ? 1 : -1;
                        }
                    }

                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(productsModArray);

    const [filtros, setFiltros] = useState({
        location: "",
        name: "",
        provider: ""
    });
    const OnFilterProvincia = (filter, filteredData) => {
        return filteredData.filter((v) =>
            v.region.toLowerCase().includes(filter.toLowerCase()) || v.state.toLowerCase().includes(filter.toLowerCase()) || v.city.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const OnFilterNombreMonte = (filter, filteredData) => {
        return filteredData.filter((v) =>
            v.name.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const OnFilterprovider = (filter, filteredData) => {
        return filteredData.filter((v) =>
            v.provider.toLowerCase().includes(filter.toLowerCase())
        );
    };


    useEffect(() => {
        const handleInputChange = () => {
            let filteredData = products.data;

            if (filtros.location) {
                filteredData = OnFilterProvincia(filtros.location, filteredData);
            }

            if (filtros.name) {
                filteredData = OnFilterNombreMonte(filtros.name, filteredData);
            }

            if (filtros.provider) {
                filteredData = OnFilterprovider(filtros.provider, filteredData);
            }

            setProductsModArray(filteredData)
        }
        handleInputChange();

    }, [filtros]);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const handleOpen = (p, index) => {
        setShowProductModal(true);
        setProductModal(p)
        setProductIndexModal(index)
    }

    const setProduct = (p, index) => {
        let newArr = [...products.data];
        newArr[index] = p;
        setProducts({ data: newArr, isFetching: true });
        setProductsModArray(newArr);
        requestSort(sortConfig)
    }

    const handleOpenProductImage = (p) => {
        setShowProductImagesModal(true);
        setProductImagesModal(p)
    }
    const [showProductModal, setShowProductModal] = useState(false)
    const [productModal, setProductModal] = useState(true)
    const [productIndexModal, setProductIndexModal] = useState(true)

    const [showProductImagesModal, setShowProductImagesModal] = useState(false)
    const [productImagesModal, setProductImagesModal] = useState(true)
    return (<>
        <div className='sm:flex sm:justify-between sm:items-center'>
            <div className="p-3 ">
                <div className="mb-2 font-bold">
                    <h2 className="font-bold">Filtros</h2>
                </div>
                <div className="grid gap-4 md:grid-cols-3">
                    <div className="">
                        <label className="block text-sm font-medium mb-1" htmlFor="default">Nombre provincia</label>
                        <input value={filtros.location}
                            className="form-input w-full rounded-lg "
                            onChange={(e) =>
                                setFiltros((prevFiltros) => ({
                                    ...prevFiltros,
                                    location: e.target.value,
                                }))
                            }
                            placeholder="Busca por provincia"
                            type="text"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="default">Nombre del monte</label>
                        <input value={filtros.name}
                            className="form-input w-full rounded-lg "
                            onChange={(e) =>
                                setFiltros((prevFiltros) => ({
                                    ...prevFiltros,
                                    name: e.target.value,
                                }))
                            }
                            placeholder="Busca por nombre del monte"
                            type="text"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="default">Propietario</label>
                        <input value={filtros.provider}
                            className="form-input w-full rounded-lg "
                            onChange={(e) =>
                                setFiltros((prevFiltros) => ({
                                    ...prevFiltros,
                                    provider: e.target.value,
                                }))
                            }
                            placeholder="Busca por propietario"
                            type="text"
                        />
                    </div>
                </div>
            </div>

            <div className="text-center sm:text-right"><b>Total hectáreas:</b> {totalHaValidadas} ha<br />
                <b>Total CO₂:</b> {totalToneladasValidadas} t <br />
                <b>Disponible CO₂:</b> {stockToneladasValidadas} t </div>
        </div>
        <EditProduct product={productModal} show={showProductModal} setShow={setShowProductModal} setProduct={setProduct} index={productIndexModal}></EditProduct>
        <ProductImages product={productImagesModal} show={showProductImagesModal} setShow={setShowProductImagesModal} setProduct={setProduct} index={productIndexModal}></ProductImages>

        <p className='text-xs'>Pulsar en las cabeceras para ordenar</p>
        <div className="overflow-x-auto rounded-lg" style={{ height: '800px', overflowY: 'scroll' }}>
            <table className="table-bordered table-striped text-md  table-auto w-full divide-y divide-gray-200 ">
                <thead className='sticky top-0 sec-green-background z-20 text-white'>
                    <tr>
                        <th>
                            <button type="button" onClick={() => requestSort('name')}
                                className={`${getClassNamesFor('name')} `}>
                                Nombre
                            </button>
                        </th>
                        <th> <button type="button" onClick={() => requestSort('provider')}
                            className={`${getClassNamesFor('provider')} `}>
                            Propietario
                        </button></th>
                        <th> <button type="button" onClick={() => requestSort('city')}
                            className={`${getClassNamesFor('city')} `}>
                            Localización
                        </button></th>
                        <th>
                            <button type="button" onClick={() => requestSort('totalCredits')}
                                className={`${getClassNamesFor('totalCredits')} `}>
                                Total CO₂
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('stockCredits')}
                                className={`${getClassNamesFor('stockCredits')} `}>
                                Stock CO₂
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('price')}
                                className={`${getClassNamesFor('price')} `}>
                                Precio tonelada
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('active')}
                                className={`${getClassNamesFor('active')} `}>
                                ¿Activo?
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('validated')}
                                className={`${getClassNamesFor('validated')} `}>
                                ¿Validado?
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('creationDate')}
                                className={`${getClassNamesFor('creationDate')} `}>
                                Creado
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('updatedDate')}
                                className={`${getClassNamesFor('updatedDate')} `}>
                                Actualizado
                            </button>
                        </th>
                        <th>Acciones</th>
                    </tr>

                </thead>
                <tbody className='bg-white'>
                    {!products.isFetching && <Spinner animation="border" variant="dark" />}
                    {items.map((p, i) => {
                        return (<tr key={i}>
                            <td>
                                {p.name}
                            </td>
                            <td>
                                {p.provider}
                            </td>
                            <td>
                                {p.city} - {p.state} - {p.region}
                            </td>
                            <td>
                                {formatThousands(p.totalCredits)} t
                            </td>
                            <td>
                                {formatThousands(p.stockCredits)} t
                            </td>
                            <td>
                                {formatThousands(p.price)} €/t
                            </td>
                            <td>
                                <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(p.active)}`}>
                                    {statusColor(p.active)}
                                </div>
                            </td>
                            <td>
                                <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(p.validated)}`}>
                                    {statusColor(p.validated)}
                                </div>
                            </td>
                            <td>
                                {formatDateTime(p.creationDate)}
                            </td>
                            <td>
                                {formatDateTime(p.updatedDate)}
                            </td>
                            <td>
                                <button title="Editar producto" onClick={() => handleOpen(p, i)} className="active" aria-pressed="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                                        <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                                    </svg>
                                </button>

                                <button title="Imágenes producto" onClick={() => handleOpenProductImage(p)} className="active" aria-pressed="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="15" y1="8" x2="15.01" y2="8" />
                                        <rect x="4" y="4" width="16" height="16" rx="3" />
                                        <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                                        <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
                                    </svg>
                                </button>
                                <button title='Ver en Marketplace' onClick={() => window.open(`${process.env.REACT_APP_CATALOGUE_MP}/name/${p.id}`)} >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-store" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 21l18 0" />
                                        <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                                        <path d="M5 21l0 -10.15" />
                                        <path d="M19 21l0 -10.15" />
                                        <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                                    </svg>
                                </button>
                                <Link to={`/adminmarketplace/form/${p.id}`} state={p} className='text-airco2-general'>
                                    <button>
                                        <img width={26} title="Formulario Información complementaria" src={addForm}></img>
                                    </button>
                                </Link>

                                {/* <Link to="/adminmarketplace/product/images?origen=mp" state={p}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="15" y1="8" x2="15.01" y2="8" />
                                    <rect x="4" y="4" width="16" height="16" rx="3" />
                                    <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                                    <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
                                </svg>
                            </Link> */}

                            </td>
                        </tr>)
                    })}

                </tbody>
            </table>
        </div></>)
}


function ProductImages({ product, show, setShow, setProduct, index }) {
    const handleClose = () => {
        setShow(false)

    }

    const [updateImages, setUpdateImages] = useState()
    useEffect(() => {
        if (product != null && updateImages != undefined) {
            product.productImageDtoList = updateImages;
            setProduct(product, index)
        }
    }, [updateImages])



    return <>
        <Modal show={show} size="lg">
            <Modal.Header>
                <Modal.Title>Imágenes <b>{product.name}  </b>      ({product.id})
                </Modal.Title>
                <div className="flex justify-end">
                    <button onClick={() => handleClose()}>
                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {/* <iframe src=''>

            </iframe> */}

                <MarketplacImages origin={'product'} state={product} setUpdateImages={setUpdateImages}></MarketplacImages>


            </Modal.Body>
            <Modal.Footer>
                <Button className=" text-xs btn btn-dashboard  bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleClose()}>
                    Cerrar
                </Button>

            </Modal.Footer>

        </Modal></>
}

function EditProduct({ product, setProduct, show, setShow, index }) {

    const handleClose = () => {
        setShow(false)
        setRespuestaPost({ message: "", type: "" })
    }

    const [respuestaPost, setRespuestaPost] = useState({
        type: '',
        message: ''
    });


    const [categories, setCategories] = useState({
        categoriesData: [],
        isFetching: false,
    });

    //Fetch categories MP
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setCategories({ categoriesData: categories.categoriesData, isFetching: true });
                await axios.get(`${process.env.REACT_APP_MP_CATEGORIES}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then(response => {
                    setCategories({ categoriesData: response.data, isFetching: false });
                });
            } catch (exception) {
                setCategories({ categoriesData: categories.categoriesData, isFetching: false });
            }
        };
        fetchCategories();
    }, []);


    //Fetch SustainabilityGoals MP
    const [sustainability, setSustainability] = useState({
        sustainabilityData: [],
        isFetching: false,
    });
    useEffect(() => {
        const fetchSustainability = async () => {
            try {
                setSustainability({ sustainabilityData: sustainability.sustainabilityData, isFetching: true });
                await axios.get(`${process.env.REACT_APP_MP_GOALS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then(response => {
                    setSustainability({ sustainabilityData: response.data, isFetching: false });
                });
            } catch (exception) {
                setSustainability({ sustainabilityData: sustainability.sustainabilityData, isFetching: false });
            }
        };
        fetchSustainability();
    }, []);

    //Objeto para el React-Select con las sustainabilityGoals
    const [goalsSelect, setGoalsSelect] = useState(null);
    useEffect(() => {
        if (sustainability.sustainabilityData[0] != undefined) {
            setGoalsSelect(sustainability.sustainabilityData.map((v) => {
                return ({
                    value: v.id, label: v.name, id: v.id
                })
            })
            )
        }
    }, [sustainability.sustainabilityData])

    const [sustainabilityDefaults, setSustainabilityDefaults] = useState(null)
    const [goalsToSend, setGoalsToSend] = useState([]);

    useEffect(() => {
        if (product.sustainabilityGoalDtoList !== undefined && product.sustainabilityGoalDtoList !== null) {
            if (goalsSelect != null) {

                let idsOfSustainabilityDefault = product.sustainabilityGoalDtoList.map(item => item.id);

                setSustainabilityDefaults(goalsSelect.filter(item => {
                    return idsOfSustainabilityDefault.includes(item.id);
                })
                )

                setGoalsToSend(product.sustainabilityGoalDtoList.map((v) => {
                    return ({
                        value: v.id, label: v.name, id: v.id
                    })
                })
                )

            }
        }
    }, [product])

    const handleSelectChange = (option) => {
        setGoalsToSend(option)
        setSustainabilityDefaults(option)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let sendProduct = {
            "id": product.id,
            "name": event.target.name.value,
            "price": event.target.price.value,
            "totalCredits": product.totalCredits,
            "stockCredits": product.stockCredits,
            "latitude": event.target.latitude.value,
            "longitude": event.target.longitude.value,
            "verifiedBy": product.verifiedBy,
            "age": event.target.age.value,
            "externalId": product.externalId,
            "size": event.target.size.value,
            "cadastre": event.target.cadastre.value,
            "active": event.target.active.checked,
            "validated": event.target.validated.checked,
            "categoryId": event.target.categoria.value == "" ? null : event.target.categoria.value,
            "forestTypeId": product.forestTypeId,
            "providerId": product.providerId,
            "cityId": product.cityId,
            "stripeId": product.stripeId,
            "verifiedBy": event.target.verifiedBy.value,
            "creationDate": product.creationDate,
            "updatedDate": new Date().toISOString(),
            "summary": event.target.summary.value,
            "description": event.target.description.value,
            "city": product.city,
            "state": product.state,
            "region": product.region,
            "provider": product.provider,
            "forestType": product.forestType,
            "automaticPrice": product.automaticPrice,
            "onSale": event.target.onsale.checked,
            "fire_insurance": product.fire_insurance,
            "commision": product.commision,
            "expireContractDate": product.expireContractDate,
            "validationDate": product.validationDate,
            "translations": [
                {
                    "summary": event.target.summary.value,
                    "description": event.target.description.value,
                    "languageId": 1
                }
            ],
            "productImageDtoList": product.productImageDtoList,
            "sustainabilityGoalDtoList": goalsToSend.map((va) => { return ({ "id": va.value }); })
        }


        axios.post(`${process.env.REACT_APP_MP_PRIVATE_PRODUCT}`,
            sendProduct, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${UserService.getToken()}`
            }
        })
            .then(response => {

                if (response.status == 200) {
                    setRespuestaPost({ message: "Producto actualizado correctamente", type: "success" })
                    setProduct(sendProduct, index)

                } else {
                    setRespuestaPost({ message: "No se ha podido guardar. Inténtalo de nuevo más tarde", type: "error" })
                }
            })
    }

    const [sustainabilityGoalDtoList, setSustainabilityGoalDtoList] = useState(goalsToSend == null ? "" : goalsToSend);
    const [categoryId, setCategoryId] = useState(product == null ? "" : product.categoryId);
    const [recommendedPrice, setRecommendedPrice] = useState({
        recommendedPriceData: [],
        isFetching: false,
    });

    useEffect(() => {
        let body;
        if (categoryId == null || categoryId == "") {
            body = { "sustainabilityGoalList": goalsToSend.map((va) => { return ({ "id": va.value }); }) }
        } else {
            body = {
                "sustainabilityGoalList": goalsToSend.map((va) => { return ({ "id": va.value }); }),
                "category": { "id": Number(categoryId) }
            }
        }
        const fetchRecommendedPrice = async () => {
            try {
                setRecommendedPrice({ recommendedPriceData: recommendedPrice.recommendedPriceData, isFetching: true });
                await axios.post(`${process.env.REACT_APP_MP_RECOMENDED_PRICE}`, body, {

                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then(response => {
                    setRecommendedPrice({ recommendedPriceData: response.data, isFetching: false });
                });
            } catch (exception) {
                setRecommendedPrice({ recommendedPriceData: recommendedPrice.recommendedPriceData, isFetching: false });
            }
        };
        fetchRecommendedPrice();
    }, [categoryId, goalsToSend]);

    return (<>
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Información <b>{product.name}  </b>      ({product.id})
                </Modal.Title>

                <div className="flex justify-end">
                    <button onClick={() => handleClose()}>
                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {respuestaPost.message !== "" && <Banner type={respuestaPost.type} open setOpen={true}>{respuestaPost.message}</Banner>}


                {respuestaPost.message === "" &&
                    <Form className='text-xs' model={product} onSubmit={(e) => handleSubmit(e)}>
                        <Row>
                            <Col col="6">
                                <FormGroup >
                                    <Label for="name">Nombre</Label>
                                    <Input className='text-xs' type="text" name="name" id="name" required defaultValue={product.name} />
                                </FormGroup>
                            </Col>
                            <Col col="6">
                                <FormGroup >
                                    <Label for="provider">Propietario</Label>
                                    <Input className='text-xs' type="text" name="provider" id="provider" disabled required defaultValue={product.provider} />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col col="8">
                                <FormGroup >
                                    <Label for="cadastre">Catastro</Label>
                                    <Input className='text-xs' type="text" name="cadastre" id="cadastre" required defaultValue={product.cadastre} />
                                </FormGroup>
                            </Col>
                            <Col col="2">
                                <FormGroup >
                                    <Label for="size">Tamaño</Label>
                                    <Input className='text-xs' type="text" name="size" id="size" required defaultValue={product.size} />
                                </FormGroup>
                            </Col>

                            <Col col="2">
                                <FormGroup >
                                    <Label for="age">Edad</Label>
                                    <Input className='text-xs' type="text" name="age" id="age" required defaultValue={product.age} />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col col="4">
                                <FormGroup >
                                    <Label for="price">Precio Recom:{recommendedPrice.recommendedPriceData}€</Label>
                                    <Input className='text-xs' type="text" name="price" id="price" required defaultValue={product.price} />
                                </FormGroup>
                            </Col>
                            <Col col="4">
                                <FormGroup >
                                    <Label for="stockCredits">Stock CO₂</Label>
                                    <Input className='text-xs' type="text" name="stockCredits" id="stockCredits" required disabled defaultValue={product.stockCredits} />
                                </FormGroup>
                            </Col>

                            <Col col="4">
                                <FormGroup >
                                    <Label for="totalCredits">Total CO₂</Label>
                                    <Input className='text-xs' type="text" name="totalCredits" id="totalCredits" required disabled defaultValue={product.totalCredits} />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col col="12">
                                <FormGroup >
                                    <Label for="summary">Descripción corta</Label>
                                    <Input type="textarea" rows={3} className='text-xs' name="summary" id="summary" required defaultValue={product.summary} maxLength={255} />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col col="12">
                                <FormGroup >
                                    <Label for="description">Descripción larga</Label>
                                    <Input type="textarea" rows={3} className='text-xs' name="description" id="description" required defaultValue={product.description} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col col="6">
                                <FormGroup >
                                    <Label for="latitude">Latitud</Label>
                                    <Input className='text-xs' type="text" name="latitude" id="latitude" required defaultValue={product.latitude} />
                                </FormGroup>
                            </Col>
                            <Col col="6">
                                <FormGroup >
                                    <Label for="longitude">Longitud</Label>
                                    <Input className='text-xs' type="text" name="longitude" id="longitude" required defaultValue={product.longitude} />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col col="4">
                                <FormGroup >
                                    <Label for="city">Municipio</Label>
                                    <Input className='text-xs' type="text" name="city" id="city" disabled required defaultValue={product.city} />
                                </FormGroup>
                            </Col>
                            <Col col="4">
                                <FormGroup >
                                    <Label for="state">Provincia</Label>
                                    <Input className='text-xs' type="text" name="state" id="state" disabled required defaultValue={product.state} />
                                </FormGroup>
                            </Col>

                            <Col col="4">
                                <FormGroup >
                                    <Label for="region">Comunidad Autónoma</Label>
                                    <Input className='text-xs' type="text" name="region" id="region" disabled required defaultValue={product.region} />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col col="4">
                                <FormGroup >
                                    <Label for="forestType">Tipo</Label>
                                    <Input className='text-xs' type="text" name="forestType" id="forestType" disabled required defaultValue={product.forestType} />
                                </FormGroup>
                            </Col>
                            <Col col="4">
                                <FormGroup >
                                    <Label for="category">Categoría</Label>
                                    <select id="categoria" name="" className="text-xs" type="text" onChange={(e) => setCategoryId(e.target.value)}>
                                        <option name="categoria" className='w-full' value="">Ninguna</option>
                                        {categories.categoriesData.map((v) => {
                                            if (v.id == product.categoryId) {
                                                return (<option selected key={v.id + "categoria"} name={v.name} className='w-full' value={v.id} >{v.name}</option>)
                                            } else {
                                                return (<option key={v.id + "categoria"} name={v.name} className='w-full' value={v.id}>{v.name}</option>)
                                            }
                                        })
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col col="4">
                                <FormGroup >
                                    <Label for="verifiedBy">Verificado por </Label>
                                    <Input className='text-xs' type="text" name="verifiedBy" id="verifiedBy" defaultValue={product.verifiedBy} />
                                </FormGroup>
                            </Col>

                        </Row>





                        <Row>
                            <Col col="12">
                                <FormGroup >
                                    <Label for="state">Buenas prácticas</Label>

                                    {/* {sustainability.sustainabilityData.length>0 ? */}
                                    <Select
                                        value={sustainabilityDefaults}
                                        isMulti
                                        name="sustainability"
                                        id="sustainability"
                                        options={goalsSelect != null ? goalsSelect : ""}
                                        classNamePrefix="Selecciona"
                                        placeholder="Selecciona"
                                        onChange={handleSelectChange}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? "#334155" : "#334155",
                                                borderColor: state.isSelected ? "#334155" : "#334155",
                                                borderRadius: "0.125rem",
                                                paddingTop: "0.5rem",
                                                paddingBottom: "0.5rem",
                                                // paddingLeft: "1rem",
                                                // paddingRight: "1rem",
                                                backgroundColor: "transparent",
                                            }),
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col col="4">
                                <FormGroup check>
                                    <Input className='text-xs' type="checkbox" name="active" id="active" defaultChecked={product.active} />
                                    <Label for="active" check>Activo </Label>
                                </FormGroup>
                            </Col>

                            <Col col="4">
                                <FormGroup check>
                                    <Input className='text-xs' type="checkbox" name="validated" id="validated" defaultChecked={product.validated} />
                                    <Label for="validated" check>Validado</Label>
                                </FormGroup>
                            </Col>
                            <Col col="4">
                                <FormGroup check>
                                    <Input className='text-xs' type="checkbox" name="onsale" id="onsale" defaultChecked={product.onSale} />
                                    <Label for="onsale" check>On sale</Label>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>

                            <Col col="12"    >

                                <Button className=" text-xs btn btn-dashboard  bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" type="submit">Actualizar</Button>

                            </Col>
                        </Row>



                    </Form>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button className=" text-xs btn btn-dashboard  bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleClose()}>
                    Cerrar
                </Button>

            </Modal.Footer>

        </Modal></>)
}



function BodyPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

            {/* Content area */}
            <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                {/*  Site header */}
                {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mt">
                            <div className="panel panel-default">

                                <ProductsMarketplace></ProductsMarketplace>

                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}