import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Banner from '../../../components/utils/Banner';
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import { useLocation } from 'react-router-dom';
import monteMP from "../../../images/ejemploMonteMP.png";
import instructionLatLng from "../../../images/instruction_LatLang.png";
import instructionLatLang2 from "../../../images/instruction_LatLang2.png";
import instructionLatLang3 from "../../../images/instruction_LatLang3.png";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

export default function FormMontes() {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const location = useLocation();
  const dataToUpdate = location.state;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showButtons, setShowButtons] = useState("");
  const [showNew, setShowNew] = useState("hidden");
  const [showForm, setShowForm] = useState("");
  const [providerInfo, setProviderInfo] = useState("");
  const [modalInfo, setModalInfo] = useState(false);
  const [modalEspecie, setModalEspecie] = useState(false);

  const [descripcionCorta, setDescripcionCorta] = useState(dataToUpdate == null ? "" : dataToUpdate.summary);
  const [descripcionLarga, setDescripcionLarga] = useState(dataToUpdate == null ? "" : dataToUpdate.description);
  const [latitude, setLatitude] = useState(dataToUpdate == null ? "" : dataToUpdate.latitude);
  const [longitude, setLongitude] = useState(dataToUpdate == null ? "" : dataToUpdate.longitude);
  const [sustainabilityGoalDtoList, setSustainabilityGoalDtoList] = useState(dataToUpdate == null ? "" : dataToUpdate.sustainabilityGoalDtoList);
  const [categoryId, setCategoryId] = useState(dataToUpdate == null ? "" : dataToUpdate.categoryId);
  const [price, setPrice] = useState(dataToUpdate == null ? "" : dataToUpdate.price);
  const [active, setActive] = useState(dataToUpdate == null ? true : dataToUpdate.active);
  const [onSale, setOnSale] = useState(dataToUpdate == null ? true : dataToUpdate.active);
  const [goalsToSend, setGoalsToSend] = useState([]);
  const [priceAuto, setPriceAuto] = useState(false);


  const [formMontesMessage, setFormMontesMessage] = useState({
    type: '',
    message: ''
  });

  const [productResponse, setProductResponse] = useState({
    productResponseData: [],
    isFetching: false
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const dataStringOrigen = urlParams.get('origen');
  const dataString = urlParams.get('data');
  const forestObject = JSON.parse(decodeURIComponent(dataString));





  if (dataToUpdate == undefined && dataStringOrigen == "mp") {
    window.location.href = "/forest/marketplace";
  }

  const [categories, setCategories] = useState({
    categoriesData: [],
    isFetching: false,
  });

  const [sustainability, setSustainability] = useState({
    sustainabilityData: [],
    isFetching: false,
  });

  const [locations, setLocations] = useState({
    locationsData: [],
    isFetching: false,
  });

  const [forestType, setForestType] = useState({
    forestTypeData: [],
    isFetching: false,
  });

  //Fetch categories MP
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setCategories({ categoriesData: categories.categoriesData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_MP_CATEGORIES}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setCategories({ categoriesData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        setCategories({ categoriesData: categories.categoriesData, isFetching: false });
      }
    };
    fetchCategories();
  }, []);

  //Fetch SustainabilityGoals MP
  useEffect(() => {
    const fetchSustainability = async () => {
      try {
        setSustainability({ sustainabilityData: sustainability.sustainabilityData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_MP_GOALS}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setSustainability({ sustainabilityData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        setSustainability({ sustainabilityData: sustainability.sustainabilityData, isFetching: false });
      }
    };
    fetchSustainability();
  }, []);

  //Fetch locations MP
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        setLocations({ locationsData: locations.locationsData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_MP_LOCATIONS}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setLocations({ locationsData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        setLocations({ locationsData: locations.locationsData, isFetching: false });
      }
    };
    fetchLocations();
  }, []);

  //Fetch forestType MP
  useEffect(() => {
    const fetchForestType = async () => {
      try {
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_MP_FORESTTYPES}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setForestType({ forestTypeData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        console.error(exception);
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: false });
      }
    };
    fetchForestType();
  }, []);

  //Estado que guarda el id del forestType de MP según el nombre de la especie del monte
  const [especie, setEspecie] = useState(null);
  if (forestObject != null) {
    useEffect(() => {
      if (forestType.forestTypeData.length > 0) {
        forestType.forestTypeData.map((v) => {
          if ((v.commonName.trim().toUpperCase()).includes(forestObject.forestType.trim().toUpperCase())) {
            setEspecie(v);
          }
        })
      }
    }, [forestType.forestTypeData])
  }
  //Objeto para el select de municipio a partir de la provincia del monte
  const [municipios, setMunicipios] = useState(null);
  if (forestObject != null) {
    useEffect(() => {
      if (locations.locationsData[0] != undefined) {
        for (let index = 0; index < locations.locationsData[0].regionList.length; index++) {
          let actual = (locations.locationsData[0]).regionList[index];

          actual.stateList.map((v) => {
            if ((v.name.toUpperCase()).includes(forestObject.province.toUpperCase())) {
              setMunicipios(v.citiesList.sort((x, y) => x.name.localeCompare(y.name)));
            }
          })
        }
      }
    }, [locations.locationsData])
  }

  //Objeto para el React-Select con las sustainabilityGoals
  const [goalsSelect, setGoalsSelect] = useState(null);
  useEffect(() => {
    if (sustainability.sustainabilityData[0] != undefined) {

      setGoalsSelect(sustainability.sustainabilityData.map((v) => {
        return ({
          value: v.id, label: v.name, id: v.id
        })
      })
      )
    }
  }, [sustainability.sustainabilityData])

  useEffect(() => {
    if (dataToUpdate != null) {
      setGoalsToSend(dataToUpdate.sustainabilityGoalDtoList.map((v) => {
        return ({
          value: v.id, label: v.name, id: v.id
        })
      }))
    }

  }, [dataToUpdate])



  const [sustainabilityDefaults, setSustainabilityDefaults] = useState(null)
  useEffect(() => {
    if (dataStringOrigen == "mp" && goalsSelect != null) {

      let idsOfSustainabilityDefault = sustainabilityGoalDtoList.map(item => item.id);

      setSustainabilityDefaults(goalsSelect.filter(item => {
        return idsOfSustainabilityDefault.includes(item.id);
      })
      )

    }
  }, [goalsSelect])



  //Guarda en el estado GoalsToSend los valores seleccionados por el ReactSelect
  const handleSelectChange = (option) => {
    setGoalsToSend(option)
    setSustainabilityDefaults(option)
  }

  //Fetch Provider MP
  useEffect(() => {
    fetch(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${UserService.getToken()}`
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((providerInfo) => {
        if (!providerInfo.error) {
          setProviderInfo(providerInfo);
        }

      });
  }, [])

  const handlePostProduct = (e) => {
    e.preventDefault();

    if (especie != null) {
      let product = {
        "name": forestObject.name,
        // "price": e.target.priceauto.checked == true ? recommendedPrice.recommendedPriceData : Number(e.target.price.value),
        "price": Number(e.target.price.value),
        "totalCredits": forestObject.anualCO2,
        "stockCredits": forestObject.availableCO2,
        "latitude": e.target.lat.value,
        "longitude": e.target.long.value,
        "verifiedBy": "Airco2",
        "age": forestObject.age,
        "externalId": forestObject.id,
        "size": forestObject.size,
        "cadastre": forestObject.cadastre,
        "active": e.target.checkboxAutoPublicar.checked,
        "validated": false,
        "categoryId": e.target.categoria.value == "" ? null : e.target.categoria.value,
        "forestTypeId": especie.id,
        "providerId": providerInfo.id,
        "cityId": e.target.municipio.value,

        "onSale": e.target.checkboxOnSale.checked,
        "fire_insurance": forestObject.seguro_incendios,
        "commision": forestObject.commision,
        "expireContractDate": forestObject.nextReloadDate,
        "validationDate": forestObject.validationDate,
        "translations": [
          {
            "summary": e.target.summary.value,
            "description": e.target.description.value,
            "languageId": 1
          }
        ],
        "productImageDtoList": forestObject.productImageDtoList,
        "sustainabilityGoalDtoList": goalsToSend.map((va) => { return ({ "id": va.value }); })
      }

      setFormMontesMessage({ ...formMontesMessage, type: "warning", message: "Actualizando datos..." });
      document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });

      const fetchProduct = async () => {
        try {
          setProductResponse({ productResponse: productResponse.productResponseData, isFetching: true });
          const response = await axios.post(`${process.env.REACT_APP_MP_PRIVATE_PRODUCT}?slackAlertType=newProduct`, product, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${UserService.getToken()}`
            }
          }).then(response => {
            if (response.status === 200) {
              setShowNew("block");
              setFormMontesMessage({ ...formMontesMessage, type: "success", message: t("account.actualizacion-ok") });
              document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
            } else {
              setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
              document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
            }
            setProductResponse({ productResponseData: response.data, isFetching: false });
          })
        } catch (exception) {
          console.error(exception);
          setProductResponse({ productResponse: productResponse.productResponseData, isFetching: false });
          setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        }
      };
      fetchProduct();

      e.target.reset()
      setShowButtons("hidden");
      setShowForm("hidden");
      // setShowNew("block");
    } else {
      setModalEspecie(true);
    }
  }

  const handlePostProductUpdate = (e) => {
    e.preventDefault();

    let productUpdate = dataToUpdate;
    productUpdate.translations = [{
      "summary": e.target.summary.value,
      "description": e.target.description.value,
      "languageId": 1
    }];
    productUpdate.latitude = e.target.lat.value;
    productUpdate.longitude = e.target.long.value;
    productUpdate.sustainabilityGoalDtoList = goalsToSend.map((va) => { return ({ "id": va.value }); });
    productUpdate.categoryId = e.target.categoria.value;

    productUpdate.price = Number(e.target.price.value);
    productUpdate.active = e.target.checkboxAutoPublicar.checked;
    productUpdate.onSale = e.target.checkboxOnSale.checked;


    delete productUpdate["summary"];
    delete productUpdate["description"];


    setFormMontesMessage({ ...formMontesMessage, type: "warning", message: "Actualizando datos..." });
    document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });

    const fetchProduct = async () => {
      try {
        setProductResponse({ productResponse: productResponse.productResponseData, isFetching: true });
        const response = await axios.post(`${process.env.REACT_APP_MP_PRIVATE_PRODUCT}?slackAlertType=updatePoduct`, productUpdate, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          if (response.status === 200) {
            setShowNew("block");
            setFormMontesMessage({ ...formMontesMessage, type: "success", message: t("account.actualizacion-ok") });
            document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
          } else {
            setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
            document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
          }
          setProductResponse({ productResponseData: response.data, isFetching: false });
        })
      } catch (exception) {
        console.error(exception);
        setProductResponse({ productResponse: productResponse.productResponseData, isFetching: false });
        setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
        document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
      }
    };
    fetchProduct();

    e.target.reset()
    setShowButtons("hidden");
    setShowForm("hidden");
    // setShowNew("block");
  }


  const [recommendedPrice, setRecommendedPrice] = useState({
    recommendedPriceData: [],
    isFetching: false,
  });

  useEffect(() => {
    let body;
    if (categoryId == null || categoryId == "") {
      body = { "sustainabilityGoalList": goalsToSend.map((va) => { return ({ "id": va.value }); }) }
    } else {
      body = {
        "sustainabilityGoalList": goalsToSend.map((va) => { return ({ "id": va.value }); }),
        "category": { "id": Number(categoryId) }
      }
    }
    const fetchRecommendedPrice = async () => {
      try {
        setRecommendedPrice({ recommendedPriceData: recommendedPrice.recommendedPriceData, isFetching: true });
        await axios.post(`${process.env.REACT_APP_MP_RECOMENDED_PRICE}`, body, {

          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setRecommendedPrice({ recommendedPriceData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        setRecommendedPrice({ recommendedPriceData: recommendedPrice.recommendedPriceData, isFetching: false });
      }
    };
    fetchRecommendedPrice();
  }, [categoryId, goalsToSend]);




  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content */}
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="mb-2 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold flex ">{t("marketplace.form_publish")}</h1>
            </div>
            <Modal show={modalEspecie}>
              <Modal.Header className='sec-green-background text-white text-lg'><b>{t("marketplace.form_speciesErr0")}</b></Modal.Header>
              <Modal.Body className='text-justify'>
                <p className='mb-2'>
                  {t("marketplace.form_speciesErr1")}<a href="mailto:forestal@airco2.eu" className='font-semibold'>{t("marketplace.form_speciesErr2")}</a>{t("marketplace.form_speciesErr3")}
                </p>
              </Modal.Body>
              <div className='flex justify-center pb-2'>
                <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => setModalEspecie(false)}>
                  {t("marketplace.form_close")}
                </Button>
              </div>
            </Modal>

            <Modal show={modalInfo}>
              <Modal.Header className='sec-green-background text-white text-lg'><b>{t("marketplace.form_latlong")}</b></Modal.Header>
              <Modal.Body className='text-justify'>
                <p className='mb-2'>{t("marketplace.form_coordsInstruction0")} <a href='https://www1.sedecatastro.gob.es/Cartografia/mapa.aspx?buscar=S' target='_blank' className='font-semibold'>{t("marketplace.form_coordsInstruction1")}</a>{t("marketplace.form_coordsInstruction2")}
                  <b>{t("marketplace.form_coordsInstruction3")}</b>{t("marketplace.form_coordsInstruction4")}
                </p>
                <div className='flex flex-col mb-2'>
                  <div className='flex justify-center'>
                    <img src={instructionLatLang2} width="400" />
                  </div>
                  <p className='text-sm px-5'>{t("marketplace.form_coordsInstruction5")}</p>
                </div>
                <div className='flex flex-col mb-2'>
                  <div className='flex justify-center text-center'>
                    <img src={instructionLatLng} width="400" />
                  </div>
                  <p className='text-sm px-5'>{t("marketplace.form_coordsInstruction6")}</p>
                </div>
                <p>{t("marketplace.form_coordsInstruction7")}</p>
                <div className='flex flex-col mt-2'>
                  <div className='flex justify-center text-center'>
                    <img src={instructionLatLang3} width="400" />
                  </div>
                  <p className='text-sm px-5'>{t("marketplace.form_coordsInstruction8")}</p>
                </div>
              </Modal.Body>
              <div className='flex justify-center pb-2'>
                <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => setModalInfo(false)}>
                  Cerrar
                </Button>
              </div>
            </Modal>

            <div className=" px-4 py-8" style={{ width: '100%' }}>

              <div className={`md:flex space-x-4`}>
                <div className="w-full md:w-3/6">
                  {dataStringOrigen == "mp" ?
                    <div>
                      <div className='flex'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="32" height="32" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" fill="none">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                          <line x1="15" y1="21" x2="15" y2="18" />
                          <path d="M8 13l-2 -2" />
                          <path d="M8 12l2 -2" />
                          <path d="M8 21v-13" />
                          <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                        </svg>
                        <label className="block h4 underline ml-2" htmlFor="nombre">{dataToUpdate.name}</label>
                      </div>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.catastral")}:</b> {dataToUpdate.cadastre}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("marketplace.form_location")}:</b> {dataToUpdate.city + ", " + dataToUpdate.state}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.especie")}:</b> {dataToUpdate.forestType}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.superficie")}:</b> {dataToUpdate.size} {t("forestList.hectareas")}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.edadplan")}:</b> {dataToUpdate.age} {t("proyectos.años")}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("marketplace.form_anualCO2")}:</b> {dataToUpdate.totalCredits} </label>
                      {/* <label className="block text-base ml-2" htmlFor="nombre"><b>CO2 disponible:</b> {forestObject.availableCO2} t CO2</label> */}
                      <div className="flex flex-col items-center justify-center mt-6 mb-4 hidden md:flex">
                        {/* <p className='text-sm'><a href={`http://carbonmarket.dev.airco2.eu/detailproduct/${dataToUpdate.id}`} target="_blank" className='underline'>Aquí</a> puedes ver un ejemplo de monte en el marketplace</p> */}
                        <img src={monteMP} width="500" className='border shadow' />
                        <p className='text-sm'>{t("marketplace.form_imgExample")}</p>

                      </div>
                    </div>
                    :
                    <div>
                      <div className='flex'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="32" height="32" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" fill="none">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                          <line x1="15" y1="21" x2="15" y2="18" />
                          <path d="M8 13l-2 -2" />
                          <path d="M8 12l2 -2" />
                          <path d="M8 21v-13" />
                          <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                        </svg>
                        <label className="block h4 underline ml-2" htmlFor="nombre">{forestObject.name}</label>
                      </div>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.catastral")}:</b> {forestObject.cadastre}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.provincia")}:</b> {forestObject.province}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.especie")}:</b> {forestObject.forestType}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.superficie")}:</b> {forestObject.size} {t("forestList.hectareas")}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.edadplan")}:</b> {forestObject.age} {t("proyectos.años")}</label>
                      <label className="block text-base ml-2" htmlFor="nombre"><b>{t("marketplace.form_anualCO2")}:</b> {forestObject.anualCO2} tCO₂</label>
                      {/* <label className="block text-base ml-2" htmlFor="nombre"><b>CO2 disponible:</b> {forestObject.availableCO2} t CO2</label> */}
                      <div className="flex flex-col items-center justify-center mt-6 mb-4 hidden md:flex">
                        <img src={monteMP} width="500" className='border shadow' />
                        <p className='text-sm'>{t("marketplace.form_imgExample")}</p>
                      </div>
                    </div>
                  }
                </div>
                <div className="w-full md:w-3/6">
                  {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>}
                  <Form className={`sec-neutro-background ${showForm}`} style={{ width: '100%' }} onSubmit={dataStringOrigen === "mp" ? handlePostProductUpdate : handlePostProduct}>
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium mb-1">{t("marketplace.form_description0")}<span className="text-red-500">*</span></label>
                        <textarea id="summary" name="summary" className="form-input w-full" type="text" maxlength="255" required value={descripcionCorta} onChange={event => setDescripcionCorta(event.target.value)} />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">{t("marketplace.form_description1")}<span className="text-red-500">*</span></label>
                        <textarea id="description" name="description" className="form-input w-full" type="text" required value={descripcionLarga} onChange={event => setDescripcionLarga(event.target.value)} />
                      </div>
                      {dataStringOrigen == "mp" ? "" :
                        <div>
                          <label className="block text-sm font-medium mb-1">{t("marketplace.form_city")}<span className="text-red-500">*</span></label>
                          {/* <input id="municipio" name="municipio" className="form-input w-full" type="text" required /> */}

                          <select id="municipio" name="" className="form-input w-full" type="text" required >
                            <option name="municipio" className='w-full' value="">{t("marketplace.form_select")}</option>
                            {municipios && (
                              municipios.map((v) => {
                                return (
                                  <option key={v.id + "municipio"} name={v.name} className='w-full' value={v.id}>{v.name}</option>
                                );
                              }))}

                          </select>
                        </div>
                      }

                      <div>
                        <label id="categoria" className="block text-sm font-medium mb-1">{t("marketplace.form_category")}</label>
                        <select id="categoria" name="" className="form-input w-full" type="text" onChange={event => setCategoryId(event.target.value)}>
                          <option name="categoria" className='w-full' value="">{t("marketplace.form_none")}</option>
                          {
                            categories.categoriesData.map((v) => {
                              if (v.id == categoryId) {
                                return (<option selected key={v.id + "categoria"} name={v.name} className='w-full' value={v.id}>{v.name}</option>)
                              } else {
                                return (<option key={v.id + "categoria"} name={v.name} className='w-full' value={v.id}>{v.name}</option>)
                              }
                            })
                          }
                        </select>
                      </div>
                      <div>
                        <label id="sustainability" className="block text-sm font-medium mb-1">{t("marketplace.form_sustainability")}</label>

                        {/* {sustainability.sustainabilityData.length>0 ? */}
                        <Select
                          value={sustainabilityDefaults}
                          isMulti
                          name="sustainability"
                          id="sustainability"
                          options={goalsSelect != null ? goalsSelect : ""}
                          classNamePrefix="Selecciona"
                          placeholder="Selecciona"
                          onChange={handleSelectChange}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? "#334155" : "#334155",
                              borderColor: state.isSelected ? "#334155" : "#334155",
                              borderRadius: "0.125rem",
                              paddingTop: "0.5rem",
                              paddingBottom: "0.5rem",
                              // paddingLeft: "1rem",
                              // paddingRight: "1rem",
                              backgroundColor: "transparent",
                            }),
                          }}
                        />

                      </div>

                      <div className='row'>
                        <div className='col-6'>
                          <label className="block text-sm font-medium mb-1">{t("marketplace.form_pricePerTon")}<span className="text-red-500">*</span></label>
                          <input id="price" name="price" className="form-input w-full text-sm" type="number" min="0" required value={price}
                            onChange={event => setPrice(event.target.value)} placeholder={"Introduce el precio por tonelada"} />
                        </div>
                        <div className='col-6'>
                          <label className="block text-sm font-medium mb-1">{t("marketplace.form_recommendedPrice0")}</label>
                          <div className='w-full flex'>
                            <label className="block text-xs font-medium">{t("marketplace.form_recommendedPrice1")}<b>{recommendedPrice.recommendedPriceData} {t("marketplace.form_recommendedPrice2")}</b>{t("marketplace.form_recommendedPrice3")}</label>
                          </div>
                        </div>
                        {price < 10 && price != "" || price > 30 && price != "" ?
                          <div className='col-12 mt-2'>
                            <p className='text-xs font-semibold'>
                              ⚠️{t("marketplace.form_recommendedPrice4")}
                            </p>
                          </div>
                          : <></>}
                        {/* <div className='col-6'>
                          <label className="block text-sm font-medium mb-1">Automatizar precio</label>
                          <div className='w-full flex mt-3'>
                            <input id="priceauto" name="priceauto" className="form-input " type='checkbox' onChange={(e) => setPriceAuto(e.target.checked)} />
                            <label className="block text-xs font-medium mb-1 ml-2" htmlFor="priceauto">{t("precio.automático")}</label>
                          </div>
                        </div> */}
                        {/* <div className='col-12 mt-2'>
                         <p className='text-xs'>
                          Precio recomendado {recommendedPrice.isFetching === true ? <Spinner animation="border" size="sm" /> : recommendedPrice.recommendedPriceData } €
                          </p> 
                        </div> */}
                      </div>

                      <div>
                        <div className='flex  space-x-4'>
                          <div className='w-full'>
                            <label className="block text-sm font-medium mb-1" htmlFor="poligono">{t("marketplace.form_lat")}</label>
                            {/* <input id="poligono" name="poligono" className="form-input w-full" width="10px" type="text" required /> */}
                            <input className="form-input w-full" type="number" id="lat" name="lat" value={latitude} onChange={event => setLatitude(event.target.value)} />
                          </div>
                          <div className='w-full'>
                            <label className="block text-sm font-medium mb-1" htmlFor="parcela">{t("marketplace.form_long")}</label>
                            {/* <input id="parcela" name="parcela" className="form-input w-full" type="text" required /> */}
                            <input className="form-input w-full" type="number" id="long" name="long" value={longitude} onChange={event => setLongitude(event.target.value)} />
                          </div>
                        </div>
                        <div className='text-sm'>
                          <button className=' flex ml-2' type="button" onClick={() => setModalInfo(true)}>
                            <span className='mt-1 mr-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg></span>
                            <p>{t("marketplace.form_latlong")}</p>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div className='w-full flex '>
                          <input id="checkboxOnSale" name="checkboxOnSale" className="form-input " type='checkbox' checked={onSale} onChange={event => setOnSale(event.target.checked)} disabled={providerInfo.stripeChargesEnabled == true ? false : true} />
                          <label className="block text-xs font-medium mb-1 ml-2" data-tip data-for="checkboxOnSale" htmlFor="checkboxOnSale">{t("formMonte.activarVenta")}</label>
                          <ReactTooltip className="text-xs" id="checkboxOnSale" place="top" effect="solid">{providerInfo.stripeChargesEnabled == true ? `${t("formMonte.expVenta")}` : `${t("formMonte.expStripe")}`}</ReactTooltip>
                        </div>
                        <div className='w-full flex '>
                          <input id="checkboxAutoPublicar" name="checkboxAutoPublicar" className="form-input " type='checkbox' checked={active} onChange={event => setActive(event.target.checked)} disabled={providerInfo.stripeChargesEnabled == true ? false : true} />
                          <label className="block text-xs font-medium mb-1 ml-2" data-tip data-for="checkTooltip" htmlFor="checkboxAutoPublicar">{t("formMonte.visibleMp")}</label>
                          <ReactTooltip className="text-xs" id="checkTooltip" place="top" effect="solid">{providerInfo.stripeChargesEnabled == true ? `${t("formMonte.expActivar")}` : `${t("formMonte.expStripe")}`}</ReactTooltip>
                        </div>

                        <div className='w-full flex '>
                          <input id="checkbox" name="checkbox" className="form-input " type='checkbox' required />
                          <label className="block text-xs font-medium mb-1 ml-2" htmlFor="checkbox">{t("formMonte.checkbox")}<span className="text-red-500">*</span></label>
                        </div>



                      </div>



                    </div>

                    {/* botones del formulario  */}
                    <div className="flex items-center justify-center mt-6">
                      <Button arial-label="btn-regristrar" type="submit" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showButtons}`}>
                        {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
                        {dataStringOrigen == "mp" ? "Guardar" : t("formMonte.registrar")}
                      </Button>
                      <Link to="/forest/forestlist">
                        <Button arial-label="btn-cancelar" type="reset" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showButtons}`}>
                          {t("formMonte.cancelar")}
                        </Button>
                      </Link>
                    </div>
                  </Form>
                  {dataStringOrigen == "mp" ?
                    <div>
                      <div className="flex flex-row sm:flex-col justify-center mt-6">
                        <Link to="/forest/forestlist" state={productResponse.productResponseData}>
                          <Button arial-label="btn-regristrar" type="button" className={`btn-sm bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showNew}`}>
                            {t("marketplace.form_myForests")}
                          </Button>
                        </Link>
                        <Link to="/forest/marketplace">
                          <Button arial-label="btn-cancelar" type="button" className={`btn-sm bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showNew}`}>
                            {t("marketplace.form_myMarketplace")}
                          </Button>
                        </Link>
                      </div>
                      <div className="flex justify-center  ml-4 pt-4 mt-6 border-t border-gray-200">
                        <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/forest/marketplace">{t("formMonte.volver")}</Link>
                      </div>
                    </div>
                    :
                    <div>
                      <div className={`mx-4 mt-4 ${showNew}`}>
                        <p>{t("marketplace.form_uploadMPOK0")}</p>
                        <p className='text-sm'>{t("marketplace.form_uploadMPOK1")}<Link className="text-indigo-500 hover:text-indigo-600" to="/forest/marketplace">{t("marketplace.marketplace")}</Link>{t("marketplace.form_uploadMPOK2")}</p>
                      </div>
                      <div className="flex flex-row sm:flex-col justify-center mt-6">
                        <Link to="/forest/imagesMarketplace?origen=form" state={productResponse.productResponseData}>
                          <Button arial-label="btn-regristrar" type="button" className={`btn-sm bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showNew}`}>
                            {t("marketplace.form_uploadImgs")}
                          </Button>
                        </Link>
                        <Link to="/forest/marketplace">
                          <Button arial-label="btn-cancelar" type="button" className={`btn-sm bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showNew}`}>
                            {t("marketplace.form_myMarketplace")}
                          </Button>
                        </Link>
                      </div>
                      <div className="flex justify-center  ml-4 pt-4 mt-6 border-t border-gray-200">
                        <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/forest/forestList">{t("formMonte.volver")}</Link>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
