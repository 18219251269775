import React from 'react'
import icon7 from '../../../images/icono07.svg'
import icon8 from '../../../images/icono08.svg'
import icon9 from '../../../images/icono09.svg'
import icon10 from '../../../images/4.png'
import certificado from '../../../images/certificado.webp'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'

export default function bloqueDoc() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className='container flex justify-center mt-5'>


            <div className='bloque-pasos  max-w-6xl mx-auto px-2 sm:px-3 text-airco2-general' >
                <h1 className='number-landing' >
                    1
                </h1>
                <div className='flex items-center pt-5 pb-3 flex-no-wrap custom-flex'>


                    <h2 className='h1 text-airco2-title-landing   m-3'> {t("landingEventos.huellaEventos")}</h2>
                    <p className='text-sub w-full md:w-1/2 m-3 font-semibold flex flex-col items-end text-right'>
                        <span> {t("landingEventos.neutralCarbono")}</span>
                        <span> {t("landingEventos.vendeMas")} </span>
                        <span className='mt-2' style={{ width: '70%', height: '1.5px', backgroundColor: ' #878787' }}> </span>



                    </p>

                </div>
                <div className='m-3 pb-3'>
               
                    <ul className="list-pasos">
                        <li className="mb-1">
                            {t("landingEventos.presencial")}
                        </li>
                        <li className="mb-1">
                            {t("landingEventos.estimativa")}
                        </li>
                        <li className="mb-1">
                            {t("landingEventos.impresionamos")}
                        </li>
                    </ul>
                </div>

                
                <div className='m-3 flex justify-center pt-3 pb-3 '>
                    <img className='transform hover:scale-105 transition duration-700 ease-out' src={certificado}></img>
                </div>
                <div className='m-3 text-airco2-general bg-green-semitr p-4 rounded-lg mb-5'>
                    <p className='font-bold text-center text-lg'>¿Te gustaría saber más?</p>
                    <div className=" mt-6 mb-6 flex justify-center" data-aos="fade-in" data-aos-delay="100">
                        <a href="" className="btn sec-green-background text-airco2-filter-content  whitespace-nowrap w-full lg:w-1/2 " >
                            <span style={{ color: 'white', textDecoration: 'none' }}>Más información</span>

                        </a>
                    </div>


                </div>


            </div>




        

        </div>

    )
}
