import React, { useContext, useEffect, useRef, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/esm/Image';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { IdiomContext } from "../context/createcontext";
import logo from '../images/logo2.png';
import UserService from '../services/UserService';
import Dropdown from '../utils/Dropdown';
import PageMetatags from '../utils/Metatags';
import { getDashboardUrl } from '../services/Functions';


function
  Header() {

  const urldashboard = getDashboardUrl(UserService.getUserType(), UserService.getOriginData());


  useEffect(() => {



    var root = document.querySelector(':root');
    var rootStyles = getComputedStyle(root);

    root.style.setProperty('--airco2-beige-color', '#F8F3EA');
    root.style.setProperty('--airco2-green-color', '#8DD4BD');
    root.style.setProperty('--airco2-greenSidebar-color', '#8DD4BD');
    root.style.setProperty('--airco2-font', 'openLight');
    root.style.setProperty('--airco2-orange-color', '#F19B7F');
    root.style.setProperty('--airco2-grey-color', '#878787');


  }, []);



  const page = "/#"

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);

    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);

    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });




  // change the language

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [spain, updateSpain] = useState(lng === "es" ? true : false)
  const [portug, updatePortugal] = useState(lng === "pt" ? true : false)
  const [england, updateEngland] = useState(lng === "en" ? true : false)
  const [galic, updateGalicia] = useState(lng === "gl" ? true : false)
  const handleChangeEs = e => {
    i18n.changeLanguage("es")
    updateLng("es")
    updateEngland(false)
    updatePortugal(false)
    updateGalicia(false)
    updateSpain(true)




  }
  const handleChangePt = e => {
    i18n.changeLanguage("pt")
    updateLng("pt")
    updatePortugal(true)
    updateEngland(false)
    updateGalicia(false)
    updateSpain(false)


  };
  const handleChangeGa = e => {
    i18n.changeLanguage("gl")
    updateLng("gl")
    updateGalicia(true)
    updateEngland(false)
    updatePortugal(false)
    updateSpain(false)


  };
  const handleChangeEn = e => {
    i18n.changeLanguage("en")
    updateLng("en")
    updateEngland(true)
    updateGalicia(false)
    updatePortugal(false)
    updateSpain(false)


  };

  const handleLogout = async () => {
    setMobileNavOpen(false);
    await Promise.resolve();

    UserService.logout();
  };
  // actualizar el idioma del localStorage

  useEffect(() => {
    localStorage.setItem('idioma', lng);
    updateLng


  }, [lng]);

  // close the mobile menu with scroll
  // const handleScroll = () => setMobileNavOpen(false)

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll)


  // }, []);

  const handleScroll = () => {
    setMobileNavOpen(false);


    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {

    window.addEventListener('scroll', handleScroll);


    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // const url = `${UserService.doRegistration()}&ui_locales=${lng}`
  // const urlLogin = `${UserService.urlLogin()}&ui_locales=${lng}`

  const url = `/register`
  const urlLogin = `/login`


  const setTitle = () => {
    const url = window.location.pathname;
    switch (url) {
      case "/terminos":
        return t("meta.terminos.title")
      case "/privacidadYcookies":
        return t("meta.privacidad.title")
      case "/faqs":
        return t("meta.faqs.title")
      default:
        break;
    }
  }

  const { pathname } = location;

  const backgroundColorNav =
    pathname.includes('/ayuntamientos-net-zero') && !scrolling
      ? 'transparent'
      : pathname.includes('/ayuntamientos-net-zero') && scrolling
        ? 'rgba(255, 186, 91, 0.8)'


        : 'var(--airco2-green-color)';


  const textLinkMvl = `linkhover py-2 flex ${pathname.includes('/ayuntamientos-net-zero') ? 'text-white font-bold' : 'text-gray-300'}`;
  return (

    <div id='inicio'>
      <PageMetatags title={setTitle()} />

      <header className="absolute w-full z-30" style={{ color: 'white' }}>
        {/* <div className="max-w-6xl mx-auto px-4 sm:px-6"> */}
        {/* <Navbar expand="lg" fixed='top' className='px-0 py-0'> */}

        <Navbar expand="xl" variant="dark" fixed='top' style={{ backgroundColor: backgroundColorNav }}>

          <div className="w-full"
          // style={pathname.includes('/ayuntamientos-net-zero') ? { backgroundColor: 'transparent' } : { backgroundColor: '#8DD4BD' }}

          >
            <div className="flex items-center justify-between h-20 ">

              {/* Site branding */}
              {/* <div className="shrink-0 mr-5 ml-5" style={{ minWidth: '30vw' }}> */}
              <div className="navMargin lg:grow" >
                <button aria-label='inicioAirco2'>

                  <HashLink aria-label='inicio' className="nav-link" to={page} smooth={true} offset={50} duration={500}> <Image height="200" width="130" src={logo}></Image></HashLink>

                </button>

              </div>

              {/* Desktop navigation */}
              {/* <Navbar className="hidden lg:flex lg:grow" style={{marginInlineStart: '150px'}}> */}
              <div className="hidden lg:flex lg:grow "
              >



                {/* Desktop menu links */}
                {/* //Empresas */}
                {/* <ul className="flex grow justify-center flex-wrap items-center menu-list ">
                  <Dropdown title={t("nav.empresas")}>

                    <li>

                      <button arial-label="btn-empresas" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight" >


                       
                        <HashLink aria-label='apartado empresa1' className='linkhover' to={`${page}empresas`} smooth={true} offset={50} duration={500}>{t("nav.gestion")}</HashLink>
                      </button>
                    </li>
                    <li>
                      <button arial-label="btn-empresas2" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight" >
                        <HashLink aria-label='apartado empresa2' className='linkhover' to={`${page}empresas2`} smooth={true} offset={50} duration={500}>{t("nav.facil")}</HashLink>
                      </button>
                    </li>
                    <li>
                      <button arial-label="btn-empresas3" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight">
                        <HashLink aria-label='apartado empresa3' className='linkhover' to={`${page}empresas3`} smooth={true} offset={50} duration={500}>{t("nav.como")}</HashLink>
                      </button>
                    </li>

                  </Dropdown>
                </ul> */}

                {/* Forestal */}
                {/* <ul className="flex grow justify-center flex-wrap items-center  menu-list">
                  <a aria-label='apartado forestal' className={`linkhover ${pathname.includes('forestal') ? 'text-orange' : 'text-white'}`}  style={{ textDecoration: 'none' }} href='/forestal'  rel="noopener noreferrer">{t("nav.forestal")}</a>
                </ul> */}

                <ul className="flex grow justify-center flex-wrap items-center  m-1  menu-list">
                  <Dropdown title={t("nav.products")}>
                  <li>
                      <button arial-label="btn-carbonManager" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight">
                        <HashLink aria-label='apartado carbonManager' className='linkhover' to={`/carbonmanager`} smooth={true} offset={50} duration={500}>{t("nav.carbonManager")}</HashLink>
                      </button>
                    </li>
                    <li>
                      <button arial-label="btn-forestal" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight">
                        <HashLink aria-label='apartado forestal1' className='linkhover' to={`/forestal`} smooth={true} offset={50} duration={500}>{t("nav.propietarios")}</HashLink>
                      </button>
                    </li>
                 
               
                  </Dropdown>
                </ul>


                {/* Nosotros */}
                {/* <ul className="flex grow justify-center flex-wrap items-center  m-1  menu-list">

                  <Dropdown title={t("nav.nosotros")} >
                    <li>
                      <button arial-label="btn-nosotros" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight">
                        <HashLink aria-label='apartado nosotros1' className='linkhover' to={`${page}nosotros`} smooth={true} offset={50} duration={500}>{t("nav.cambiamos")}</HashLink>
                      </button>
                    </li>
                    <li>
                      <button arial-label="btn-nosotros2" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight" >
                        <HashLink aria-label='apartado nosotros2' className='linkhover' to={`${page}nosotros2`} smooth={true} offset={50} duration={500}>{t("nav.team")}</HashLink>
                      </button>
                    </li>
                    <li>
                      <button arial-label="btn-nosotros3" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight">
                        <HashLink aria-label='apartado nosotros3' className='linkhover' to={`${page}nosotros3`} smooth={true} offset={50} duration={500}>{t("nav.crecer")}</HashLink>
                      </button>
                    </li>
                    <li>
                      <button arial-label="btn-nosotros4" className="text-ms text-gray-100 hover:text-blue-600  py-2 px-4 leading-tight">
                        <a aria-label='apartado nosotros4' className='linkhover' href='/faqs' target="_blank" rel="noopener noreferrer">FAQs</a>
                      </button>
                    </li>
                  </Dropdown>
                </ul> */}

                {/* Blog */}
                <ul className="flex grow justify-center flex-wrap items-center  menu-list">
                  <a aria-label='apartado blog' className='linkhover text-white' style={{ textDecoration: 'none' }}  href='https://blog.airco2.earth/' target="_blank" rel="noopener noreferrer">Blog</a>
                </ul>

                {/* Contacto */}
                {/* <ul className="flex grow justify-center flex-wrap items-center m-1  menu-list">
                  <HashLink aria-label='apartado contacto' className='linkhover text-white' style={{ textDecoration: 'none' }} to={`${page}contacto`} smooth={true} offset={50} duration={500}>
                    {t("nav.contacto")}</HashLink >
                </ul> */}

                {/* marketplace */}
                <ul className="flex grow justify-center flex-wrap items-center  m-1 menu-list">
                  <a aria-label='apartado marketplace' className='linkhover text-white ' style={{ textDecoration: 'none' }} href="https://www.airco2.earth/market" target="_blank" rel="noopener noreferrer">
                    {t("nav.marketplace")}</a >
                </ul>

          


                {/* dropw idiomas, empieza hoy y login*/}
                <ul className="hidden lg:flex grow justify-center flex-wrap items-center m-1 menu-list ">
                  <Dropdown title={
                    <span><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-world" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="9" />
                      <line x1="3.6" y1="9" x2="20.4" y2="9" />
                      <line x1="3.6" y1="15" x2="20.4" y2="15" />
                      <path d="M11.5 3a17 17 0 0 0 0 18" />
                      <path d="M12.5 3a17 17 0 0 1 0 18" />
                    </svg></span>}
                  >
                    <li className={spain ? 'sec-green-text font-semibold' : 'text-white'}>
                      <button className='text-ms linkhover' aria-label='btn-lng-es' onClick={handleChangeEs}>  Español</button>
                    </li>

                    <li className={galic ? 'sec-green-text font-semibold' : 'text-white'}>
                      <button className="text-ms linkhover" aria-label='btn-lng-ga' onClick={handleChangeGa}>
                        Galego
                      </button>
                    </li>

                    <li className={england ? 'sec-green-text font-semibold' : 'text-white'}>
                      <button className='text-ms linkhover' aria-label='btn-lng-en' onClick={handleChangeEn}>  English</button>
                    </li>

                    <li className={portug ? 'sec-green-text font-semibold' : 'text-white'}>
                      <button className='text-ms linkhover' aria-label='btn-lng-pt' onClick={handleChangePt}>  Português</button>
                    </li>

                  </Dropdown>
                </ul>


              </div>

              {/* Desktop sign in links */}
              <ul className="hidden lg:flex lg:grow lg:justify-end flex-wrap items-center menu-list  lg:mr-5">

                {UserService.isLoggedIn() ?

                  <>
                    <li className='text12' >
                      <Nav.Link href={urldashboard} className="nav-link  text-white
                       flex items-center transition duration-150 ease-in-out sec-green-text">{UserService.getUsername()}</Nav.Link>
                    </li>
                    <li className='text12' >
                      <a aria-label='cerrar sesion' className="nav-link font-medium text-600   text-white
                         flex items-center transition duration-150 ease-in-out"  href="# " onClick={() => { UserService.logout(), window.location.reload() }} >{t("hometext.cerrarsesion")}</a>

                    </li>
                  </>
                  :
                  <>
                    <li className='text12' >
                      <Nav.Link aria-label='registro' href={url} className="nav-link  btn-banner-o
                        flex items-center transition duration-150 ease-in-out font-bold rounded" >{t("hometext.empieza")}</Nav.Link>
                    </li>


                    <li className='text12' >
                      <button aria-label='btn-login'>
                        <Nav.Link
                          href="/login"
                          className="nav-link pr-1 text12  text-white 
                          flex items-center transition duration-150 ease-in-out" >
                          {t("hometext.login")}
                        </Nav.Link>
                      </button>

                    </li>
                  </>
                }

              </ul>



              {/* Mobile menu */}
              {/* <div className="md:hidden "> */}
              <div className="lg:hidden">


                {/* Hamburger button */}
                <button aria-label='btn-hamburger' ref={trigger} className={` mr-3 hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>

                  <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <rect y="4" width="24" height="2" rx="1" />
                    <rect y="11" width="24" height="2" rx="1" />
                    <rect y="18" width="24" height="2" rx="1" />
                  </svg>
                </button>

                {/*Mobile navigation */}
                <nav id="mobile-nav" ref={mobileNav} className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out" style={mobileNavOpen ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: .8 }} >
                  <ul className=" px-4 py-2"
                    style={pathname.includes('/ayuntamientos-net-zero') ? { backgroundColor: 'rgba(255, 186, 91)' } : { backgroundColor: 'rgb(30, 41, 59)' }}


                  >
                    {/* <li>
                      <button aria-label='btn-empresas'>
                        <HashLink aria-label='apartado empresas' to={`${page}empresas`} smooth={true} offset={50} duration={500} className={textLinkMvl}>{t("nav.empresas")}</HashLink>
                      </button>
                    </li> */}

                    <li>
                      <button aria-label='btn-forestal'>
                        <HashLink aria-label='apartado forestal' to={`/forestal`} smooth={true} offset={50} duration={500} className={textLinkMvl}>{t("nav.forestal")}</HashLink>
                      </button>
                    </li>

                    {/* <li>
                      <button aria-label='btn-nosotros'>
                        <HashLink aria-label='apartado nosotros' to={`${page}nosotros`} smooth={true} offset={50} duration={500} className={textLinkMvl}>{t("nav.nosotros")}</HashLink>
                      </button>
                    </li> */}

                    <li>
                      <button aria-label='btn-blog'>
                        <a aria-label='apartado blog' className={textLinkMvl} href='https://blog.airco2.earth/' target="_blank" rel="noopener noreferrer">Blog</a>
                      </button>
                    </li>

                    {/* <li>
                      <button aria-label='btn-contacto'>
                        <HashLink aria-label='apartado contacto' to={`${page}contacto`} smooth={true} offset={50} duration={500} className={textLinkMvl}>{t("nav.contacto")}</HashLink>
                      </button>
                    </li> */}
                    <li>
                      <button aria-label='btn-marketplace'>
                        <a aria-label='apartado marketplace' className={textLinkMvl} href="https://www.airco2.earth/market" target="_blank" rel="noopener noreferrer">{t("nav.marketplace")}</a>
                      </button>
                    </li>
                    <li>
                      <button aria-label='btn-carbonManager'>
                        <a aria-label='apartado carbonManager' className={textLinkMvl} href="/carbonmanager" rel="noopener noreferrer">{t("nav.carbonManager")}</a>
                      </button>
                    </li>
                    


                    <li className="py-2 my-2 border-t border-b border-gray-700">

                      <ul className="flex grow justify-start flex-wrap items-center mr-2 gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-world" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx="12" cy="12" r="9" />
                          <line x1="3.6" y1="9" x2="20.4" y2="9" />
                          <line x1="3.6" y1="15" x2="20.4" y2="15" />
                          <path d="M11.5 3a17 17 0 0 0 0 18" />
                          <path d="M12.5 3a17 17 0 0 1 0 18" />
                        </svg>
                        {(spain === false) &&
                          <button className='linkhover' aria-label='btn-lng-es' onClick={handleChangeEs}> ES</button>

                        }
                        {(galic === false) &&
                          <button className='linkhover' aria-label='btn-lng-ga' onClick={handleChangeGa}>GL </button>

                        }
                        {(england === false) &&

                          <button className='linkhover' aria-label='btn-lng-en' onClick={handleChangeEn}>EN</button>


                        }

                        {(portug === false) &&
                          <button className='linkhover' aria-label='btn-lng-pt' onClick={handleChangePt}> PT</button>

                        }


                      </ul>

                    </li>
                    {UserService.isLoggedIn() ?

                      <>
                        <li>
                          <Nav.Link href={urldashboard} className="flex font-medium w-full text-600 hover:text-gray-200 py-2 justify-center" style={{ color: 'white' }} >{UserService.getUsername()}</Nav.Link>
                        </li>
                        <li>
                          <a aria-label='cerrar sesion' className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white hover:bg-700 transition duration-150 ease-in-out"
                            style={{ backgroundColor: !pathname.includes('/ayuntamientos-net-zero') ? 'var(--airco2-green-color)' : '#545a6d ' }} href="# " onClick={() => { handleLogout() }}>{t("hometext.cerrarsesion")}</a>

                        </li>
                      </>
                      :
                      <>



                        <li>


                          <a aria-label='login' className="flex font-medium w-full text-600 hover:text-gray-200 py-2 justify-center" style={{ color: 'white' }} href={urlLogin} >{t("hometext.login")}</a>


                        </li>


                        <li>
                          <a aria-label='registro' className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white hover:bg-700 transition duration-150 ease-in-out"
                            style={{ backgroundColor: !pathname.includes('/ayuntamientos-net-zero') ? 'var(--airco2-green-color)' : '#545a6d ' }} href={url} >{t("nav.regis")}</a>



                        </li>


                      </>
                    }










                  </ul>
                </nav>

              </div>

            </div>
          </div>
        </Navbar>
      </header>
    </div>
  );
}

export default Header;
