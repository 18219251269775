import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import global_es from './translation/es/global.json';
import global_pt from './translation/pt/global.json';
import global_en from './translation/en/global.json';
import global_gl from './translation/gl/global.json';

import * as serviceWorker from './serviceWorker';
import UserService from './services/UserService';

const browserLanguage = window.navigator.language.substring(0,2);
const availableLanguages = ["es", "en", "gl", "pt"];
const txtnav = browserLanguage != null && availableLanguages.some(v => v === browserLanguage) ? browserLanguage : 'es';
//console.log(txtnav)
let idioma = localStorage.getItem('idioma')
i18next.init({
    interpolation: { escapeValue: false },
    lng:  idioma != null ? idioma : txtnav,
    resources: {
        es: {
            global: global_es,
        },
        pt: {
            global: global_pt,
        }, 
        gl: {
            global: global_gl,
        },
        en: {
            global: global_en,
        }         
    },
})


const renderApp = () => ReactDOM.render(
    < I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>,
    document.getElementById('root'));

UserService.initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
