import React, { useState, useEffect, useRef, useContext } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import UserService from '../../services/UserService';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import logo from '../../logo2.png';
import logoby from '../../images/byAirco2verde.png'
import axios from 'axios';
import { getLogo, whiteLabelData } from '../../services/FunctionsDataWhiteLabel';

function Sidebar({
  sidebarOpen,
  setSidebarOpen
}) {

  const location = useLocation();
  const { pathname } = location;
  const whiteLabel = UserService.getwhiteLabel()
  const trigger = useRef(null);
  const sidebar = useRef(null);
  const logoExternal = getLogo(whiteLabel)
  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');
  const [currentPlan, setCurrentPlan] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserPlan = async () => {
      const url = `${process.env.REACT_APP_CH_PLAN}`;

      try {
        const response = await axios.get(`${url}`, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${UserService.getToken()}`,
          },
        });


        setCurrentPlan(response?.data?.data?.planId)


      } catch (exception) {
        console.error(exception);
      }
    };
    if (UserService.getUserType() == "Representante empresarial") {
      fetchUserPlan();
    }
  }, []);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);


    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);


    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  // cambiar el idioma

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  // if (pathname.includes('footprint')) {
  //   setSidebarExpanded(true);
  // }


  useEffect(() => {
    const whiteLabel = UserService.getwhiteLabel();
    const selectedWhiteLabel = whiteLabelData.find(item => item.id === whiteLabel);

    if (selectedWhiteLabel) {
      var root = document.querySelector(':root');
      var rootStyles = getComputedStyle(root);

      root.style.setProperty('--airco2-beige-color', selectedWhiteLabel.colorBg);
      root.style.setProperty('--airco2-green-color', selectedWhiteLabel.color);
      root.style.setProperty('--airco2-greenSidebar-color', selectedWhiteLabel.colorBgSidebar);
      root.style.setProperty('--airco2-font', selectedWhiteLabel.font);
      root.style.setProperty('--airco2-orange-color', selectedWhiteLabel.secodarycolor);
      root.style.setProperty('--airco2-grey-color', selectedWhiteLabel.secodarycolor);

    }
  }, []);

  const isFootprintForm = pathname === '/footprint/carbonmanager' || pathname === '/footprint/finalized';
  return (
    
    <div className="welcome-sidebar-general">

      {/* Sidebar backdrop (mobile only) */}
      <div className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-50 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        // className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 sec-green-background p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
        className={`flex flex-col absolute z-50 left-0 top-0 
          ${ !isFootprintForm &&  "lg:static lg:left-auto lg:top-auto lg:translate-x-0 lg:overflow-y-auto lg:w-64 lg:sidebar-expanded:!w-64 2xl:!w-64"}
           
          transform h-screen overflow-y-scroll 
        no-scrollbar w-64   shrink-0 sec-sidebar-background 
       p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}>

        {/* Sidebar header */}
        <div className="flex justify-between mb-4 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-sidebar hover:text-gray-400"
            onClick={(e) => { e.stopPropagation(); setSidebarOpen(!sidebarOpen) }}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}

          {logoExternal &&
            <img alt='logo' src={logoExternal}></img>

          }
          {logoExternal === '' &&
            <NavLink to="/" className="">
              <img alt='logo' src={logo} width="75%" className='ml-2'></img>
            </NavLink>
          }

        </div>
        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-sidebar font-semibold pl-3">
              {/* <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span> */}
              {/* <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Pages</span> */}
              <span className="hidden lg:block lg:sidebar-expanded:block 2xl:block">  {t("sidebar.paginas")}</span>
            </h3>
            <ul className="mt-3">

              {UserService.getUserType() == undefined ?

                <>

                  {/* AdminUsuarios */}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('/adminusuarios') && 'sec-neutro-background'}`}>
                    <NavLink to="/adminusuarios" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('/adminusuarios') && 'hover:text-gray-200'}`}>
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('adminusuarios') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                        <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Usuarios</span>
                      </div>
                    </NavLink>
                  </li>

                  {/* AdminAllForest */}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('/adminmontes') && 'sec-neutro-background'}`}>
                    <NavLink to="/adminmontes" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('/adminmontes') && 'hover:text-gray-200'}`}>
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('adminmontes') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                          <line x1="15" y1="21" x2="15" y2="18" />
                          <path d="M8 13l-2 -2" />
                          <path d="M8 12l2 -2" />
                          <path d="M8 21v-13" />
                          <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                        </svg>
                        <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Montes</span>
                      </div>
                    </NavLink>
                  </li>

                  {/* AdminBanco*/}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('adminbanco') && 'sec-neutro-background'}`}>
                    <NavLink to="/adminbanco" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('adminbanco') && 'hover:text-gray-200'}`}>
                      <div className="flex items-center">
                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                          <path className={`fill-current text-gray-600 ${pathname.includes('adminbanco') && 'text-indigo-500'}`} d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z" />
                          <path className={`fill-current text-gray-700 ${pathname.includes('adminbanco') && 'text-indigo-300'}`} d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z" />
                        </svg>
                        <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Banco</span>
                      </div>
                    </NavLink>
                  </li>


                  {/* MarketplaceAdmin */}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('marketplace') && 'sec-neutro-background'}`}>
                    <NavLink to="/marketplaceAdmin" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('marketplace') && 'hover:text-gray-200'}`}>
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('marketplace') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="3" y1="21" x2="21" y2="21" />
                          <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                          <line x1="5" y1="21" x2="5" y2="10.85" />
                          <line x1="19" y1="21" x2="19" y2="10.85" />
                          <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                        </svg>
                        <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Marketplace</span>
                      </div>
                    </NavLink>
                  </li>

                  {/* FootPrint Calculator Admin */}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint') && 'sec-neutro-background'}`}>
                    <NavLink to="/admin/footprint" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint') && 'hover:text-gray-200'}`}>
                      <div className="flex items-center">
                        {/* <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                          <path className={`fill-current text-gray-600 ${pathname.includes('footprint') && 'text-indigo-500'}`} d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z" />
                          <path className={`fill-current text-gray-600 ${pathname.includes('footprint') && 'text-indigo-500'}`} d="M1 1h22v23H1z" />
                          <path className={`fill-current text-gray-400 ${pathname.includes('footprint') && 'text-indigo-300'}`} d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z" />
                        </svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 3m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                          <path d="M8 7m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" />
                          <path d="M8 14l0 .01" />
                          <path d="M12 14l0 .01" />
                          <path d="M16 14l0 .01" />
                          <path d="M8 17l0 .01" />
                          <path d="M12 17l0 .01" />
                          <path d="M16 17l0 .01" />
                        </svg>
                        <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Cálculadora Huella</span>
                      </div>
                    </NavLink>
                  </li>

                  {/* Mapa  Especies */}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('speciesmap') && 'sec-neutro-background'}`}>
                    <NavLink to="/speciesmap" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('speciesmap') && 'hover:text-gray-200'}`}>
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('speciesmap') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M11 18l-2 -1l-6 3v-13l6 -3l6 3l6 -3v10" />
                          <path d="M9 4v13" />
                          <path d="M15 7v5" />
                          <circle cx="16.5" cy="17.5" r="2.5" />
                          <path d="M18.5 19.5l2.5 2.5" />
                        </svg>
                        <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.speciesMap")}</span>
                      </div>
                    </NavLink>
                  </li>

                </>

                :

                UserService.getUserType() == "Representante empresarial" ?
                  <>
                    {(UserService.getOriginData()).indexOf("excel") == -1 ?
                      <>
                        {/* Form */}
                        {(UserService.getOriginData() === 'Formulario') &&
                          <div>

                            <li className={` welcome-sidebar-mis-calculos px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/myfootprint') && 'sec-neutro-background'}`}>
                              <NavLink to="/footprint/myfootprint" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint/myfootprint') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint/myfootprint') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">

                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M4 3m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                    <path d="M8 7m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" />
                                    <path d="M8 14l0 .01" />
                                    <path d="M12 14l0 .01" />
                                    <path d="M16 14l0 .01" />
                                    <path d="M8 17l0 .01" />
                                    <path d="M12 17l0 .01" />
                                    <path d="M16 17l0 .01" />
                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.miscalculos")}</span>
                                </div>
                              </NavLink>
                            </li>

                            <li className={`welcome-sidebar-nuevo-calculo px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/calculationform') && 'sec-neutro-background'}`}>
                              <button onClick={() => navigate('/footprint/calculationform', { state: { tour: true, idUser: null } })} className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('/footprint/calculationform') && 'hover:text-gray-200'}`}>
                                {/* <NavLink to={{ pathname:"/footprint/calculationform", state: {idUser : null} }} className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('/footprint/calculationform') && 'hover:text-gray-200'}`}> */}
                                <div className="flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('/footprint/calculationform') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">



                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M13 5h8" />
                                    <path d="M13 9h5" />
                                    <path d="M13 15h8" />
                                    <path d="M13 19h5" />
                                    <path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                    <path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />

                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.choooseType")}</span>
                                </div>
                              </button>
                            </li>


                            <li className={`welcome-sidebar-organizaciones px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/organizations') && 'sec-neutro-background'}`}>
                              <NavLink to="/footprint/organizations" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint/organizations') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint/organizations') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">



                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M3 21l18 0" />
                                    <path d="M5 21v-14l8 -4v18" />
                                    <path d="M19 21v-10l-6 -4" />
                                    <path d="M9 9l0 .01" />
                                    <path d="M9 12l0 .01" />
                                    <path d="M9 15l0 .01" />
                                    <path d="M9 18l0 .01" />


                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.organizaciones")}</span>
                                </div>
                              </NavLink>
                            </li>

                            <li className={`welcome-sidebar-footprint-evolution px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/evolution') && 'sec-neutro-background'}`}>
                              <NavLink to="/footprint/evolution" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint/evolution') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint/evolution') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/evolution') && 'text-indigo-300'}`}  />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/evolution') && 'text-indigo-300'}`} d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/evolution') && 'text-indigo-300'}`} d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/evolution') && 'text-indigo-300'}`} d="M9 17v-5" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/evolution') && 'text-indigo-300'}`} d="M12 17v-1" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/evolution') && 'text-indigo-300'}`} d="M15 17v-3" />
                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.evolution")}</span>
                                </div>
                              </NavLink>
                            </li>

                            <li className={`welcome-sidebar-footprint-decarbonization px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/decarbonization') && 'sec-neutro-background'}`}>
                              <NavLink to="/footprint/decarbonization" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint/decarbonization') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  {/* <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint/decarbonization') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/decarbonization') && 'text-indigo-300'}`}  />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/decarbonization') && 'text-indigo-300'}`} d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/decarbonization') && 'text-indigo-300'}`} d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/decarbonization') && 'text-indigo-300'}`} d="M9 17v-5" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/decarbonization') && 'text-indigo-300'}`} d="M12 17v-1" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('footprint/decarbonization') && 'text-indigo-300'}`} d="M15 17v-3" />
                                  </svg> */}
                                  <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint/decarbonization') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                  <path d="M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h5" />
                                  <path d="M9 21v-6a2 2 0 0 1 2 -2h2c.325 0 .631 .077 .902 .215" />
                                  <path d="M16 22s0 -2 3 -4" />
                                  <path d="M19 21a3 3 0 0 1 0 -6h3v3a3 3 0 0 1 -3 3z" />
                                </svg>
                                      <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.decarbonization")}</span>
                                </div>
                              </NavLink>
                            </li>

                            {(currentPlan != undefined && currentPlan == 1) &&
                              <li className={`welcome-sidebar-planes px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/plans') && 'sec-neutro-background'}`}>
                                <NavLink to="/footprint/plans" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint/plans') && 'hover:text-gray-200'}`}>
                                  <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint/plans') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                      <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                      <path d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                                      <path d="M12 17v1m0 -8v1" />
                                    </svg>
                                    <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("footPrint.planes.titulo")}</span>
                                  </div>
                                </NavLink>
                              </li>
                            }

                            <li className={`welcome-sidebar-contactos px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/contacts') && 'sec-neutro-background'}`}>
                              <NavLink to="/footprint/contacts" className={`block text-gray-500 hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint/contacts') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint/contacts') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">

                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z" />
                                    <path d="M10 16h6" />
                                    <path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                    <path d="M4 8h3" />
                                    <path d="M4 12h3" />
                                    <path d="M4 16h3" />
                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.contacts")}</span>
                                </div>
                              </NavLink>
                            </li>

                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/tutorials') && 'sec-neutro-background'}`}>
                              <NavLink to="/footprint/tutorials" className={`block text-gray-500 hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint/tutorials') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-message-question fill-current text-gray-400 ${pathname.includes('footprint/tutorials') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M8 9h8" />
                                    <path d="M8 13h6" />
                                    <path d="M14 18h-1l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" />
                                    <path d="M19 22v.01" />
                                    <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" />
                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Tutoriales</span>
                                </div>
                              </NavLink>
                            </li>

                            {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('footprint/formContacts') && 'sec-neutro-background'}`}>
                              <NavLink to="/footprint/formContacts" className={`block text-gray-500 hover:text-gray-900 truncate transition duration-150 ${pathname.includes('footprint/formContacts') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('footprint/formContacts') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">

                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />
                                    <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />
                                    <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />
                                    <path d="M11 6l9 0" />
                                    <path d="M11 12l9 0" />
                                    <path d="M11 18l9 0" />
                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.formContacts")}</span>
                                </div>
                              </NavLink>
                            </li> */}






                          </div>

                        }

                        {/* Dashboard */}
                        {(UserService.getOriginData() !== 'Formulario') &&
                          <>
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('/company/dashboardintranet') && 'sec-neutro-background'}`}>
                              <NavLink to="/company/dashboardintranet" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('/company/dashboardintranet') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                    <path className={`fill-current text-gray-400 ${pathname.includes('/company/dashboardintranet') && '!text-indigo-500'}`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                                    <path className={`fill-current text-gray-600 ${pathname.includes('/company/dashboardintranet') && 'text-indigo-600'}`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                                    <path className={`fill-current text-gray-400 ${pathname.includes('/company/dashboardintranet') && 'text-indigo-200'}`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Dashboard</span>
                                </div>
                              </NavLink>
                            </li>
                            {/* Upload transactions */}
                            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('/company/uploadtransactions') && 'sec-neutro-background'}`}>
                              <NavLink to="/company/uploadtransactions" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('/company/uploadtransactions') && 'hover:text-gray-200'}`}>
                                <div className="flex items-center">
                                  <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                    <path className={`fill-current text-gray-400 ${pathname.includes('uploadtransactions') && 'text-indigo-300'}`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                                    <path className={`fill-current text-gray-700 ${pathname.includes('uploadtransactions') && '!text-indigo-600'}`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                                    <path className={`fill-current text-gray-600 ${pathname.includes('uploadtransactions') && 'text-indigo-500'}`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
                                  </svg>
                                  <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.uploadTransactions")} </span>
                                </div>
                              </NavLink>
                            </li>
                          </>

                        }




                        {/* Compensations*/}



                        < li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('compensations') && 'sec-neutro-background'}`}>
                          <NavLink to="/company/compensations" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('compensations') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-600 ${pathname.includes('compensations') && 'text-indigo-500'}`} d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z" />
                                <path className={`fill-current text-gray-700 ${pathname.includes('compensations') && 'text-indigo-300'}`} d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z" />
                              </svg>
                              <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.transacciones")}</span>
                            </div>
                          </NavLink>
                        </li>



                        {/* Emisiones */}
                        {(UserService.getOriginData() !== 'Formulario') &&
                          <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('emissions') && 'sec-neutro-background'}`}>
                            <NavLink to="/company/emissions" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('emissions') && 'hover:text-gray-200'}`}>
                              <div className="flex items-center">
                                <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                  <path className={`fill-current text-gray-400 ${pathname.includes('emissions') && 'text-indigo-300'}`} d="M13 6.068a6.035 6.035 0 0 1 4.932 4.933H24c-.486-5.846-5.154-10.515-11-11v6.067Z" />
                                  <path className={`fill-current text-gray-600 ${pathname.includes('emissions') && 'text-indigo-600'}`} d="M18.007 13c-.474 2.833-2.919 5-5.864 5a5.888 5.888 0 0 1-3.694-1.304L4 20.731C6.131 22.752 8.992 24 12.143 24c6.232 0 11.35-4.851 11.857-11h-5.993Z" />
                                  <path className={`fill-current text-gray-400 ${pathname.includes('emissions') && 'text-indigo-200'}`} d="M6.939 15.007A5.861 5.861 0 0 1 6 11.829c0-2.937 2.167-5.376 5-5.85V0C4.85.507 0 5.614 0 11.83c0 2.695.922 5.174 2.456 7.17l4.483-3.993Z" />
                                </svg>
                                <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.emisiones")}</span>
                              </div>
                            </NavLink>
                          </li>
                        }



                        {/* Proyectos */}
                        {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('proyectos') && 'sec-neutro-background'}`}>
                    <NavLink to="/company/proyectos" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('proyectos') && 'hover:text-gray-200'}`}>
                      <div className="flex items-center">
                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                          <path className={`fill-current text-gray-400 ${pathname.includes('proyectos') && 'text-indigo-300'}`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                          <path className={`fill-current text-gray-600 ${pathname.includes('proyectos') && 'text-indigo-600'}`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                          <path className={`fill-current text-gray-400 ${pathname.includes('proyectos') && 'text-indigo-200'}`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
                        </svg>
                        <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.proyectos")}</span>
                      </div>
                    </NavLink>
                  </li> */}


                        {/* Account */}
                        <li className={` welcome-sidebar-perfil px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('account') && 'sec-neutro-background'}`}>
                          <NavLink to="/account/account" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('account') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-600 ${pathname.includes('account') && 'text-indigo-500'}`} d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('account') && 'text-indigo-300'}`} d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('account') && 'text-indigo-500'}`} d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('account') && 'text-indigo-300'}`} d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z" />
                              </svg>
                              <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.account")}</span>
                            </div>
                          </NavLink>
                        </li>
                      </>
                      :

                      <>
                        {/* Dashboard */}
                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('/company/dashboardintranet') && 'sec-neutro-background'}`}>
                          <NavLink to="/company/dashboardintranet" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('/company/dashboardintranet') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-400 ${pathname.includes('/company/dashboardintranet') && '!text-indigo-500'}`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('/company/dashboardintranet') && 'text-indigo-600'}`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('/company/dashboardintranet') && 'text-indigo-200'}`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                              </svg>
                              <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Dashboard</span>
                            </div>
                          </NavLink>
                        </li>

                        {/* Transacciones*/}
                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('compensations') && 'sec-neutro-background'}`}>
                          <NavLink to="/company/compensations" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('compensations') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-600 ${pathname.includes('compensations') && 'text-indigo-500'}`} d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z" />
                                <path className={`fill-current text-gray-700 ${pathname.includes('compensations') && 'text-indigo-300'}`} d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z" />
                              </svg>
                              <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.transacciones")}</span>
                            </div>
                          </NavLink>
                        </li>



                        {/* Emisiones */}
                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('emissions') && 'sec-neutro-background'}`}>
                          <NavLink to="/company/emissions" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('emissions') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-400 ${pathname.includes('emissions') && 'text-indigo-300'}`} d="M13 6.068a6.035 6.035 0 0 1 4.932 4.933H24c-.486-5.846-5.154-10.515-11-11v6.067Z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('emissions') && 'text-indigo-600'}`} d="M18.007 13c-.474 2.833-2.919 5-5.864 5a5.888 5.888 0 0 1-3.694-1.304L4 20.731C6.131 22.752 8.992 24 12.143 24c6.232 0 11.35-4.851 11.857-11h-5.993Z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('emissions') && 'text-indigo-200'}`} d="M6.939 15.007A5.861 5.861 0 0 1 6 11.829c0-2.937 2.167-5.376 5-5.85V0C4.85.507 0 5.614 0 11.83c0 2.695.922 5.174 2.456 7.17l4.483-3.993Z" />
                              </svg>
                              <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.emisiones")}</span>
                            </div>
                          </NavLink>
                        </li>

                        {/* Upload single excel */}
                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('/company/uploadsingleexcel') && 'sec-neutro-background'}`}>
                          <NavLink to="/company/uploadsingleexcel" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('/company/uploadsingleexcel') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-400 ${pathname.includes('uploadsingleexcel') && 'text-indigo-300'}`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                                <path className={`fill-current text-gray-700 ${pathname.includes('uploadsingleexcel') && '!text-indigo-600'}`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('uploadsingleexcel') && 'text-indigo-500'}`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
                              </svg>
                              <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.uploadTransactions")} </span>
                            </div>
                          </NavLink>
                        </li>

                        {/* Account */}
                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('account') && 'sec-neutro-background'}`}>
                          <NavLink to="/account/account" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('account') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path className={`fill-current text-gray-600 ${pathname.includes('account') && 'text-indigo-500'}`} d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('account') && 'text-indigo-300'}`} d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z" />
                                <path className={`fill-current text-gray-600 ${pathname.includes('account') && 'text-indigo-500'}`} d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z" />
                                <path className={`fill-current text-gray-400 ${pathname.includes('account') && 'text-indigo-300'}`} d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z" />
                              </svg>
                              <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.account")}</span>
                            </div>
                          </NavLink>
                        </li>
                      </>
                    }
                  </>

                  :

                  <>
                    {/* Dashboard */}
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('dashboardmontes') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/dashboardmontes" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('dashboardmontes') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          {/* <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                            <path className={`fill-current text-gray-400 ${pathname.includes('dashboardmontes') && '!text-indigo-500'}`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                            <path className={`fill-current text-gray-600 ${pathname.includes('dashboardmontes') && 'text-indigo-600'}`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                            <path className={`fill-current text-gray-400 ${pathname.includes('dashboardmontes') && 'text-indigo-200'}`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                          </svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('dashboardmontes') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="9" />
                            <circle cx="12" cy="12" r="1" />
                            <line x1="13.41" y1="10.59" x2="16" y2="8" />
                            <path d="M7 12a5 5 0 0 1 5 -5" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Dashboard</span>
                        </div>
                      </NavLink>
                    </li>

                    {/* MisMontes */}
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('forestlist') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/forestlist" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('forestlist') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          {/* icono grafica donut */}
                          {/* <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                            <path className={`fill-current text-gray-400 ${pathname.includes('forestlist') && 'text-indigo-300'}`} d="M13 6.068a6.035 6.035 0 0 1 4.932 4.933H24c-.486-5.846-5.154-10.515-11-11v6.067Z" />
                            <path className={`fill-current text-gray-600 ${pathname.includes('forestlist') && 'text-indigo-600'}`} d="M18.007 13c-.474 2.833-2.919 5-5.864 5a5.888 5.888 0 0 1-3.694-1.304L4 20.731C6.131 22.752 8.992 24 12.143 24c6.232 0 11.35-4.851 11.857-11h-5.993Z" />
                            <path className={`fill-current text-gray-400 ${pathname.includes('forestlist') && 'text-indigo-200'}`} d="M6.939 15.007A5.861 5.861 0 0 1 6 11.829c0-2.937 2.167-5.376 5-5.85V0C4.85.507 0 5.614 0 11.83c0 2.695.922 5.174 2.456 7.17l4.483-3.993Z" />
                          </svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('forestlist') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                            <line x1="15" y1="21" x2="15" y2="18" />
                            <path d="M8 13l-2 -2" />
                            <path d="M8 12l2 -2" />
                            <path d="M8 21v-13" />
                            <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.montes")}</span>
                        </div>
                      </NavLink>
                    </li>
                    {/* SubirDocumentos */}
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('uploaddocuments') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/uploaddocuments" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('uploaddocuments') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" className=" shrink-0 h-6 w-6" viewBox="0 0 24 24" strokeWidth="1.8" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round" width={24} height={24}>
                            <path className={` text-gray-400 ${pathname.includes('uploaddocuments') && 'text-indigo-300'}`} stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path className={`fill-current text-gray-600 ${pathname.includes('uploaddocuments') && 'text-indigo-600'}`} d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                            <polyline points="9 15 12 12 15 15" />
                            <line x1="12" y1="12" x2="12" y2="21" />
                          </svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('uploaddocuments') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                            <polyline points="9 15 12 12 15 15" />
                            <line x1="12" y1="12" x2="12" y2="21" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.subirDoc")}</span>
                        </div>
                      </NavLink>
                    </li>
                    {/* ClausulasContratoForestal */}
                    {/* {!process.env.REACT_APP_FOREST_TERMS.includes(UserService.getUsername()) &&
                      <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('clausulas') && 'sec-neutro-background'}`}>
                        <NavLink to="/forest/clausulas" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('clausulas') && 'hover:text-gray-200'}`}>
                          <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('clausulas') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" />
                              <line x1="9" y1="7" x2="13" y2="7" />
                              <line x1="9" y1="11" x2="13" y2="11" />
                            </svg>
                            <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("contratoForestal.sidebar")}</span>
                          </div>
                        </NavLink>
                      </li>
                    } */}

                    {/* MarketplaceForestal */}
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('marketplace') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/marketplace" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('marketplace') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('marketplace') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="3" y1="21" x2="21" y2="21" />
                            <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                            <line x1="5" y1="21" x2="5" y2="10.85" />
                            <line x1="19" y1="21" x2="19" y2="10.85" />
                            <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Marketplace</span>
                        </div>
                      </NavLink>
                    </li>

                    {/* CalculatePrice */}
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('calculateprice') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/calculateprice" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('calculateprice') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('calculateprice') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="3" width="16" height="18" rx="2" />
                            <rect x="8" y="7" width="8" height="3" rx="1" />
                            <line x1="8" y1="14" x2="8" y2="14.01" />
                            <line x1="12" y1="14" x2="12" y2="14.01" />
                            <line x1="16" y1="14" x2="16" y2="14.01" />
                            <line x1="8" y1="17" x2="8" y2="17.01" />
                            <line x1="12" y1="17" x2="12" y2="17.01" />
                            <line x1="16" y1="17" x2="16" y2="17.01" />
                          </svg>

                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"> {t("sidebar.calculateprice")}</span>
                        </div>
                      </NavLink>
                    </li>

                    {/* CalculatorForest*/}
                    {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('incomecalculator') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/incomecalculator" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('incomecalculator') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                            <path className={`fill-current text-gray-600 ${pathname.includes('incomecalculator') && 'text-indigo-500'}`} d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z" />
                            <path className={`fill-current text-gray-700 ${pathname.includes('incomecalculator') && 'text-indigo-300'}`} d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("calculadoraMontes.calculatorMenu")}</span>
                        </div>
                      </NavLink>
                    </li> */}

                    {/* Servicios Forestales */}
                    {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('forestryServices') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/forestryServices" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('forestryServices') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('forestryServices') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" />
                            <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" />
                            <line x1="12" y1="20" x2="12" y2="10" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.forestryService")}</span>
                        </div>
                      </NavLink>
                    </li> */}

                    {/*Catálogo Servicios Forestales */}
                    {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('catalogServices') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/catalogServices" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('catalogServices') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">

                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('catalogServices') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 19h-6a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h6a2 2 0 0 1 2 2a2 2 0 0 1 2 -2h6a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-6a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2z" />
                            <path d="M12 5v16" />
                            <path d="M7 7h1" />
                            <path d="M7 11h1" />
                            <path d="M16 7h1" />
                            <path d="M16 11h1" />
                            <path d="M16 15h1" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.catalogServices")}</span>
                        </div>
                      </NavLink>
                    </li> */}



                    {/* Mapa  Especies */}
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('speciesmap') && 'sec-neutro-background'}`}>
                      <NavLink to="/forest/speciesmap" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('speciesmap') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('speciesmap') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M11 18l-2 -1l-6 3v-13l6 -3l6 3l6 -3v10" />
                            <path d="M9 4v13" />
                            <path d="M15 7v5" />
                            <circle cx="16.5" cy="17.5" r="2.5" />
                            <path d="M18.5 19.5l2.5 2.5" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.speciesMap")}</span>
                        </div>
                      </NavLink>
                    </li>

                    {/* Account */}
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('account') && 'sec-neutro-background'}`}>
                      <NavLink to="/account/account" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('account') && 'hover:text-gray-200'}`}>
                        <div className="flex items-center">
                          {/* <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                            <path className={`fill-current text-gray-600 ${pathname.includes('account') && 'text-indigo-500'}`} d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z" />
                            <path className={`fill-current text-gray-400 ${pathname.includes('account') && 'text-indigo-300'}`} d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z" />
                            <path className={`fill-current text-gray-600 ${pathname.includes('account') && 'text-indigo-500'}`} d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z" />
                            <path className={`fill-current text-gray-400 ${pathname.includes('account') && 'text-indigo-300'}`} d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z" />
                          </svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pathname.includes('account') && 'text-indigo-400'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="7" r="4" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          </svg>
                          <span className="text-sm font-medium ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{t("sidebar.account")}</span>
                        </div>
                      </NavLink>
                    </li>

                  </>
              }
              {/* FAQs */}
              {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('faqs') && 'sec-neutro-background'}`}>
                <NavLink to="/faqs" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('faqs') && 'hover:text-gray-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <circle className={`fill-current text-gray-400 ${pathname.includes('faqs') && 'text-indigo-300'}`} cx="18.5" cy="5.5" r="4.5" />
                      <circle className={`fill-current text-gray-600 ${pathname.includes('faqs') && 'text-indigo-500'}`} cx="5.5" cy="5.5" r="4.5" />
                      <circle className={`fill-current text-gray-600 ${pathname.includes('faqs') && 'text-indigo-500'}`} cx="18.5" cy="18.5" r="4.5" />
                      <circle className={`fill-current text-gray-400 ${pathname.includes('faqs') && 'text-indigo-300'}`} cx="5.5" cy="18.5" r="4.5" />
                    </svg>
                    <span className="text-sm font-medium ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">FAQs</span>
                  </div>
                </NavLink>
              </li> */}

              {/* proyectos CO2 */}
              {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('shop-2') && 'sec-neutro-background'}`}>
                <NavLink to="/shop-2" className={`block text-sidebar hover:text-gray-900 truncate transition duration-150 ${pathname.includes('shop-2') && 'hover:text-gray-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path className={`fill-current text-gray-400 ${pathname.includes('shop-2') && 'text-indigo-300'}`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                      <path className={`fill-current text-gray-700 ${pathname.includes('shop-2') && '!text-indigo-600'}`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                      <path className={`fill-current text-gray-600 ${pathname.includes('shop-2') && 'text-indigo-500'}`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
                    </svg>
                    <span className="text-sm font-medium ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">proyectos CO2</span>
                  </div>
                </NavLink>
              </li> */}


            </ul>
          </div>


        </div>




        {logoExternal &&
          <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}>
            <img alt='logo' src={logoby} width="75%"></img>
          </div>
        }


      </div>




    </div >
  );
}

export default Sidebar; 