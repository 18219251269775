import { useTranslation } from 'react-i18next';
import { formatDate, formatThousands } from '../utils/Utils';
import ReactTooltip from 'react-tooltip';
import { useEffect } from 'react';

export default function SaveAnswerResponse({ savedAnswer, showAnswerTableByQuestion, initCurrentQuestion }) {
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        const buttonElement = document.getElementById('button-with-tooltip');
        if (buttonElement) {
          buttonElement.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
        }
      }, [savedAnswer]);

    return <div>
        {savedAnswer.success &&
            <>
                <div className='text-lg font-bold'>{t("fpThird.addAnswerOk1")}</div>
                <div>{t("fpThird.addAnswerOk2")}</div>

                {savedAnswer.data.map(tables => (<div className="overflow-x-auto mt-2 mb-4">
                    <table className="table-bordered table-striped text-sm table-auto w-full divide-y divide-gray-200 ">
                        <thead>
                            <tr>
                                {Object.keys(tables.answerHeaderOutputDto).map((key) => (
                                    <th key={key} className="px-4 py-2" style={{ overflowWrap: 'break-word' }}>
                                        {tables.answerHeaderOutputDto[key]}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tables.answerBodyOutputDtos.length > 0 &&
                                tables.answerBodyOutputDtos.map(item =>
                                    <tr >
                                        {Object.keys(tables.answerHeaderOutputDto).map((key) => (
                                            <td key={key} className="px-4 py-2" >
                                                {item[key] === undefined || item[key] === "" ?
                                                    "" : ((isNaN(item[key])
                                                        ? (key === "registrationDate"
                                                            ? formatDate(item[key])
                                                            : item[key])
                                                        : formatThousands(item[key])))}
                                            </td>
                                        ))}

                                    </tr>

                                )}
                        </tbody>
                    </table>
                </div>))}
            </>
        }
        {
            !savedAnswer.success && <>
                <div className='text-lg font-bold'>{t("fpThird.addAnswerKO1")}</div>
                <div>{t("fpThird.addAnswerKO2")}</div>
            </>
        }
        <div className={`flex items-center justify-center md:justify-between gap-4 flex-wrap`} style={{ marginTop: '3rem' }}>
            <button id="button-with-tooltip" data-tip data-for="tooltip" className="btn-sm btn-banner-o text-md  rounded-lg px-8 py-2" type="submit" onClick={() => initCurrentQuestion(savedAnswer.data[0].answerBodyOutputDtos[0])}>
                {t("fpThird.addOtherAnswer")}
            </button>
            <ReactTooltip id="tooltip" place="top" effect="solid" getContent={() => "Si quieres añadir otro viaje distinto, haz click en este botón"} />
                <button className="btn-green text-md  rounded-lg px-4 py-2" type="submit" onClick={() => showAnswerTableByQuestion(true)}>
                {t("fpThird.respuestas")} </button>
        </div>


    </div >
}