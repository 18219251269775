import React from 'react'
import { useContext } from "react";
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';

export default function BannerDemo() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const url = "https://calendly.com/msanchez-airco2/30min"
    return (
        <section className=' bg-footer  '>
            <div className="max-w-6xl mx-auto px-4 sm:px-6  ">
                <div className="  p-6 flex flex-col pt-5 text-airco2-general-grey items-center">

                    <div className="mx-auto text-center " data-aos="fade-down">
                        <h2 className="h2 font-cabinet-grotesk">  {t("newHome.hiringTitle")}</h2>
                        <p className="text-xl sec-grey-text">  {t("newHome.hitingText1")}</p>
                    </div>

                    <div className='flex flex-row gap-4 items-center justify-between w-full  mt-2 mb-3  py-4' data-aos="fade-right" data-aos-delay="400">
                        <div className=''>
                            <p>{t("newHome.hitingText2")}</p>
                            <p>{t("newHome.hitingText3")}</p>
                            <p>{t("newHome.hitingText4")}</p>

                        </div>
                        <div className='mt-4' data-aos="fade-left" data-aos-delay="600">
                            <a className="btn text-airco2-principal bg-white linkNav   shadow-sm" href={url} target='_blank'>
                                {t("hometext.btnagendademo")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
