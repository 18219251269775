import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../../context/createcontext";
import employe from '../../../../../images/team3.svg';
import { formatThousands } from "../../../utils/Utils";

export default function EmployeBilling({ highLights, formatNumber }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");


    const [perEmployee, setPerEmployee] = useState();
    const [perBilling, setPerBilling] = useState();

    useEffect(() => {
        setPerEmployee(formatNumber(highLights?.data.perEmployee, 2));
        setPerBilling(formatNumber(highLights?.data.perBilling, 2));
    }, [highLights])




    return (
        <div className='grid-report-hightlights  rounded-sm border border-gray-200 px-2 py-3'>

            <div className='flex flex-col items-center '>
                <img src={employe} className="" />
            </div>

            <div className='flex flex-col text-center items-center justify-center'>
                {t("footPrint.empleadosFacturacion")}
            </div>

            <div className='flex flex-col justify-center text-center'>

                {!highLights.isFetching && <div className='text-center'>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" /></div>}

                {highLights.isFetching && <>
                    {perEmployee !== undefined &&
                        <div className='text-md mb-4'>
                            <span className='font-bold text-xl'>{formatThousands(perEmployee)}</span>
                            <span className='text-xs '> kgCO<sub>2</sub>e/{t("footPrint.employee")}</span></div>
                    }
                    {perEmployee === undefined &&
                        <div className='text-xs'>
                            {t("footPrint.sinDatoEmpleados")}
                        </div>}</>
                }

                {!highLights.isFetching && <div className="text-center">  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true" /></div>}

                {highLights.isFetching && <>
                    {perBilling !== undefined &&
                        <div className='text-md'>
                            <span className='font-bold text-xl'>{formatThousands(perBilling)}</span>
                            <span className='text-xs '> kgCO<sub>2</sub>e/{t("footPrint.facturacion")}</span>
                        </div>}
                    {highLights.isFetching && perBilling === undefined &&
                        <div className='text-xs'>        {t("footPrint.sinDatoFacturacion")}              </div>
                    }
                </>
                }


            </div>

        </div>
    )
}
