import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
import mibosque from '../../../images/mibosque.webp'

export default function BloqueDocNeutraliza() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className='container flex justify-center mt-5'>
            <div className='bloque-pasos  max-w-6xl mx-auto px-2 sm:px-3 text-airco2-general'>
                <h1 className='number-landing' >
                    4
                </h1>
                <div className='flex items-center pt-5 pb-3 flex-no-wrap custom-flex'>
                    <h2 className='h1 text-title text-airco2-title-landing w-full md:w-1/2 mt-3  m-3'> {t("landingEventos.neutraliza")}</h2>
                    <p className='text-sub w-full md:w-1/2 m-3 font-semibold flex flex-col items-end text-right'>
                        <span>{t("landingEventos.enamoraBosque")}</span>
                      
                        <span className='mt-2' style={{ width: '100%', height: '1.5px', backgroundColor: ' #878787' }}> </span>
                    </p>
                </div>
              



                <div className='m-3'>
                    <h3 className="  text-airco2-principal font-bold pb-3 text-center text-2xl" data-aos="fade-up" data-aos-delay="100" > {t("landingEventos.enamorarBosque")} </h3>
                    <p className="  pb-3 text-center text-lg" data-aos="fade-up" data-aos-delay="200" > {t("landingEventos.referente")} </p>
                </div>

                <div className='m-3 text-airco2-general bg-green-semitr p-4 rounded-lg'>
                    <p className='font-bold'>{t("landingEventos.4pasos")}:</p>
                    <ul className='list-pasos'>
                        <li>
                            {t("landingEventos.generarLanding")}
                        </li>
                        <li>
                            {t("landingEventos.insertarQr")}
                        </li>
                        <li>
                            {t("landingEventos.presentarBosque")}
                        </li>
                        <li>
                            {t("landingEventos.registrar")}
                        </li>

                    </ul>

                </div>
                <div className='m-3 pt-4 pb-4 italic font-bold text-center '>
                    <p>{t("landingEventos.muchoRuido")}</p>
                    <div className='flex justify-center mt-4 banner-foto'>
                        <img className='rounded-lg' style={{ width: '350px' }} src={mibosque}></img>

                    </div>


                </div>

                <div className='m-3 text-airco2-general font-semibold pb-4 pt-4'>
                    <span>
                    {t("landingEventos.proxEvento")}
                       
                    </span>

                </div>


                <div className='m-3 text-airco2-general bg-green-semitr p-4 rounded-lg mb-5'>
                    <p className='font-bold text-center text-lg'>¿Te gustaría saber más?</p>
                    <div className=" mt-6 mb-6 flex justify-center" data-aos="fade-in" data-aos-delay="100">
                        <a href="" className="btn sec-green-background text-airco2-filter-content  whitespace-nowrap w-full lg:w-1/2 " >
                            <span style={{ color: 'white', textDecoration: 'none' }}>Más información</span>

                        </a>
                    </div>


                </div>


            </div>
        </div>
    )
}
