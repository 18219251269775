import React from 'react'

export default function PruebaCoolfarming() {
  return (
    <iframe
        title="Iframe-Login"
        width="100%"
        height="700px"
        src="http://localhost:3000/login-external?param=coolberries"
        allowFullScreen
      ></iframe>
  )
}
