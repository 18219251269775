
import { default as React, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext";
import { formatThousands } from '../../utils/Utils';
import { Spinner } from 'reactstrap';

function ReportSummary({ report }) {


    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [scope1, setScope1] = useState(undefined);
    const [scope2, setScope2] = useState(undefined);
    const [scope3, setScope3] = useState(undefined);



    useEffect(() => {
        const sumsByScope = {
            1: 0,
            2: 0,
            3: 0,
        };


        report?.forEach(item => {
            sumsByScope[item.scope] += item.totalKgCO2e;
        });


        setScope1(sumsByScope[1]);
        setScope2(sumsByScope[2]);
        setScope3(sumsByScope[3]);
    }, [report])



    const circuloLeyenda = `width:'40px',height:'40px', borderRadius: '50%',border:'solid',gap:'25px'`

    return (
        <div id="gdashboard04" className="flex flex-col col-span-full xl:col-span-6 bg-white rounded-sm border border-gray-200">




            <div className=" p-3 square-grid-report-footprint-emissions  ">
                <div className="box-report-footprint-emissions rounded-sm border border-gray-200 ">
                    <div className='p-2 flex flex-col items-center'>
                        <div className='mb-2 text-center'>
                            <div className='circulo1'>
                                <div className="text-lg font-bold text-center relative" style={{ color: 'grey', top: '50%' }}>
                                    {report && scope1 && formatThousands(scope1)}
                                    {!report && <Spinner className='' />}
                                    <span className=' font-medium'> KgCO<sub>2</sub>e</span></div>

                                <div className='iconos flex gap-2 relative ' style={{ top: '86%', left: '15%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-car" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                        <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                        <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className=" mt-2 icon icon-tabler icon-tabler-building-factory-2" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 21h18" />
                                        <path d="M5 21v-12l5 4v-4l5 4h4" />
                                        <path d="M19 21v-8l-1.436 -9.574a.5 .5 0 0 0 -.495 -.426h-1.145a.5 .5 0 0 0 -.494 .418l-1.43 8.582" />
                                        <path d="M9 17h1" />
                                        <path d="M14 17h1" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-gas-station" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 11h1a2 2 0 0 1 2 2v3a1.5 1.5 0 0 0 3 0v-7l-3 -3" />
                                        <path d="M4 20v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14" />
                                        <path d="M3 20l12 0" />
                                        <path d="M18 7v1a1 1 0 0 0 1 1h1" />
                                        <path d="M4 11l10 0" />
                                    </svg>

                                </div>

                            </div>
                            <span className='text-xs'>{t("dash.alcance1")}</span>
                            <p className='text-xs italic'>{t("footPrint.emisionesDirectas")}</p>
                        </div>


                    </div>
                </div>
                <div className="box-report-footprint-emissions rounded-sm border border-gray-200 ">
                    <div className='p-2 flex flex-col items-center'
                    >
                        <div className='mb-2 text-center'>
                            <div className='circulo2'>
                                <div className="text-lg font-bold text-center relative" style={{ color: 'grey', top: '50%' }}>
                                    {report && scope2 && formatThousands(scope2)}
                                    {!report && <Spinner className='' />}
                                    <span className='font-medium'> KgCO<sub>2</sub>e</span>
                                </div>

                                <div className='iconos flex gap-2 relative px-1 ' style={{ top: '86%', left: '15%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-crane" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M6 21h6" />
                                        <path d="M9 21v-18l-6 6h18" />
                                        <path d="M9 3l10 6" />
                                        <path d="M17 9v4a2 2 0 1 1 -2 2" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 icon icon-tabler icon-tabler-building-broadcast-tower" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                        <path d="M16.616 13.924a5 5 0 1 0 -9.23 0" />
                                        <path d="M20.307 15.469a9 9 0 1 0 -16.615 0" />
                                        <path d="M9 21l3 -9l3 9" />
                                        <path d="M10 19h4" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="  icon icon-tabler icon-tabler-flame" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z" />
                                    </svg>

                                </div>

                            </div>
                            <span className='text-xs'>{t("dash.alcance2")}</span>
                            <p className='text-xs italic'>{t("footPrint.emisionesIndirectas")}</p>
                        </div>

                    </div>
                </div>

                <div className="box-report-footprint-emissions hidden md:flex md:grow" >


                    <div className='mb-2 h-full flex'>

                        <div className='flex gap-2  flex-wrap items-center justify-center'>
                            <div className='circuloLeyenda' style={{ color: 'grey', backgroundColor: ' rgba(250, 204, 21, 0.15)', borderColor: '#facc15', }}>

                                <p className='text-xs px-2'>   <span className='font-bold'>{t("dash.alcance1")}. </span>{t("footPrint.expAlcance1")}</p>
                            </div>
                            <div className='circuloLeyenda' style={{ borderColor: '#6366f1', color: 'grey', backgroundColor: 'rgba(99, 102, 241, 0.15)' }}>

                                <p className='text-xs px-2'>   <span className='font-bold'>{t("dash.alcance2")}. </span>{t("footPrint.expAlcance2")}</p>
                            </div>
                            <div className='circuloLeyenda' style={{ borderColor: '#fb923c', color: 'grey', backgroundColor: 'rgba(251, 146, 60, 0.15)' }}>

                                <p className='text-xs px-2'>   <span className='font-bold'>{t("dash.alcance3")}. </span>{t("footPrint.expAlcance3")}</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="box-report-footprint-emissions rounded-sm border border-gray-200 ">
                    <div className='p-2 flex flex-col items-center'
                    >
                        <div className='mb-2 text-center'>
                            <div className='circulo3'>
                                <div className="text-lg font-bold text-center relative" style={{ color: 'grey', top: '50%' }}>
                                    {report && scope3 && formatThousands(scope3)}
                                    {!report && <Spinner className='' />}
                                    <span className=' font-medium'> KgCO<sub>2</sub>e</span>
                                </div>

                                <div className='iconos flex gap-2 relative ' style={{ top: '86%', left: '15%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-truck" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                        <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                        <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className=" mt-2 icon icon-tabler icon-tabler-device-imac" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 4a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-12z" />
                                        <path d="M3 13h18" />
                                        <path d="M8 21h8" />
                                        <path d="M10 17l-.5 4" />
                                        <path d="M14 17l.5 4" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plane-departure" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14.639 10.258l4.83 -1.294a2 2 0 1 1 1.035 3.863l-14.489 3.883l-4.45 -5.02l2.897 -.776l2.45 1.414l2.897 -.776l-3.743 -6.244l2.898 -.777l5.675 5.727z" />
                                        <path d="M3 21h18" />
                                    </svg>

                                </div>

                            </div>
                            <span className='mt-2'>
                                <p className='text-xs'>{t("dash.alcance3")}</p>
                                <p className='text-xs italic'>{t("footPrint.emisionesIndirectas")}</p>

                            </span>

                        </div>

                    </div>
                </div>

                <div className="box-report-footprint-emissions  grow md:hidden" >


                    <div className='mb-2 h-full flex'>

                        <div className='flex gap-2  flex-wrap items-center justify-center'>
                            <div className='circuloLeyenda' style={{ color: 'grey', backgroundColor: ' rgba(250, 204, 21, 0.15)', borderColor: '#facc15', }}>

                                <p className='text-xs px-2'>   <span className='font-bold'>{t("dash.alcance1")}. </span>{t("footPrint.expAlcance1")}</p>
                            </div>
                            <div className='circuloLeyenda' style={{ borderColor: '#6366f1', color: 'grey', backgroundColor: 'rgba(99, 102, 241, 0.15)' }}>

                                <p className='text-xs px-2'>   <span className='font-bold'>{t("dash.alcance2")}. </span>{t("footPrint.expAlcance2")}</p>
                            </div>
                            <div className='circuloLeyenda' style={{ borderColor: '#fb923c', color: 'grey', backgroundColor: 'rgba(251, 146, 60, 0.15)' }}>

                                <p className='text-xs px-2'>   <span className='font-bold'>{t("dash.alcance3")}. </span>{t("footPrint.expAlcance3")}</p>
                            </div>

                        </div>

                    </div>
                </div>

            </div>





        </div>

    );
}

export default ReportSummary;
