import { Link } from "react-router-dom";
function Contreebuye() {
    return (<main id="main" style={{ backgroundColor: "var(--airco2-green-color)" }} className=" min-h-screen h-full">
        <div className="container" style={{ maxWidth: "64rem" }}>

            <div className="relative flex">

                {/* Content */}
                <div className="w-full md:w-1/2">


                    <img alt="bosque limpio" id="portada" src="/BosqueLimpio.png" />

                </div>

                {/* Image */}
                <div className=" md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
                    <div id="header-text" className=" text-white  text-right mr-5">


                        <div className=" text-6xl" style={{ marginTop: "60px", letterSpacing: "0.1em" }}>
                            <p>BOSQUE</p>
                            <p>LIMPIO,</p>
                            <p>BOSQUE</p>
                            <p>VIV<b>O<sub>2</sub></b></p>
                        </div>

                        <div className="text-2xl">
                            <p>#Con<i>Tree</i>buye</p>
                        </div>
                        <br /><br /><br /><br />
                        <div className="m">
                            <p style={{ letterSpacing: "0.1em" }}>UNA PROPUESTA DE</p>
                            <img alt="logo" src='/logo.svg' height="150" width="130" style={{ float: "right" }} />
                            <div style={{ clear: "both" }}></div>
                            <p className="mt-5" style={{ letterSpacing: "0.1em" }}>EN COLABORACIÓN CON</p>
                            <img alt="juan_xxiii" src='/JUAN_xxiii.jpeg' height="150" width="130" style={{ float: "right" }} />
                            <div style={{ clear: "both" }}></div>
                        </div>
                    </div>
                    <div id="header-text-resp" className=" text-white  text-right mr-5">


                        <div className=" text-3xl" style={{ marginTop: "50px", letterSpacing: "0.1em" }}>
                            <p>BOSQUE</p>
                            <p>LIMPIO,</p>
                            <p>BOSQUE</p>
                            <p>VIV<b>O<sub>2</sub></b></p>
                        </div>

                        <div className="text-xl">
                            <p>#Con<i>Tree</i>buye</p>
                        </div>
                        <br /><br /><br />
                        <div className="text-sm">
                            <p style={{ letterSpacing: "0.1em" }}>UNA PROPUESTA DE</p>
                            <img alt="logo" src='/logo.svg' height="150" width="100" style={{ float: "right" }} />
                            <div style={{ clear: "both" }}></div>
                            <p className="mt-3" style={{ letterSpacing: "0.1em" }}>EN COLABORACIÓN CON</p>
                            <img alt="juan_xxiii" src='/JUAN_xxiii.jpeg' height="150" width="100" style={{ float: "right" }} />
                            <div style={{ clear: "both" }}></div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="text-white text-xl text-justify pt-5 pb-5">
                Airco2 es la startup que ayuda a las empresas a ser más sostenibles, ir un paso por delante y crecer, gestionando la huella de carbono con un software pionero que automatiza el cálculo, la reducción y la compensación de su huella. Queremos cuidar el ecosistema y ayudar a prevenir las consecuencias del cambio climático.
                <br />
                <div className="text-center mt-5 mb-5">
                    <a href="https://www.instagram.com/p/Ci14ki3sYH5/" target={"_blank"}  className="h-100 btn hover:bg-white shadow sec-white-text sec-orange-background text12">Quiero con<i>tree</i>buir</a>
                    <br />
                </div>
                Este verano se han registrado las emisiones de carbono más altas de los últimos 20 años en Europa, debido a la cantidad de incendios. Desde principios de año y hasta el 3 de septiembre, en la UE, la superficie total quemada ronda las 750.000 hectáreas.
                (Fuente: Servicio de Vigilancia Atmosférica Copernicus (CAMS).
                <br /><br />
                Nuestros montes son imprescindibles para neutralizar la huella de carbono, pero también para controlar la erosión y mantener el ecosistema.
                <br /><br />
                Es por ello que queremos aportar nuestro granito a la prevención de incendio y el cuidado de los montes, contribuyendo a las labores de limpieza y desbroce.
                <br /><br />
                Para conseguirlo, contamos con la colaboración de la Fundación Juan XXIII, que se encarga de ejecutar estas acciones fundamentales con un equipazo constituido por personas con discapacidad intelectual.
                <br /><br />

                <img alt="basura" src="/basura.jpg" />

                <p className="mt-5 mb-3 text-3xl uppercase"> ¿Cómo participar?</p>
                Participar es gratis y, con vuestra colaboración, esperamos contribuir entre todos a esta labor de
                concienciación social.
                <br /><br />
                Aýudanos a conocer a empresas que tengan una visión ecofriendly como nosotros, para participar en el movimiento #Contreebuye.
                <br /><br />
                Menciona en nuestro post de la cuenta de <a href="https://www.instagram.com/p/Ci14ki3sYH5/" target={"_blank"} style={{ textDecoration: "underline" }}> Instagram @Airco2_fintech</a> a alguna de estas empresas y contactaremos con ellos para proponerles unirse al movimiento con un simbólico donativo de 1 € que será destinado a la limpieza y desbroce de los montes gallegos.
                <br /><br />
                Por cada empresa que se una al movimiento nosotros donaremos también 1€. Todo el dinero recaudado será íntegramente aportado a la Fundación Juan XXIII de Pontevedra para este fin.
                <br /><br />



                <div className="relative flex mt-5">

                    {/* Content */}


                    {/* Image */}
                    <div className=" md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">

                        <img id="img-hojas" src="/hojas.png" style={{ maxWidth: "400px", float: "right" }} />

                    </div>

                    <div className="w-full md:w-1/2">


                        <div className="text-white  text-left mr-5">

                            <p className="mt-3 mb-3 text-3xl uppercase"> ¿Quieres contribuir un poco más?</p>

                            Si quieres aportar un granito más al movimiento #Contreebuye te proponemos varias opciones:
                            <br /><br />
                            Puedes ayudarnos a compartir en Redes Sociales la publicación junto con el hashtag #contreebuye
                            ¡Díselo a tus conocidos! Ayúdanos a conocer más empresas que quieran colaborar.
                            <br /><br />
                            Si quieres, también puedes escribirnos por mensaje privado.
                            Si tienes una empresa y quieres colaborar directmente puedes contactar con nosotros para unirte a la iniciativa.
                            <br /><br />
                        </div>
                    </div>

                </div>



                <p className="mt-5 mb-3 text-3xl uppercase"> Duración</p>

                La campaña de recaudación comenzará el viernes 23 de septiembre y finalizará el 15 de noviembre. Una vez concluida esa fecha, comunicaremos en nuestras RRSS la cantidad recaudada y la destinaremos a la Fundación Juan XXIII para contribuir a su labor de limpieza y desbroce de montes.
                <div className="text-center mt-5">
                    <a href="https://www.instagram.com/p/Ci14ki3sYH5/" target={"_blank"}  className="h-100 btn hover:bg-white shadow sec-white-text sec-orange-background text12">Quiero con<i>tree</i>buir</a>

                </div>

                <div className="mt-5">
                    <a href="https://www.instagram.com/p/Ci14ki3sYH5/" target={"_blank"}>   <img src="BosqueLimpio.jpg" style={{ display: "block", marginLeft: "auto", marginRight: "auto", maxHeight: "400px" }} /></a>

                </div>

            </div>
        </div>

    </main>)
}

export default Contreebuye;