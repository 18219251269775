import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Transition from './Transition';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import idioma from '../../../images/idioma1.png';


function DropdownLanguage({
  align
}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const currentPath = window.location.pathname;
  const [show, setShow] = useState(true)
  useEffect(() => {
    if (currentPath.includes("forest/formMarketplace") || currentPath.includes("forest/imagesMarketplace")) {
      setShow(false)
    }
  })

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // Cambiar el idioma y ocultar el idioma que este seleccionado


  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [spain, updateSpain] = useState(lng === "es" ? true : false)
  const [portug, updatePortugal] = useState(lng === "pt" ? true : false)
  const [england, updateEngland] = useState(lng === "en" ? true : false)
  const [galic, updateGalicia] = useState(lng === "gl" ? true : false)

  // actualizar el idioma del localStorage
  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);

      localStorage.setItem('idioma', lng);
      updateLng(lng)
    }
  }, [lng, i18n]);




  // close the mobile menu with scroll
  const handleScroll = () => setDropdownOpen(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

  }, []);

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    updateLng(lng);

    localStorage.setItem('idioma', lng);

    updateSpain(lng === "es");
    updatePortugal(lng === "pt");
    updateGalicia(lng === "gl");
    updateEngland(lng === "en");
  };



  return (
    !show ? <></> :
      <div className="relative inline-flex">
        <button arial-label="btn-openFilter"
          ref={trigger}
          className={`flex items-center justify-center  hover:bg-gray-200 transition duration-150 rounded-full ${dropdownOpen && 'bg-gray-200'}`}
         
        
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
        >
        
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-world" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
            <path d="M3.6 9h16.8" />
            <path d="M3.6 15h16.8" />
            <path d="M11.5 3a17 17 0 0 0 0 18" />
            <path d="M12.5 3a17 17 0 0 1 0 18" />
          </svg>
        </button>

        <Transition
          className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
          show={dropdownOpen}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            <div className="text-xs font-semibold text-gray-400 uppercase pt-1.5 pb-2 px-4"></div>
            <ul>
              {spain === false ?
                <li onClick={() => setDropdownOpen(false)}>
                  <Link
                    className="font-medium text-sm text-airco2-principal hover:text-indigo-600 flex items-center py-1 px-3"
                    to="#0"
                    onClick={() => handleChangeLanguage('es')}
                  >
                    <svg className="w-3 h-3 fill-current var(--airco2-orange-color) shrink-0 mr-2" viewBox="0 0 12 12">

                      <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" />
                    </svg>
                    <span>Español</span>
                  </Link>
                </li>
                : ""}

              {galic === false ?
                <li onClick={() => setDropdownOpen(false)}>
                  <Link
                    className="font-medium text-sm text-airco2-principal hover:text-indigo-600 flex items-center py-1 px-3"
                    to="#0"
                    onClick={() => handleChangeLanguage('gl')}
                  >
                    <svg className="w-3 h-3 fill-current var(--airco2-orange-color) shrink-0 mr-2" viewBox="0 0 12 12">
                      <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" />
                    </svg>
                    <span>Galego</span>
                  </Link>
                </li>
                : ""}

              {portug === false ?
                <li onClick={() => setDropdownOpen(false)}>
                  <Link
                    className="font-medium text-sm text-airco2-principal hover:text-indigo-600 flex items-center py-1 px-3"
                    to="#0"
                    onClick={() => handleChangeLanguage('pt')}
                  >
                    <svg className="w-3 h-3 fill-current var(--airco2-orange-color) shrink-0 mr-2" viewBox="0 0 12 12">
                      <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" />
                    </svg>
                    <span>Portugues</span>
                  </Link>
                </li>
                : ""}

              {england === false ?
                <li onClick={() => setDropdownOpen(false)}>
                  <Link
                    className="font-medium text-sm text-airco2-principal hover:text-indigo-600 flex items-center py-1 px-3"
                    to="#0"
                    onClick={() => handleChangeLanguage('en')}
                  >
                    <svg className="w-3 h-3 fill-current var(--airco2-orange-color) shrink-0 mr-2" viewBox="0 0 12 12">
                      <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" />
                    </svg>
                    <span>English</span>
                  </Link>
                </li>
                : ""}
            </ul>
          </div>
        </Transition>
      </div>

  )
}

export default DropdownLanguage;