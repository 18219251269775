import { Helmet } from "react-helmet"
import metatag_image from './../images/bussines.webp'
import { useTranslation } from 'react-i18next';

export default function PageMetatags({ title, desc, image }) {
    const { t } = useTranslation("global");


    if (title === undefined || title === "") {
        title = ""
    }

    if (desc === undefined || desc === "") {
        desc = t("meta.description")
    }

    if (image === undefined || image === "") {
        image = metatag_image;
    }

    return (


        <Helmet>
            <title>{t("meta.title") + title}</title>
            <meta name="title" content={t("meta.title") + title} />
            <meta name="description" content={desc} />
            <link rel="canonical" href={window.location.href} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={t("meta.title") + title} />
            <meta property="og:description" content={desc} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={window.location.href} />

            <meta name="twitter:card" content="photo" />
            <meta name="twitter:title" content={t("meta.title") + title} />
            <meta name="twitter:description" content={desc} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:url" content={window.location.href} />

        </Helmet>)

}