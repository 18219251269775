import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
import mvl from '../../../images/Mockup_Movil.webp'
import plaza from '../../../images/Plaza_Herreria_Ponte.webp'
export default function BloqueTxtImg() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    return (
        <div>
            <div className='container-top flex flex-wrap'>
                <div className='w-full md:w-1/2'
                
                style={{
                    backgroundImage: `url(${mvl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    minHeight:'200px',
                  

                }}>
                 
                </div>
                <div className='w-full md:w-1/2    text-airco2-concellos sec-green-background flex flex-col justify-center' style={{paddingTop:'8rem',paddingBottom:'8rem',paddingLeft:'2rem', paddingRight:'2rem'}}>
                    <p className='text-2xl text-airco2-titles-bloques  text-center'>
                        <span className='text-subtitle-bloques font-bold'>
                        {(t("landingConcellos.proyectoAyuntamiento"))}
                        </span>
                        <span>
                        {(t("landingConcellos.de"))}
                        </span>
                        <span className='font-bold'>
                        Airco2*
                        </span>
                        <span>
                        {(t("landingConcellos.y"))}
                        </span>
                        <span className='font-bold'>
                        {(t("landingConcellos.tuAyuntamiento"))}
                        </span>
                        
                        
                        
                        </p>
                    <p className='text-xl mt-5'>{(t("landingConcellos.unicoMarket"))}</p>

                </div>

            </div>

            <div className='container-bottom flex flex-wrap'>

                <div className='w-full md:w-1/2  text-airco2-concellos p-4 flex flex-col '>
                    <p id='txtJuntos' className='text-2xl text-airco2-titles-bloques font-bold text-airco2-principal px-1'>{(t("landingConcellos.juntosMarcha"))}</p>
                    <div id='txtImgJuntos' className='textos-img-plaza p-2'>
                        <div className='mb-4'>
                            <p className='font-bold text-xl'>{(t("landingConcellos.ayudas"))}</p>
                            <p className='text-lg'>{(t("landingConcellos.ayudasTxt"))}</p>

                        </div>
                        <div className='mb-4'>
                        <p className='font-bold text-xl'>{(t("landingConcellos.ingresos"))}</p>
                        <p className='text-lg'>{(t("landingConcellos.ingresosTxt"))}</p>

                        </div>
                        <div className='mb-4'>
                        <p className='font-bold text-xl'>{(t("landingConcellos.mejoras"))}</p>
                        <p className='text-lg'>{(t("landingConcellos.mejorasTxt"))}</p>

                        </div>
                        <div className='mb-4'>
                        <p className='font-bold text-xl'>{(t("landingConcellos.formacion"))}</p>
                        <p className='text-lg'>{(t("landingConcellos.formacionTxt"))}</p>

                        </div>
                        <div className='mb-4'>
                        <p className='font-bold text-xl'>{(t("landingConcellos.reconocimiento"))}</p>
                        <p className='text-lg'>{(t("landingConcellos.reconocimientoTxt"))}<span className='font-bold'>{(t("landingConcellos.reconocimientoTxt2"))}</span></p>

                        </div>



                    </div>

                </div>

                <div className='w-full md:w-1/2'
                style={{
                    backgroundImage: `url(${plaza})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    minHeight:'300px',
                   

                }}>
                   
                </div>

            </div>


        </div>
    )
}
