import React from 'react'
import Header from '../../../partials/Header'
import Footer from '../../../partials/Footer'

import BloqueBienvenida from './components/BloqueBienvenida'
import BloquePuntos from './components/BloquePuntos'
import BloqueDescrip from './components/BloqueDescrip'
import BloqueDemo from './components/BloqueDemo'
import BloquePlanes from './components/BloquePlanes'
import BloqueNews from './components/BloqueNews'

import BloqueFooter from './components/BloqueFooter'
import BloqueElegir from './components/BloqueElegir'
import BloqueLogos from './components/BloqueLogos'


export default function CarbonManager() {
  return (
    <div>  
        <Header/>
        <BloqueBienvenida/>
        <BloquePuntos/>
        <BloqueLogos/>
        <BloqueDescrip/>
        <BloqueElegir/>
        {/* <BloqueDemo/> */}
        {/* <BloquePlanes/> */}
        <BloqueNews/>
        {/* <BloqueMoreRead/> */}
        <BloqueFooter/>
        <Footer/>
    </div>
  )
}
