import React from 'react'
import imagenBanner from '../../../../images/02_DataDriven.webp'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext";
import { useContext } from 'react'
import { HashLink } from 'react-router-hash-link';


export default function BannerBienvenida() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className='bg-landing'>
          <div className="pt-12 md:pt-20">
            {/* Section content */}
            <div className="flex  justify-center items-center">
              {/* Content */}
              <div className="w-full md:w-1/2 justify-center px-custom" 
              data-aos="fade-right"
              >
                <div className='h-100 flex flex-col justify-between px-8'>
                  <h1 className="mt-5 md:mt-2 pt-4 md:pt-1  sec-green-text text35"> {t("landingCarbon.CalculatingYourCompany")}</h1>
                  <h2 className="sec-grey-text  text16 mt-4"><span>{t("landingCarbon.con")} </span><span className='font-bold'>Airco2</span><span>{t("landingCarbon.QuiclyConfidently")}</span></h2>
                  <div className='mt-4 alignCenterMvl'>
                    <a href="https://calendar.app.google/2qHuuXoCdm8jknpb6" target={"_blank"}>
                      <button className='btn btn-banner-o lg:w-full mb-4 sm:w-auto sm:mb-0 mr-2'>{t("landingCarbon.btnDemo")}</button>
                    </a>
                    <HashLink aria-label='informacion' className='linkhover' to={`#informacion`} smooth={true} offset={50} duration={500}>
                      <button className='btn btn-banner lg:w-full mb-4 sm:w-auto sm:mb-0'>{t("landingCarbon.btnInfo")}</button>
                    </HashLink>
                  </div>
                </div>
              </div>
              {/* Image */}
              <div className="w-full md:w-1/2 hidden md:flex"
                data-aos="fade-left"
                style={{
                  backgroundImage: `url(${imagenBanner})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  minHeight: '800px',
                //   width: '100%',
                }}>
              </div>
            </div>
          </div>
        </div>
      )
      
}
