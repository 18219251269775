import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import Header from '../Header'
import Sidebar from '../Sidebar';
import axios from 'axios';
import { OverlayTrigger } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Spinner } from 'react-bootstrap';
import { Modal, Button, Col } from "react-bootstrap";
import { Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Contacts from './ContactsComponent';
import { useLocation } from 'react-router-dom';
import Banner from '../../../components/utils/Banner';
import QRCodeGenerator from './QRCodeGenerator';


export default function Qr() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const location = useLocation();
    const pathParams = useParams();
    const dataCalculation = location.state?.data;
    const idExternalQuest = pathParams.id

    const [urlQR, setUrlQR] = useState({
        data: [],
        isFetching: false
    });

    const fetchUrlQr = async () => {
        setUrlQR({ data: urlQR.data, isFetching: true });
        try {

            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternalQuest}/url?organizationName=${dataCalculation.orgName}&calculationYear=${dataCalculation.year}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setUrlQR({ data: response.data, isFetching: false });




        } catch (exception) {
            setUrlQR({ data: [], isFetching: false });

        }
    };

    useEffect(() => {

        fetchUrlQr();

    }, [])

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                    <div className="mb-8 text-gray-800  flex justify-between flex-wrap">

                        <h1 className="text-xl md:text-2xl font-bold flex gap-1">
                            <span>
                                {t("fpThird.calculo")}
                            </span>
                            <span>
                                {dataCalculation.year}
                            </span></h1>
                        <span className='text-xl md:text-2xl font-bold flex gap-1'>
                            {t("fpThird.organizacion")}:{dataCalculation.orgName}
                        </span>
                        <span className='text-xl md:text-2xl font-bold flex gap-1'>
                            {t("fpThird.estado")}:{dataCalculation.status}
                        </span>


                    </div>

                    <div>
                        <QRCodeGenerator
                            url={urlQR.data.data}
                        ></QRCodeGenerator>
                    </div>
                </div>

            </div>

        </div>
    )
}
