import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import Banner from "../../../components/utils/Banner";
import { IdiomContext } from "../../../context/createcontext";
import UserService from "../../../services/UserService";
import DocumentsListTable from "../subirDoc/DocumentsListTable";
import { formatDate, formatDefaultDate, formatThousands } from '../utils/Utils';

function AnswerTable({ idQuestion, idCalculation, addAnswer, activePostButton, status, setNoAnswer, inputs }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [modalConfirmDelete, setModalConfirmDelete] = useState({ view: false, id: "", indexTable: 0 });
    const [modalDocument, setModalDocument] = useState({ view: false, id: "" });
    const [modalConfirmDuplicate, setModalConfirmDuplicate] = useState({ view: false, answer: {}, id: "" });
    const [listInputsByQuestionId, setListInputsByQuestionId] = useState([]);

    const [answers, setAnswer] = useState({
        data: [],
        isFetching: false
    });

    const [deletedAnswer, setDeletedAnswer] = useState({
        data: [],
        isFetching: false
    });

    const renderTooltipEditar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.noEditExternalAnswer")}
        </Tooltip>
    );

    const renderTooltipEditartrue = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.editar")}
        </Tooltip>
    );

    const renderTooltipdelete = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.eliminar")}
        </Tooltip>
    );

    const renderTooltipDuplicar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.duplicar")}
        </Tooltip>
    );
    const renderTooltipWarningFile = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.uploadExternalCertification")}
        </Tooltip>
    );

    const getQuestionInputs = async (idQ) => {
        const url = `${process.env.REACT_APP_CH_QUESTIONNAIRE}/calculation/${idCalculation}/questionblock/${idQuestion}?idQuestion=${idQ}`;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            return { idQuestion: idQ, inputs: response.data.data.inputs };
        } catch (exception) {
            console.error(exception);
            return { idQuestion: id, inputs: [] };
        }
    };

    useEffect(() => {
        const fetchInputs = async () => {
            if (inputs?.length === 1) {
                let currentIdQuestions = inputs[0].input.options.map(v => v.value);
                try {
                    const questionInputs = await Promise.all(currentIdQuestions.map(id => getQuestionInputs(id)));
                    setListInputsByQuestionId(questionInputs);
                } catch (error) {
                    console.error('Error fetch ?idQuestion:', error);
                }
            }
        };
        fetchInputs();
    }, [inputs])

    useEffect(() => {
        let finalIdQuestion
        if (idQuestion?.data === undefined) {
            finalIdQuestion = idQuestion;
        } else {
            finalIdQuestion = idQuestion?.data?.id;
        }


        const fetchAnswer = async () => {
            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answers/${finalIdQuestion}`
            try {
                setAnswer({ answers: answers.data, isFetching: true });
                const response = await axios.get(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setAnswer({ data: response.data, isFetching: false });
                activePostButton(response.data);
                setEditRowData(undefined);
                setEditRowDataId(undefined);
            } catch (exception) {
                setAnswer({ data: [], isFetching: false });
                console.error('Error al realizar la solicitud:', exception);
            }
        };
        if (idQuestion != undefined) { fetchAnswer(); }
    }, [idQuestion, addAnswer])



    const [uploadSpinner, setUploadSpiner] = useState(false)
    const [duplicateSpinner, setDuplicateSpiner] = useState(false)
    const [updateSpinner, setUpdateSpinner] = useState(false)
    const [deleteSpinner, setDeleteSpinner] = useState({ status: false, index: -1 })


    const handleDelete = (idCalcAnswer, indexTable) => {

        const originalData = answers.data.data[indexTable]; // Guardar la referencia al objeto original
        const filteredAnswerBodyOutputDtos = originalData.answerBodyOutputDtos.filter((item) => item.id !== idCalcAnswer);
        const updatedData = {
            ...originalData, // Conservar las otras variables intactas
            answerBodyOutputDtos: filteredAnswerBodyOutputDtos, // Actualizar solo answerBodyOutputDtos
        };

        let newArray = answers.data.data[indexTable].answerBodyOutputDtos.filter((item) => item.id != idCalcAnswer);
        const fetchDeleteAnswer = async () => {
            setDeleteSpinner({ status: true, index: idCalcAnswer })
            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answer/${idCalcAnswer}`
            try {
                setDeletedAnswer({ deletedAnswer: deletedAnswer.data, isFetching: true });
                const response = await axios.delete(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setDeletedAnswer({ data: response.data, isFetching: false });
                if (response.data.success) {
                    const updatedArray = [...answers.data.data]; // Clonar el array
                    updatedArray[indexTable] = updatedData; // Actualizar el objeto en el índice correspondiente
                    if (updatedArray[0]?.answerBodyOutputDtos.length == 0) {
                        setNoAnswer()
                    }
                    setAnswer({ data: { data: updatedArray }, isFetching: false });
                }

            } catch (exception) {
                setDeletedAnswer({ data: [], isFetching: false });
            }
            setDeleteSpinner({ status: false, index: -1 })
        };
        setModalConfirmDelete({ view: false, id: "", indexTable: 0 })
        fetchDeleteAnswer();

    }


    const handleDuplicate = (answerDuplicate) => {
        const fetchSavedAnswer = async () => {
            setDuplicateSpiner(true)
            let { creationDate, updateDate, organizationBuildingName, questionId, calculationId, id, organizationBuildingId, factorId, factorName, observations, registrationDate, ...newObj } = answerDuplicate;

            newObj.question = questionId;
            newObj.organizationBuilding = organizationBuildingId;
            newObj.concept = factorId;
            newObj.registration_date = registrationDate;
            if (newObj.gdo !== undefined) {
                const inputInfo = inputs.find(item => item.input.inputOptions.toLowerCase() === "gdo").input.options.find(x => x.name == newObj.gdo).id;
                newObj.gdo = inputInfo;
            }
            newObj.observations = "Copia" + (observations !== undefined ? " " + observations : "");

            if (newObj.externalQuestionnaireAnswerId != undefined) {
                delete newObj.externalQuestionnaireAnswerId;
            }

            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answer`;
            try {
                const response = await axios.post(url, newObj, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });

                const idToFind = answerDuplicate.id;
                const newData = answers.data.data.map(entry => {
                    const answerBodyOutputDtos = entry.answerBodyOutputDtos;
                    const index = answerBodyOutputDtos.findIndex(element => element.id === idToFind);
                    const clonedAnswerBodyOutputDtos = [...answerBodyOutputDtos];
                    if (index !== -1) {
                        clonedAnswerBodyOutputDtos.splice(index + 1, 0, response.data.data);
                    }
                    return {
                        ...entry,
                        answerBodyOutputDtos: clonedAnswerBodyOutputDtos
                    };
                });

                setAnswer(prevData => ({
                    ...prevData,
                    data: {
                        ...prevData.data,
                        data: newData
                    }
                }));
                setModalConfirmDuplicate({ view: false, answer: {}, id: response.data.data.id });
            } catch (exception) {
                console.error(exception)
                setAnswer({ data: [], isFetching: false });
            }
            setDuplicateSpiner(false)
        }
        fetchSavedAnswer();
    }

    const handleUpdate = (answerUpdate) => {
        const fetchSavedAnswer = async () => {
            setUpdateSpinner(true)
            let { creationDate, updateDate, organizationBuildingName, questionId, calculationId, organizationBuildingId, registrationDate, factorId, factorName, gdoId, ...newObj } = answerUpdate;

            newObj.question = questionId;
            newObj.organizationBuilding = organizationBuildingId;
            newObj.concept = factorId;
            newObj.registration_date = registrationDate;
            newObj.gdo = gdoId;

            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answer`;
            try {
                const response = await axios.patch(url, newObj, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });

                const idToFind = answerUpdate.id;
                const newData = answers.data.data.map(entry => {
                    const answerBodyOutputDtos = entry.answerBodyOutputDtos;
                    const index = answerBodyOutputDtos.findIndex(element => element.id === idToFind);
                    if (index !== -1) {
                        const clonedAnswerBodyOutputDtos = [...answerBodyOutputDtos];
                        clonedAnswerBodyOutputDtos[index] = response.data.data;
                        return {
                            ...entry,
                            answerBodyOutputDtos: clonedAnswerBodyOutputDtos
                        };
                    }
                    return entry;
                });

                setAnswer(prevData => ({
                    ...prevData,
                    data: {
                        ...prevData.data,
                        data: newData
                    }
                }));
                setModalConfirmDuplicate({ view: false, answer: {}, id: response.data.data.id });
            } catch (exception) {
                console.error(exception)
                setAnswer({ data: [], isFetching: false });
            }
            setUpdateSpinner(false)
            setEditRowData(undefined)

        }
        fetchSavedAnswer();


    }

    // const factor = answers?.data?.data?.map(x => x.factorName)

    const [file, setFile] = useState(null);
    const [fileMsg, setFileMsg] = useState({ state: "", text: "" })

    const handleFileChange = (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];

        const maxFileSize = process.env.REACT_APP_CH_CALCULATION;
        if (selectedFile && selectedFile.size > maxFileSize) {
            setFileMsg({ state: "warning", text: t("footPrint.archivoGrande") });
        } else {
            setFile(selectedFile);
            setFileMsg({ state: "", text: "" });
        }

        const file = e.target.files[0];
        const fileName = file.name;
        let fileOk = true;
        let errorMsg = "";
        if (file) {

            if (/\s/.test(fileName) || /[^\w.-]/.test(fileName)) {
                fileOk = false;
                errorMsg = t("marketplace.errImgNombre")
            }

            const extension = fileName.split(".").pop();
            if (extension !== extension.toLowerCase()) {
                fileOk = false;
                errorMsg = t("marketplace.errImgExtensionMayusculas");
            }

            const fileExtension = fileName.split('.').pop().toLowerCase();
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
            if (!allowedExtensions.includes(fileExtension)) {
                fileOk = false;
                errorMsg = t("footPrint.logo-extension");
            }

            if (fileOk) {
                setFile(e.target.files[0]);
                setFileMsg({ state: "", text: "" });
            } else {
                setFileMsg({ state: "warning", text: errorMsg });
                console.error('Error: ' + errorMsg);
                e.target.value = null;
                // setuploadLogoValue([])
            }

        }


    };

    const handleUploadFileAnswer = async (e, idCalculationAnswer, item) => {
        setUploadSpiner(true);
        e.preventDefault();
        let formData = new FormData();
        formData.append('files', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation?idQuestionAnswer=${idCalculationAnswer}`,
                formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            item.calculationDocumentation = response.data.data;
            modalDocument.item.calculationDocumentation = response.data.data[0];
            setFileMsg({ state: "success", text: t("exceltransactions.fileUpload") });
            setFile(null);

        } catch (exception) {
            console.error(exception);
        }
        setUploadSpiner(false);

    }

    const setDocumentList = (item, documentList) => {
        item.calculationDocumentation = documentList;
        modalDocument.item.calculationDocumentation = documentList[0];
    }

    const cleanMessage = () => {
        setFileMsg({ state: "", text: "" });
    }

    const [editRowData, setEditRowData] = useState(undefined);
    const [editRowDataId, setEditRowDataId] = useState(undefined);

    const handleUpdateClick = (rowData) => {
        setEditRowData(rowData); // Almacenar los datos actuales de la fila
        setEditRowDataId(rowData.id)
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e;
        const isCheckbox = type === 'checkbox';

        setEditRowData((prevState) => ({
            ...prevState,
            [name]: isCheckbox ? e.target.checked : value,
        }));
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
            boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--airco2-green-color)' : provided.borderColor,
            },
            width: 'auto',
            minWidth: '150px',

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : provided.backgroundColor,
            '&:hover': {
                backgroundColor: 'var(--airco2-green-color)',

            },
            color: state.isSelected ? 'var(--airco2-green-color)' : provided.color,
            width: '100%',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'auto', // Establecer el ancho automático para que se ajuste al contenido
            minWidth: '150px', // Establecer el ancho mínimo para asegurarse de que no sea demasiado pequeño
            maxWidth: '100vw', // Establecer el ancho máximo para que no exceda el ancho de la ventana
            position: 'absolute', // Establecer la posición absoluta para que el menú desplegable no se vea afectado por otros elementos en la página
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999, // Asegurar que el menú desplegable se renderice por encima de otros elementos
        }),

    };


    const InputComponent = React.memo(({ type, value, onChange }) => {
        const handleChange = (e) => {
            const newValue = e.target.value;
            newInputValue = newValue;
        };

        const handleSelectChange = (e) => {
            const newValue = e.value;
            const newLabel = e.label;
            newInputValue = { value: newValue, label: newLabel };
        };



        const handleBlur = (e) => {
            onChange(e.target); // Llamar a la función onChange cuando se termina la edición
        };

        const handleSelectBlur = () => {
            if (newInputNameInput === "sedes") {
                onChange({ value: newInputValue.value, name: "organizationBuildingId" });
                onChange({ value: newInputValue.label, name: "organizationBuildingName" });
            } else {
                onChange({ value: newInputValue.value, name: newInputNameInput + "Id" });
                onChange({ value: newInputValue.label, name: newInputNameInput });
            }
        };



        let newInputType = "text";
        let newInputName = type;
        let newInputNameInput = type;

        let newInputValue = value;
        let newInputMin = "";
        let newInputStep = "";
        if (type.toLowerCase() === "factorname") {
            return editRowData[type];
        }

        if (newInputName.toLowerCase() === "quantity0" &&
            (!editRowData["factorName"].toLowerCase().includes("otro")
                && editRowData["questionId"] !== 11
                && editRowData["questionId"] !== 14)) {
            return editRowData[type] || "";
        }

        if (newInputName.toLowerCase() === "organizationbuildingname") {
            newInputNameInput = "sedes";
        }
        if (newInputName.toLowerCase() === "registrationdate") {
            newInputNameInput = "registration_date";
        }

        let inputInfo = undefined;

        if (inputs?.length == 1) {
            let selectedQuestionInputs = listInputsByQuestionId?.find(v => v.idQuestion == editRowData.questionId)?.inputs;
            inputInfo = selectedQuestionInputs?.find(item => item.input.inputOptions.toLowerCase() === newInputNameInput)?.input;
        } else {
            inputInfo = inputs.find(item => item.input.inputOptions.toLowerCase() === newInputNameInput)?.input;
        }

        if (inputInfo) {
            const { inputType, options } = inputInfo;

            if (inputType === "INT") {
                newInputType = "number";
                newInputMin = 0;
                newInputStep = 1;
            }

            if (inputType === "DOUBLE") {
                newInputType = "number";
                newInputMin = 0;
                newInputStep = 0.01;
            }

            if (inputType === "DATE") {
                newInputType = "date";
                newInputValue = formatDefaultDate(newInputValue);
            }

            if (inputType === "LIST") {
                if (newInputName.toLowerCase() === "organizationbuildingname") {
                    newInputValue = { value: editRowData["organizationBuildingId"], label: newInputValue }
                } else {
                    newInputValue = { value: editRowData[newInputName], label: newInputValue }

                }

                return <Select
                    name={`${inputInfo.inputOptions}`}
                    className='custom-width-md text-xs'
                    options={options.map(option => ({ label: option.name, value: option.id, hijos: option.combustible }))}
                    styles={customStyles}
                    placeholder={`${t('footPrint.selecciona')}...`}
                    onChange={(e) => handleSelectChange(e)}
                    onBlur={handleSelectBlur}
                    defaultValue={options.length === 1 ? { label: options[0].name, value: options[0].id } : newInputValue}
                    required />
            }
        }


        return (
            <input
                className='custom-width-md text-xs'
                style={{ borderColor: 'hsl(0, 0%, 80%)', minWidth: "150px" }}
                type={newInputType}
                name={newInputName}
                defaultValue={newInputValue}
                min={newInputMin}
                step={newInputStep}
                readOnly={false}
                onBlur={(e) => handleBlur(e)}
                onChange={(e) => handleChange(e)}
            />
        );
    });


    useEffect(() => {
        setModalConfirmDuplicate({ view: false, answer: {}, id: "" });
    }, [modalConfirmDelete, modalDocument, addAnswer, editRowDataId])

    return (
        <>

            <Modal show={modalConfirmDuplicate.view}>
                <ModalHeader className='sec-neutro-background sec-grey-text font-semibold text-base'>
                    {t("footPrint.duplicar")}
                </ModalHeader>
                <ModalBody>
                    <p className="text-center  text-sm"> {t("footPrint.duplicarSeguro")}</p>
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className="btn-sm btn-cancel text-xs" onClick={() => setModalConfirmDuplicate({ view: false, answer: {} })}>
                        {t("footPrint.cancelar")}
                    </button>
                    <button className="btn-sm btn-green text-xs" disabled={duplicateSpinner} onClick={() => handleDuplicate(modalConfirmDuplicate.answer)}>
                        {duplicateSpinner ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" /> : t("footPrint.duplicar")}
                    </button>
                </div>
            </Modal>

            <Modal show={modalConfirmDelete.view}>
                <ModalHeader className='sec-neutro-background sec-grey-text text-base font-semibold'>
                    {t("footPrint.eliminar")}
                </ModalHeader>
                <ModalBody>
                    <p className="text-center text-sm"> {t("footPrint.eliminarSeguro")}</p>
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className="btn-sm btn-cancel text-xs" onClick={() => setModalConfirmDelete({ view: false, id: "", indexTable: 0 })}> {t("footPrint.cancelar")}</button>
                    <button className="btn-sm btn-green text-xs" onClick={() => handleDelete(modalConfirmDelete.id, modalConfirmDelete.indexTable)}> {t("footPrint.eliminar")}</button>
                </div>
            </Modal>

            <Modal show={modalDocument.view} size="lg">
                <ModalHeader className='sec-neutro-background sec-grey-text text-base font-semibold'>
                    {t("forestList.NombreDoc")}
                </ModalHeader>
                <ModalBody>

                    <div className="mb-4 flex justify-between items-center gap-1">
                        {modalDocument.item?.questionSubtitle !== undefined && <div>{modalDocument.item?.questionSubtitle}</div>}
                        <div>{modalDocument.item?.organizationBuildingName !== undefined ? modalDocument.item?.organizationBuildingName : ""}</div>
                        <div>{modalDocument.item?.factorName}</div>
                        <div>{modalDocument.item?.quantity && formatThousands(modalDocument.item?.quantity)}</div>
                    </div>

                    {fileMsg.state !== "" && <div className="mb-4"> <Banner type={fileMsg.state} setOpen={true} open={fileMsg.state !== "" ? "open" : ""}>
                        {fileMsg.text}</Banner>
                    </div>}

                    {modalDocument.item?.calculationDocumentation != undefined && <>
                        <DocumentsListTable
                            documents={[modalDocument.item.calculationDocumentation]}
                            enableDelete={status === 'FINALIZED' ? false : true}
                            idCalculation={idCalculation}
                            handleUpdateDocuments={setDocumentList}
                            idCuestionAnswer={modalDocument.item}
                        /></>}
                    {modalDocument.item?.calculationDocumentation === undefined &&
                        <form className=" flex justify-center items-center"
                            onSubmit={(e) => handleUploadFileAnswer(e, modalDocument.item.id, modalDocument.item)}>
                            <input className=''
                                type="file" id="subir_archivo"
                                required
                                onChange={(e) => { cleanMessage(); handleFileChange(e); }}
                                title={t("footPrint.seleccionaArchivo")} />
                            {file && <button className="btn-sm btn-banner-o " type="submit" disabled={uploadSpinner ? "disabled" : ""}>
                                <div className="mr-1">
                                    {!uploadSpinner && <svg xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-file-upload"
                                        width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                        <path d="M12 11v6" />
                                        <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
                                    </svg>}
                                    {uploadSpinner && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />}
                                </div>
                                {t("lectorfacturas.subirArchivos")}</button>}
                        </form>}
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className=" text-xs btn-sm btn-cancel" onClick={() => {
                        setModalDocument({ view: false, id: "" });
                        setFileMsg({ state: "", text: "" });
                    }}> {t("footPrint.cerrar")}</button>
                </div>
            </Modal >


            {
                answers.isFetching ?
                    <div className="flex justify-center">
                        <Spinner animation="border" variant="dark" className="m-4" />
                    </div> :
                    (answers.data.data != undefined && answers.data.data.length > 0 ?
                        <div className="mt-4 mb-4">

                            <div className="table-responsive">

                                {answers.data.data.map((bloquePreguntas, indexTabla) => {
                                    return (
                                        <div className={bloquePreguntas.length > 1 ? "" : ""}>
                                            <div className="font-bold container-simple-table">{bloquePreguntas.title} <span className="text-xs ml-2">{bloquePreguntas.subtitle}</span></div>
                                            {/* <table className="table-bordered sec-neutro-background  table-auto w-full divide-y divide-gray-200  mt-2 mb-4"> */}
                                            <table className="simple-table mt-2 mb-4">
                                                <thead className="text-center text-white sec-green-background">
                                                    <tr key={indexTabla}>
                                                        {Object.keys(bloquePreguntas.answerHeaderOutputDto).map((key) => (
                                                            <th key={key}>{bloquePreguntas.answerHeaderOutputDto[key]}</th>
                                                        ))}
                                                        {(status !== 'FINALIZED') && <>
                                                            <th>{t("footPrint.subirArchivos")}</th>

                                                            <th>{t("fpThird.acciones")}</th>

                                                        </>
                                                        }

                                                    </tr>

                                                </thead>
                                                <tbody className="text-center ">

                                                    {bloquePreguntas.answerBodyOutputDtos.map((item, index) => (
                                                        <>     <tr key={index}
                                                            className={`${modalDocument?.view && modalDocument?.item.id == item.id || modalConfirmDelete?.view && modalConfirmDelete?.id == item?.id ? "sec-green-background-tr" : ''}
                                                                     ${modalConfirmDuplicate?.id === item?.id && "sec-green-background-tr2"}`}>

                                                            {Object.keys(bloquePreguntas.answerHeaderOutputDto).map((key) => (
                                                                <td key={key}>
                                                                    {editRowData !== undefined && editRowDataId === item.id ? <>
                                                                        {/* <span className="text-xs">  {bloquePreguntas.answerHeaderOutputDto[key]}</span> */}
                                                                        <InputComponent type={key} value={editRowData[key]} onChange={handleInputChange}></InputComponent>
                                                                    </>
                                                                        : item[key] === undefined || item[key] === "" ?
                                                                            "" : ((isNaN(item[key])
                                                                                ? (key === "registrationDate"
                                                                                    ? formatDate(item[key])
                                                                                    : item[key])
                                                                                : formatThousands(item[key])))}</td>
                                                            ))}

                                                            {(status !== 'FINALIZED') && <>
                                                                <td >
                                                                    {editRowData?.id !== item.id && <>

                                                                        <button onClick={() => setModalDocument({ view: true, item: item })}
                                                                            className="relative">
                                                                            {item.calculationDocumentation === undefined ? <>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    className="icon icon-tabler icon-tabler-clipboard-plus" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                                                                    <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                                                    <path d="M10 14h4" />
                                                                                    <path d="M12 12v4" />
                                                                                </svg>
                                                                                {bloquePreguntas.questionType === "EXTERNAL_CALCULATION" &&
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={renderTooltipWarningFile}>
                                                                                        <span className="absolute rounded-full bg-orange " style={{ left: "15px", bottom: "12px", padding: "4px" }}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-triangle" width="13" height="13" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff " fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                <path d="M12 9v4" />
                                                                                                <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                                                                                                <path d="M12 16h.01" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                }
                                                                            </>
                                                                                :
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    className="icon icon-tabler icon-tabler-file-check" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                                                    <path d="M9 15l2 2l4 -4" />
                                                                                </svg>}
                                                                        </button>



                                                                    </>
                                                                    }
                                                                </td>


                                                                <td className="">
                                                                    <div className="grid grid-flow-col gap-1">
                                                                        {(status !== 'FINALIZED' && editRowData?.id !== item.id) &&
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                delay={{ show: 250, hide: 400 }}
                                                                                overlay={renderTooltipDuplicar}
                                                                            >
                                                                                <button onClick={() => setModalConfirmDuplicate({ view: true, answer: item })}>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                        <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                                                                        <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                                                                    </svg>
                                                                                </button>

                                                                            </OverlayTrigger>}
                                                                        {(status !== 'FINALIZED' && editRowData === undefined) &&
                                                                            <button onClick={() => handleUpdateClick(item)} disabled={item.externalQuestionnaireAnswerId != undefined ? true : false} >
                                                                                {item.externalQuestionnaireAnswerId != undefined ?
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={renderTooltipEditar}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#64748b" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                                            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                                            <path d="M16 5l3 3" />
                                                                                        </svg>
                                                                                    </OverlayTrigger>
                                                                                    :
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={renderTooltipEditartrue}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                                            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                                            <path d="M16 5l3 3" />
                                                                                        </svg>
                                                                                    </OverlayTrigger>
                                                                                }
                                                                            </button>
                                                                        }
                                                                        {updateSpinner && editRowData?.id === item.id && <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true" />}
                                                                        {!updateSpinner && editRowData !== undefined && editRowData.id === item.id && <>

                                                                            <button onClick={() => handleUpdate(editRowData)}>

                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                                                                                    <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                                                                    <path d="M14 4l0 4l-6 0l0 -4" />
                                                                                </svg>
                                                                            </button>

                                                                            <button onClick={() => {
                                                                                setEditRowData(undefined);
                                                                                setEditRowDataId(undefined)
                                                                            }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <path d="M18 6l-12 12" />
                                                                                    <path d="M6 6l12 12" />
                                                                                </svg>
                                                                            </button>
                                                                        </>}
                                                                        {editRowData?.id !== item.id && <>
                                                                            {(!deleteSpinner.status || deleteSpinner.index !== item.id) &&
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                    overlay={renderTooltipdelete}
                                                                                >
                                                                                    <button onClick={() => setModalConfirmDelete({ view: true, id: item.id, indexTable: indexTabla })}>
                                                                                        {item.externalQuestionnaireAnswerId ?

                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-corner-up-left-double" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                <path d="M19 18v-6a3 3 0 0 0 -3 -3h-7" />
                                                                                                <path d="M13 13l-4 -4l4 -4m-5 8l-4 -4l4 -4" />
                                                                                            </svg>

                                                                                            :

                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                <path d="M4 7l16 0" />
                                                                                                <path d="M10 11l0 6" />
                                                                                                <path d="M14 11l0 6" />
                                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                                            </svg>
                                                                                        }
                                                                                    </button>
                                                                                </OverlayTrigger>


                                                                            }
                                                                            {deleteSpinner.status && deleteSpinner.index === item.id && <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true" />}
                                                                        </>}
                                                                    </div>
                                                                </td>



                                                            </>
                                                            }
                                                        </tr >

                                                            {(status === 'FINALIZED') && (item?.associatedAnswerOutputDto != undefined && item?.associatedAnswerOutputDto.length > 0) &&
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={Object.keys(bloquePreguntas.answerHeaderOutputDto).length}>
                                                                            <table className=" text-sm table-auto w-full divide-y divide-gray-200 table-associated-footprint mb-4">
                                                                                <thead style={{ backgroundColor: "#d9d9d9" }}>
                                                                                    <th>Dato de consumo</th>
                                                                                    <th>gCH₄</th>
                                                                                    <th>gN₂O</th>
                                                                                    <th>kgCO₂</th>
                                                                                    <th>Total KgCO₂e</th>
                                                                                </thead>
                                                                                <tbody >
                                                                                    {item.associatedAnswerOutputDto.map((x) =>
                                                                                        <>
                                                                                            <tr style={{ backgroundColor: "#e5e1d7" }}>
                                                                                                <td>{x.factorName}</td>
                                                                                                <td>{x.gch4 != undefined ? formatThousands(x.gch4) : ""}</td>
                                                                                                <td>{x.gn2O != undefined ? formatThousands(x.gn2O) : ""}</td>
                                                                                                <td>{x.kgCO2 != undefined ? formatThousands(x.kgCO2) : ""}</td>
                                                                                                <td>{formatThousands(x.totalKgCO2e)}</td>
                                                                                            </tr>
                                                                                        </>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </>}

                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>)
                                })}


                            </div>
                        </div >
                        :
                        <div className="my-4 footprint-seccion-respuestas">
                            {(status !== 'FINALIZED') && <><p className="text-sm font-bold">{t("footPrint.noDatosTabla")}</p>
                                <p className="text-sm">{t("footPrint.cubreForm")}.</p></>}
                            {(status == 'FINALIZED') && <><p className="text-sm font-bold">{t("footPrint.noDatos")}</p></>}
                        </div>
                    )
            }
        </>
    )


}
export default AnswerTable;