import React from 'react'

import citet from '../../../images/citet.jpg'
import inmove from '../../../images/inmove.jpg'
import vinis from '../../../images/vinisTerrae.jpg'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'

export default function bloqueCasos() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className='container mt-5 ' >
            <h1 className=" h1 text-airco2-principal font-bold text-airco2-principal  text-center mt-2 " data-aos="fade-up" data-aos-delay="100" >  {(t("landingEventos.casosExito"))}
            </h1>
            <div className="max-w-6xl mx-auto px-2 sm:px-3" >
                <div className="py-6 md:py-10">



                    {/* Articles list */}
                    <div className="max-w-6xl mx-auto px-2 sm:px-3" >
                        <div className="grid gap-12 md:grid-cols-3   md:gap-x-6 md:gap-y-8 items-start text-airco2-general">

                            {/* 1st article */}
                          
                            <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="200">
                                <header>
                                    <p className="block mb-6">

                                        <img className="flex flex-col inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={inmove}  alt="News 02" style={{height:'300px'}}/>

                                    </p>

                                    <h4 className="h4 mb-2">
                                        <p className=" text-airco2-principal  hover:text-gray-400 transition duration-150 ease-in-out" href="blog-post.html" >In-move</p>
                                    </h4>
                                </header>
                                <p className="text-sm grow case-description">  {(t("landingEventos.TxtInmove"))}</p>
                                <ul className='text-sm list-case'>
                                    <li>
                                        <span>  {(t("landingEventos.ubicacion"))}</span> Tecnocampus Mataró-Maresme, Barcelona

                                    </li>
                                    <li>

                                        <span>  {(t("landingEventos.fecha"))}</span> 03 de julio de 2023


                                    </li>
                                    <li>
                                    <span>  {(t("landingEventos.organizado"))} </span>  In-Move by Railgrup, Clúster de Movilidad y Logística Multimodal

                                    </li>
                                    <li>
                                    <span>  {(t("landingEventos.huella"))}</span> {(t("landingEventos.enbosque"))} In-Move
                                    </li>
                                </ul>
                            </article>

                            {/* 2nd article */}
                            <article className="flex flex-col h-full" data-aos="fade-up">
                                <header>
                                    <div className="block mb-6">

                                        <img className="flex flex-col inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={vinis}  alt="News 01" style={{height:'300px'}} />
                                       
                                    </div>

                                    <h4 className="h4 mb-2">
                                        <p className=" text-airco2-principal   hover:text-gray-400 transition duration-150 ease-in-out  " href="blog-post.html">Vinisterrae
                                        </p>
                                    </h4>
                                </header>
                                <p className="text-sm grow case-description" >  {(t("landingEventos.txtVinis"))}</p>
                                <ul className='text-sm list-case'>
                                    <li>
                                        <span>  {(t("landingEventos.ubicacion"))}</span> Expourense, Ourense

                                    </li>
                                    <li>

                                        <span> {(t("landingEventos.fecha"))}</span>  05 de junio de 2023

                                    </li>
                                    <li>
                                    <span> {(t("landingEventos.organizado"))}</span>  Expourense

                                    </li>
                                    <li>
                                    <span> {(t("landingEventos.huella"))}</span> 
                                    </li>
                                </ul>

                            </article>
                         
                            {/* 3 */}

                            <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="400">
                                <header>
                                    <p className="block mb-6">

                                        <img className="  flex flex-col inset-0 w-full  object-cover transform hover:scale-105 transition duration-700 ease-out" src={citet} alt="News 03" style={{height:'300px'}} />

                                    </p>

                                    <h4 className="h4 mb-2">
                                        <p className=" text-airco2-principal   hover:text-gray-400 transition duration-150 ease-in-out" >Citet</p>
                                    </h4>
                                </header>
                                <p className="text-sm grow case-description">  {(t("landingEventos.txtCitet"))}</p>
                                <ul className='text-sm list-case'>
                                    <li>
                                        <span>  {(t("landingEventos.ubicacion"))}</span> Madrid International Lab, Madrid

                                    </li>
                                    <li>

                                        <span>  {(t("landingEventos.fecha"))}</span>  27 de junio de 2023

                                    </li>
                                    <li>
                                    <span>{(t("landingEventos.organizado"))}</span> CITET, centro de innovación para la logística y el transporte de mercancías.

                                    </li>
                                    <li>
                                    <span>  {(t("landingEventos.huella"))}</span> {(t("landingEventos.enbosque"))} CITET: 1t
                                    </li>
                                </ul>

                            </article>


                           

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
