import 'chartjs-adapter-moment';
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import { formatThousands } from '../../utils/Utils';


export default function DecarbonizationScenarioResume({ resume, updatedValues }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [highlight, setHighlight] = useState(false);

    useEffect(() => {
        if (updatedValues?.length !== 0) {
            setHighlight(true)
        }
    }, [updatedValues]);

    useEffect(() => {
        if (highlight) {
            const timer = setTimeout(() => {
                setHighlight(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [highlight]);

    if (!resume) {
        return <div className="text-center"><div>{t("decarbonization.loading")}</div><Spinner className='' /></div >;
    }


    return (<div>
        <div className="flex justify-between text-lg mb-4">
            <div className="text-center font-bold mb-2"> {resume.name}</div>
            <div className="text-center font-bold"> {resume.baseYear} - {resume.targetYear}</div>
        </div>

        <table className="simple-table">
            <thead>
                <tr>
                    <th></th>
                    <th className="text-right">{resume.baseYear}<br /> <span style={{ fontSize: "10px" }}>{t("decarbonization.baseScenario")}</span></th>
                    <th className="text-right"> {resume.targetYear}<br /><span style={{ fontSize: "10px" }}>{t("decarbonization.targetScenario")}</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{t("decarbonization.scopes1and2")}</td>
                    <td className="text-right"><span>{formatThousands(resume.tco2eScopes1and2)} kgCO<sub>2</sub>e</span>
                        <br />
                        <div className={`text-right ${highlight && updatedValues.includes("currentScopes1and2") ? 'highlight' : ''}`}>
                            <span style={{ fontSize: "10px" }}>{t("decarbonization.resume.baseReduction")}:</span> <span className="text-xs">  {formatThousands(resume.currentScopes1and2)}%  </span>
                            <br />
                            {resume.targetScopes1and2 - resume.currentScopes1and2 > 0 && <>
                                <span style={{ fontSize: "10px" }}>{t("decarbonization.resume.toReduce")}:</span>
                                <span className="text-xs"> {formatThousands(resume.targetScopes1and2 - resume.currentScopes1and2)}%</span>
                            </>}

                            {resume.targetScopes1and2 - resume.currentScopes1and2 < 0 && <>
                                <span style={{ fontSize: "10px" }}>{t("decarbonization.resume.targetExceeded")} </span>
                                <span className="text-xs">    {formatThousands(resume.targetScopes1and2 - resume.currentScopes1and2)}% </span>
                            </>}

                        </div>
                    </td>
                    <td className="text-right font-bold">
                        <span>{formatThousands(resume.tco2eScopes1and2 - (resume.tco2eScopes1and2 * (resume.targetScopes1and2 / 100)))} kgCO<sub>2</sub>e</span>
                        <br />
                        <span style={{ fontSize: "10px" }}>{t("decarbonization.resume.reductionTarget")}:</span> <span className="text-xs">  {formatThousands(resume.targetScopes1and2)}%</span>
                    </td>
                </tr>

                <tr>
                    <td>{t("decarbonization.scope3")}</td>
                    <td className="text-right"><span>{formatThousands(resume.tco2eScope3)} kgCO<sub>2</sub>e</span>
                        <br />
                        <div className={`text-right ${highlight && updatedValues.includes("currentScope3") ? 'highlight' : ''}`}>
                            <span style={{ fontSize: "10px" }}>{t("decarbonization.resume.baseReduction")}:</span>  <span className="text-xs">    {formatThousands(resume.currentScope3)}% </span>
                            <br />
                            {resume.targetScope3 - resume.currentScope3 > 0 && <>
                                <span style={{ fontSize: "10px" }}>{t("decarbonization.resume.toReduce")}: </span>
                                <span className="text-xs">    {formatThousands(resume.targetScope3 - resume.currentScope3)}% </span>
                            </>}

                            {resume.targetScope3 - resume.currentScope3 < 0 && <>
                                <span style={{ fontSize: "10px" }}>{t("decarbonization.resume.targetExceeded")}</span>
                                <span className="text-xs">    {formatThousands(resume.targetScope3 - resume.currentScope3)}% </span>
                            </>}
                        </div>
                    </td>
                    <td className="text-right font-bold"><span>{formatThousands(resume.tco2eScope3 - (resume.tco2eScope3 * (resume.targetScope3 / 100)))} kgCO<sub>2</sub>e</span>
                        <br />
                        <span style={{ fontSize: "10px" }}>{t("decarbonization.resume.reductionTarget")}:</span> <span className="text-xs">  {formatThousands(resume.targetScope3)}%</span>
                    </td>
                </tr>

                <tr>
                    <td>{t("decarbonization.cost")}</td>
                    <td className={`text-right ${highlight && updatedValues.includes("currentCost") ? 'highlight' : ''}`}>{resume.currentCost}€</td>
                    <td className="text-right"></td>
                </tr>
            </tbody>
        </table>

    </div>)
}