import transporte from "../images/transportation-truck-svg.svg";
import compras from "../images//buy-svg.svg";
import electricidad from "../images/lightning-svg.svg";
import viajes from "../images/travel-svg.svg";
import ocio from "../images/ocio.png";
import otros from "../images/copy.png";
import paqueteria from "../images/paqueteria.svg";
import sincategory from "../images/sinCategory.svg";
import shopping from "../images/shopping.svg";
import combustible from "../images/combustible.png";
import combustible2 from "../images/combustible2.png";
import combustible3 from "../images/combustible3.png";
import tripCombustible from "../images/tripCombustible.svg";
import electric from "../images/electric.png";
import emisiones from "../images/emisiones.png";
import gas from "../images/gas.png";
import telec from "../images/telework.svg";
import travel from "../images/travel.png";
import travel3 from "../images/travel3.png";
import iconoProceso from "../images/iconoProceso.png";
import iconoAereoProveedores from "../images/avion.png";
import iconoAereoDistribuidores from "../images/de-viaje.png";
import iconoAereoViajes from "../images/correo-aereo.png";
import iconoFerroviariaViajes from "../images/tren (1).png";
import iconoFerroviario from "../images/tren.png";
import iconoMaritimo from "../images/ship_870119.png";
import iconoMaritimoViajes from "../images/cruise_8098080.png";
import iconoCarreteraViajes from "../images/la-carretera.png";
import iconoHotel from "../images/hotel.png";
import iconoCarreteraEmpleados from "../images/coche.png";
import iconoAereo from "../images/travel_826070.png";
import iconoCarreteraProveedores from "../images/enviado.png";
import zero from "../images/zero.png";
import iconoEnmiendasCalizas from "../images/iconoEnmiendasCalizas.png";
import iconoBarco from "../images/barco.png";
import iconoAvion from "../images/avionTrabajadores.png";
import iconoTren from "../images/trenTrabajadores.png";
import iconoBus from "../images/bus.png";
import iconoCalorVaporAire from "../images/iconoCalorVaporAire.png";
import iconCrops from "../images/crops.png";
import iconoFertilizante from "../images/fertilizante.png";
import iconoEstiercol from "../images/estiercol.png";
import iconoBarcoDis from "../images/barco2.png";
import iconoBarcoProv from "../images/barco3.png";
import iconoTrenProv from "../images/tren1.png";
import iconoTrenDis from "../images/tren2.png";
import iconoN2O from "../images/n2o.png";
import fermentacion from "../images/fermentacion_enterica.png";
import residuos from "../images/residuos.png";
import combustibleExpense from "../images/combustible_expense.png";
import waterConsumption from "../images/agua.png";
import tuberiaGas from "../images/tuberia-de-gas.png";
import iconowtt from "../images/barril-de-petroleo.png";
import iconoservices from "../images/services.png";
import iconomaterials from "../images/materials.png";
import iconoinversion from "../images/inversion.png";

export function getDashboardUrl(userType, originData) {
  if (userType === "Representante empresarial" && originData !== "Formulario") {
    return "/company/dashboardintranet";
  } else if (
    userType === "Representante empresarial" &&
    originData === "Formulario"
  ) {
    return "/footprint/welcome";
  } else if (userType === "Propietario Forestal") {
    return "/forest/dashboardmontes";
  } else {
    return "/adminusuarios";
  }
}

export const categoryIcono = (category) => {
  let categoryImage = "";

  switch (category) {
    case "Gastos hogar":
      categoryImage = compras;

      break;
    case "Electricidad":
      categoryImage = electricidad;
      break;

    case "sin categoría":
      categoryImage = sincategory;

      break;
    case "Automovil y transporte":
      categoryImage = transporte;

      break;
    case "nóminas":
      categoryImage = viajes;

      break;
    case "ocio":
      categoryImage = ocio;

      break;
    case "Refrigerantes":
      categoryImage = paqueteria;

      break;

    case "compras":
      categoryImage = shopping;
      break;
    default:
      categoryImage = otros;

      break;
  }

  return (
    <div
      className={`w-10 h-10 shrink-0 flex items-center justify-center rounded-full mr-2 sm:mr-3`}
    >
      <img src={categoryImage} width="20" height="20" alt={category} />
    </div>
  );
};

export function renderIcon(name) {
  switch (name) {
    //español
    case "FIXED_INSTALLATIONS":
      return <img width={35} src={gas} alt="Icono 1" />;
    case "COMBUSTIBLE_CONSUMED":
      return <img width={35} src={combustible} alt="Icono 2" />;
    case "SOIL_TILLAGE":
      return <img width={35} src={combustible} alt="Icono 2" />;
    case "SUPPLIERS_COMBUSTIBLE_CONSUMED":
      return <img width={35} src={combustible2} alt="Icono 2" />;
    case "DISTRIBUTORS_COMBUSTIBLE_CONSUMED":
      return <img width={35} src={combustible3} alt="Icono 2" />;
    case "ROAD_TRANSPORT_DISTANCE":
      return <img width={35} src={travel} alt="Icono 2" />;
    case "DISTRIBUTORS_ROAD_TRANSPORT_DISTANCE":
      return <img width={35} src={travel3} alt="Icono 2" />;
    case "ELECTRIC_POWER_MARKETER":
      return <img width={35} src={electric} alt="Icono 2" />;
    case "FUGITIVE_EMISSIONS":
      return <img width={35} src={emisiones} alt="Icono 2" />;
    case "EMPLOYEES_TELECOMMUTING":
      return <img width={35} src={telec} alt="Icono 2" />;
    case "BUSINESS_TRIPS_COMBUSTIBLE_CONSUMED":
      return <img width={35} src={tripCombustible} alt="Icono 2" />;
    case "PROCESS_EMISSIONS":
      return <img width={35} src={iconoProceso} alt="Icono 2" />;
    case "PROCESS_EMISSIONS_PARTIALS":
      return <img width={35} src={iconoProceso} alt="Icono 2" />;
    case "SUPPLIERS_AIR_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoAereoProveedores} alt="Icono 2" />;
    case "DISTRIBUTORS_AIR_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoAereoDistribuidores} alt="Icono 2" />;
    case "BUSINESS_TRIPS_RAILWAY_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoFerroviariaViajes} alt="Icono 2" />;
    case "BUSINESS_TRIPS_ROAD_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoCarreteraViajes} alt="Icono 2" />;
    case "BUSINESS_TRIPS_MARITIME_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoMaritimoViajes} alt="Icono 2" />;
    case "BUSINESS_TRIPS_AIR_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoAereoViajes} alt="Icono 2" />;
    case "RAILWAY_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoFerroviario} alt="Icono 2" />;
    case "MARITIME_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoMaritimo} alt="Icono 2" />;
    case "HOTEL_NIGHTS":
      return <img width={35} src={iconoHotel} alt="Icono 2" />;
    case "EMPLOYEES_ROAD_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoCarreteraEmpleados} alt="Icono 2" />;
    case "AIR_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoAereo} alt="Icono 2" />;
    case "SUPPLIERS_ROAD_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoCarreteraProveedores} alt="Icono 2" />;
    case "HEAT_STEAM_COLD_COMPRESSEDAIR":
      return <img width={35} src={iconoCalorVaporAire} alt="Icono 1" />;
    case "LIMESTONE_AMENDMENTS":
      return <img width={35} src={iconoEnmiendasCalizas} alt="Icono 2" />;
    case "EMPLOYEES_BUS_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoBus} alt="Icono 3" />;
    case "EMPLOYEES_RAILWAY_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoTren} alt="Icono 4" />;
    case "EMPLOYEES_AIR_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoAvion} alt="Icono 5" />;
    case "EMPLOYEES_MARITIME_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoBarco} alt="Icono 6" />;
    case "CROP_EMISSIONS":
      return <img width={35} src={iconCrops} alt="Icono 2" />;
    case "OTHER_ORGANICS_FERTILIZER":
      return <img width={35} src={iconoFertilizante} alt="Icono 6" />;
    case "FERTILIZER_EMISSIONS":
      return <img width={35} src={iconoFertilizante} alt="Icono 6" />;
    case "MANURE_AND_SLURRY":
      return <img width={35} src={iconoEstiercol} alt="Icono 6" />;
    case "SUPPLIERS_MARITIME_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoBarcoDis} alt="Icono 6" />;
    case "SUPPLIERS_RAILWAY_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoTrenDis} alt="Icono 6" />;
    case "DISTRIBUTORS_RAILWAY_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoTrenProv} alt="Icono 6" />;
    case "DISTRIBUTORS_MARITIME_TRANSPORT_DISTANCE":
      return <img width={35} src={iconoBarcoProv} alt="Icono 6" />;
    case "INDIRECT_N2O_EMISSIONS":
      return <img width={35} src={iconoN2O} alt="Icono 6" />;
    case "INDUSTRIAL_WASTE_DARI":
      return <img width={35} src={residuos} alt="Residuos industriales" />;
    case "INDUSTRIAL_WASTE_NO_DARI":
      return <img width={35} src={residuos} alt="Residuos industriales" />;
    case "ENTERIC_FERMENTATION":
      return <img width={35} src={fermentacion} alt="Fermentación Entérica" />;
    case "ROAD_TRANSPORT_EXPENSE":
      return (
        <img width={35} src={combustibleExpense} alt="Gasto en combustible" />
      );
    case "WATER_CONSUMPTION":
      return <img width={35} src={waterConsumption} alt="Consumo de agua" />;
    case "UNKNOWN_NON_INDUSTRIAL_WASTE":
      return <img width={35} src={residuos} alt="Residuos no industriales" />;
    case "NON_INDUSTRIAL_TREATMENT_WASTE":
      return <img width={35} src={residuos} alt="Residuos no industriales" />;
    case "AIR_WELL_TO_TANK":
      return <img width={35} src={iconowtt} alt="AIR WTT" />;
    case "ROAD_WELL_TO_TANK":
      return <img width={35} src={iconowtt} alt="ROAD WTT" />;
    case "MARITIME_WELL_TO_TANK":
      return <img width={35} src={iconowtt} alt="MARITIME WTT" />;
    case "RAILWAY_WELL_TO_TANK":
      return <img width={35} src={iconowtt} alt="RAILWAY WTT" />;
    case "MACHINERY_COMBUSTIBLE_CONSUMED_WELL_TO_TANK":
      return <img width={35} src={iconowtt} alt="MACHINERY WTT" />;
    case "FIXED_INSTALLATIONS_COMBUSTIBLE_CONSUMED_WELL_TO_TANK":
      return <img width={35} src={iconowtt} alt="FIXED INSTALLATIONS WTT" />;
    case "COMBUSTIBLE_CONSUMED_WELL_TO_TANK":
      return <img width={35} src={iconowtt} alt="COMBUSTIBLE CONSUMED WTT" />;
    case "PURCHASED_SERVICES_MITECO":
      return <img width={35} src={iconoservices} alt="Servicios adquiridos MITECO" />;
    case "PURCHASED_SERVICES_EXTERNAL_FACTOR":
      return <img width={35} src={iconoservices} alt="Servicios adquiridos" />;
    case "PURCHASED_SERVICES_ADEME":
      return <img width={35} src={iconoservices} alt="Servicios adquiridos ADEME" />;
    case "INVESTED_SERVICES_ADEME":
      return <img width={35} src={iconoinversion} alt="Inversiones ADEME" />;
    case "INVESTED_SERVICES_MITECO":
      return <img width={35} src={iconoinversion} alt="Inversiones MITECOs" />;
    case "PURCHASED_MATERIALS":
      return <img width={35} src={iconomaterials} alt="Bienes adquiridos DEFRA y ADEME" />;
    case "PURCHASED_MATERIALS_EXTERNAL_FACTOR":
      return <img width={35} src={iconomaterials} alt="Bienes adquiridos" />;
    default:
      return <img width={35} src={zero} alt="Icono predeterminado" />;
  }
}

export const customButtonStylesTourTooltip = () => {
  return {
    buttonNext: {
      backgroundColor: "var(--airco2-green-color)", // Set your desired color for the Next button
      color: "#ffffff", // Text color
    },
    buttonBack: {
      backgroundColor: "var(--airco2-grey-color)", // Set your desired color for the Back button
      color: "#ffffff", // Text color
    },
  };
  // You can also customize other parts of the tooltip here
};

export const handleBack = () => {
  window.history.back();
};
