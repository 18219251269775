import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, Spinner, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Banner from '../../../components/utils/Banner';
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';
import OrganizationsLocations from './OrganizationsLocations';

export default function NewEditOrganization() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [name, setName] = useState("");
  const [cif, setCif] = useState("");
  const [sector, setSector] = useState("");
  const [organizationType, setOrganizatioType] = useState("");
  const [organizationTypeId, setOrganizatioTypeId] = useState("");
  const [sectorId, setSectorId] = useState("")
  const [subSectorId, setSubSectorId] = useState("")
  const [idSede, setIdSede] = useState("");
  const [nameSede, setNameSede] = useState("");
  const [logo, setLogo] = useState(organization?.data?.data?.urlLogo)
  const [description, setDescription] = useState('');
  const [extraInformation, setExtraInformation] = useState('');
  const [fleet, setFleet] = useState('');
  const [abroad, setAbroad] = useState(undefined);
  const [subsidiary, setSubsidiary] = useState(undefined);
  const startRef = useRef(null);
  const pathParams = useParams();
  const [sedes, setSedes] = useState([]);
  const [contadorSedes, setContadorSedes] = useState(0);
  const [showSpinner, setShowSpinner] = useState({ delete: false, save: false })
  const [sedesOrigin, setSedesOrigin] = useState([]);

  const [uploadLogoValue, setuploadLogoValue] = useState("")
  const [uploadLogo, setuploadLogo] = useState(false)
  const [showDelete, setShowDelete] = useState(false);
  const [result, setResult] = useState(false);
  const [deleteOrgBuildingsQueue, setdDeleteOrgBuildingsQueue] = useState([]);

  const location = useLocation();
  const idUserAdmin = location?.state?.idUser;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlFrom = urlParams.get('from');
  let idOrganization = pathParams.id;



  const [message, setMessage] = useState([]);

  const cleanMessage = () => {
    setMessage([]);
  }

  const [organization, setOrganization] = useState({
    data: [],
    isFetching: false
  });

  const [CNAE, setCNAE] = useState({
    data: [],
    isFetching: false
  });

  const [typeOrg, setTypeOrg] = useState({
    data: [],
    isFetching: false
  });


  useEffect(() => {
    var fetchOrganization = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CH_ORGANIZATION}/${idOrganization}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setOrganization({ data: response.data, isFetching: true });

        const dataOrganization = response.data.data

        if (dataOrganization) {
          const buildings = dataOrganization?.organizationBuildings || [];
          const buildingsClonado = JSON.parse(JSON.stringify(buildings));

          setName(dataOrganization?.name);
          setCif(dataOrganization?.cif);
          setOrganizatioType(dataOrganization?.organizationType?.name);
          setOrganizatioTypeId(dataOrganization?.organizationType?.id);
          setSector(dataOrganization?.organizationCNAE?.name);
          setSectorId(dataOrganization?.organizationCNAE?.id);
          fetchOrganizationsCNAEServices(dataOrganization?.organizationCNAE?.id)
          setSubSectorId(dataOrganization?.service?.id)
          setSedes([...buildings]);
          setContadorSedes(buildings.length)
          setSedesOrigin(buildingsClonado);
          setResult(hasEmptyName(buildings));
          setLogo(dataOrganization?.urlLogo);
          setAbroad(dataOrganization?.abroad);
          setDescription(dataOrganization?.description);
          setExtraInformation(dataOrganization?.extraInformation);
          setFleet(dataOrganization?.fleet);
          setSubsidiary(dataOrganization?.subsidiary)

        }
      } catch (exception) {
        setOrganization({ data: [], isFetching: true });
      }
    };

    var createSede0 = async () => {
      const newSede = { name: '', isNew: true, organizationBuildingType: '', stateId: '', identifier: contadorSedes, sources: '', description: '' };
      setSedes([newSede]);
      setContadorSedes(newSede.length)
    }

    if (pathParams.id !== undefined) {
      fetchOrganization();
    } else {
      createSede0();
    }
  }, [logo]);


  const handleshowDelete = (x) => {
    setShowDelete(!showDelete);
    setNameSede(x.name)
    setIdSede(x.id)
  }

  const handleDeleteNewField = (indice) => {
    const nuevaLista = [...sedes];
    nuevaLista.splice(indice, 1);
    setSedes(nuevaLista);
    setResult(hasEmptyName(nuevaLista));

  }

  const handleClose = () => {
    setShowSpinner({ delete: false, save: false })
    setShowDelete(!showDelete);
    setNameSede('')
    setIdSede('')
    setMessage([]);
  }




  const handleSubmitPut = async (e) => {
    e.preventDefault();
    setShowSpinner({ delete: false, save: true })

    if (deleteOrgBuildingsQueue.length > 0) {
      deleteOrgBuildingsQueue.map(v => deleteBuilding(v));
    }
    const url = `${process.env.REACT_APP_CH_ORGANIZATION}`;
    const data = {
      "id": idOrganization,
      "name": name,
      "organizationTypeId": organizationTypeId,
      "organizationCNAEId": sectorId,
      "organizationBuildings": sedes,
      "serviceId": parseInt(subSectorId),
      "cif": cif,
      "urlLogo": logo,
      "description": description,
      "abroad": abroad,
      "subsidiary": subsidiary,
      "fleet": fleet,
      "extraInformation": extraInformation,
      "userId": UserService.hasRole(["admin"]) && idUserAdmin !== undefined ? idUserAdmin.value : UserService.getUserId(),
      "userEmail": UserService.hasRole(["admin"]) && idUserAdmin !== undefined ? idUserAdmin.label : UserService.getUsername(),
    };


    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      setMessage([{ type: "success", message: t("footPrint.update-ok") }]);
      if (uploadLogoValue.length > 0) {
        await handleUploadLogo();
      }

      await handleSubmitSedes();
      startRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Error:', error.message);
      setMessage([{ type: "error", message: t("footPrint.update-ko") }]);
    }
  };




  const handleSubmitPost = (e) => {
    e.preventDefault();
    setShowSpinner({ delete: false, save: true });

    if (deleteOrgBuildingsQueue.length > 0) {
      deleteOrgBuildingsQueue.map(v => deleteBuilding(v));
    }
    const url = `${process.env.REACT_APP_CH_ORGANIZATION}`;
    const data = {
      "name": name,
      "cif": cif,
      // "userEmail": UserService.getUsername(),
      "organizationCNAEId": sectorId,
      "organizationTypeId": organizationTypeId,
      "serviceId": parseInt(subSectorId),
      "userId": UserService.hasRole(["admin"]) && idUserAdmin !== undefined ? idUserAdmin.value : UserService.getUserId(),
      "userEmail": UserService.hasRole(["admin"]) && idUserAdmin !== undefined ? idUserAdmin.label : UserService.getUsername(),
      "urlLogo": logo,
      "description": description,
      "abroad": abroad,
      "subsidiary": subsidiary,
      "fleet": fleet,
      "extraInformation": extraInformation

    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${UserService.getToken()}`
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(async (data) => {
        if (data.success) {
          setMessage([{ type: "success", message: t("footPrint.new-ok") }]);

          if (urlFrom === "previousdata") {
            let idOrganizationAndIdCNAE = JSON.stringify({id: data.data.id, cnaeId: data.data.organizationCNAE.id});
            localStorage.setItem("lastOrgCreated", idOrganizationAndIdCNAE);
          }

          idOrganization = data.data.id;
          await handleUploadLogo();
          handleSubmitSedes();
        } else {
          let warningText = t("footPrint.new-ko");
          if (data.message.split("-->").length > 1) {
            warningText = data.message.split("-->")[1];
          }
          setMessage([...message, { type: "warning", message: warningText }]);
        }
        setShowSpinner({ delete: false, save: true });
      })
      .catch((error) => {
        console.error('Error:', error.message);
        setMessage([{ type: "error", message: t("footPrint.new-ko") }]);
        setShowSpinner({ delete: false, save: true });
      })
      .finally(() => {
        startRef.current.scrollIntoView({ behavior: 'smooth' });
      });
  };


  const handleUploadLogo = async () => {
    setuploadLogo(true);
    const archivos = uploadLogoValue;
    const formData = new FormData();
    formData.append('files', archivos[0]);
    const url = `${process.env.REACT_APP_CH_ORGANIZATION}/${idOrganization}/img`;

    try {
      const response = await axios.post(url, formData, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${UserService.getToken()}`
        }
      });

      if (response.status === 200) {
        setLogo(response.data.data.urlLogo);
        setMessage(prevMessages => [...prevMessages, { type: "success", message: t("footPrint.logoUpload") }]);
      } else {
        console.error(response.error);
      }
    } catch (error) {
      console.error('Error al realizar la petición:', error);
    } finally {
      setuploadLogo(false);
    }
  };


  const handleDeleteBuilding = async (id) => {
    setShowSpinner({ delete: true, save: false })
    setSedes((prevSedes) => prevSedes.filter((sede) => sede.id !== id));
    setdDeleteOrgBuildingsQueue([...deleteOrgBuildingsQueue, id]);
    handleClose()
  };

  const deleteBuilding = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_CH_BUILDING}/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
      });
      setMessage([{ type: "success", message: t("footPrint.deleteB-ok") }]);
    } catch (error) {
      console.error('Error al eliminar:', error);
      setMessage([{ type: "error", message: t("footPrint.delete-ko") }])
    }
  }


  const handlePostBuilding = async (name, organizationId, organizationBuildingType, stateId, sources, description) => {
    try {
      const url = process.env.REACT_APP_CH_BUILDING;

      const data = {
        "name": name,
        "organizationId": organizationId,
        "organizationBuildingType": organizationBuildingType,
        "stateId": stateId,
        "sources": sources,
        "description": description
      };

      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${UserService.getToken()}`
      }

      await axios.post(url, data, { headers }).then(response => {
        if (response.data.success) {
          setMessage(prevMessages => [...prevMessages, { type: "success", message: t("footPrint.addbuilding") }]);
        } else {
          let warningText = t("footPrint.new-ko");
          if (response.data.message.split("-->").length > 1) {
            warningText = response.data.message.split("-->")[1];
          }
          setMessage(prevMessages => [...prevMessages, { type: "warning", message: warningText }]);
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
      setMessage(prevMessages => [...prevMessages, { type: 'error', message: t('footPrint.new-ko') }]);
    }
  };


  const handlePutBuilding = async (sede) => {
    const url = process.env.REACT_APP_CH_BUILDING;
    const data = {
      "id": sede.id,
      "name": sede.name,
      "organizationBuildingType": sede.organizationBuildingType,
      "stateId": sede.stateId,
      "description": sede.description,
      "sources": sede.sources

    };


    await fetch(url, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${UserService.getToken()}`
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('error');
        }
        return response.json();
      })
      .then(data => {
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };

  const handleAddSede = (name) => {
    const newSede = { name: name ? name : '', isNew: true, organizationBuildingType: '', stateId: '', identifier: contadorSedes };
    setSedes([...sedes, newSede]);
    setContadorSedes(contadorSedes + 1)
    setResult(hasEmptyName([...sedes, newSede]));
  };

  const handleInputChange = (e, index) => {
    const nuevoNombre = e.target.value;
    const nombreCampo = e.target.name


    handleModificarNombre(index, nuevoNombre, nombreCampo); // Llamar a la función para modificar el estado principal
  }

  const handleModificarNombre = (index, nuevoNombre, nombreCampo) => {

    const nuevaLista = sedes;
    nuevaLista[index][nombreCampo] = nuevoNombre;

    setSedes(nuevaLista);
    setResult(hasEmptyName(nuevaLista));
  };

  const handleSetSedes = (index, stateId) => {
    const nuevaLista = sedes;
    nuevaLista[index].stateId = stateId;
    setSedes(nuevaLista);

    setResult(hasEmptyName(nuevaLista));
  };


  const handleSelectChange = (e, index) => {
    const nuevoTipo = e.target.value;
    handleModificarTipo(index, nuevoTipo);
  };

  const handleModificarTipo = (index, nuevoTipo) => {
    const nuevaLista = sedes;
    nuevaLista[index].organizationBuildingType = nuevoTipo;
    setSedes(nuevaLista);
    setResult(hasEmptyName(nuevaLista));
  };



  const handleSubmitSedes = async (e) => {

    const changingSedes = [...sedes];

    try {
      for (const sede of changingSedes) {

        if (sede.isNew && sedes.length > 0) {


          await handlePostBuilding(sede.name, idOrganization, sede.organizationBuildingType, sede.stateId, sede.sources, sede.description);
        } else {
          const prevSedes = sedesOrigin.find(originSede => originSede.id === sede.id);
          if (prevSedes && prevSedes.name !== sede.name || prevSedes.organizationBuildingType != sede.organizationBuildingType || prevSedes.stateId != sede.stateId || prevSedes.description != sede.description || prevSedes.sources != sede.sources) {


            await handlePutBuilding(sede);
          }
        }
      }
      setShowSpinner({ delete: false, save: false })
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    const fetchCNAE = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CH_CNAE}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setCNAE({ data: response.data, isFetching: true });
      } catch (exception) {
        setCNAE({ data: [], isFetching: true });
      }
    };
    fetchCNAE();
  }, []);


  const [servicesCNAE, setServicesCNAE] = useState({
    data: [],
    isFetching: false
  });
  const fetchOrganizationsCNAEServices = async (idCNAE) => {
    if (idCNAE != undefined) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CH_CNAE_SECTORS}/${idCNAE}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setServicesCNAE({ data: response.data, isFetching: false });

      } catch (exception) {
        setServicesCNAE({ data: [], isFetching: false });
        console.error(exception.message)
      }
    }
  };

  useEffect(() => {
    const fetchTypeOrg = async () => {
      try {

        const response = await axios.get(`${process.env.REACT_APP_CH_TYPE_ORG}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setTypeOrg({ data: response.data, isFetching: true });

      } catch (exception) {
        setTypeOrg({ data: [], isFetching: true });
      }
    };
    fetchTypeOrg();
  }, []);



  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_CH_LOCATIONS}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {

          const countriesList = response.data.data.filter(item => item.regionList.length > 0).map(item => ({
            id: item.id,
            name: item.name,
            regionList: item.regionList
          }))

          const sortedCountries = JSON.parse(JSON.stringify(countriesList)).sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

          setCountries(sortedCountries);
        });
      } catch (exception) {
      }
    };
    fetchLocations();
  }, []);


  function hasEmptyName(objects) {
    for (const obj of objects) {
      if (obj.hasOwnProperty("name") && obj.name === "") {
        return false;
      }
    }

    return true;
  }


  const yearActual = new Date().getFullYear();
  const years = [];

  for (let year = process.env.REACT_APP_CH_YEAR; year <= yearActual; year++) {
    years.push(year);
  }

  const handlerReturnButton = (v) => {
    window.history.back();
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    let fileOk = true;
    let errorMsg = "";
    if (file) {

      if (/\s/.test(fileName) || /[^\w.-]/.test(fileName)) {
        fileOk = false;
        errorMsg = t("marketplace.errImgNombre")
      }

      const extension = fileName.split(".").pop();
      if (extension !== extension.toLowerCase()) {
        fileOk = false;
        errorMsg = t("marketplace.errImgExtensionMayusculas");
      }

      const fileExtension = fileName.split('.').pop().toLowerCase();
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      if (!allowedExtensions.includes(fileExtension)) {
        fileOk = false;
        errorMsg = t("footPrint.logo-extension");
      }

      if (fileOk) {
        setuploadLogoValue(e.target.files)
      } else {
        setMessage(prevMessages => [...prevMessages, { type: "warning", message: errorMsg }]);
        console.error('Error: ' + errorMsg);
        e.target.value = null;
        setuploadLogoValue([])
      }

    }
  };

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(location?.hash.includes("idFlota") ? true : false);

  const handleClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const [showAdvancedOptionsSede, setShowAdvancedOptionsSede] = useState(location?.hash.includes("idSources") ? true : false);

  const handleClickSede = () => {
    setShowAdvancedOptionsSede(!showAdvancedOptionsSede);
  };

  useEffect(() => {
    if (location?.hash.includes("idFlota")) {

      document.getElementById('idFlota').scrollIntoView({ behavior: 'smooth' });

    }

  }, [sedes]);

  const handleOrganizationCNAE = (idCNAE) => {
    fetchOrganizationsCNAEServices(idCNAE)
  }

  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto" >

            {/* Page header */}
            <div className="mb-8 " id="start" ref={startRef}>
              {/* Title */}
              {pathParams.id === undefined ?
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.crearOrganizacion")}</h1>
                :
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.editarOrganizacion")}</h1>
              }
            </div>

            {/* Content */}
            {message.filter(msg => msg.type != "").map(msg => {
              return <div className='mb-1'><Banner type={msg.type} open setOpen={true}>{msg.message}</Banner></div>
            })}

            <Modal
              show={showDelete}
              style={{ width: '70vw', top: '30vh', left: '15vw' }}
              className="flex text-center"
            >
              <div className="flex m-2 justify-end">
                <button onClick={() => handleClose()}>
                  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                </button>
              </div>

              <Modal.Body className="mt-2"  >
                {t("footPrint.borrarSede")} <span className='italic font-bold'>{nameSede}</span>  ?
              </Modal.Body>

              <div className="flex mb-3 flex-wrap gap-2 justify-center">
                <button className="btn sec-green-background text-white ml-3" variant="secondary" onClick={() => handleDeleteBuilding(idSede)}>
                  {showSpinner.delete ? <Spinner animation="border" variant='light' /> : t("footPrint.eliminar")}
                </button>
                <Button className="btn sec-green-background text-white ml-3" variant="secondary" onClick={() => handleClose()}>
                  {t("footPrint.cerrar")}
                </Button>
              </div>
            </Modal>


            <div className='max-w-6xl mx-auto w-full'>
              <div className=''>
                <div className='text-right'>
                  {logo === '' || logo === undefined ?
                    ""
                    :
                    <div className='flex justify-end px-4 mx-1'>
                      <div className='flex justify-center' style={{ border: 'solid', borderColor: 'grey', borderRadius: '50%', overflow: 'hidden', width: '60px', height: '60px' }}>
                        <img src={logo} alt='Logo' style={{ width: '100%', height: 'auto', borderRadius: '50%' }} />
                      </div>
                    </div>
                  }
                </div>



                <form onSubmit={pathParams.id === undefined ? handleSubmitPost : handleSubmitPut} className='text-airco2-general  '>

                  {/* bloques form */}
                  <div className='w-full'>
                    <div className='flex flex-wrap m-4'>
                      <label className="block text-sm font-medium w-full  mb-1" htmlFor="name">{t("footPrint.nombreOrganizacion")} <span className="text-red-500">*</span></label>
                      <input id="name" className="form-user-data  w-full bg-white" type="text" required defaultValue={name} onChange={(e) => { cleanMessage(); setName(e.target.value) }} />

                    </div>
                    <div className='flex flex-wrap m-4'>
                      <label className="block text-sm font-medium w-full  mb-1" htmlFor="cif">CIF/NIF <span className="text-red-500">*</span></label>
                      <input id="cif" className="form-user-data  w-full bg-white" type="text" required defaultValue={cif} onChange={(e) => { cleanMessage(); setCif(e.target.value) }} />
                    </div>

                    <div className='flex flex-wrap m-4'>
                      <label className="block text-sm font-medium mb-1" htmlFor="organizationType">{t("footPrint.tipoOrganizacion")} <span className="text-red-500">*</span></label>
                      <select required value={organizationTypeId} list="organizationType" className="form-user-data w-full bg-white" id="organizationType" name="organizationType" onChange={(e) => { cleanMessage(); setOrganizatioTypeId(e.target.value) }}>
                        <option disabled="disabled" value={''}>{t("footPrint.tipoOrganizacion")}...</option>
                        {typeOrg?.data?.data?.map((x, y) =>
                          <option key={y} value={x.id}>{x.name}</option>
                        )}
                      </select>
                    </div>

                    <div className='flex flex-wrap m-4'>
                      <label className="block text-sm font-medium mb-1" htmlFor="usertype">{t("footPrint.sector")} <span className="text-red-500">*</span></label>
                      <select required value={sectorId} list="sector" className="form-user-data w-full bg-white" id="sector" name="sector"
                        onChange={(e) => { cleanMessage(); handleOrganizationCNAE(e.target.value); setSectorId(e.target.value) }}>
                        <option value={""} disabled="disabled">{t("footPrint.sector")}...</option>
                        {CNAE?.data?.data?.map((x, y) =>
                          <option key={y} value={x.id}>{x.name}</option>)}
                      </select>
                    </div>

                    <div className='flex flex-wrap m-4'>
                      <label className="block text-sm font-medium mb-1" htmlFor="usertype">{t("footPrint.subsector")} <span className="text-red-500">*</span></label>
                      <select required value={subSectorId} list="subsector" className="form-user-data w-full bg-white" id="subsector"
                        name="subsector"
                        onChange={(e) => { cleanMessage(); setSubSectorId(e.target.value) }}>
                        <option value={""} disabled="disabled">{t("footPrint.subsector")}...</option>
                        {servicesCNAE?.data?.map((x, y) =>
                          <option key={y} value={x.id}>{x.name}</option>)}
                      </select>
                    </div>

                    <div className='flex flex-wrap m-4'>
                      <label className="block text-sm font-medium mb-1" htmlFor="surbir_archivo">{t("footPrint.logo")} </label>
                      <input className='form-user-data border-1  w-full bg-white' accept="image/png, image/jpeg,image/jpg" type="file" id="subir_archivo" onChange={(e) => { cleanMessage(); handleFileChange(e) }} style={{ borderColor: '#64748b' }} ></input>
                      <p className='mt-1 text-xs italic pl-1'>JPG,JPEG,PNG</p>
                    </div>

                    {/* PARTE DE SEDES */}
                    <div className='m-4'>
                      <label className="block text-sm font-medium mr-4 " htmlFor="sede">{t("footPrint.sede")} <span className="text-red-500">*</span></label>
                      <div className=''  >
                        {
                          sedes.length > 0
                          && sedes.map((item, index) => {

                            return (
                              <div className='row mb-4 box-calculations pt-4 ' key={`${item.id}-${item.identifier}`}>

                                <div className='pb-2 col-3 col-md-2 col-xxl-1'>
                                  <label className="block text-sm font-medium mb-1" htmlFor="index">{t("footPrint.numero")} </label>
                                  <input
                                    key={item.id}
                                    name='index'
                                    className="form-user-data bg-white  w-full"
                                    type="text"
                                    style={{ minHeight: '50px' }}
                                    defaultValue={'#' + (index + 1)}
                                    readOnly

                                  />

                                </div>

                                <div className='pb-2 col-9 col-md-4 col-xxl-7'>
                                  <label className="block text-sm font-medium mb-1" htmlFor="name">{t("footPrint.name")} </label>
                                  <input

                                    key={item.id}
                                    name='name'
                                    className="form-user-data bg-white  w-full"
                                    type="text"
                                    required
                                    style={{ minHeight: '50px' }}

                                    defaultValue={item.name}
                                    onChange={(e) =>
                                      handleInputChange(e, index)}
                                  />
                                </div>
                                <div className='pb-2 col-12 col-md-6 col-xxl-4' >
                                  <label className="block text-sm font-medium mb-1" htmlFor="tipoSede">{t("footPrint.tipoSede")} </label>
                                  <select
                                    className="form-user-data bg-white  w-full"
                                    name="orgBuildingType"
                                    defaultValue={item.organizationBuildingType} onChange={(e) => handleSelectChange(e, index)} required>
                                    <option value=""  >{t("footPrint.seleccionaTipoSede")}</option>
                                    <option value="FINANCIAL">{t("footPrint.sedeFinanciera")}</option>
                                    <option value="OPERATIONAL">{t("footPrint.sedeOperacional")}</option>
                                  </select>
                                </div>





                                {countries !== undefined && countries.length > 0 &&
                                  <OrganizationsLocations
                                    countries={countries}
                                    selectedInputs={[item.countryId, item.regionId, item.stateId]}
                                    index={index}
                                    handleSetSedes={handleSetSedes} />
                                }

                                {countries === undefined || countries.length == 0 &&
                                  <Spinner className='m-2' animation="border" variant='dark' />
                                }

                                {/* BOTONES VOLVER Y GUARDAR */}
                                <div className="pb-2" id='idSources'>
                                  <button type="button" onClick={handleClickSede} className="text-sm" >
                                    <div className='flex flex-inline'>
                                      {showAdvancedOptionsSede ? t("footPrint.hideInformationMiteco") : t("footPrint.showInformationMiteco")}
                                      <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-chevron-up transition-transform duration-200  ${showAdvancedOptionsSede ? 'transform rotate-180' : ''}`}
                                        width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5"
                                        stroke="#545a6d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M6 15l6 -6l6 6" />
                                      </svg>
                                    </div>
                                  </button>
                                </div>

                                <div id="advancedOptionsSede" className={`my-4 ${!showAdvancedOptionsSede ? "hidden" : ""}`}>
                                  <div className='pb-2 col-12 '>
                                    <label className="block text-sm font-medium mb-1" htmlFor="sources">{t("footPrint.sources")} </label>
                                    <input
                                      key={item.id}
                                      className="form-user-data bg-white  w-full"
                                      type="text"
                                      name='sources'


                                      defaultValue={item.sources}
                                      onChange={(e) =>
                                        handleInputChange(e, index)}
                                    />
                                  </div>
                                  <div className='pb-2 col-12 '>
                                    <label className="block text-sm font-medium mb-1" htmlFor="sources">{t("footPrint.description")} </label>
                                    <textarea
                                      key={item.id}
                                      className="form-user-data bg-white  w-full"

                                      maxLength={500}
                                      name='description'
                                      style={{ minHeight: '100px' }}


                                      defaultValue={item.description}
                                      onChange={(e) =>
                                        handleInputChange(e, index)}
                                    />
                                  </div>

                                </div>



                                <div className='col-12'>
                                  <div className='flex items-center justify-end  gap-2 mb-4'>


                                    {(item.isNew != true) &&
                                      <span className='cursor-pointer' onClick={() => handleshowDelete(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M4 7l16 0" />
                                          <path d="M10 11l0 6" />
                                          <path d="M14 11l0 6" />
                                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                        </svg>
                                      </span>

                                    }
                                    {(item.isNew) &&
                                      <span className='cursor-pointer' onClick={() => handleDeleteNewField(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          <path d="M18 6l-12 12" />
                                          <path d="M6 6l12 12" />
                                        </svg>
                                      </span>
                                    }

                                  </div>
                                </div>
                              </div>

                            )
                          }

                          )}




                      </div>
                      <div className='flex justify-end'>
                        <span className='cursor-pointer btn btn-green text-white ' onClick={() => handleAddSede()}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home-plus" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M19 12h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h5.5" />
                            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2" />
                            <path d="M16 19h6" />
                            <path d="M19 16v6" />
                          </svg>
                        </span>
                      </div>

                    </div>
                  </div>

                  {/* BOTONES VOLVER Y GUARDAR */}
                  <div className="m-4"  >
                    <button type="button" onClick={handleClick} className="text-sm">
                      <div className='flex flex-inline'>
                        {showAdvancedOptions ? t("footPrint.hideInformationMiteco") : t("footPrint.showInformationMiteco")}
                        <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-chevron-up transition-transform duration-200  ${showAdvancedOptions ? 'transform rotate-180' : ''}`}
                          width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5"
                          stroke="#545a6d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M6 15l6 -6l6 6" />
                        </svg>
                      </div>
                    </button>
                  </div>


                  <div id="advancedOptions" className={`my-4 ${!showAdvancedOptions ? "hidden" : ""}`}>
                    <div className='w-full'>
                      <div className='flex flex-wrap m-4'>
                        <label className="block text-sm font-medium w-full  mb-1" htmlFor="name">{t("footPrint.description")}</label>
                        <textarea id="description" className="form-user-data  w-full bg-white" maxLength={500} defaultValue={description} onChange={(e) => { cleanMessage(); setDescription(e.target.value) }} />

                      </div>
                      <div className='flex flex-wrap m-4 items-center justify-between'>
                        <div className='flex flex-inline'>
                          <input
                            name="abroad"
                            onChange={(e) => setAbroad(e.target.checked)}
                            checked={abroad}
                            className='custom-width-md'
                            style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                            type="checkbox" />
                          <label className='ml-2 text-sm'>{t("footPrint.abroad")}</label>
                        </div>
                        <div className='flex flex-inline'>
                          <input
                            name="subsidiary"
                            onChange={(e) => setSubsidiary(e.target.checked)}
                            checked={subsidiary}
                            className='custom-width-md'
                            style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                            type="checkbox" />
                          <label className='ml-2 text-sm'>{t("footPrint.subsidiary")}</label>
                        </div>
                      </div>

                      <div className='flex flex-wrap m-4'>
                        <label className="block text-sm font-medium w-full  mb-1" htmlFor="name">{t("footPrint.fleet")}</label>
                        <input id="name" className="form-user-data  w-full bg-white" type="text" defaultValue={fleet} onChange={(e) => { cleanMessage(); setFleet(e.target.value) }} />

                      </div>

                      <div className='flex flex-wrap m-4'>
                        <label className="block text-sm font-medium w-full  mb-1" htmlFor="name">{t("footPrint.extraInformation")}</label>
                        <textarea id="name" className="form-user-data  w-full bg-white" style={{ minHeight: '100px' }} maxLength={500} defaultValue={extraInformation} onChange={(e) => { cleanMessage(); setExtraInformation(e.target.value) }} />

                      </div>




                    </div>
                  </div>

                  <div className='flex items-center mt-8 pt-2 justify-between ' id='idFlota' >
                    <span arial-label="btn-saveFom" className="btn btn-cancel text-white ml-3" onClick={() => handlerReturnButton()} style={{ width: '10rem' }} >{t("footPrint.volver")}</span>
                    <button disabled={(message.length > 0 ? true : false) && (sedes.length == 0 ? true : false)} arial-label="btn-saveFom" className="btn btn-green text-white ml-3" type='submit' style={{ width: '10rem' }} >  {showSpinner.save ? <Spinner animation="border" variant='light' /> : t("footPrint.guardar")}</button>
                  </div>


                </form>

              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )

}