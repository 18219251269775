import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "leaflet/dist/leaflet.css";
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sticky from 'sticky-js';
import './App.css';
import './HomeApp.css';
import RenderOnAnonymous from './components/auth/RenderOnAnonymous';
import RenderOnAuthenticated from './components/auth/RenderOnAuthenticated';
import RenderOnEmpresa from './components/auth/RenderOnEmpresa';
import RenderOnMonte from './components/auth/RenderOnMonte';
import RenderOnRole from './components/auth/RenderOnRole';
import { TourProvider } from './context/TourContext.js'; // Import the TourProvider
import IdiomProvider from './context/context';
import './css/style.scss';
import { Hubspot } from './hubspot/hubspot';
import cartelCeo from './images/Monte_CEO.webp';
import cartelAlia from './images/alia_cartel.webp';
import Home from './pages/home/Home';
import Account from './pages/intranet/account/Account';
import Apps from './pages/intranet/account/Apps';
import Billing from './pages/intranet/account/Billing';
import Feedback from './pages/intranet/account/Feedback';
import NotFoundAcount from './pages/intranet/account/NotFoundAcount';
import Notifications from './pages/intranet/account/Notifications';
import Plans from './pages/intranet/account/Plans';
import PlansConfirmationSubscription from './pages/intranet/account/PlansConfirmationSubscription';
import AdminBanco from './pages/intranet/admin/AdminBanco';
import AllForest from './pages/intranet/admin/AllForest';
import ForestUser from './pages/intranet/admin/ForestUser.jsx';
import MarketPlaceAdmin from './pages/intranet/admin/MarketplaceAdmin';
import { MarketplaceProduct } from './pages/intranet/admin/MarketplaceProduct';
import MarketplaceProvider from './pages/intranet/admin/MarketplaceProvider';
import Usuarios from './pages/intranet/admin/Usuarios';
import FootprintAdmin from './pages/intranet/admin/footprint/FootprintAdmin.js';
import FormAdmin from './pages/intranet/admin/formAdmin';
import { ParseExcel } from './pages/intranet/banking-transactions/ParseExcel';
import CalculationForm from './pages/intranet/calculo-huella/CalculationForm';
import CalculoReporte from './pages/intranet/calculo-huella/CalculoReporte';
import Form from './pages/intranet/calculo-huella/Form';
import MisCalculos from './pages/intranet/calculo-huella/MisCalculos';
import NewEditOrganization from './pages/intranet/calculo-huella/NewEditOrganization';
import Organizaciones from './pages/intranet/calculo-huella/Organizaciones';
import PaymentResponse from './pages/intranet/calculo-huella/PaymentResponse';
import PlansFootprint from './pages/intranet/calculo-huella/PlansFootprint.jsx';
import PreviousData from './pages/intranet/calculo-huella/PreviousData';
import ReductionCommitment from './pages/intranet/calculo-huella/ReductionCommitment';
import UploadDocuments from './pages/intranet/calculo-huella/UploadDocuments';
import WelcomeFootprint from './pages/intranet/calculo-huella/WelcomeFootprint.jsx';
import Contacts from './pages/intranet/calculo-terceros/Contacts.jsx';
import DataEmail from './pages/intranet/calculo-terceros/DataEmail.jsx';
import FormContacts from './pages/intranet/calculo-terceros/FormContacts.jsx';
import Qr from './pages/intranet/calculo-terceros/Qr.jsx';
import SendForm from './pages/intranet/calculo-terceros/SendForm.jsx';
import ValidateContact from './pages/intranet/calculo-terceros/ValidateContact.jsx';
import MapaEspecie from './pages/intranet/catastro/EspeciesMap';
import FomNavarraPV from './pages/intranet/catastro/FomNavarraPV';
import NewForestForm from './pages/intranet/catastro/NewForestForm';
import NewForestFormMultiple from './pages/intranet/catastro/NewForestForm_multiple';
import CompensationsMarketplacePage from './pages/intranet/empresas/CompensationsMarketplacePage';
import DashboardIntranet from './pages/intranet/empresas/DashboardIntranet';
import Emissions from './pages/intranet/empresas/Emissions';
import RedirectCoinscrap from './pages/intranet/empresas/RedirectCoinscrap';
import UploadSingleExcel from './pages/intranet/empresas/UploadSingleExcel';
import Compensateco2 from './pages/intranet/empresas/compensateco2';
import CompensationPaymentInformation from './pages/intranet/empresas/compensationPaymentInformation';
import DashboardCoinscrap from './pages/intranet/empresas/dashboardcoinscrap';
import DashboardExcel from './pages/intranet/empresas/dashboardexcel';
import CalculatePriceContent from './pages/intranet/forestales/CalculatePriceContent';
import Dashboardmontes from './pages/intranet/forestales/Dashboardmontes';
import ContratoForestal from './pages/intranet/forestales/FaqsContratoForestal';
import ForestList from './pages/intranet/forestales/ForestList';
import FormInfoExtra from './pages/intranet/forestales/FormInfoExtra';
import Proyectos from './pages/intranet/forestales/Proyectos';
import AppDrop from './pages/intranet/lector-facturas/AppDrop';
import MarketplaceFormMonte from './pages/intranet/marketplace/FormMontesMP';
import MarketplacImages from './pages/intranet/marketplace/ImagesMP';
import MPOrdersList from './pages/intranet/marketplace/MPOrdersList';
import MarketplacePanel from './pages/intranet/marketplace/MarketplacePanel';
import StripeRegisterResponse from './pages/intranet/marketplace/StripeRegisterResponse';
import StripeResponse from './pages/intranet/marketplace/StripeResponse';
import CatalogoServicios from './pages/intranet/serviciosForestales/CatalogoServicios';
import ServiciosForestales from './pages/intranet/serviciosForestales/ServiciosForestales';
import AppDropMontes from './pages/intranet/subirDoc/AppDropMontes';
import CarbonManager from './pages/landings/carbonManager/CarbonManager.jsx';
import LandingEventoNetZero from './pages/landings/evento-net-zero/landingEventoNetZero';
import GreatPackaging from './pages/landings/landing-great-packaging/gp.jsx';
import GreatPackagingQr1Redirect from './pages/landings/landing-great-packaging/greatPackagingQrRedirect.jsx';
import LandingBosqueTemplate from './pages/landings/landingBosque/landingBosqueTemplate';
import Concellos from './pages/landings/landingConcellos/Concellos';
import Landing from './pages/landings/landingTemplate';
import Faqs from './pages/legales-wp/Faqs';
import Notfound from './pages/legales-wp/PageNotFound.js';
import Terminos from './pages/legales-wp/Terminos';
import Cookies from './pages/legales-wp/cookies';
import RedirectExternal from './pages/login-register/RedirectExternal.jsx';
import ResetPassword from './pages/login-register/ResetPassword';
import ResetPasswordExternal from './pages/login-register/ResetPasswordExternal.jsx';
import Signin from './pages/login-register/Signin';
import SigninExternal from './pages/login-register/SigninExternal.jsx';
import Signup from './pages/login-register/Signup';
import SignupExternal from './pages/login-register/SignupExternal.jsx';
import Contreebuye from './quiz/contreebuye';
import Quiz from './quiz/quiz';
import ModifyReportPdf from "./pages/intranet/admin/ModifyReportPdf.jsx";

import PruebaCoolfarming from './pages/login-register/pruebaCoolfarming.js';

import AnswersValidate from './pages/intranet/calculo-terceros/AnswersValidate.jsx';

import Tutorials from './pages/intranet/calculo-huella/Tutorials.jsx';
import QRAnefhop from './pages/landings/QRAnefhop.jsx';
import EvolutionPage from './pages/intranet/calculo-huella/evolution/EvolutionPage.jsx';
import DecarbonizationMainPage from './pages/intranet/calculo-huella/decarbonization/DecarbonizationMainPage.jsx';
import DecarbonizationPage from './pages/intranet/calculo-huella/decarbonization/DecarbonizationPage.jsx';
import ForestalHome from './pages/home/ForestalHome.jsx';


function App() {

  useEffect(() => {
    AOS.init({
      once: true,
      // disable: 'phone',
      disable: false,
      duration: 700,
      easing: 'ease-out-cubic',
      anchorPlacement: 'top-top'
    });

    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');
  });

  return (

    <BrowserRouter>
      <TourProvider> {/* Wrap your routes with TourProvider */}
      <div>
        <div className="">
          <IdiomProvider>
            <Routes>
              <Route path="/" element={<RenderOnAnonymous><Home></Home></RenderOnAnonymous>} />
              <Route path="/forestal" element={<RenderOnAnonymous><ForestalHome></ForestalHome></RenderOnAnonymous>} />
              <Route path="/register" element={<RenderOnAnonymous><Signup></Signup></RenderOnAnonymous>} />
              <Route path="/register-external" element={<RenderOnAnonymous><SignupExternal></SignupExternal></RenderOnAnonymous>} />
              <Route path="/login" element={<RenderOnAnonymous><Signin></Signin></RenderOnAnonymous>} />
              <Route path="/login-external" element={<RenderOnAnonymous><SigninExternal></SigninExternal></RenderOnAnonymous>} />
              <Route path="/reset-password-external" element={<RenderOnAnonymous><ResetPasswordExternal /></RenderOnAnonymous>} />
              <Route path="/redirect-external" element={<RenderOnAnonymous><RedirectExternal/></RenderOnAnonymous>} />
              <Route path="/reset-password" element={<RenderOnAnonymous><ResetPassword /></RenderOnAnonymous>} />
              <Route path='/carbonmanager' element={<RenderOnAnonymous><CarbonManager></CarbonManager></RenderOnAnonymous>} />

              <Route path="/bosque-ALIA" element={<RenderOnAnonymous><Landing tipo='Bosque' nombre='ALIA' cartel={cartelAlia} subtitle1="El monte que cuidaremos entre todos." subtitle2="Un monte aragonés que absorbe nuestras emisiones." tons={"10t"}/></RenderOnAnonymous>} />
              <Route path="/monte-ceo" element={<RenderOnAnonymous><Landing tipo='Monte' nombre='CEO' cartel={cartelCeo} infoExtra={true}   subtitle1="El monte que cuidaremos entre todos." subtitle2="Un monte ourensano que absorbe nuestras emisiones." tons={"10t"}/></RenderOnAnonymous>} />
              <Route path="/bosques-anefhop" element={<RenderOnAnonymous><Landing tipo='Red de Bosques' nombre='ANEFHOP' cartel="" infoExtra={false}  subtitle1="Un espacio natural que cuidaremos entre todos" subtitle2="y que ayudará a absorver nuestras emisiones." tons={false}/></RenderOnAnonymous>} />
              <Route path="/bosque-evento" element={<RenderOnAnonymous>
                <Landing tipo={process.env.REACT_APP_TYPE_EVENT} nombre={process.env.REACT_APP_NAME_EVENT} cartel=""  infoExtra={false}  subtitle1={process.env.REACT_APP_SUBTITLE1_EVENT}  subtitle2={process.env.REACT_APP_SUBTITLE2_EVENT}  tons={process.env.REACT_APP_TONS_EVENT.toLowerCase() === 'true'}
              /></RenderOnAnonymous>} />
              <Route path="/eventos-net-zero" element={<RenderOnAnonymous><LandingEventoNetZero /></RenderOnAnonymous>} />
              <Route path="/bosque-corporativo" element={<RenderOnAnonymous><LandingBosqueTemplate /></RenderOnAnonymous>} />
              <Route path="/ayuntamientos-net-zero" element={<RenderOnAnonymous><Concellos /></RenderOnAnonymous>} />
              <Route path="/qr1" element={<RenderOnAnonymous><GreatPackagingQr1Redirect /></RenderOnAnonymous>} />
              <Route path="/airco2gpcencosud" element={<RenderOnAnonymous><GreatPackaging /></RenderOnAnonymous>} />
              <Route path="/qranefhop" element={<RenderOnAnonymous><QRAnefhop /></RenderOnAnonymous>} />

              <Route path="/pruebacb" element={<RenderOnAnonymous><PruebaCoolfarming/></RenderOnAnonymous>} />


              {/* <Route path="/hubspot" element={<Hubspot />} /> */}

              {/* <Route path="/app2" element={<App2 />} /> */}

              <Route path="/company/dashboardintranet" element={<RenderOnEmpresa><DashboardIntranet /></RenderOnEmpresa>} />
              <Route path="/company/dashboardcoinscrap" element={<RenderOnEmpresa><DashboardCoinscrap /></RenderOnEmpresa>} />
              <Route path="/company/dashboardexcel" element={<RenderOnEmpresa><DashboardExcel /></RenderOnEmpresa>} />
              <Route path="/company/uploadsingleexcel" element={<RenderOnEmpresa><UploadSingleExcel /></RenderOnEmpresa>} />

              <Route path="/company/redirect" element={<RenderOnAuthenticated><RedirectCoinscrap /></RenderOnAuthenticated>} />
              <Route path="/company/compensateco2" element={<RenderOnEmpresa><Compensateco2 /></RenderOnEmpresa>} />
              <Route path="/company/compensateco2/payment" element={<RenderOnEmpresa><CompensationPaymentInformation /></RenderOnEmpresa>} />

              {/* <Route path="/company/plans" element={<RenderOnEmpresa><Plans /></RenderOnEmpresa>} /> */}
              <Route path="/company/confirm_subscription" element={<RenderOnEmpresa><PlansConfirmationSubscription /></RenderOnEmpresa>} />
              <Route path="/company/compensations" element={<RenderOnEmpresa><CompensationsMarketplacePage /></RenderOnEmpresa>} />
              <Route path="/company/emissions" element={<RenderOnEmpresa><Emissions /></RenderOnEmpresa>} />
              <Route path="/company/uploadtransactions" element={<RenderOnEmpresa><ParseExcel /></RenderOnEmpresa>} />
              <Route path="/company/billing" element={<RenderOnAuthenticated><Billing /></RenderOnAuthenticated>} />
              <Route path="/company/proyectos" element={<RenderOnEmpresa><Proyectos /></RenderOnEmpresa>} />

              <Route path="/company/uploadinvoices" element={<RenderOnEmpresa><AppDrop /></RenderOnEmpresa>} />
              {/* <Route path="/footprint/calculationform" element={<RenderOnEmpresa><CalculationForm /></RenderOnEmpresa>} /> */}
              <Route path="/footprint/calculationform" element={<RenderOnAuthenticated><CalculationForm /></RenderOnAuthenticated>} />

              <Route path="/footprint/organizations" element={<RenderOnAuthenticated><Organizaciones /></RenderOnAuthenticated>} />
              <Route path="/footprint/organization/:id" element={<RenderOnAuthenticated><NewEditOrganization /></RenderOnAuthenticated>} />
              <Route path="/footprint/organization" element={<RenderOnAuthenticated><NewEditOrganization /></RenderOnAuthenticated>} />
              <Route path="/footprint/previousdata" element={<RenderOnAuthenticated><PreviousData /></RenderOnAuthenticated>} />
              <Route path="/footprint/carbonmanager" element={<RenderOnAuthenticated><Form /></RenderOnAuthenticated>} />
              <Route path="/footprint/finalized" element={<RenderOnAuthenticated><Form /></RenderOnAuthenticated>} />
              <Route path="/footprint/myfootprint" element={<RenderOnAuthenticated><MisCalculos /></RenderOnAuthenticated>} />
              <Route path="/footprint/myfootprint/report/:id" element={<RenderOnAuthenticated><CalculoReporte /></RenderOnAuthenticated>} />
              <Route path="/footprint/uploaddocuments/:id" element={<RenderOnAuthenticated><UploadDocuments /></RenderOnAuthenticated>} />
              <Route path="/footprint/paysubscription" element={<RenderOnAuthenticated><PaymentResponse /></RenderOnAuthenticated>} />
              <Route path="/footprint/myfootprint/reductionCommitment/:id" element={<RenderOnAuthenticated><ReductionCommitment /></RenderOnAuthenticated>} />
              <Route path="/footprint/plans" element={<RenderOnEmpresa><PlansFootprint></PlansFootprint></RenderOnEmpresa>}></Route>
              <Route path="/footprint/welcome" element={<RenderOnEmpresa><WelcomeFootprint></WelcomeFootprint></RenderOnEmpresa>}></Route>
              <Route path="/footprint/contacts" element={<RenderOnEmpresa><Contacts></Contacts></RenderOnEmpresa>}></Route>
              <Route path="/footprint/formContacts/:id" element={<RenderOnEmpresa><FormContacts></FormContacts></RenderOnEmpresa>}></Route>
              <Route path="/footprint/sendForm/:id" element={<RenderOnEmpresa><SendForm></SendForm></RenderOnEmpresa>}></Route>
              <Route path="/footprint/tutorials/*" element={<RenderOnAuthenticated><Tutorials></Tutorials></RenderOnAuthenticated>}></Route>
              <Route path="/footprint/qr/:id" element={<RenderOnEmpresa><Qr></Qr></RenderOnEmpresa>}></Route>
              <Route path="/footprint/externalanswers/:id" element={<RenderOnEmpresa><AnswersValidate></AnswersValidate></RenderOnEmpresa>}></Route>
              <Route path="/footprint/evolution" element={<RenderOnEmpresa><EvolutionPage></EvolutionPage></RenderOnEmpresa>}></Route>
              <Route path="/footprint/decarbonization" element={<RenderOnEmpresa><DecarbonizationMainPage></DecarbonizationMainPage></RenderOnEmpresa>}></Route>
              <Route path="/footprint/decarbonization/scenario/:id" element={<RenderOnEmpresa><DecarbonizationPage></DecarbonizationPage></RenderOnEmpresa>}></Route>
             

              <Route path="/:organization/:year/externalQuestionnaire/:idExternal/contact/:idContact"  element={<DataEmail></DataEmail>}></Route>
              <Route path="/:organization/:year/externalQuestionnaire/:idExternal/*"  element={<DataEmail></DataEmail>}></Route>
              <Route path="/externalQuestionnaire/:idExternal/validateExternalContact/:id" element={<ValidateContact></ValidateContact>}></Route>

              <Route path="/account/account" element={<RenderOnAuthenticated><Account /></RenderOnAuthenticated>} />
              <Route path="/account/plans" element={<RenderOnAuthenticated><Plans /></RenderOnAuthenticated>} />


              <Route path="/account/notifications" element={<Notifications />} />
              <Route path="/account/apps" element={<Apps />} />

              <Route path="/account/feedback" element={<Feedback />} />

              <Route path="/forest/dashboardmontes" element={<RenderOnMonte><Dashboardmontes /></RenderOnMonte>} />
              <Route path="/forest/forestlist" element={<RenderOnMonte><ForestList /></RenderOnMonte>} />
              <Route path="/forest/newmonte" element={<RenderOnMonte><FomNavarraPV /></RenderOnMonte>} />
              <Route path="/forest/newforestform" element={<RenderOnMonte><NewForestForm /></RenderOnMonte>} />
              <Route path='/forest/newmultiforest' element={<RenderOnMonte><NewForestFormMultiple /></RenderOnMonte>} />
              <Route path="/forest/uploaddocuments" element={<RenderOnMonte><AppDropMontes /></RenderOnMonte>} />
              <Route path="/forest/clausulas" element={<RenderOnMonte><ContratoForestal /></RenderOnMonte>} />
              <Route path="/forest/marketplace" element={<RenderOnMonte><MarketplacePanel /></RenderOnMonte>} />
              <Route path="/forest/formMarketplace" element={<RenderOnMonte><MarketplaceFormMonte /></RenderOnMonte>} />
              <Route path="/forest/imagesMarketplace" element={<RenderOnMonte><MarketplacImages /></RenderOnMonte>} />
              <Route path='/forest/calculateprice' element={<RenderOnMonte><CalculatePriceContent /></RenderOnMonte>} />
              <Route path="/forest/marketplace/orders" element={<RenderOnMonte><MPOrdersList /></RenderOnMonte>} />
              <Route path='/forest/formInfo/:id' element={<RenderOnMonte><FormInfoExtra></FormInfoExtra></RenderOnMonte>} />
              <Route path="/forest/speciesmap" element={<RenderOnMonte><MapaEspecie /></RenderOnMonte>} />
              <Route path="/forest/forestryServices" element={<RenderOnMonte><ServiciosForestales /></RenderOnMonte>} />
              <Route path="/forest/catalogServices" element={<RenderOnMonte><CatalogoServicios /></RenderOnMonte>} />
              <Route path='/forest/stripe/connect/verified' element={<RenderOnMonte><StripeResponse status={true}></StripeResponse></RenderOnMonte>} />
              <Route path='/forest/stripe/connect/retry' element={<RenderOnMonte><StripeResponse status={false} ></StripeResponse></RenderOnMonte>} />
              <Route path='/forest/stripe/verified' element={<RenderOnMonte><StripeRegisterResponse status={true}></StripeRegisterResponse></RenderOnMonte>} />
              <Route path='/forest/stripe/retry' element={<RenderOnMonte><StripeRegisterResponse status={false} ></StripeRegisterResponse></RenderOnMonte>} />



              <Route path="/faqs" element={<Faqs />} />
              {/* <Route path="/signin" element={<Home />} /> */}


              <Route path='/terminos' element={<Terminos></Terminos>} />
              <Route path='/privacidadYcookies' element={<Cookies></Cookies>} />


              <Route path='/contreebuye' element={<Contreebuye />} />
              <Route path='/quiz' element={<Quiz />} />
              <Route path='/quizdata' element={<Hubspot />} />



              <Route path='/forest/*' element={<RenderOnAuthenticated><NotFoundAcount /></RenderOnAuthenticated>} />
              <Route path='/company/*' element={<RenderOnAuthenticated><NotFoundAcount /></RenderOnAuthenticated>} />
              <Route path='/account/*' element={<RenderOnAuthenticated><NotFoundAcount /></RenderOnAuthenticated>} />
              <Route path='*' element={<Notfound></Notfound>} />

              {/* ADMIN */}
              <Route path="/adminusuarios" element={<RenderOnRole roles={['admin']}><Usuarios /></RenderOnRole>} />
              <Route path="/adminbanco" element={<RenderOnRole roles={['admin']}><AdminBanco /></RenderOnRole>} />
              <Route path="/forestUser" element={<RenderOnRole roles={['admin']}><ForestUser /></RenderOnRole>} />
              <Route path="/adminmontes" element={<RenderOnRole roles={['admin']}><AllForest /></RenderOnRole>} />
              <Route path="/speciesmap" element={<RenderOnRole roles={['admin']}><MapaEspecie /></RenderOnRole>} />

              <Route path="/marketplaceAdmin" element={<RenderOnRole roles={['admin']}><MarketPlaceAdmin /></RenderOnRole>} />
              <Route path="/adminmarketplaceProduct" element={<RenderOnRole roles={['admin']}><MarketplaceProduct /></RenderOnRole>} />
              <Route path="/adminmarketplaceProvider" element={<RenderOnRole roles={['admin']}><MarketplaceProvider /></RenderOnRole>} />
              <Route path="/adminmarketplace/product/images" element={<RenderOnRole roles={['admin']}><MarketplacImages /></RenderOnRole>} />
              <Route path="/adminmarketplace/form/:id" element={<RenderOnRole roles={['admin']}><FormAdmin /></RenderOnRole>} />
              {/* <Route path="/admin/footprint" element={<FootprintCalculationAdmin roles={['admin']}><FootprintCalculationAdmin /></FootprintCalculationAdmin>} /> */}
              <Route path="/admin/footprint" element={<FootprintAdmin roles={['admin']}><FootprintAdmin /></FootprintAdmin>} />
              <Route path="/admin/footprint/documents/:id" element={<RenderOnRole roles={['admin']}><UploadDocuments /></RenderOnRole>} />
              <Route path="/admin/footprint/report/edit/:id/:reportType" element={<RenderOnRole roles={['admin']}><ModifyReportPdf /></RenderOnRole>} />

            </Routes>
          </IdiomProvider>
        </div>
      </div>
    </TourProvider>
    </BrowserRouter>
  );
}



export default App;
