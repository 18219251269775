import React from 'react';

import { useContext } from "react";
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import foto from '../../images/Nosotros.webp'

function Career() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <section id='nosotros3'>
      <div className="max-w-6xl mx-auto pt-5 sm:pt-6">
        <div className="py-4 md:py-5">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-6 md:pb-8">
            <h2 className="text48 sec-blue-text mb-4" >{t("wecontac.crecer")}</h2>
            {/* <p className="text18 sec-text-blue" >
              {t("wecontac.movimiento")}
            </p> */}
            <p className="text18 sec-blue-text" >
              {t("wecontac.mov1")}
            </p>
            <p className="text18 sec-blue-text" >
              {t("wecontac.mov2")}
            </p>
          </div>
        </div>
      </div>

      {/* Team images */}
      <div className="max-w-3xl mx-auto " data-aos="fade-down" >
        <div className="pb-12 md:pb-20">
          <div className="relative w-full h-0 pb-3/4">
            <img alt='foto manos' src={foto}></img>

          </div>
        </div>
      </div>







    </section>
  );
}

export default Career;