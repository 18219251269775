import { useTranslation } from 'react-i18next';
import { formatThousands } from '../utils/Utils';
import { Link } from 'react-router-dom';

export default function TotalCalculoBlock({ calculation, urlStamp, totalCO2eByReportData }) {

    const [t, i18n] = useTranslation("global");

    return (<div className='p-3 flex flex-col justify-between bg-white  rounded-sm border border-gray-200 px-2 py-3'>
        <div className='text-xl text-center'>{t("footPrint.huellaResultado")} {calculation?.data?.year}:</div>
        {calculation?.data?.totalKgCO2e !== undefined && <div className=' rounded-sm text-center p-1'>
            <div className='relative'>
                {urlStamp != undefined && <img src={urlStamp} alt="Background Image" className='sello' style={{ width: '100%', opacity: '50%' }} />}
                <div className='' style={{
                    width: '75%',
                    height: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <p className='font-bold text-3xl'>
                        {calculation?.data?.calculationStatus === "CURRENTYEAR" ?
                            formatThousands(totalCO2eByReportData / 1000) :
                            formatThousands((calculation?.data?.totalKgCO2e / 1000))
                        } tCO<sub>2</sub>e</p>
                </div>
                {urlStamp != undefined &&
                    <div className='' style={{
                        width: '75%',
                        height: 'auto',
                        position: 'absolute',
                        top: '62%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        opacity: '0.8'
                    }}>

                        <p className='text-md pt-4 text-gray-800 font-semibold'>
                            {calculation?.data?.calculationStatus === "CURRENTYEAR" ?
                                formatThousands(totalCO2eByReportData) :
                                formatThousands((calculation?.data?.totalKgCO2e))
                            } kgCO<sub>2</sub>e</p>
                    </div>}

            </div>
        </div>}
        <div className='text-md text-center font-bold text-airco2-principal'>
            <Link to={`/footprint/organization/${calculation?.data?.organization.id}`}
                className='' >
                {calculation?.data?.organization.name}
            </Link>
        </div>
    </div>)
}