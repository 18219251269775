import axios from "axios";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import { Input } from "reactstrap";
import UserService from "../../../../services/UserService";
import FilterButton from "../../utils/DropdownFilterOrders";

function FootprintUserList() {
  const [users, setUsers] = useState({
    data: { data: [] },
    isFetching: false,
  });
  const [usersOrigin, setUsersOrigin] = useState({
    data: { data: [] },
    isFetching: false,
  });

  const initialQuestionnaires = {
    general: false,
    agropecuario: false,
    logistica: false,
    hosteleria: false,
  };

  const [modalQuestionnaires, setModalQuestionnaires] = useState({
    view: false,
    data: undefined,
  });

  const [modalTerceros, setModalTerceros] = useState({
    view: false,
    user: {name:undefined, id: undefined},
    thirdPartyForm: undefined,
    spinner: false
  });

  const [questions, setQuestions] = useState({
    data: [],
    isFetching: false
  });

  const [questionsIds, setQuestionsIds] = useState('');

  const [originalQuestionnaires, setOriginalQuestionnaires] = useState(
    initialQuestionnaires
  );
  const [modifiedQuestionnaires, setModifiedQuestionnaires] = useState(
    initialQuestionnaires
  );
  const [fetchQuestionnaires, setFetchQuestionnaires] = useState({
    spinner: false,
    visible: true,
  });

  useEffect(() => {
    if (modalQuestionnaires.data?.questionnairesId != undefined) {
      let questionnaires = modalQuestionnaires.data.questionnairesId;
      let objQuestionnairesForCheckbox = {
        general: questionnaires.includes(1) ? true : false,
        agropecuario: questionnaires.includes(2) ? true : false,
        logistica: questionnaires.includes(3) ? true : false,
        hosteleria: questionnaires.includes(4) ? true : false,
      };
      setOriginalQuestionnaires(objQuestionnairesForCheckbox);
      setModifiedQuestionnaires(objQuestionnairesForCheckbox);
    }
  }, [modalQuestionnaires]);

  const questionnaireName = (props) => {
    switch (props) {
      case 1:
        return "General ";
      case 2:
        return "Agropecuario ";
      case 3:
        return "Logística ";
      case 4:
        return "Hostelería ";
    }
  };

  const [uniqueQuestionnairesTypeList, setUniqueQuestionnairesTypeList] =
    useState([1, 2, 3, 4]);
  const [statusList, setStatusList] = useState(
    uniqueQuestionnairesTypeList.map((v) => {
      return {
        state: questionnaireName(v),
        check: false,
      };
    })
  );

  const renderTooltipEditar = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Editar tipos de cuestionario
    </Tooltip>
  );

  const renderTooltipTerceros = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Cuestionario de terceros
    </Tooltip>
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CH_ADMIN_USERS}`,
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        );

        setUsers({ data: response.data, isFetching: true });
        setUsersOrigin({ data: response.data, isFetching: true });
      } catch (exception) {
        console.error(exception);
        setUsers({ data: { data: [] }, isFetching: true });
      }
    };
    fetchUsers();

    const fetchQuestions = async () => {
      setQuestions({ data: questions.data, isFetching: true });
      try {
          const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questions`, {
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  // 'Access-Control-Allow-Origin': '*',
                  'Authorization': `Bearer ${UserService.getToken()}`
              }
          });
          setQuestions({ data: response.data, isFetching: false });
      } catch (exception) {
          setQuestions({ data: [], isFetching: false });
      }
    };
    fetchQuestions();

  }, []);

  const postUserQuestionnaires = () => {
    const valuesModifiedQuestionnaires = Object.values(
      modifiedQuestionnaires
    ).map((value) => value);

    //Ids cuestionarios checkeados
    const idModifiedQuestionnairesTrue = valuesModifiedQuestionnaires.reduce(
      (acc, value, index) => {
        if (value) {
          acc.push(index + 1);
        }
        return acc;
      },
      []
    );

    //Ids cuestionarios sin check
    const idModifiedQuestionnairesFalse = valuesModifiedQuestionnaires.reduce(
      (acc, value, index) => {
        if (!value) {
          acc.push(index + 1);
        }
        return acc;
      },
      []
    );

    addQuestionnaires({
      add: idModifiedQuestionnairesTrue,
      remove: idModifiedQuestionnairesFalse,
    });
  };

  //Llamada a endpoint para eliminar los cuestionarios no selccionados y actualizar el objeto correspondiente
  const removeQuestionnaires = async (props) => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_CH_ADMIN_USER}/${modalQuestionnaires.data.id}/remove/questionnaires`,
          props,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        )
        .then((response) => {
          let newObject = response.data.data;
          let newState = users.data?.data.map((item) => {
            if (item.id === modalQuestionnaires.data.id) {
              return newObject;
            }
            return item;
          });

          let newStateFiltered = usersOrigin.data?.data.map((item) => {
            if (item.id === modalQuestionnaires.data.id) {
              return newObject;
            }
            return item;
          });

          setUsers({ data: { data: newState }, isFetching: true });
          setUsersOrigin({
            data: { data: newStateFiltered },
            isFetching: true,
          });
        });
    } catch (exception) {
      console.error(exception);
    }
    setFetchQuestionnaires({ spinner: false, visible: false });
  };

  //Añadir los cuestionarios marcados para el usuario
  const addQuestionnaires = async (props) => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_CH_ADMIN_USER}/${modalQuestionnaires.data.id}/add/questionnaires`,
          props.add,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        )
        .then((response) => {
          removeQuestionnaires(props.remove);
        });
    } catch (exception) {
      console.error(exception);
    }
  };

  //setear terceros
  const updateThidPartyForm = async () => {
    setModalTerceros({
      view: modalTerceros.view,
      user: modalTerceros.user,
      thirdPartyForm: modalTerceros.thirdPartyForm,
      spinner: true
    })
    try {
      await axios
        .patch(
          `${process.env.REACT_APP_CH_ADMIN_USER}/${modalTerceros.user.id}`,
          { thirdPartyForm: modalTerceros.thirdPartyForm,
            externalQuestionsId: questionsIds
           },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        )
        .then((response) => {
          setUsers((prevState) => {
            const newDataArray = prevState.data.data.map((user) => {
              if (user.id === modalTerceros.user.id) {
                // Si encontramos un usuario con el ID deseado, lo actualizamos con newData
                return response.data.data;
              }
              return user; // Devolvemos el usuario sin cambios
            });

            return { data: { data: newDataArray }, isFetching: true }; // Actualizamos el estado
          });
        });
    } catch (exception) {
      console.error(exception);
    }
    setModalTerceros({
      view: modalTerceros.view,
      user: modalTerceros.user,
      thirdPartyForm: modalTerceros.thirdPartyForm,
      spinner: false
    })
  };

  //Funciones filtros

  const applyStatusFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      status: data,
    }));
  };

  const [filtros, setFiltros] = useState({
    status: [],
    name: "",
    id: "",
    email: "",
  });

  
  useEffect(() => {
    const handleInputChange = () => {
      let filteredData = usersOrigin.data?.data;

      if (filtros.status) {
        filteredData = OnFilterStatus(filtros.status, filteredData);
      }

      if (filtros.name) {
        filteredData = OnfilterName(filtros.name, filteredData);
      }

      if (filtros.id) {
        filteredData = OnFilterUserId(filtros.id, filteredData);
      }

      if (filtros.email) {
        filteredData = OnFilterEmail(filtros.email, filteredData);
      }

      setUsers({ data: { data: filteredData }, isFetching: true });
    };
    handleInputChange();
  }, [filtros]);

  const OnfilterName = (filter, filteredData) => {
    return filteredData.filter((v) =>
      (v.name + " " + v.lastName)?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterUserId = (filter, filteredData) => {
    return filteredData.filter((v) =>
      v.id.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterEmail = (filter, filteredData) => {
    return filteredData.filter((v) =>
      v.email?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterStatus = (data, filteredData) => {
    let states = data.filter((c) => c.check).map((c) => c.state);
    if (states.length > 0) {
      return filteredData.filter((obj) =>
        obj.questionnairesId.some((id) =>
          states.includes(questionnaireName(id))
        )
      );
    }
    return filteredData;
  };

  //Tabla con orden por columnas
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      let sortableItems = [...items];

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (!isNaN(valueA) || moment(valueA).isValid()) {
            if (valueA < valueB) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (
              valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (
              valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(users.data?.data);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleChange = (questionnaire, newValue) => {
    setModifiedQuestionnaires((prevObject) => ({
      ...prevObject,
      [questionnaire]: newValue,
    }));
  };

  const handleCloseModal = () => {
    setModalQuestionnaires({ view: false, data: undefined });
    setModalTerceros({
      view: false,
      user: {name:undefined, id: undefined},
      thirdPartyForm: undefined,
      spinner: false
    });
    setQuestionsIds();
    setFetchQuestionnaires({ spinner: false, visible: true });
  };

  const handleSubmitModal = () => {
    setFetchQuestionnaires({ spinner: true, visible: true });
    postUserQuestionnaires();
  };

  const toggleState = () => {
    setModalTerceros({
      view: modalTerceros.view,
      user: modalTerceros.user,
      thirdPartyForm: !modalTerceros.thirdPartyForm,
      spinner: modalTerceros.spinner
    })
  };

  const handleCheckboxChange = (e, questionId) => {
    const checked = e.target.checked;
    setQuestionsIds((prevQuestionIds) => {
        if (checked) {
            return [...prevQuestionIds, questionId];
        } else {
            return prevQuestionIds.filter((id) => id !== questionId);
        }
    });
};


  return (
    <>
      {!users.isFetching && <Spinner animation="border" variant="dark" />}

      {users.isFetching && users.data.data != undefined && (
        <>
          <Modal show={modalQuestionnaires.view}>
            <Modal.Header className="sec-green-background">
              <Modal.Title>Añadir o eliminar tipo de cuestionario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>Usuario: {modalQuestionnaires.data?.name}</p>
                <div className="mt-2">
                  <span className="font-semibold">Tipos de cuestionario:</span>
                  <ul>
                    <li>
                      <div className="flex justify-between">
                        <span>General</span>
                        <span>
                          <input
                            id="check-general-quest"
                            type="checkbox"
                            checked={modifiedQuestionnaires.general}
                            onChange={(e) =>
                              handleChange("general", e.target.checked)
                            }
                          />
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        <span>Agropecuario</span>
                        <span>
                          <input
                            id="check-agropecuario-quest"
                            checked={modifiedQuestionnaires.agropecuario}
                            type="checkbox"
                            onChange={(e) =>
                              handleChange("agropecuario", e.target.checked)
                            }
                          />
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        <span>Logística</span>
                        <span>
                          <input
                            id="check-logistica-quest"
                            checked={modifiedQuestionnaires.logistica}
                            type="checkbox"
                            onChange={(e) =>
                              handleChange("logistica", e.target.checked)
                            }
                          />
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        <span>Hostelería</span>
                        <span>
                          <input
                            id="check-hosteleria-quest"
                            checked={modifiedQuestionnaires.hosteleria}
                            type="checkbox"
                            onChange={(e) =>
                              handleChange("hosteleria", e.target.checked)
                            }
                          />
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Modal.Body>
            <div className="flex justify-center space-x-4 m-2">
              <button
                className="btn-sm btn-green"
                onClick={() => handleCloseModal()}
              >
                Cerrar
              </button>
              <button
                className={`btn-sm btn-green ${
                  !fetchQuestionnaires.visible && "invisible"
                }`}
                disabled={
                  (JSON.stringify(originalQuestionnaires) !==
                  JSON.stringify(modifiedQuestionnaires)
                    ? false
                    : true) || fetchQuestionnaires.spinner
                }
                onClick={() => handleSubmitModal()}
              >
                {fetchQuestionnaires.spinner ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Modal>

          <Modal show={modalTerceros.view}>
            <Modal.Header className="sec-green-background">
              <Modal.Title>Gestionar cuestionario de terceros</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>Usuario: {modalTerceros.user.name}</p>
                <div className="mt-2">
                  <div className="flex items-center">
                    <div
                      onClick={toggleState}
                      className={`relative w-10 h-6 rounded-full cursor-pointer transition-colors ${
                        modalTerceros.thirdPartyForm ? 'bg-green-500' : 'bg-red-500'
                      }`} 
                    >
                      <div
                        className={`absolute w-6 h-6 bg-white rounded-full transition-transform transform `}
                        style={{transform: modalTerceros.thirdPartyForm ? 'translateX(1rem)' : 'translateX(0rem)', borderWidth:'1.5px', borderColor: modalTerceros.thirdPartyForm ? 'rgb(34 197 94)' : 'rgb(244 63 94)'}}
                      ></div>
                    </div>
                    <span className="ml-2 font-semibold">Cuestionario de terceros</span>

                  </div>
                  {questions.isFetching === false && questions?.data?.data?.length > 0 &&
                    <div className={`p-4`}>
                      {questions.data?.data?.map((question) => (
                        <div key={question.id} className=" text-sm form-check ">
                          <input
                            className="form-check-input"
                            disabled={!modalTerceros.thirdPartyForm}
                            type="checkbox"
                            value={question.id}
                            id={`questionCheckbox_${question.id}`}
                            defaultChecked={questionsIds?.includes(question.id)}
                            onChange={(e) => handleCheckboxChange(e, question.id)}
                            />
                          <label className="form-check-label" for={`questionCheckbox_${question.id}`}>
                            {question.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  }

                </div>
              </div>
            </Modal.Body>
            <div className="flex justify-center space-x-4 m-2">
              <button
                className="btn-sm btn-green"
                onClick={() => handleCloseModal()}
              >
                Cerrar
              </button>
              <button
                className={`btn-sm btn-green`}
                onClick={() => updateThidPartyForm()}
                disabled={(modalTerceros.thirdPartyForm && questionsIds.length == 0)}
              >
                {modalTerceros.spinner ? <Spinner animation="border" variant="light" /> : "Save"}
              </button>
            </div>
          </Modal>

          <div className="p-3 border mb-3">
            <div className="mb-2 font-bold">
              <h2 className="font-bold">Filtros</h2>
            </div>
            <div className="grid gap-4 md:grid-cols-3">
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="default"
                >
                  Id usuario
                </label>
                <Input
                  value={filtros.id}
                  className="form-input w-full rounded-lg "
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      id: e.target.value,
                    }))
                  }
                  placeholder="Busca por id usuario"
                  type="text"
                />
              </div>

              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="default"
                >
                  Nombre usuario
                </label>
                <Input
                  value={filtros.name}
                  className="form-input w-full rounded-lg "
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      name: e.target.value,
                    }))
                  }
                  placeholder="Busca por nombre"
                  type="text"
                />
              </div>

              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="default"
                >
                  Email
                </label>
                <Input
                  value={filtros.email}
                  className="form-input w-full rounded-lg "
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      email: e.target.value,
                    }))
                  }
                  placeholder="Busca por email"
                  type="text"
                />
              </div>

              <div>
                <div className="w-full rounded-lg ">
                  <FilterButton
                    statusPaymentUniq={uniqueQuestionnairesTypeList}
                    stateCheck={statusList}
                    applyStatePaymentFilter={applyStatusFilter}
                    nombreFiltro={"Tipo de cuestionario"}
                  />
                </div>
                <label className="block text-sm font-medium mb-1">
                  {filtros.status.filter((x) => x.check).length > 0 && (
                    <span className="ml-2">
                      Estado:{" "}
                      {filtros.status
                        .filter((x) => x.check)
                        .map((x) => x.state)
                        .join(", ")}
                    </span>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="text-sm font-medium text-right">
            Resultados: {users.data?.data.length}
          </div>
          {users.data?.data.length == 0 ? (
            <div className="flex justify-center">
              Cargando usuarios&nbsp;
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div
              className="overflow-x-auto rounded-lg"
              style={{ height: "800px", overflowY: "scroll" }}
            >
              <table className="table-bordered table-striped  text-md  table-auto w-full divide-y divide-gray-200">
                <thead className="text-center sticky top-0 sec-green-background z-20 text-white">
                  <tr>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("id")}
                        className={`${getClassNamesFor("id")} `}
                      >
                        Id
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("name")}
                        className={`${getClassNamesFor("name")} `}
                      >
                        Nombre
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("email")}
                        className={`${getClassNamesFor("email")} `}
                      >
                        Email
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("questionnairesId")}
                        className={`${getClassNamesFor("questionnairesId")} `}
                      >
                        Tipo de cuestionarios
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("thirdPartyForm")}
                        className={`${getClassNamesFor("thirdPartyForm")} `}
                      >
                        Terceros
                      </button>
                    </th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {items.map((p, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>{p.id}</td>
                          <td>{p.name + " " + p.lastName}</td>
                          <td>{p.email}</td>
                          <td>
                            {p.questionnairesId.map((v) =>
                              questionnaireName(v)
                            )}
                          </td>
                          <td>
                            {p.thirdPartyForm ? "Si" : "No" }
                          </td>
                          <td>
                            <div className="flex justify-center items-center">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipEditar}
                              >
                                <button
                                  onClick={() =>
                                    setModalQuestionnaires({
                                      view: true,
                                      data: p,
                                    })
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-clipboard-text"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                     stroke="var(--airco2-green-color)"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                    <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                    <path d="M9 12h6" />
                                    <path d="M9 16h6" />
                                  </svg>
                                </button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipTerceros}>
                                <button
                                  onClick={() =>{
                                    setModalTerceros({
                                      view: true,
                                      user: {name:p.name, id: p.id},
                                      thirdPartyForm: p.thirdPartyForm,
                                      spinner: false
                                    }),
                                    setQuestionsIds(p.externalQuestionsId.map(x => x))
                                  }
                                  }
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list-numbers" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M11 6h9" />
                                    <path d="M11 12h9" />
                                    <path d="M12 18h8" />
                                    <path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4" />
                                    <path d="M6 10v-6l-2 2" />
                                  </svg>
                                </button>
                              </OverlayTrigger>
                            </div>  
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default FootprintUserList;
