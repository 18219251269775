import React from 'react'

import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import circle from '../../images/circle.svg'
import { formatThousands } from '../intranet/utils/Utils'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../context/createcontext";

export default function MonteCeoInfo() {
  const iddetail = 87
  const [productsid, setProductsid] = useState()
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PRODUCT_ID}/${iddetail}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // Authorization: `Bearer ${UserService.getToken()}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            setProductsid(404);
          } else {
            // For other error statuses, handle them accordingly
            console.error('Request failed with status ' + response.status);
          }
          throw new Error('Request failed with status ' + response.status);
        }
        return response.json();
      })
      .then(data => {
        setProductsid(data);

      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, []);


  const picSatelital = productsid?.productImageDtoList?.find(x => x.satelital);
  const pathImagenSatelital = picSatelital ? picSatelital.path : null;

 

  return (

    productsid !== undefined ?
      (<div className="mt-1 md:mt-4 lg:mt-1 flex flex-col lg:flex-row lg:space-x-8 xl:space-x-16 flex justify-center">


        <div className=' m-4'>

          <div className="">
            <div className='text-center pb-3 font-bold text-2xl'>Conoce Monte CEO</div>


            <div className='img-monteCeo'>


              <img className="rounded-sm" src={pathImagenSatelital} width="100%" alt="Product" style={{ maxHeight: '100%', objectFit: 'contain' }} />

            </div>





            <div className='' >


              <ul className='pl-2 flex justify-end ' >
                <li className='flex gap-1' >
                  <img alt='circulo-icono' src={circle} width={'16px'} ></img>
                  <a href={`https://maps.google.com/?q=${productsid.latitude},${productsid.longitude}`} aria-label='irige a googleMaps' target='_blank' rel='noopener noreferrer' className="text-airco2-general">
                    <p>{productsid.city}, {productsid.state}</p>
                  </a>
                </li>
              </ul>

              {productsid.active && (productsid.stockCredits !== null && productsid.stockCredits > 0 && productsid.stockCredits !== "")
                && <>
                  <ul className=' grow md:flex pl-2 pt-3 pb-3  flex-wrap justify-between'>

                    <li className=' text-airco2-principal'>  <span className='font-bold'>{formatThousands(productsid.price)}</span>€/ tonelada CO₂ </li>


                    <li className=' text-airco2-secondary'>
                      {productsid.reservedCredits > 0 && <>
                        <span className='font-bold'> {formatThousands((productsid.stockCredits - productsid.reservedCredits))}</span>{t("detail.tdisponibles")} /</>}
                      <span className='font-bold'>{productsid.stockCredits}</span>t. de stock</li>
                    <li className=''> Abosorción anual  <span className='font-semibold'>{formatThousands(productsid.totalCredits)}t</span> </li>

                  </ul>

                </>
              }



            </div>


          </div>


          {/* Product content */}
          <div>

            <p className="mb-2 pt-2">
              {productsid.summary}
            </p>
            <p className="mb-4 pt-2"
              dangerouslySetInnerHTML={{ __html: productsid.description }} />

            <Row>

              <ul className=" grow md:flex flex-wrap justify-between">


                {productsid.forestType != null && productsid.forestType.length !== 0 ?
                  <li className='flex inline mb-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                      <line x1="15" y1="21" x2="15" y2="18" />
                      <path d="M8 13l-2 -2" />
                      <path d="M8 12l2 -2" />
                      <path d="M8 21v-13" />
                      <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                    </svg>
                    <span className='ml-2'>{productsid.forestType} </span>
                  </li>
                  :
                  ""

                }

                <li className='flex inline mb-2'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dimensions" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none"   >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 5h11" />
                    <path d="M12 7l2 -2l-2 -2" />
                    <path d="M5 3l-2 2l2 2" />
                    <path d="M19 10v11" />
                    <path d="M17 19l2 2l2 -2" />
                    <path d="M21 12l-2 -2l-2 2" />
                    <rect x="3" y="10" width="11" height="11" rx="2" />
                  </svg>
                  <span className='ml-2'>{formatThousands(productsid.size)} hectáreas</span>
                </li>



                <li className='flex inline mb-2'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-stack-3" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2l-8 4l8 4l8 -4l-8 -4" />
                    <path d="M4 10l8 4l8 -4" />
                    <path d="M4 18l8 4l8 -4" />
                    <path d="M4 14l8 4l8 -4" />
                  </svg>
                  <span className='ml-2'> Verificado por  {productsid.verifiedBy}</span>
                </li>




              </ul>
            </Row>

            {/* 
          {(productsid.sustainabilityGoalDtoList.length !== 0) &&

            <div className="mt-6">
              <h2 className='font-bold text-xl'>{t("detail.buenasPracticas")}</h2>
              <ul className=" mt-3 grid grid-cols-2">
                {productsid.sustainabilityGoalDtoList.map((y, index) =>
                  <li key={index} className=' hidden sm:block list-objetivos-sost  flex-column py-1' >
                    <h3 className='flex items-center mb-4 gap-2'>
                      <img alt='iconosostenibilidad' src={y.image} width={'50px'} />
                      <span className='font-bold text-lg'> {y.name}</span>
                    </h3>
                    <div className='flex' >
                      <span className=''>  {y.desc} </span>
                    </div>
                  </li>
                )}
              </ul>
              <ul className="mt-3">
                <li className=' block sm:hidden py-1 list-objetivos-sost-mvl' >
                  {productsid.sustainabilityGoalDtoList.map((y, index) =>

                    <span key={index}>

                      <h3 className='flex gap-2 items-center mb-1 mt-3'>
                        <img alt='iconosostenibilidad' src={y.image} width={'50px'} />
                        <span className='font-bold text-lg'> {y.name}</span>
                      </h3>
                      <span className=''>  {y.desc} </span>
                    </span>

                  )}
                </li>
              </ul>
            </div>

          } */}
          </div>

        </div>




      </div>)

      :

      <Spinner animation="border" variant="dark" />



  )
}
