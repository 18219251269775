import React, { useState,useContext, useEffect} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import { IdiomContext } from "../../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import UserService from "../../../../services/UserService";

import axios from 'axios';
import FootprintCalculationAdmin from './FootprintCalculationAdmin';
import FootprintSubscriptionAdmin from './FootprintSubscriptionAdmin';
import FootprintUserAdmin from './FootprintUsersAdmin';



function FootprintPlaceAdmin() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [pestaña, setPestaña]= useState("calculos");
 
 


  return (
      <div className=" sec-neutro-background flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

              {/* Page header */}
              <div className="mb-2">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Calculadora Huella</h1>
              </div>
             
                
                  
               
                {/* Content */}
                <div className="bg-white rounded-sm mb-8">
                  <div className="flex flex-col xxl:flex-row xxl:-mr-px">
  
                    {/* <MarketplaceSidebar /> */}
                    <div className="sec-neutro-background flex flex-nowrap overflow-x-auto no-scrollbar xxl:block xxl:overflow-auto px-3 py-2 border-b xxl:border-b-0 xxl:border-r border-gray-200 min-w-60 xxl:space-y-3">
                      {/* Group 1 */}
                      <div>
                        {/* <div className="text-xs font-semibold text-gray-400 uppercase mb-3">{t("account.configuracion")}</div> */}
                        <ul className="flex flex-nowrap xxl:block mr-3 xxl:mr-0 space-x-2">
                          <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                            <button onClick={()=>setPestaña("calculos")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pestaña.includes('calculos') && 'sec-green-background'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pestaña.includes('calculos') && 'text-white'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M4 3m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                    <path d="M8 7m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" />
                                    <path d="M8 14l0 .01" />
                                    <path d="M12 14l0 .01" />
                                    <path d="M16 14l0 .01" />
                                    <path d="M8 17l0 .01" />
                                    <path d="M12 17l0 .01" />
                                    <path d="M16 17l0 .01" />
                                </svg>
                                <span className={`text-md ml-2 font-bold ${pestaña.includes('calculos') ? 'text-white' : 'hover:text-gray-700'}`}>Cálculos</span>
                            </button>
                          </li>
                          <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                            <button onClick={()=>setPestaña("suscripciones")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pestaña.includes('suscripciones') && 'sec-green-background'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pestaña.includes('suscripciones') && 'text-white'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M11.453 8.056c0 -.623 .518 -.979 1.442 -.979c1.69 0 3.41 .343 4.605 .923l.5 -4c-.948 -.449 -2.82 -1 -5.5 -1c-1.895 0 -3.373 .087 -4.5 1c-1.172 .956 -2 2.33 -2 4c0 3.03 1.958 4.906 5 6c1.961 .69 3 .743 3 1.5c0 .735 -.851 1.5 -2 1.5c-1.423 0 -3.963 -.609 -5.5 -1.5l-.5 4c1.321 .734 3.474 1.5 6 1.5c2 0 3.957 -.468 5.084 -1.36c1.263 -.979 1.916 -2.268 1.916 -4.14c0 -3.096 -1.915 -4.547 -5 -5.637c-1.646 -.605 -2.544 -1.07 -2.544 -1.807z" />
                                </svg>
                                <span className={`text-md ml-2 font-bold ${pestaña.includes('suscripciones') ? 'text-white' : 'hover:text-gray-700'}`}>Suscripciones</span>
                            </button>
                          </li>
                          <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                            <button onClick={()=>setPestaña("users")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pestaña.includes('users') && 'sec-green-background'}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pestaña.includes('users') && 'text-white'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                              </svg>
                              <span className={`text-md ml-2 font-bold ${pestaña.includes('users') ? 'text-white' : 'hover:text-gray-700'}`}>Usuarios</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>    
                    {
                      pestaña == "calculos" ?
                    <FootprintCalculationAdmin></FootprintCalculationAdmin>
                      :
                      pestaña == "suscripciones" ?
                     <FootprintSubscriptionAdmin></FootprintSubscriptionAdmin>
                     :
                     <FootprintUserAdmin></FootprintUserAdmin>
                    }
                  </div>
  
                </div>
               
              
            </div>
          </main>

        </div>

      </div>
  );
}

export default FootprintPlaceAdmin;