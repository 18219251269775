import React, { useState } from 'react'
import Header from '../../partials/Header'
import Footer from '../../partials/Footer'
import Banner from '../../quiz/Banner'
import MonteCeoInfo from './MonteCeoInfo'



export default function landingTemplate({
    nombre,
    cartel,
    tipo,
    infoExtra,
    subtitle1,
    subtitle2,
    tons

}) {

    const color = 'RGBA(135, 135, 135, 0.5)'
    const claseI = 'p-2 rounded-lg flex items-center'
    const claseT = 'p-2 rounded-lg flex items-center w-full'
    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState();
    const [consent, setConsent] = useState(false);
    const [event, setEvent] = useState("");
    const [hubspotResponse, setHubspotResponse] = useState("");
    const [formError, setFormError] = useState("");
    const [text, setText] = useState("")


    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!name || !lastName || !email || !phone || !event || !company) {
            handleError("Debes cubrir todos los campos obligatorios.")
            return;


        }

        if (!consent) {
            handleError("Debes marcar la casilla que nos permita almacenar tus datos personales.")
            return;

        }


        setHubspotResponse(await submit_landing_hubspot_form(name, lastName, email, phone, company, event));
    }

    const handleOnchange = () => {
        setFormError("");

    }


    const handleError = (mensaje) => {
        document.getElementById('main')?.scrollIntoView({ behavior: 'smooth' });
        setFormError(mensaje);
    }

    const submit_landing_hubspot_form = async (name, lastName, email, phone, company, event) => {
        const portalId = '25435066';
        const formGuid = '567afcca-d37e-4e6e-92b3-414f58a34965';
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`
        const data = {
            fields: [

                {
                    name: 'firstname',
                    value: name
                },
                {
                    name: 'lastname',
                    value: lastName
                },
                {
                    name: 'email',
                    value: email
                }
                ,
                {
                    name: 'phone',
                    value: phone
                }
                ,
                {
                    name: 'evento',
                    value: event
                },
                {
                    name: 'company',
                    value: company
                },


            ]
        };



        return fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((responseJson) => {
                setText('Gracias, tu solicitud ha sido registrada. Nos pondremos en contacto contigo lo antes posible')
                return responseJson;


            });

    }

    return (
        <main>
            <Header></Header>
            <div style={{ minHeight: 'calc(100vh - 130px)', paddingTop: '88px' }} >
                <div className='flex flex-wrap h-full'>
                    <div className={`banner-landing-${nombre} text-airco2-landing  w-full lg:w-1/2`}
                        style={{
                            position: 'relative',
                        }}>
                        <div className='container flex flex-column items-center'>
                            <div className='  hidden md:flex'>
                                <img style={{
                                    height: '200px',
                                    position: 'absolute',
                                    bottom: "0",
                                    left: "-30px",
                                }} src={cartel}>
                                </img>
                            </div>

                            <div className="w-full lg:w-50">
                                <div className="max-w-3xl mx-auto text-center pt-4 " >
                                    <h3 className="text-white text-landing-title" data-aos="fade-up" data-aos-delay="100">{tipo} {nombre}
                                    </h3>
                                    <p className="text-xl mt-4 text-white" data-aos="fade-up" data-aos-delay="200">
                                        {subtitle1}</p>
                                    <p className="text-xl mb-8 text-white" data-aos="fade-up" data-aos-delay="200">
                                        {subtitle2}</p>
                                    <div className=" mt-6 mb-6" data-aos="fade-in" data-aos-delay="400">
                                        <a href="#formulario" className="btn sec-green-background text-airco2-filter-content  whitespace-nowrap w-full lg:w-1/2 " >
                                            <span style={{ color: 'white', textDecoration: 'none' }}>Me interesa</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='textos pb-2 w-full lg:w-1/2' data-aos-id-blocks >
                                <div className='flex gap-1  mb-4'>
                                    <div className={claseI} style={{ backgroundColor: color }} data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plant-2" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M2 9a10 10 0 1 0 20 0" />
                                            <path d="M12 19a10 10 0 0 1 10 -10" />
                                            <path d="M2 9a10 10 0 0 1 10 10" />
                                            <path d="M12 4a9.7 9.7 0 0 1 2.99 7.5" />
                                            <path d="M9.01 11.5a9.7 9.7 0 0 1 2.99 -7.5" />
                                        </svg>
                                    </div>
                                    <div className={claseT} style={{ backgroundColor: color }} data-aos="fade-right" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" >
                                        {tons !== false && <p className=''>Avanza hacia la neutralidad en carbono aportando  {tons} de tu huella al {tipo?.toLowerCase()} {nombre}</p>}
                                        {!tons && <p className=''>Ayuda a avanzar hacia la neutralidad en carbono neutralizando tu huella con {tipo?.toLowerCase()} {nombre}</p>}
                                    </div>
                                </div>
                                <div className='flex gap-1  mb-4'>
                                    <div className={claseI} style={{ backgroundColor: color }} data-aos="fade-left" data-aos-delay="400" data-aos-anchor="[data-aos-id-blocks]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                                            <path d="M15 21l0 -3" />
                                            <path d="M8 13l-2 -2" />
                                            <path d="M8 12l2 -2" />
                                            <path d="M8 21v-13" />
                                            <path d="M5.824 16a3 3 0 0 1 -2.743 -3.69a3 3 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a3 3 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                                        </svg>
                                    </div>
                                    <div className={claseT} style={{ backgroundColor: color }} data-aos="fade-right" data-aos-delay="400" data-aos-anchor="[data-aos-id-blocks]">
                                        <p>Un coste simbólico. Un gran impacto </p>
                                    </div>
                                </div>
                                <div className='flex gap-1  mb-4'>
                                    <div className={claseI} style={{ backgroundColor: color }} data-aos="fade-left" data-aos-delay="500" data-aos-anchor="[data-aos-id-blocks]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-world-heart" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M21 12a9 9 0 1 0 -9.679 8.974" />
                                            <path d="M3.6 9h16.8" />
                                            <path d="M3.6 15h6.9" />
                                            <path d="M11.5 3a17 17 0 0 0 0 18" />
                                            <path d="M12.5 3a16.983 16.983 0 0 1 2.556 8.136" />
                                            <path d="M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z" />
                                        </svg>
                                    </div>
                                    <div className={claseT} style={{ backgroundColor: color }} data-aos="fade-right" data-aos-delay="500" data-aos-anchor="[data-aos-id-blocks]">
                                        <p>Sé parte de algo que nos posicione como referentes, con una acción sostenible y con retorno</p>
                                    </div>
                                </div>
                                <div className='flex gap-1  mb-4'>
                                    <div className={claseI} style={{ backgroundColor: color }} data-aos="fade-left" data-aos-delay="600" data-aos-anchor="[data-aos-id-blocks]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users-group" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                            <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1" />
                                            <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                            <path d="M17 10h2a2 2 0 0 1 2 2v1" />
                                            <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                            <path d="M3 13v-1a2 2 0 0 1 2 -2h2" />
                                        </svg>
                                    </div>
                                    <div className={claseT} style={{ backgroundColor: color }} data-aos="fade-right" data-aos-delay="600" data-aos-anchor="[data-aos-id-blocks]">
                                        <p>Con un poco de cada uno, haremos mucho ruido</p>
                                    </div>
                                </div>
                                <div className=' flex grow md:hidden justify-center'>
                                    <img style={{ height: '300px', position: 'relative', bottom: '-8px' }} src={cartel}></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        formError != "" &&
                        <div className='mb-3'><Banner type="error" children={formError} open setOpen={true} ></Banner></div>
                    }
                    {
                        (hubspotResponse === "") &&
                            infoExtra === true ?
                            <div className=' w-full lg:w-1/2' data-aos="fade-up" data-aos-delay="100">
                                <MonteCeoInfo></MonteCeoInfo>
                            </div>
                            :
                            <div className='registro container flex items-center justify-center  w-full lg:w-1/2'>
                                <div className='' id='formulario'>
                                    <p className="text-lg mb-8 mt-4 text-airco2-principal font-semibold text-center" data-aos="fade-up"
                                        data-aos-delay="200">{nombre} da el primer paso neutralizando la huella de carbono de este evento en su nuevo bosque.</p>
                                    {tons !== false &&
                                        <p className="text-xl mb-8 mt-4 text-airco2-principal font-semibold text-center" data-aos="fade-up"
                                            data-aos-delay="200">Solicita tus {tons} y nos pondremos en contacto contigo lo antes posible</p>
                                    }
                                    {!tons &&
                                        <p className="text-xl mb-8 mt-4 text-airco2-principal font-semibold text-center" data-aos="fade-up"
                                            data-aos-delay="200">Cubre este formulario y nos pondremos en contacto contigo lo antes posible</p>
                                    }
                                    <div>
                                    </div>
                                    <div className='text-airco2-general p-3' data-aos="fade-up"
                                        data-aos-delay="400">
                                        <form onSubmit={handleSubmit}>
                                            <div className='flex flex-wrap NameLastname '>
                                                <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1'>
                                                    <label className="block text-sm font-medium mb-1" htmlFor="name">Nombre <span className="text-red-500">*</span></label>
                                                    <input id="name" className="form-user-data w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setName(e.target.value), handleOnchange() }} />
                                                </div>
                                                <div className="w-full sm:w-1/2 pr-1 mt-1 mb-1">
                                                    <label className="block text-sm font-medium mb-1" htmlFor="lastName">Apellidos <span className="text-red-500">*</span></label>
                                                    <input id="lastName" className="form-user-data w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setLastName(e.target.value), handleOnchange() }} />
                                                </div>
                                            </div>
                                            <div className='flex flex-wrap phoneEmail  '>
                                                <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1 '>
                                                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-500">*</span></label>
                                                    <input id="email" className=" w-full bg-white form-user-data" type="email" defaultValue={''} required onChange={(e) => { setEmail(e.target.value), handleOnchange() }} />
                                                </div>
                                                <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1'>
                                                    <label className="block text-sm font-medium mb-1" htmlFor="phone">Teléfono<span className="text-red-500">*</span></label>
                                                    <input id="phone" className=" w-full bg-white form-user-data" type="tel" pattern="[0-9]{7}|[0-9]{9}" defaultValue={''} required onChange={(e) => { setPhone(e.target.value), handleOnchange() }} />
                                                </div>
                                            </div>
                                            <div className='flex flex-wrap companyEvent '>
                                                <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1 '>
                                                    <label className="block text-sm font-medium mb-1" htmlFor="company">Empresa <span className="text-red-500">*</span></label>
                                                    <input id="company" className=" w-full bg-white form-user-data" type="text" defaultValue={''} required onChange={(e) => { setCompany(e.target.value), handleOnchange() }} />
                                                </div>
                                                <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1'>
                                                    <label className="block text-sm font-medium mb-1" htmlFor="event">Nombre del evento <span className="text-red-500">*</span></label>
                                                    <input id="event" className=" w-full bg-white form-user-data" type="text" defaultValue={''} required onChange={(e) => { setEvent(e.target.value), handleOnchange() }} />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="flex items-center font-medium">
                                                    <input type="checkbox" className="form-checkbox" required onChange={e => { setConsent(e.target.checked), handleOnchange() }} />
                                                    <span className="text-sm ml-2">He leído y acepto las <a href="/terminos">condiciones legales y política de privacidad</a><span className="text-red-500" >*</span></span>
                                                </label>
                                            </div>
                                            <div className=" mt-6">
                                                <button className="btn sec-green-background text-airco2-filter-content  whitespace-nowrap w-full" type="submit" >
                                                    Enviar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                    }
                    {
                        (hubspotResponse !== "") &&
                        <div className='registro container flex-col mb-4  w-full lg:w-1/2' >
                            <p className="text-3xl mb-8 mt-4 text-airco2-principal font-semibold text-center" data-aos="fade-up"
                                data-aos-delay="200">{text}</p>
                            <button className="btn sec-green-background text-white  whitespace-nowrap w-full" onClick={() => (window.location.href = "/")} >
                                Visita nuestra Web
                            </button>
                        </div>
                    }
                </div >
            </div>

            <Footer></Footer>

        </main>
    )
}
