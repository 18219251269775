
import React, {useContext } from 'react';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import mapa from '../../images/mapa.webp';
import transaccion from '../../images/transaccion.webp'
import sello from '../../images/sello.webp'
import arbol3d from '../../images/arbol3d.webp'
import bosque3d from '../../images/bosque3d.webp'
import arbol1 from '../../images/arbol13d.webp'
import arbol2 from '../../images/arbol23d.webp'
import arbol3 from '../../images/arbol33d.webp'
import airco2 from '../../images/airco2_verde.jpeg'
import flecha1 from '../../images/flecha1.png'
import flecha2 from '../../images/flechalado2.png'
import co21 from '../../images/co2.png'
import huerta from '../../images/huerta.webp'




function Stats() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="text48 sec-blue-text mb-4"  data-aos="zoom-y-out">{t("weabout.cambiamos")} </h2>
            <p className="text28 sec-green-text"  data-aos="zoom-y-out">{t("weabout.cambiarmundo")}</p>
          </div>

          {/* World illustration */}
          <div className='world'>
            <div className="flex flex-col items-center pt-16 pb-12 md:pt-20 md:pb-16 w-100 md:w-25">
              <div className="relative">
                {/* Halo effect */}
                <svg className="absolute inset-0 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none" width="800" height="800" viewBox="0 0 800 800" style={{ maxWidth: '200%' }} xmlns="http://www.w3.org/2000/svg">
                  <g className="fill-current sec-green-text opacity-75">
                    <circle className="pulse" cx="400" cy="400" r="200" />
                    <circle className="pulse pulse-1" cx="400" cy="400" r="200" />
                    <circle className="pulse pulse-2" cx="400" cy="400" r="200" />
                  </g>
                </svg>
                {/* White box */}
                {/* <svg className="absolute w-32 h-auto rounded-full shadow-xl" viewBox="0 0 128 48" style={{ width: '32%', top: '20%', right: '-16%' }} xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-white" width="128" height="48" rx="24" />
             
              </svg> */}

                {/* Globe image */}

                <img className="relative rounded-full shadow-xl" src={mapa} width="500" height="500" alt="Spain" />
                {/* Static dots */}
                {/* <svg className="absolute top-0 w-full h-auto" viewBox="0 0 400 400" style={{ right: '80%' }} xmlns="http://www.w3.org/2000/svg">
                <defs>
                
                
                  <linearGradient x1="80%" y1="0%" x2="50%" y2="100%" id="world-ill-f">
                    <stop stopColor="#0070F4" stopOpacity="0" offset="0%" />
                    <stop stopColor="#0070F4" stopOpacity=".64" offset="52.449%" />
                    <stop stopColor="#0070F4" stopOpacity="0" offset="100%" />
                  </linearGradient>
                </defs>
                <g transform="translate(0 -.818)" fill="none" fillRule="evenodd">
                  <use fill="#000" filter="url(#world-ill-a)" xlinkHref="#world-ill-b" />
                  <use fill="#8DD4BD" xlinkHref="#world-ill-b" />
                  <use fill="#000" filter="url(#world-ill-c)" xlinkHref="#world-ill-d" />
                  <use fill="8DD4BD" xlinkHref="#world-ill-d" />
                  <ellipse fillOpacity=".32" fill="#8DD4BD" cx="293" cy="142.303" rx="8" ry="7.788" />
                  <ellipse fillOpacity=".64" fill="#8DD4BD" cx="250" cy="187.083" rx="6" ry="5.841" />
                  <ellipse fillOpacity=".64" fill="#8DD4BD" cx="13" cy="233.811" rx="2" ry="1.947" />
                 
                  <path d="M258 256.2l87-29.204" stroke="#666" strokeWidth="2" opacity=".16" filter="url(#world-ill-e)" />
                  <path d="M258 251.333c111.333-40.237 141-75.282 89-105.136M280 103.364c66.667 4.543 104.667 32.45 114 83.72" stroke="url(#world-ill-f)" strokeWidth="3" strokeDasharray="6" />
                </g>
              </svg> */}

                {/* <svg className="absolute top-0 w-full h-auto" viewBox="20 20 600 500" style={{ right: '20%' }}>

                  <line strokeDasharray="5, 10" style={{ stroke: '#f00', strokeWidth: '2' }} x1="85" y1="180" x2="100" y2="35" x3='75'></line>


                </svg>
                 */}
                 <img alt='flecha1' className="flecha1" style={{ right:'98%', width:'14%', opacity:'0.6' }} src={flecha1}></img>
                 <img alt='flecha2' className="flecha2" style={{ right:'98%', width:'18%', opacity:'0.6' }} src={flecha2}></img>
               


                {/* Dynamic dots */}

                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" viewBox="0 0 48 48" style={{ width: '8%', top: '45%', left: '50%' }} src={arbol3}></img>
                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" viewBox="0 0 48 48" style={{ width: '8%', top: '5%', left: '50%' }} src={arbol3}></img>
                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" viewBox="0 0 48 48" style={{ width: '8%', top: '15%', left: '70%' }} src={arbol3}></img>

                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '8%', top: '4%', left: '28%' }} src={arbol1}></img>
                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '8%', top: '12%', left: '60%' }} src={arbol1}></img>
                <img  alt="arboles-mapa"className="absolute max-w-full" width="48" height="48" style={{ width: '8%', top: '40%', left: '60%' }} src={arbol1}></img>

                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '8%', top: '20%', left: '55%' }} src={arbol2}></img>
                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '8%', top: '5%', left: '40%' }} src={arbol2}></img>
                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '8%', top: '58%', left: '38%' }} src={arbol2}></img>

                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '12%', top: '30%', left: '40%' }} src={arbol3d}></img>
                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '12%', top: '50%', left: '35%' }} src={arbol3d}></img>
                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '12%', top: '60%', left: '51%' }} src={arbol3d}></img>

                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '12%', top: '36%', left: '81%' }} src={bosque3d}></img>
                <img alt="arboles-mapa" className="absolute max-w-full" width="48" height="48" style={{ width: '12%', top: '82%', left: '20%' }} src={bosque3d}></img>


                {/* Avatars */}
                <div className=" hidden md:flex md:grow absolute text10 max-w-full transform animate-float sec-blue-background rounded-pill text-center" style={{ height: '15%', width: '81.75%', top: '-11%', left: '-23.5%' }}>
                  <p className='mt-1 sec-white-text'>{t("weabout.mganadinero")}</p>
                </div>
               
                <img alt='icono-huerta' className='flex grow md:hidden md:hidden absolute  max-w-full transform animate-float'style={{ height: '18%', width: '18%', top: '-20%', left: '-14%'}} src={huerta}></img>
              


                
                < div className=" hidden md:flex md:grow absolute text10 max-w-full transform animate-float sec-blue-background rounded-pill text-center animation-delay-1000" style={{ height: '13%', width: '66%', bottom: '15%', right: '-18%' }}>
                  <p className='mt-1 sec-white-text'>{t("weabout.mempresa")}</p>
                </div>
                <img alt='icono-co2' className='flex grow md:hidden md:hidden absolute  max-w-full transform animate-float'style={{ height: '18%', width: '18%',bottom: '15%', right: '18%'}} src={co21}></img>

                {/* White box with AircoLogo */}
                <svg className="absolute w-32 h-auto rounded-full shadow-xl" viewBox="0 0 128 48" style={{ width: '32%', top: '35%', left: '-18%' }} xmlns="http://www.w3.org/2000/svg">
                  <rect className="fill-current text-white" width="128" height="48" rx="24" />

                </svg>
                <img src={airco2} className="absolute w-32 h-auto rounded-full  shadow-xl" viewBox="0 0 128 48" style={{ width: '30%', top: '37%', left: '-17%' }} ></img>


                {/* Green icon V  */}

                <img alt="icono-transaccion" className="absolute w-32 h-auto rounded-full shadow-xl" viewBox="0 0 128 48" style={{ width: '14%', top: '7%', right: '-12%' }} src={transaccion}></img>
                {/* Green icon circulo */}
                <img alt='icono-sello' className='absolute max-w-full w-16 h-auto rounded-full shadow-xl' viewBox="0 0 64 64" style={{ width: '16%', bottom: '12%', left: '-17%' }} src={sello}></img>

              </div>
            </div>
          </div>
          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:gap-16 md:grid-cols-2 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-5xl pt-8 md:pt-12">


            {/* 1st item */}
            {/* <div className="text-center">
              <div className="h3 mb-1">2021</div>
              <div className="text-gray-600">{t("weabout.año")}</div>
            </div> */}

            {/* 2nd item */}
            <div className="text-center "  data-aos="zoom-y-out">
              <div className="sec-blue-text text30 mb-1" >100M </div>
              <div className="sec-green-text text14" >{t("weabout.transacc")}</div>
            </div>

            {/* 3rd item */}
            <div className="text-center"  data-aos="zoom-y-out">
              <div className="sec-blue-text text30 mb-1" >3M</div>
              <div className="sec-green-text text14" >{t("weabout.hasanalizadas")}</div>
            </div>

            {/* 4th item */}
            <div className="text-center"  data-aos="zoom-y-out">
              <div className="sec-blue-text text30 mb-1" >15M</div>
              <div className="sec-green-text text14" >{t("weabout.hasmercado")}</div>
            </div>

            {/* 5th item */}
            <div className="text-center"  data-aos="zoom-y-out">
              <div className="sec-blue-text text30 mb-1" >3M</div>
              <div className="sec-green-text text14" >{t("weabout.mpropietarios")}</div>
            </div>

            {/* 6th item */}
            {/* <div className="text-center">
              <div className="h3 mb-1">Millions</div>
              <div className="text-gray-600">Lorem ipsum is placeholder text commonly used tristique senectus et netus.</div>
            </div> */}

          </div>

        </div>
      </div>
    </section>
  );
}

export default Stats;