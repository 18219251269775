import { useState } from "react";
import { useTranslation } from 'react-i18next';
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import EvolutionChart from "./EvolutionChart";
import EvolutionTable from "./EvolutionTable";

export default function EvolutionPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [t, i18n] = useTranslation("global");

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">
                        <div className="mb-8">
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("evolution.title")}</h1>
                        </div>
                        <div className='container'>

                            <div className="evolution-years-block">
                                <div className=" evolution-info-card flex ">
                                    <div className="bg-white rounded-sm border border-gray-200 p-4 flex flex-col justify-content-center" >
                                        <p className="pb-3"> {t("evolution.info1a")} </p>
                                        <p className="pb-3"> {t("evolution.info1b")} </p>
                                        <h3 className="pt-3 pb-2"> {t("evolution.infoTitle")}</h3>
                                        <ul>
                                            <li className="pb-3 pl-2">
                                                <strong>{t("evolution.infoAlcance")}:</strong> {t("evolution.infoAlcanceExp")}
                                            </li>
                                            <li className="pb-3 pl-2">
                                                <strong>{t("evolution.infoActividad")}:</strong> {t("evolution.infoActividadExp")}
                                            </li>
                                        </ul>
                                        <p className="pt-3 text-center">
                                            {t("evolution.infoDespedida")}
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full flex items-stretch">
                                    <div className="w-full flex">
                                        <div className="w-full bg-white rounded-sm border border-gray-200 p-4 flex flex-col justify-content-center" >
                                            <h3 className="text-center font-bold mb-2">{t("evolution.infoAlcance")}</h3>
                                            <EvolutionChart></EvolutionChart>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="w-full bg-white rounded-sm border border-gray-200 p-4 pb-2 flex flex-col justify-content-center" >
                                    <h3 className="text-center font-bold mb-4">{t("evolution.infoActividad")}</h3>
                                    <EvolutionTable></EvolutionTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )

}