import axios from "axios";
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import ReductionCommitmentCreateModal from "./ReductionCommitmentCreateModal";

Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function DecarbonizationTable({ resume, items, updateScenarioItem, updateCurrentScopes1and2, updateCurrentScope3 }) {
    const [t, i18n] = useTranslation("global");

    const [calculationQuesitonEvolutions, setCalculationQuestionEvolutions] = useState({ data: undefined, scopea1and2: undefined, scope3: undefined, isFetching: false });
    const [alcanceActivo, setAlcanceActivo] = useState(1);
    const [reductionCommitmentsList, setReductionCommitmentsList] = useState([]);
    const [newCreatedCommitment, setNewCreatedCommitment] = useState(undefined);


    const [showModalCreate, setShowModalCreate] = useState({ view: false, questionId: undefined });
    const handleSetShowModalCreate = (show, question) => {
        setShowModalCreate({ view: show, questionId: question })
    }

    useEffect(() => {
        if (items !== undefined) {
            try {
                const tablaScope1y2 = items.filter(item => item.scope === 1 || item.scope === 2);
                const tablaScope3 = items.filter(item => item.scope === 3);
                setCalculationQuestionEvolutions({ data: items, scopea1and2: tablaScope1y2, scope3: tablaScope3, isFetching: true })
            } catch (exception) {
                setCalculationQuestionEvolutions({ data: undefined, scopea1and2: undefined, scope3: undefined, isFetching: true })
                console.error(exception);
            }
        }
    }, [items]);

    useEffect(() => {
        const fetchCalculationEvolutions = async () => {
            const url = `${process.env.REACT_APP_CH_REDUCTION}`;
            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });
                setReductionCommitmentsList({ data: response?.data?.data, isFetching: true })
            } catch (exception) {
                setReductionCommitmentsList({ data: undefined, isFetching: false });
                console.error(exception);
            }
        };

        if (!reductionCommitmentsList?.isFetching) {
            fetchCalculationEvolutions();
        }
    }, []);



    const cambiarAlcance = (alcance) => {
        setAlcanceActivo(alcance);
    };

    const handleUpdateScenarioItem = (questionId, reductionCommitmentItem, oldPercent, oldCost) => {
        updateScenarioItem(questionId, reductionCommitmentItem, oldPercent, oldCost);
    }

    const handleAddReductionCommitment = (commitment) => {
        setReductionCommitmentsList(prevState => ({
            ...prevState,
            data: [...prevState.data, commitment],
            isFetching: true
        }));
        setNewCreatedCommitment(commitment.id)
    }

    return (
        <React.Fragment>
            <ReductionCommitmentCreateModal
                showModal={showModalCreate}
                hanldeShow={handleSetShowModalCreate}
                handleAddReductionCommitment={handleAddReductionCommitment} />

            {!calculationQuesitonEvolutions?.isFetching && <div className="text-center"><Spinner className='' /></div>}
            {calculationQuesitonEvolutions?.isFetching && <>
                <div className="mb-3">
                    <ul className="flex flex-wrap -m-1">
                        <li className="m-1">
                            <button onClick={() => cambiarAlcance(1)} className={`${alcanceActivo === 1 ? 'sec-green-background text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                {t("decarbonization.scopes1and2")}
                                <span className={`ml-2 px-2 py-1 rounded-full font-bold text-xs text-airco2-general
                                     ${alcanceActivo === 1 ? 'bg-white' : ' sec-green-background text-white'}`}>
                                    {calculationQuesitonEvolutions?.scopea1and2?.length}
                                </span>
                            </button>
                        </li>
                        <li className="m-1">
                            <button onClick={() => cambiarAlcance(3)} className={`${alcanceActivo === 3 ? 'sec-green-background text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                {t("decarbonization.scope3")}
                                <span className={`ml-2 px-2 py-1 rounded-full font-bold text-xs text-airco2-general
                                     ${alcanceActivo === 3 ? 'bg-white' : ' sec-green-background text-white'}`}>
                                    {calculationQuesitonEvolutions?.scope3?.length}
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="container-simple-table">
                    <table className="simple-table">
                        <thead className="">
                            <tr>
                                <th> <div className="text-left">{t("decarbonization.source")}</div></th>
                                <th> <div className="text-right">{t("decarbonization.percentToReduce")}</div></th>
                                <th> <div className="text-left">{t("decarbonization.reductionList")}</div></th>
                                <th> <div className="text-right">{t("decarbonization.reductionCost")}</div></th>
                                <th> <div className="text-right">{t("decarbonization.reductionPercent")}</div></th>
                                <th> <div className="text-right">{t("decarbonization.baseYear")} <span >({resume.baseYear})</span></div></th>
                                <th> <div className="text-right">{t("decarbonization.currentYear")} <span >({new Date().getFullYear()})</span></div></th>
                                <th> <div className="text-right">{t("decarbonization.targetYear")} <span >({resume.targetYear})</span></div></th>
                                <th> <div className="text-right">{t("decarbonization.currentVsTarget")}<br /><span >({new Date().getFullYear()} vs. {resume.targetYear})</span></div></th>
                                <th> <div className="text-right">{t("decarbonization.currentVsTarget")}<br /><span >({new Date().getFullYear()} vs. {resume.targetYear})</span></div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {alcanceActivo === 1 &&
                                calculationQuesitonEvolutions?.scopea1and2?.map((item) => <FilaTabla
                                    key={item.questionId}
                                    item={item}
                                    scope={alcanceActivo}
                                    scenarioId={resume.id}
                                    reductionCommitmentsList={reductionCommitmentsList}
                                    handleUpdateScenarioItem={handleUpdateScenarioItem}
                                    handleSetShowModalCreate={handleSetShowModalCreate}
                                    newCreatedCommitment={newCreatedCommitment} />)}
                            {alcanceActivo === 3 &&
                                calculationQuesitonEvolutions?.scope3?.map((item) => <FilaTabla
                                    key={item.questionId}
                                    item={item}
                                    scope={alcanceActivo}
                                    scenarioId={resume.id}
                                    reductionCommitmentsList={reductionCommitmentsList}
                                    handleUpdateScenarioItem={handleUpdateScenarioItem}
                                    handleSetShowModalCreate={handleSetShowModalCreate}
                                    newCreatedCommitment={newCreatedCommitment} />)}
                        </tbody>
                    </table>
                </div></>}
        </React.Fragment>
    );


}

function FilaTabla({ item, scope, scenarioId, reductionCommitmentsList, handleUpdateScenarioItem, handleSetShowModalCreate, newCreatedCommitment }) {

    const [showEdit, setShowEdit] = useState(false);
    const [reductionCommitmentPercent, setReductionCommitmentPercent] = useState(0);
    const [itemReductionCommitmentList, setItemReductionCommitmentList] = useState({});
    const [targetCO2e, setTargetCO2e] = useState(0);
    const [currentVsTarget, setCurrentVsTarget] = useState(0);
    const [currentVsTargetPercent, setCurrentVsTargetPercent] = useState(0);
    const [highlightPercent, setHighlightPercent] = useState(false);
    const [highlightReductionCommitment, setHighlightReductionCommitment] = useState(false);
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        if (item !== undefined) {
            setReductionCommitmentPercent(item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje);
            setItemReductionCommitmentList(item?.decarbonizationScenarioItemOutputDtos?.reductionCommitments);
            let target = item.totalKgCO2e - (item.totalKgCO2e * (item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje / 100));
            setTargetCO2e(target);

            if (item.currentKgCO2e && target) {
                setCurrentVsTarget(item.currentKgCO2e - target);
                setCurrentVsTargetPercent(((item.currentKgCO2e - target) / target) * 100);
            }
        }
    }, [item]);

    const updateSelectedReductionCommitmentList = (commitmentsList) => {
        setItemReductionCommitmentList(commitmentsList)
        setHighlightReductionCommitment(true)
        let newItem = item;
        newItem.decarbonizationScenarioItemOutputDtos.reductionCommitments = commitmentsList;
        handleUpdateScenarioItem(item.questionId, item?.decarbonizationScenarioItemOutputDtos, undefined, itemReductionCommitmentList.reduce(
            (acumulador, elemento) => {
                return elemento.hasOwnProperty("averageImplementationCost")
                    ? acumulador + elemento.averageImplementationCost
                    : acumulador;
            },
            0
        ));
    }

    const [isSaving, setIsSaving] = useState(false)
    const addReductionCommitmentpercent = () => {
        if (reductionCommitmentPercent !== undefined) {
            setIsSaving(true)
            const url = `${process.env.REACT_APP_CH_DECARBONIZATION}/${scenarioId}/target`;
            const oldPercent = item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje;
            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${UserService.getToken()}`,
            };
            let data = {
                id: item?.decarbonizationScenarioItemOutputDtos?.id,
                discountPercentaje: reductionCommitmentPercent,
                questionId: item.questionId,
                decarbonizationScenarioId: scenarioId
            };

            try {
                let responsePromise;
                if (data.id !== undefined) {
                    responsePromise = axios.patch(`${url}`, data, { headers });
                } else {
                    responsePromise = axios.post(`${url}`, data, { headers });
                }

                responsePromise.then(response => {
                    handleUpdateScenarioItem(item.questionId, response.data.data, oldPercent, undefined);
                    setReductionCommitmentPercent(undefined);
                    setHighlightPercent(true);
                    setShowEdit(false);
                }).catch(exception => {
                    console.error(exception);
                }).finally(() => {
                    setIsSaving(false);
                });
            } catch (exception) {
                console.error(exception);
            }
        }
    }


    useEffect(() => {
        if (highlightPercent) {
            const timer = setTimeout(() => {
                setHighlightPercent(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [highlightPercent]);


    useEffect(() => {
        if (highlightReductionCommitment) {
            const timer = setTimeout(() => {
                setHighlightReductionCommitment(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [highlightReductionCommitment]);


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            addReductionCommitmentpercent();
        }
    };

    return <tr key={item.questionId} >
        <td className="text-left">
            {item.questionTitle}{scope !== 3 &&
                <><br /><span className="ml-2 text-xs text-airco2-general">{t("dash.alcance")} {item.scope}</span></>}
        </td>
        <td className="text-right">
            <div className="flex items-center justify-end space-x-2">
                {showEdit && <>
                    <div className="relative">
                        <input value={reductionCommitmentPercent}
                            className="text-sm pr-8" type="number" step={"0.01"} min={0.01} max={100}
                            onChange={(e) => setReductionCommitmentPercent(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)} />
                        <div className="absolute inset-0 left-auto flex items-center justify-end pointer-events-none">
                            <span className="text-sm text-gray-400 font-medium px-3">%</span>
                        </div>
                    </div>
                    {!isSaving && <>
                        <button onClick={() => addReductionCommitmentpercent()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                                <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                <path d="M14 4l0 4l-6 0l0 -4" />
                            </svg>
                        </button>
                        <button onClick={() => setShowEdit(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M18 6l-12 12" />
                                <path d="M6 6l12 12" />
                            </svg>
                        </button>
                    </>}
                    {isSaving && <div className="text-center"><Spinner className='' /></div>}
                </>}
                {!showEdit &&
                    <>{item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje ?
                        <> {formatThousands(item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje)}<span className="text-xs">%</span></>
                        :
                        <butons>Añadir reducción </butons>
                    }
                        <button onClick={() => setShowEdit(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                <path d="M16 5l3 3" />
                            </svg>
                        </button>
                    </>
                }

            </div>
        </td>
        <td className="text-left">{item?.decarbonizationScenarioItemOutputDtos?.discountPercentaje &&
            <ReductionCommitmentsBlocks
                reductionCommitments={itemReductionCommitmentList}
                scenarioId={scenarioId}
                targetId={item?.decarbonizationScenarioItemOutputDtos?.id}
                reductionCommitmentsList={reductionCommitmentsList}
                questionId={item.questionId}
                handleSetShowModalCreate={handleSetShowModalCreate}
                newCommitment={newCreatedCommitment}
                updateSelectedReductionCommitmentList={updateSelectedReductionCommitmentList}>
            </ReductionCommitmentsBlocks>}

        </td>

        <td className={`text-right ${highlightReductionCommitment ? 'highlight' : ''}`}>
            {itemReductionCommitmentList?.length > 0 ? (
                <>
                    {itemReductionCommitmentList.reduce(
                        (acumulador, elemento) => {
                            return elemento.hasOwnProperty("averageImplementationCost")
                                ? acumulador + elemento.averageImplementationCost
                                : acumulador;
                        },
                        0
                    )}
                    <span className="text-xs">€</span>
                </>
            ) : null}
        </td>
        <td className={`text-right ${highlightReductionCommitment ? 'highlight' : ''}`}>
            {itemReductionCommitmentList?.length > 0 ? (
                <>
                    {itemReductionCommitmentList.reduce(
                        (acumulador, elemento) => {
                            return elemento.hasOwnProperty("averagePercentageReduction")
                                ? acumulador + elemento.averagePercentageReduction
                                : acumulador;
                        },
                        0
                    )}
                    <span className="text-xs">%</span>
                </>
            ) : null}
        </td>


        <td className="text-right">{item.totalKgCO2e ? <>{formatThousands(item.totalKgCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className="text-right">{item.currentKgCO2e ? <>{formatThousands(item.currentKgCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className={`text-right ${highlightPercent ? 'highlight' : ''}`}>{targetCO2e ? <>{formatThousands(targetCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className={`text-right ${highlightPercent ? 'highlight' : ''}`}>{currentVsTarget ? <span className={`${currentVsTarget < 0 ? "down" : "up"}`}>{formatThousands(currentVsTarget)}<span className="text-xs">kgCO<sub>2</sub></span></span> : "-"} </td>
        <td className={`text-right ${highlightPercent ? 'highlight' : ''}`}>{currentVsTargetPercent ? <span className={`${currentVsTargetPercent < 0 ? "down" : "up"}`}>{formatThousands(currentVsTargetPercent)}<span className="text-xs">%</span></span> : "-"} </td>

    </tr>
}


const ReductionCommitmentsBlocks = ({ reductionCommitments, reductionCommitmentsList, scenarioId, targetId, questionId, handleSetShowModalCreate, newCommitment, updateSelectedReductionCommitmentList }) => {
    const [chips, setChips] = useState([]);
    const [inputValue, setInputValue] = useState(undefined);
    const [t, i18n] = useTranslation("global");
    const [showAdd, setShowAdd] = useState(false);


    useEffect(() => {
        if (reductionCommitments !== undefined && reductionCommitments.length > 0) {
            setChips(reductionCommitments)
        }
    }, [reductionCommitments]);

    useEffect(() => {
        if (newCommitment !== undefined) {
            setInputValue(newCommitment)
        }
    }, [newCommitment]);


    const addChip = () => {
        if (addReductionCommitment(inputValue)) {
            if (inputValue !== undefined) {
                const nuevoChip = reductionCommitmentsList.data.find(x => x.id == inputValue);

                const chipsActualizados = [...chips, nuevoChip];
                setChips(chipsActualizados);
                updateSelectedReductionCommitmentList(chipsActualizados);
                setInputValue(undefined);
            }
        }
    };

    const removeChip = (index, id) => {
        if (deleteReductionCommitment(id)) {
            const chipsActualizados = chips.filter((_, i) => i !== index)
            setChips(chipsActualizados);
            updateSelectedReductionCommitmentList(chipsActualizados);
        }
    };

    const deleteReductionCommitment = (reductionCommitmentId) => {
        const url = `${process.env.REACT_APP_CH_DECARBONIZATION}/${scenarioId}/target/${targetId}/reduction-commitment/${reductionCommitmentId}`;
        try {
            const response = axios.delete(`${url}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            });
            return true;
        } catch (exception) {
            console.error(exception);
            return false;
        }
    }

    const addReductionCommitment = (reductionCommitmentId) => {
        const url = `${process.env.REACT_APP_CH_DECARBONIZATION}/${scenarioId}/target`;
        let data = {
            reductionCommitmentsids: [reductionCommitmentId],
            id: targetId,
            questionId: questionId
        }
        try {
            const response = axios.patch(`${url}`, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            });
            return true;
        } catch (exception) {
            console.error(exception);
            return false;
        }
    }

    const filterUsedItems = (originalList, usedList) => {
        const usedIds = new Set(usedList.map(item => item.id));
        return originalList.filter(item => !usedIds.has(item.id));
    };


    return (
        <div>
            <div className="flex flex-wrap gap-1 mb-3">
                {chips?.map((chip, index) => (
                    <div
                        key={index}
                        className="chip flex items-center sec-green-background text-white text-xs px-3 py-1 rounded-full"
                        title={chip.name}
                    >
                        {chip.name.length > 20 ? `${chip.name.slice(0, 20)}...` : chip.name}
                        <button
                            className="ml-2 text-sm text-red-500 hover:text-red-700"
                            onClick={() => removeChip(index, chip.id)}
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>
            <div className="flex flex-wrap">
                <div>
                    <button className="text-xs flex items-center" onClick={() => setShowAdd(!showAdd)}>
                        {showAdd ? <>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-minus mr-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                <path d="M9 12l6 0" />
                            </svg>
                            {t("decarbonization.hideNewReductionCommitments")}</> : <>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                <path d="M9 12h6" />
                                <path d="M12 9v6" />
                            </svg> {t("decarbonization.showNewReductionCommitments")}</>}
                    </button>
                </div>
                {showAdd && <div className="flex">
                    <select
                        className="w-full text-sm"
                        type="text"
                        value={inputValue}
                        onChange={(e) => e.target.value === "nuevo" ? handleSetShowModalCreate(true, questionId) : setInputValue(e.target.value)}
                    >
                        <option value="">{t("decarbonization.selectReductionCommitment")}</option>
                        <option value="nuevo" className="font-bold"> {t("decarbonization.createReductionCommitment")}</option>

                        {filterUsedItems(reductionCommitmentsList?.data, chips).filter(item => item.questionId === questionId || item.questionId === undefined).map(x =>
                            <option key={x.id} value={x.id}>
                                {x.name.charAt(0).toUpperCase() + x.name.slice(1).toLowerCase()}
                            </option>)}

                    </select>
                    <button
                        className="btn-banner-o text-white px-4 py-2 ml-2"
                        onClick={addChip}
                    >
                        {t("decarbonization.addReductionCommitment")}
                    </button>
                </div>
                }
            </div>
        </div>
    );
};
